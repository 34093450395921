import { Badge, Button } from '@finalytic/components';
import {
  showApiErrorNotification,
  useApiClient,
  useApiMutation,
  useGqtyClient,
  useInvalidateQueries,
  useTeamRole,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import type { currency_enum } from '@finalytic/graphql';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  CreditCardIncomeIcon,
  HashtagIcon,
  LoaderIcon,
  NoteTextIcon,
  OfficeIcon,
} from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuCopyItem,
  EllipsisMenuDivider,
  type ExtendedCustomColors,
} from '@finalytic/ui';
import {
  type Maybe,
  day,
  formatCurrency,
  sum,
  toTitleCase,
} from '@finalytic/utils';
import {
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Title,
  Tooltip,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { EllipsisMenuSourceDownloadItem } from '../../components';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useDepositDetailDrawer } from '../deposit-drawer';
import { useExpenseDetailDrawer } from '../expense-drawer';
import { useBankRecordDetailDrawer } from './useBankRecordDetailDrawer';

function useBankRecordQuery(id: Maybe<string>) {
  const $api = useApiClient();
  const $db = useGqtyClient();

  const query = useTanstackQuery({
    queryFn: async () => {
      if (!id) return null;

      const [bankRecord, bankAccount] = await Promise.all([
        $api.GET('/bank-records/{id}', {
          params: {
            path: {
              id,
            },
          },
        }),
        $db.query(
          (q) =>
            q
              .bankAccounts({
                where: {
                  bankRecords: {
                    id: {
                      _eq: id,
                    },
                  },
                },
                limit: 1,
                order_by: [
                  {
                    id: 'asc_nulls_last',
                  },
                ],
              })
              .map((bankAccount) => ({
                id: bankAccount.id,
                title: bankAccount.name,
                currency: bankAccount.currency,
                category: bankAccount.category,
                type: bankAccount.type,
                connectionId: bankAccount.connectionId,
              }))[0]
        ),
      ]);

      if (bankRecord.error) {
        showApiErrorNotification({
          error: bankRecord.error,
          title: 'Failed to fetch bank record',
          defaultMessage:
            'We failed to fetch the bank record. Please try again and contact support if the issue persists.',
        });

        throw new Error(bankRecord.error.message);
      }

      return bankRecord.data
        ? { ...bankRecord.data, bankAccount, currency: bankAccount.currency }
        : null;
    },
    // initialData: null,
    queryKey: ['bankRecords', id],
    enabled: !!id,
  });

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type BankRecord = NonNullable<ReturnType<typeof useBankRecordQuery>['data']>;

type PaidStatus = NonNullable<
  NonNullable<BankRecord['reconciliation']>['status']
>;

const BAGDE_OPTIONS: Record<
  PaidStatus,
  { label: string; color: ExtendedCustomColors }
> = {
  overpaid: {
    label: 'Overpaid',
    color: 'red',
  },
  paid: {
    label: 'Matched',
    color: 'green',
  },
  unpaid: {
    label: 'Unmatched',
    color: 'yellow',
  },
  underpaid: {
    label: 'Underpaid',
    color: 'yellow',
  },
};

export const BankRecordStatusBadge = ({
  status,
}: {
  status: Maybe<PaidStatus>;
}) => {
  if (!status || !BAGDE_OPTIONS[status]) return null;

  return (
    <Badge color={BAGDE_OPTIONS[status].color} size="md">
      {BAGDE_OPTIONS[status].label}
    </Badge>
  );
};

export const BankRecordDetailDrawer = () => {
  const { opened, close, bankRecordId } = useBankRecordDetailDrawer();
  const { isLoading, data: bankRecord } = useBankRecordQuery(bankRecordId);
  const { isVrpAdmin } = useTeamRole();

  return (
    <Drawer opened={opened} onClose={close} size={550}>
      <DrawerHeader
        closeDrawer={close}
        loading={isLoading}
        type="Bank record"
        title={
          bankRecord && (
            <Group wrap="nowrap" gap="xs" mt={rem(5)}>
              <Box>
                <Title order={4} fw={500} component="p" m={0}>
                  {bankRecord.description}
                </Title>
              </Box>
            </Group>
          )
        }
        menuItems={
          bankRecord &&
          isVrpAdmin && (
            <HiddenFeatureIndicator permission="vrp-admin">
              <EllipsisMenuDivider />

              <EllipsisMenuCopyItem value={bankRecord.id} />

              {!!bankRecord.bankAccount.connectionId && (
                <EllipsisMenuSourceDownloadItem
                  fileName={bankRecord.uniqueRef || bankRecord.id}
                  where={{
                    bankRecord: {
                      id: {
                        _eq: bankRecord.id,
                      },
                    },
                  }}
                />
              )}
            </HiddenFeatureIndicator>
          )
        }
      />
      {!bankRecord && !isLoading ? (
        <Center>
          <Text c="gray" size="xs" ta="center">
            No details found
          </Text>
        </Center>
      ) : (
        <Content bankRecord={bankRecord} isLoading={isLoading} />
      )}
    </Drawer>
  );
};

const Content = ({
  bankRecord,
  isLoading,
}: { bankRecord: Maybe<BankRecord>; isLoading: boolean }) => {
  if (!bankRecord) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: CircleDollarIcon,
            title: 'Amount',
            text: formatCurrency(bankRecord.amount / 100, bankRecord.currency),
          },
          {
            icon: LoaderIcon,
            title: 'Status',
            text:
              bankRecord.bankAccount.category === 'trust' ? (
                <BankRecordStatusBadge
                  status={bankRecord.reconciliation?.status}
                />
              ) : null,
          },
          {
            icon:
              bankRecord.bankAccount.type === 'creditCard'
                ? CreditCardIncomeIcon
                : OfficeIcon,
            title: 'Bank account',
            text: bankRecord.bankAccount.title,
          },
          {
            icon: CalendarEventIcon,
            title: 'Date',
            text: day(bankRecord.date).format('MMM D, YYYY'),
          },
          {
            icon: HashtagIcon,
            title: 'Reference',
            text: bankRecord.uniqueRef,
          },
          {
            icon: NoteTextIcon,
            title: 'Description',
            text: bankRecord.description,
          },
        ]}
      />

      <Lines
        lines={bankRecord.reconciliation.transactions ?? []}
        currency={bankRecord.currency}
        bankRecordId={bankRecord.id}
      />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type ExpenseLine = NonNullable<
  BankRecord['reconciliation']['transactions']
>[number];

const Lines = ({
  lines,
  currency,
  bankRecordId,
}: {
  lines: ExpenseLine[];
  currency: currency_enum | undefined;
  bankRecordId: string;
}) => {
  const { open: openExpense } = useExpenseDetailDrawer();
  const { open: openDeposit } = useDepositDetailDrawer();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const total = useMemo(
    () => formatCurrency(sum(lines, (x) => x.amount) / 100, currency),
    [currency]
  );

  const invalidate = useInvalidateQueries([
    'bankRecords',
    'expenses',
    'deposits',
    'transactions',
  ]);

  const { isPending: loadingMutation, mutateAsync } = useApiMutation(
    'put',
    '/bank-records/{id}',
    {
      onError: (error: any) => {
        showApiErrorNotification({
          title: 'Failed to update bank records',
          defaultMessage:
            'Failed to update bank records. Please reach out to support if the issue persists.',
          error,
        });
      },
      onSuccess: () => {
        invalidate();
      },
    }
  );

  const columns = useMemo<MRT_ColumnDef<ExpenseLine>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
      },
      {
        header: 'Badge',
        size: 100,
        Cell: ({ row }) => {
          const line = row.original;

          return <Badge>{toTitleCase(line.type)}</Badge>;
        },
      },
      {
        header: 'Split',
        accessorKey: 'centTotal',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          sx: (theme) => ({
            paddingRight: theme.spacing.xs,
            fontWeight: 'bold',
          }),
        },
        size: 100,
        Cell: ({ row }) => {
          return formatCurrency(row.original.amount / 100, currency);
        },
        Footer: !lines.length ? undefined : () => total,
      },
    ],
    [currency, lines.length, total]
  );

  return (
    <DrawerCollapsableTable
      title="Matched transactions"
      rightSection={
        lines.length && (
          <Tooltip label="Reset all matched transactions" withArrow>
            <Button
              variant="light"
              loading={loadingMutation}
              size="xs"
              sx={(theme) => ({
                paddingInline: theme.spacing.xs,
                paddingTop: 0,
              })}
              onClick={async () => {
                await mutateAsync({
                  params: {
                    path: {
                      id: bankRecordId,
                    },
                  },
                  body: {
                    reconciledTransactionIds: [],
                  },
                });
              }}
              rightIcon={'CrossCircleIcon'}
              rightIconSize={14}
            >
              Remove
            </Button>
          </Tooltip>
        )
      }
      onRowClick={{
        handler: (row) => {
          if (row.original.type === 'expense')
            return openExpense(row.original.id, 'push');

          if (row.original.type === 'deposit')
            openDeposit(row.original.id, 'push');
        },
        disabled: (row) => !row.original.type,
      }}
      rowData={lines}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No matched transactions
          </Text>
        </Center>
      )}
    />
  );
};
