import { unparse } from 'papaparse';

export const getCsvUrl = (
  rows: Record<string, string | number | undefined | null | boolean>[]
) => {
  const csv = unparse(rows, {
    quotes: true,
    escapeChar: '/',
    skipEmptyLines: true,
  });
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  return window.URL.createObjectURL(csvData);
};
