import { Button, IconButton, InputTextarea } from '@finalytic/components';
import { useEnabledFeatures, useMe } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { showSuccessNotification } from '@finalytic/ui';
import { Group, Button as MantineButton, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import * as Sentry from '@sentry/react';
import { formatUserName } from '@vrplatform/ui-common';
import { Controller, useForm } from 'react-hook-form';
import { useNavbarExpanded } from '../../../hooks';

export const NavbarIssueReportButton = () => {
  const { LIVEBLOCKS_NOTIFICATIONS } = useEnabledFeatures();
  const [opened, handlers] = useDisclosure(false);
  const { isDesktopExpanded } = useNavbarExpanded();
  const { firstName, lastName, email } = useMe();

  type FormInputs = {
    issue: string;
  };

  const methods = useForm<FormInputs>();

  const closeModal = () => {
    handlers.close();
    methods.reset({
      issue: '',
    });
  };

  const handleSubmit = (data: FormInputs) => {
    const event_id = Sentry.captureMessage(`Issue reported: ${data.issue}`, {
      tags: {
        category: 'Feedback',
      },
      fingerprint: [new Date().toISOString() + Math.random()],
    });

    Sentry.captureFeedback({
      name: formatUserName({
        firstName,
        lastName,
      }),
      email,
      message: data.issue,
      associatedEventId: event_id,
    });

    showSuccessNotification({
      title: 'Issue reported',
      message: 'We will get back to you soon.',
    });

    closeModal();
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={closeModal}
        title="Report issue"
        size={500}
        centered
        styles={{
          title: {
            fontWeight: 500,
          },
        }}
      >
        <form
          onSubmit={methods.handleSubmit(handleSubmit)}
          onReset={closeModal}
        >
          <Controller
            control={methods.control}
            name="issue"
            rules={{
              validate: (value) =>
                value.trim().length > 0 || 'Please describe your issue',
            }}
            render={({ field, fieldState: { error } }) => (
              <InputTextarea
                mt={3}
                mb="xl"
                {...field}
                placeholder="E.g. Noticed a bug, missing feature, etc."
                error={error?.message}
              />
            )}
          />
          <Group>
            <Button type="reset">Cancel</Button>
            <Button
              variant="primary"
              type="submit"
              sx={{
                flex: 1,
              }}
            >
              Submit
            </Button>
          </Group>
        </form>
      </Modal>

      {LIVEBLOCKS_NOTIFICATIONS ? (
        <IconButton
          icon="AlertTriangleIcon"
          tooltip="Report issue"
          onClick={handlers.open}
          color={(theme) => theme.colors.neutral[3]}
        />
      ) : (
        <MantineButton
          onClick={handlers.open}
          variant="light"
          color="white"
          sx={{
            background: 'none',
            border: 'none',
            height: 40,
            '&:disabled': {
              background: 'transparent',
            },
          }}
          fw={500}
          radius={'md'}
          styles={{
            label: isDesktopExpanded
              ? {
                  marginRight: 'auto',
                  opacity: '1 !important',
                }
              : {
                  fontSize: 16,
                  opacity: '1 !important',
                },
            rightIcon: {
              fontSize: 16,
            },
          }}
          px="xs"
          mx="xs"
          mb={4}
          size="xs"
          leftSection={
            isDesktopExpanded && (
              <Icon
                icon="AlertTriangleIcon"
                size={16}
                color={(theme) => theme.white}
              />
            )
          }
        >
          {isDesktopExpanded ? (
            'Report issue'
          ) : (
            <Icon
              icon="AlertTriangleIcon"
              size={16}
              color={(theme) => theme.white}
            />
          )}
        </MantineButton>
      )}
    </>
  );
};
