import { AmountFilter } from './AmountFilter';
import {
  type CalendarFilterDateType,
  type CheckInOutType,
  DateFilter,
} from './DateFilter';
import { MonthFilter } from './MonthFilter';
import { SearchFilter } from './SearchFilter';
import { SelectFilter } from './SelectFilter';
import { StatusFilter } from './StatusFilter';

export type { CalendarFilterDateType, CheckInOutType };

export const Filter = {
  Search: SearchFilter,
  Select: SelectFilter,
  Date: DateFilter,
  Amount: AmountFilter,
  Month: MonthFilter,
  Status: StatusFilter,
};
