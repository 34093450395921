import { StringParam, type UrlUpdateType, useQueryParams } from '@finalytic/ui';

export function useTransferDetailDrawer() {
  const [opts, set] = useQueryParams({
    transfer: StringParam,
  });

  return {
    opened: !!opts.transfer,
    open: (id: string, type?: UrlUpdateType) => set({ transfer: id }, type),
    close: () => set({ transfer: undefined }),
    transferId: opts.transfer,
  };
}
