import type { SelectItem } from '@finalytic/ui';
import { sortBy } from '@finalytic/utils';
import { Box, Popover } from '@mantine/core';
import type { useDisclosure } from '@mantine/hooks';
import { useCallback, useMemo, useState } from 'react';
import type { JSX } from 'react';
import {
  type DropdownProps,
  type InfiniteDataProps,
  SelectDropdown,
} from './SelectDropdown';
import type { CustomAction, PinnedItems, SelectValueProps } from './_types';

type BasicData<TSelectValue extends string> = {
  loading?: boolean;
  error?: Error | null;
  options: SelectItem<TSelectValue>[];
  sort?: 'asc' | 'desc' | null;
  onSearchChange?: (search: string) => void;
};

export type SelectDataProps<TSelectValue extends string> =
  | {
      data: BasicData<TSelectValue>;
      infiniteData?: undefined;
    }
  | {
      data?: undefined;
      infiniteData: InfiniteDataProps<TSelectValue>;
    };

export type SelectProps<TSelectValue extends string> = {
  dropdownProps?: DropdownProps;
  inputProps?: InputProps;
} & SelectValueProps<TSelectValue> &
  SelectDataProps<TSelectValue> &
  CustomAction &
  PinnedItems;

type InputProps = {
  placeholder?: string;
  placeholderColor?: string;
  loadingQuery?: boolean;
  loadingMutation?: boolean;
  withClearButton?: boolean;
  withBorder?: boolean;
  disabled?: boolean;
  width?: number;
  error?: boolean | string;
  backgroundColor?: string;
  lineClamp?: number;
};

export type SelectInputProps<TSelectValue extends string> = InputProps & {
  opened: boolean;
  handlers: ReturnType<typeof useDisclosure>[1];
} & SelectValueProps<TSelectValue>;

export const Select = <TSelectValue extends string = string>({
  value,
  setValue,
  type,
  dropdownProps,
  inputProps,
  children: Children,
  ...rest
}: SelectProps<TSelectValue> & {
  children: (props: SelectInputProps<TSelectValue>) => JSX.Element;
}) => {
  const [opened, setOpened] = useState(false);

  const handleSetValue = (updated: any, value: any) => {
    setValue(updated, value);

    if (Array.isArray(updated) && updated.length === 0) {
      return setOpened(false);
    }

    if (!dropdownProps?.preventCloseOnSelect) {
      setOpened(false);
    }
  };

  const onChange = useCallback(
    (v: boolean) => {
      setOpened(v);
      rest.infiniteData?.setSearch?.('');
    },
    [rest.infiniteData?.setSearch]
  );

  const customActionTop = useMemo(
    () =>
      rest.customActionTop
        ? {
            ...rest.customActionTop,
            onSubmit: (search: string) => {
              rest.customActionTop?.onSubmit?.(search);
              setOpened(false);
            },
          }
        : undefined,
    [rest.customActionTop]
  );

  const customBottomActions = useMemo(
    () =>
      rest.customBottomActions
        ? rest.customBottomActions.map((action) => ({
            ...action,
            onSubmit: (search: string) => {
              action.onSubmit?.(search);
              setOpened(false);
            },
          }))
        : undefined,
    [rest.customBottomActions]
  );

  const handlers = useMemo(
    () => ({
      toggle: () => setOpened((v) => !v),
      close: () => setOpened(false),
      open: () => setOpened(true),
    }),
    []
  );

  return (
    <Popover
      withinPortal={dropdownProps?.withinPortal}
      opened={
        inputProps?.loadingMutation || inputProps?.loadingQuery ? false : opened
      }
      onChange={onChange}
      shadow="md"
      radius="md"
      disabled={inputProps?.disabled}
      width={dropdownProps?.width || 300}
      position={dropdownProps?.position}
      zIndex={dropdownProps?.zIndex}
    >
      <Popover.Target>
        <Box
          onClick={(e) => {
            e.stopPropagation();
            if (!inputProps?.disabled) setOpened((v) => !v);
          }}
        >
          {Children({
            type,
            value: value as any,
            setValue: handleSetValue,
            opened,
            handlers,
            ...inputProps,
          })}
        </Box>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          padding: 0,
          overflow: 'hidden',
        }}
      >
        {!rest.data ? (
          <SelectDropdown
            value={value}
            setValue={handleSetValue}
            {...dropdownProps}
            {...rest.infiniteData}
            {...rest}
            customActionTop={customActionTop}
            customBottomActions={customBottomActions}
          />
        ) : (
          <BasicOptions
            value={value as any}
            setValue={handleSetValue}
            dropdownProps={dropdownProps}
            {...rest}
            data={rest.data}
            type={type}
            customActionTop={customActionTop}
            customBottomActions={customBottomActions}
          />
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

const BasicOptions = <TSelectValue extends string>({
  value,
  setValue,
  data: dataProps,
  dropdownProps,
  // type,
  customBottomActions,
  customActionTop,
  pinnedItems,
}: {
  data: BasicData<TSelectValue>;
  dropdownProps: DropdownProps | undefined;
} & SelectValueProps<TSelectValue> &
  CustomAction &
  PinnedItems) => {
  const [search, setSearch] = useState('');
  const trimmed = search?.trim();

  const sorted = useMemo(() => {
    const opts = dataProps?.options || [];

    if (!dataProps.sort) return opts;

    return sortBy(opts, 'label', dataProps.sort);
  }, [dataProps.sort, dataProps.options]);

  const filtered = useMemo(() => {
    if (!trimmed) return sorted;

    return sorted.filter(
      (option) => {
        return option.label?.toLowerCase().includes(trimmed?.toLowerCase());
      },
      [trimmed]
    );
  }, [trimmed, sorted]);

  const data = useMemo(
    () => ({
      pageParams: [],
      pages: [
        {
          list: filtered,
          aggregate: filtered.length,
        },
      ],
    }),
    [filtered]
  );

  return (
    <SelectDropdown
      value={value}
      setValue={setValue} // overwrite setValue type
      error={dataProps.error || null}
      data={data}
      fetchNextPage={async () => {}}
      hasNextPage={false}
      // isFetching={dataProps.loading || false}
      isFetching={dataProps.loading ?? false}
      setSearch={dataProps.onSearchChange || setSearch}
      isFetchingNextPage={false}
      customBottomActions={customBottomActions}
      customActionTop={customActionTop}
      pinnedItems={pinnedItems}
      {...dropdownProps}
    />
  );
};
