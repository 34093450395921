import { type gqlV2, useQuery, useTeam } from '@finalytic/data';
import { type Maybe, ensure } from '@finalytic/utils';
import { whereSettingAcrossAutomations } from '@vrplatform/ui-common';
import { useAutomationsQuery } from '../queries';

export function useSingleSettingByTargetQuery({
  automationId,
  targetId,
  mapping: {
    settingKey,
    isLocalOverwrite,
    leftType,
    rightType,
    parentSettingId,
  },
}: {
  automationId: string | undefined;
  targetId: string | undefined;
  mapping: {
    settingKey: Maybe<string>;
    leftType: Maybe<string>;
    rightType: Maybe<string>;
    parentSettingId: Maybe<string>;
    isLocalOverwrite?: boolean;
  };
}) {
  const [{ id: teamId }] = useTeam();
  const { data: automations = [], isLoading: loadingAutomations } =
    useAutomationsQuery();
  const automation = automations.find((i) => i.automationId === automationId);

  const defaultIsLocalOverwrite =
    automation?.mappings?.[settingKey || '']?.scope === 'local';

  const types = {
    leftConnectionId: automation?.leftConnectionId,
    rightConnectionId: automation?.rightConnectionId,
    leftType: leftType || automation?.mappings[settingKey || ''].left.schema,
    rightType: rightType || automation?.mappings[settingKey || ''].right.schema,
  };

  const isLocal: boolean = (() => {
    if (typeof isLocalOverwrite === 'boolean') return isLocalOverwrite;

    if (settingKey) {
      return (
        automation?.viewSettings?.[settingKey]?.isLocal ??
        defaultIsLocalOverwrite
      );
    }

    return false;
  })();

  const {
    data,
    isLoading: loading,
    refetch,
    ...queryProps
  } = useQuery(
    (q, args) => {
      const setting =
        q
          .setting({
            where: args.whereSettings,
            order_by: [{ updated_at: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((setting) => ({
            settingId: setting.id,
            value: setting.value,
          }))[0] || null;

      return { setting };
    },
    {
      skip: Object.values(types).some((i) => !i),
      queryKey: 'settings',
      variables: {
        whereSettings:
          targetId === '*'
            ? ensure<gqlV2.setting_bool_exp>({
                tenant_id: { _eq: teamId },
                key: { _eq: settingKey },
                target: { _eq: '*' },
                localAutomationId: isLocal
                  ? { _eq: automationId }
                  : { _is_null: true },
                _or: whereSettingAcrossAutomations({
                  leftConnectionId: types.leftConnectionId,
                  leftSchema: undefined,
                  rightConnectionId: types.rightConnectionId,
                  rightSchema: types.rightType,
                  automationId: automationId,
                }),
              })
            : ensure<gqlV2.setting_bool_exp>({
                tenant_id: { _eq: teamId },
                key: { _eq: settingKey },
                parentSettingId: parentSettingId
                  ? { _eq: parentSettingId }
                  : { _is_null: true },
                localAutomationId: isLocal
                  ? { _eq: automationId }
                  : { _is_null: true },
                _or: whereSettingAcrossAutomations({
                  leftConnectionId: types.leftConnectionId,
                  leftSchema: types.leftType,
                  rightConnectionId: types.rightConnectionId,
                  rightSchema: types.rightType,
                  automationId: automationId,
                  targetId,
                }),
              }),
      },
    }
  );

  const setting = data?.setting;

  return {
    setting,
    loading: loading || loadingAutomations,
    refetch,
    ...queryProps,
  };
}

// TYPES OF SETTINGS

// MAPPINGS
// ---
// 1. fallback
// target: *
// value: any
// key: mappingKey
// leftType: mapping.left.schema
// rightType: mapping.right.schema
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: null
// localAutomationId: automation.id | null

// 2. default exceptions
// target: any
// value: any
// key: mappingKey
// leftType: !== mapping.left.schema (finalytic.XXX)
// rightType: mapping.right.schema
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: null
// localAutomationId: automation.id | null

// 3. mappings
// target: any
// value: any
// key: mappingKey
// leftType: mapping.left.schema
// rightType: mapping.right.schema
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: null
// localAutomationId: automation.id | null

// 4. mapping child exceptions
// target: any
// value: any
// key: mappingKey
// leftType: !== mapping.left.schema (finalytic.XXX)
// rightType: mapping.right.schema
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: setting.id
// localAutomationId: automation.id | null

// AUTOMATION_SETTINGS
// ---
// 1. Global
// target: *
// value: any
// key: settingKey
// leftType: "automationSetting"
// rightType: null
// leftConnectionId: finalyticConnectionId
// rightConnectionId: accountingConnectionId
// parentSettingId: null

// 2. Local
// target: *
// value: any
// key: settingKey
// leftType: "automationSetting"
// rightType: null
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: null

// LISTING_DISABLE_SETTINGS
// ---
// target: listing.id
// value: "true"
// key: "exclude"
// leftType: "finalytic.listing"
// rightType: "schema.boolean"
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null
// automationId: // ! uses automationId as determinator currently

// PARTNER_LINE_MASTER
// ---
// 1. Accounting type
// target: lineClassification.id
// value: "invoice" | "journalEntry" | "exclude"
// group: "ximplifi" // ! uses legacy group
// key: "exclude"
// leftType: "finalytic.lineType"
// rightType: null
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null
// partner: partnerTeamName.toLowerCase()

// 2. Accounting type exceptions
// target: bookingChannel.id
// value: "invoice" | "journalEntry" | "exclude"
// group: "ximplifi" // ! uses legacy group
// key: "exclude"
// leftType: "finalytic.bookingChannel"
// rightType: "finalytic.accountingType"
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: setting.id
// partner: partnerTeamName.toLowerCase()

// 3. Description
// target: lineClassification.id
// value: description (TEXT)
// group: "ximplifi" // ! uses legacy group
// key: "exclude"
// leftType: "finalytic.lineType"
// rightType: null
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null
// partner: partnerTeamName.toLowerCase()

// TENANT_SETTINGS
// ---
// 1. Owner portal show reservations
// target: "showReservations"
// value: null
// key: "tenantSettings"
// group: "ownerPortal"
// leftType: "ownerPortal"
// rightType: null
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null

// 2. Owner portal show reservations
// target: "forceTwoFactorAuth"
// value: null
// key: "tenantSettings"
// group: "pmDashboard"
// leftType: "pmDashboard"
// rightType: null
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null
