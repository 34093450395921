import {
  Button,
  InputSelect,
  InputWrapper,
  Select,
} from '@finalytic/components';
import {
  useApiMutation,
  useBrowserTracking,
  useInvalidateQueries,
  useQuery,
} from '@finalytic/data';
import { ChevronIcon, Icon, Send2Icon, UsersIcon } from '@finalytic/icons';
import {
  type ExtendedCustomColors,
  LoadingIndicator,
  type SelectItem,
  showErrorNotification,
  showWarnNotification,
} from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import {
  Badge,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Title,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { formatOwnerName, orderByContact } from '@vrplatform/ui-common';
import { type JSX, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DrawerInfoCard } from '../../../drawers/_components';
import type { OwnerStatement } from '../statement/_types';

type OwnerStatementStatus = OwnerStatement['status'];

type StatementProps = {
  statementId: Maybe<string>;
  periodId: Maybe<string>;
  month: string;
  status: OwnerStatementStatus;
  listingId: string;
  hasNoRows: boolean;
  currency: string;
} | null;

type Props = {
  statement: StatementProps;
  type: 'button' | 'badge';
};

function useStatusMutation() {
  const { track } = useBrowserTracking();

  const invalidate = useInvalidateQueries(['ownerStatements']);

  const onError = (error: any) => {
    showErrorNotification({
      title: 'Failed to update status',
      message:
        error?.message ||
        "We couldn't update the status of the statement. Please try again and contact support if the issue persists.",
    });
  };

  const onSettled = () => {
    invalidate();
  };

  const { mutateAsync: update, isPending: loadingUpdate } = useApiMutation(
    'put',
    '/statements/{id}',
    {
      onError,
      onSettled,
    }
  );

  const { mutateAsync: insert, isPending: loadingInsert } = useApiMutation(
    'post',
    '/statements',
    {
      onError,
      onSettled,
    }
  );

  return {
    loading: loadingInsert || loadingUpdate,
    updateStatus: async (
      input: StatementProps & {
        status: Exclude<OwnerStatementStatus, 'posted'>;
        notification: Notification;
        periodId: string;
      }
    ) => {
      const newStatus = input.status === 'preview' ? 'draft' : input.status;

      try {
        if (input.statementId) {
          await update({
            params: {
              path: {
                id: input.statementId,
              },
            },
            body: {
              status: newStatus,
            },
          });
        } else {
          await insert({
            body: {
              listingPeriodIds: [input.periodId],
              month: input.month,
              status: newStatus,
            },
          });
        }

        track('owner_statement_status_changed', {
          ownerStatementId: input.statementId,
          status: newStatus,
        });
      } catch (error) {
        console.error(error);
      } finally {
        invalidate();
      }
    },
  };
}

const STATUS_COLORS: Record<OwnerStatementStatus, ExtendedCustomColors> = {
  void: 'gray',
  preview: 'yellow',
  draft: 'yellow',
  inReview: 'blue',
  published: 'green',
};

export const LedgerStatementStatusSelect = ({ type, statement }: Props) => {
  const { loading, updateStatus } = useStatusMutation();
  const [open, handlers] = useDisclosure(false);

  const updateStatement = (newStatus: OwnerStatementStatus) => {
    if (!statement) return;

    if (!statement.periodId)
      return showWarnNotification({
        title: 'Missing ownership',
        message: 'Please reach out to support if the issue persists.',
      });

    const {
      month,
      periodId,
      statementId,
      status: currentStatus,
      listingId,
    } = statement;

    if (newStatus === currentStatus) return;

    const isModalConfirm = newStatus === 'published';

    if (isModalConfirm) {
      handlers.open();
    } else {
      updateStatus({
        month,
        periodId,
        statementId,
        status: newStatus,
        listingId,
        hasNoRows: false,
        notification: 'disabled',
        currency: statement.currency,
      });
    }
  };

  const options = useMemo(() => ledgerStatementStatusSelectOptions, []);

  if (statement?.hasNoRows || !statement?.periodId) {
    const label = statement?.hasNoRows
      ? 'Missing net revenue'
      : 'Missing ownership';

    if (type === 'button') {
      return (
        <Button disabled rightIcon={'ChevronIcon'}>
          {label}
        </Button>
      );
    }

    return (
      <Badge
        variant="light"
        color={'gray'}
        component="button"
        disabled
        sx={(theme) => ({
          paddingInline: rem(5),
          textTransform: 'none',
          fontWeight: 500,
          color: theme.colors.gray[9],
          cursor: 'not-allowed',
        })}
      >
        {label}
      </Badge>
    );
  }

  return (
    <>
      <StatusConfirmModal
        opened={open}
        closeModal={handlers.close}
        statement={statement}
      />
      <Select
        data={{
          options: options.filter((x) => x.value !== 'preview'),
          sort: null,
        }}
        dropdownProps={{
          position: 'bottom-end',
          width: 300,
          withinPortal: true,
        }}
        inputProps={{
          loadingMutation: loading,
          disabled: !statement || statement.hasNoRows || !statement.periodId,
        }}
        type="single"
        value={options.find((o) => o.value === statement?.status) || null}
        setValue={(_, update) => {
          if (update.type === 'removed') return;

          updateStatement(update.value.value as OwnerStatementStatus);
        }}
      >
        {({ loadingMutation, disabled, value }) => {
          const item =
            (value as SelectItem<OwnerStatementStatus>) || options.at(1);

          const status = item?.value;

          if (type === 'button') {
            return (
              <Button
                disabled={disabled}
                loading={loadingMutation}
                rightIcon={'ChevronIcon'}
                leftIcon={() => item.icon as JSX.Element} // this doesnt make any sense
                sx={(theme) => ({
                  '&, &:hover': {
                    backgroundColor: theme.colors[STATUS_COLORS[status]][0],
                  },
                })}
                loaderProps={{
                  color: STATUS_COLORS[status],
                }}
              >
                {item.label}
              </Button>
            );
          }
          if (loadingMutation) return <LoadingIndicator size="xs" />;

          return (
            <LedgerStatementStatusBadge disabled={disabled} status={status} />
          );
        }}
      </Select>
    </>
  );
};

export const LedgerStatementStatusBadge = ({
  status,
  disabled,
}: {
  status: OwnerStatementStatus;
  disabled: boolean | undefined;
}) => {
  const theme = useMantineTheme();

  const value = ledgerStatementStatusSelectOptions.find(
    (x) => x.value === status
  );

  const item =
    (value as SelectItem<OwnerStatementStatus>) ||
    ledgerStatementStatusSelectOptions.at(1);

  return (
    <Badge
      variant="light"
      component="button"
      disabled={disabled}
      color={STATUS_COLORS[status]}
      leftSection={<Center>{item?.icon}</Center>}
      sx={(theme) => ({
        paddingInline: rem(5),
        textTransform: 'none',
        fontWeight: 500,
        color: theme.colors[STATUS_COLORS[status]][9],
      })}
      rightSection={
        !disabled && (
          <Center>
            <ChevronIcon
              size={14}
              strokeWidth={1.5}
              color={theme.colors[STATUS_COLORS[status]][6]}
            />
          </Center>
        )
      }
    >
      {item?.label}
    </Badge>
  );
};

type Notification = '2hrs' | 'disabled' | 'now';

type FormInputs = {
  notification: Notification;
};

const StatusConfirmModal = ({
  closeModal: cl,
  opened,
  statement,
}: {
  opened: boolean;
  closeModal: () => void;
  statement: StatementProps;
}) => {
  const { colors, primaryColor } = useMantineTheme();
  const primary = colors[primaryColor][6];
  const { updateStatus } = useStatusMutation();

  const methods = useForm<FormInputs>({
    values: {
      notification: '2hrs',
    },
  });

  const closeModal = () => {
    cl();
    methods.reset({
      notification: '2hrs',
    });
  };

  const queryData = useQuery(
    (q, args) => {
      return q
        .listingOwnershipPeriods({
          where: {
            id: { _eq: args.periodId },
          },
          limit: 1,
        })
        .flatMap((period) =>
          period
            .members({
              order_by: [
                {
                  contact: orderByContact[1],
                },
              ],
              where: {
                contactId: { _is_null: false },
              },
            })
            .map((periodMember) => ({
              id: periodMember.contactId,
              name: formatOwnerName(periodMember.contact, {
                lastNameFirst: true,
              }),
            }))
        );
    },
    {
      skip: !statement?.periodId || !opened,
      variables: {
        periodId: statement?.periodId,
        opened,
      },
    }
  );

  const options = useMemo<SelectItem<Notification>[]>(
    () => [
      {
        label: 'Send now  ',
        value: 'now',
        icon: <Icon icon="Send2Icon" size={16} />,
      },
      {
        label: 'in 2 hours',
        value: '2hrs',
        icon: <Icon icon="ClockIcon" size={16} />,
      },
      {
        label: 'Do not send',
        value: 'disabled',
        icon: <Icon icon="CrossCircleIcon" size={16} />,
      },
    ],
    []
  );

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered
      onClick={(e) => e.stopPropagation()}
      size={500}
    >
      <Center mb={rem(32)}>
        <Send2Icon size={24} color={primary} />
      </Center>

      <Title order={3} mb="sm" ta="center">
        Update status to published
      </Title>
      <Text c="neutral" ta="center" size="sm" mb={rem(32)}>
        Once the the statement is published, an email is sent to the owners to
        notify them about their new statement.
      </Text>

      <Stack pos="relative" gap={0} align="stretch" mb="xl">
        <LoadingOverlay visible={queryData.isLoading} />

        <DrawerInfoCard
          rows={[
            {
              icon: UsersIcon,
              title: 'Owners',
              text: queryData.data?.length
                ? queryData.data?.map((x) => x.name).join(', ')
                : '-',
            },
          ]}
        />

        <Box mb="xs" mt="xs">
          <Controller
            control={methods.control}
            name="notification"
            defaultValue="2hrs"
            render={({ field, fieldState }) => (
              <InputWrapper label="Send email to owners">
                <InputSelect
                  type="single"
                  value={options.find((o) => o.value === field.value) || null}
                  setValue={(value) =>
                    value?.value && field.onChange(value.value)
                  }
                  data={{
                    options,
                  }}
                  inputProps={{
                    error: fieldState.error?.message,
                  }}
                  dropdownProps={{
                    width: 200,
                    hideSearch: true,
                  }}
                />
              </InputWrapper>
            )}
          />
        </Box>
      </Stack>

      <Group>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={closeModal}
          disabled={methods.formState.isSubmitting}
        >
          Close
        </Button>
        <Button
          variant="primary"
          loading={methods.formState.isSubmitting}
          onClick={methods.handleSubmit(async (data) => {
            if (!statement) return;

            if (!statement.periodId) {
              showWarnNotification({
                title: 'Missing ownership',
                message: 'Please reach out to support if the issue persists.',
              });
              return;
            }

            return await updateStatus({
              ...statement,
              status: 'published',
              notification: data.notification,
              periodId: statement.periodId,
            }).then(closeModal);
          })}
          disabled={!statement || !opened || queryData.isLoading}
          sx={{
            flex: 2,
          }}
        >
          Change status to published
        </Button>
      </Group>
    </Modal>
  );
};

export const ledgerStatementStatusSelectOptions: SelectItem<OwnerStatementStatus>[] =
  [
    {
      label: 'Void',
      description: 'Cancelled statement',
      value: 'void',
      icon: (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.833344 8.50009C0.833344 4.54202 4.04199 1.33337 8.00006 1.33337C11.9581 1.33337 15.1668 4.54202 15.1668 8.50009C15.1668 12.4582 11.9581 15.6668 8.00006 15.6668C4.04199 15.6668 0.833344 12.4582 0.833344 8.50009ZM6.3536 6.14652C6.15834 5.95126 5.84175 5.95126 5.64649 6.14652C5.45123 6.34178 5.45123 6.65837 5.64649 6.85363L7.29295 8.50009L5.64649 10.1465C5.45123 10.3418 5.45123 10.6584 5.64649 10.8537C5.84175 11.0489 6.15834 11.0489 6.3536 10.8537L8.00006 9.2072L9.64652 10.8537C9.84178 11.0489 10.1584 11.0489 10.3536 10.8537C10.5489 10.6584 10.5489 10.3418 10.3536 10.1465L8.70716 8.50009L10.3536 6.85363C10.5489 6.65837 10.5489 6.34178 10.3536 6.14652C10.1584 5.95126 9.84178 5.95126 9.64652 6.14652L8.00006 7.79298L6.3536 6.14652Z"
            fill="#A3A3A3"
          />
        </svg>
      ),
    },
    {
      label: 'Preview',
      description: 'Set to draft to start editing',
      value: 'preview',
      icon: (
        <Box
          sx={{
            alignSelf: 'flex-start',
          }}
        >
          <Icon
            icon="LoaderIcon"
            color={(theme) => theme.colors.yellow[6]}
            size={18}
          />
        </Box>
      ),
    },
    {
      label: 'Draft',
      description: 'Editable statement',
      value: 'draft',
      icon: (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6668 8.50009C14.6668 12.182 11.682 15.1668 8.00006 15.1668C4.31813 15.1668 1.33334 12.182 1.33334 8.50009C1.33334 4.81816 4.31813 1.83337 8.00006 1.83337C11.682 1.83337 14.6668 4.81816 14.6668 8.50009Z"
            stroke="#F59E0B"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00024 3.87817C8.00024 3.60203 8.22464 3.37564 8.49947 3.40255C9.67459 3.51759 10.7798 4.03619 11.6219 4.87832C12.5825 5.83885 13.1221 7.14161 13.1221 8.5C13.1221 9.85839 12.5825 11.1611 11.6219 12.1217C10.7798 12.9638 9.67459 13.4824 8.49947 13.5975C8.22464 13.6244 8.00024 13.398 8.00024 13.1218L8.00024 8.5V3.87817Z"
            fill="#F59E0B"
          />
        </svg>
      ),
    },
    {
      label: 'In Review',
      description:
        'Statement is locked and can be reviewed by your team members',
      value: 'inReview',
      icon: (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6668 8.50009C14.6668 12.182 11.682 15.1668 8.00006 15.1668C4.31813 15.1668 1.33334 12.182 1.33334 8.50009C1.33334 4.81816 4.31813 1.83337 8.00006 1.83337C11.682 1.83337 14.6668 4.81816 14.6668 8.50009Z"
            stroke="#0284C7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00025 3.88001C8.00025 3.60386 8.22464 3.37747 8.49947 3.4044C9.33578 3.48633 10.1418 3.77319 10.8448 4.24288C11.6867 4.80547 12.343 5.60511 12.7305 6.54067C13.118 7.47622 13.2194 8.50568 13.0219 9.49887C12.8243 10.4921 12.3367 11.4043 11.6206 12.1204C10.9046 12.8364 9.99229 13.3241 8.99911 13.5216C8.00593 13.7192 6.97647 13.6178 6.04091 13.2303C5.10535 12.8427 4.30572 12.1865 3.74312 11.3445C3.27343 10.6416 2.98657 9.83553 2.90464 8.99923C2.87772 8.7244 3.10411 8.5 3.38025 8.5H7.50025C7.77639 8.5 8.00025 8.27615 8.00025 8V3.88001Z"
            fill="#0284C7"
          />
        </svg>
      ),
    },
    {
      label: 'Published',
      description:
        'Publish to notify the owner and allow them to view on owner portal',
      value: 'published',
      icon: (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.833344 8.50009C0.833344 4.54202 4.04199 1.33337 8.00006 1.33337C11.9581 1.33337 15.1668 4.54202 15.1668 8.50009C15.1668 12.4582 11.9581 15.6668 8.00006 15.6668C4.04199 15.6668 0.833344 12.4582 0.833344 8.50009ZM11.3536 7.18696C11.5489 6.9917 11.5489 6.67512 11.3536 6.47986C11.1584 6.2846 10.8418 6.2846 10.6465 6.47986L7.33339 9.793L6.02026 8.47987C5.825 8.28461 5.50842 8.28461 5.31316 8.47987C5.11789 8.67513 5.11789 8.99172 5.31316 9.18698L6.97983 10.8537C7.0736 10.9474 7.20078 11.0001 7.33339 11.0001C7.466 11.0001 7.59317 10.9474 7.68694 10.8537L11.3536 7.18696Z"
            fill="#16A34A"
          />
        </svg>
      ),
    },
  ];
