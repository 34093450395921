import { StringParam, type UrlUpdateType, useQueryParams } from '@finalytic/ui';
import { useCallback } from 'react';

type View = 'overview' | 'edit';

export function useOwnerDetailDrawer() {
  const [opts, set] = useQueryParams({
    owner: StringParam,
    view: StringParam,
  });

  return {
    opened: !!opts.owner,
    open: useCallback(
      (id: string, view?: 'overview' | 'edit', type?: UrlUpdateType) =>
        set({ owner: id, view }, type),
      [set]
    ),
    close: useCallback(() => set({ owner: undefined, view: undefined }), [set]),
    ownerId: opts.owner,
    view: (opts.view || 'overview') as View,
    setView: useCallback((view: View) => set({ view }), [set]),
  };
}
