import { useTeamRole } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  useQueryParamsSet,
} from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { connectionHiddenStatusDefault } from '@vrplatform/ui-common';
import { useExtractModal } from '../../../modals';
import type { ConnectionRow } from './useConnectionTableQuery';

export const ConnectionEllipsisMenuItems = ({
  data,
  openRenameModal,
  openDeleteModal,
  openUpdateModal,
}: {
  data: Maybe<ConnectionRow>;
  openRenameModal: () => void;
  openDeleteModal: () => void;
  openUpdateModal: () => void;
}) => {
  const { isPartnerAdmin } = useTeamRole();

  const id = data?.id;

  const setSearchParams = useQueryParamsSet({
    connectionId: StringParam,
    connectionDetailId: StringParam,
  });

  const { open: openExtractModal } = useExtractModal();

  const reconnect = () => setSearchParams({ connectionId: id });
  const connectionStatus = data?.status || 'active';
  const isArchived = connectionHiddenStatusDefault.includes(
    connectionStatus.toLowerCase()
  );

  if (!id) return null;

  return (
    <>
      <EllipsisMenuItem
        customIcon={<Icon icon="Edit3Icon" size={16} />}
        onClick={openRenameModal}
      >
        Rename
      </EllipsisMenuItem>
      <EllipsisMenuItem
        customIcon={<Icon icon="LightningIcon" size={16} />}
        onClick={reconnect}
      >
        Reconnect
      </EllipsisMenuItem>
      <EllipsisMenuItem
        customIcon={<Icon icon="RefreshCwIcon" size={16} />}
        onClick={() => {
          openExtractModal({
            connectionIds: [id],
            extractType: undefined,
          });
        }}
      >
        Fetch
      </EllipsisMenuItem>
      <EllipsisMenuDivider />
      <EllipsisMenuItem
        onClick={openUpdateModal}
        customIcon={
          <Icon icon={isArchived ? 'RefreshCwIcon' : 'ArchiveIcon'} size={16} />
        }
      >
        {isArchived ? 'Enable' : 'Archive'}
      </EllipsisMenuItem>

      <EllipsisMenuDangerItem
        onClick={openDeleteModal}
        data-testid="connection-delete"
      >
        Delete
      </EllipsisMenuDangerItem>

      {isPartnerAdmin && <EllipsisMenuCopyItem value={id} />}
    </>
  );
};
