import { Badge } from '@finalytic/components';
import type { owner_type_enum } from '@finalytic/graphql';
import { Icon } from '@finalytic/icons';
import { StringParam, useQueryParamSet } from '@finalytic/ui';
import { useMantineColorScheme } from '@mantine/core';
import { formatPercentage } from '@vrplatform/ui-common';

type Props = {
  ownerId: string;
  type: owner_type_enum;
  name: string;
  splitBasePoints: number;
};

export const OwnerBadge = ({ ownerId, type, name, splitBasePoints }: Props) => {
  const setOwner = useQueryParamSet('owner', StringParam);

  const { colorScheme } = useMantineColorScheme();

  const cent = splitBasePoints / 100;

  return (
    <Badge
      component="button"
      onClick={(e) => {
        e.stopPropagation();
        setOwner(ownerId, 'push');
      }}
      color={type === 'company' ? 'violet' : undefined}
      leftIcon={
        <Icon
          icon={type === 'company' ? 'OfficeIcon' : 'UserIcon'}
          size={14}
          style={{ marginRight: 5 }}
          color={(theme) =>
            theme.colors[type === 'company' ? 'violet' : theme.primaryColor][5]
          }
        />
      }
      sx={() => ({
        '&:active': {
          transform: 'translateY(1px)',
        },
        color: colorScheme === 'dark' ? undefined : 'black',
      })}
    >
      {name}
      {cent === 100
        ? ''
        : ` (${formatPercentage(splitBasePoints, 'base-points')})`}
    </Badge>
  );
};
