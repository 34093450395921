import {
  AddressFormInputs,
  Badge,
  Button,
  Input,
  InputDay,
  InputWrapper,
} from '@finalytic/components';
import {
  showApiErrorNotification,
  useApiMutation,
  useBrowserTracking,
  useEnabledFeatures,
  useQuery,
  useTeam,
  useTeamId,
  useTeamRole,
  useTrpcMutation,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { AlertTriangleIcon } from '@finalytic/icons';
import {
  LoadingIndicator,
  showErrorNotification,
  showWarnNotification,
} from '@finalytic/ui';
import { day, ensure, toTitleCase, utc } from '@finalytic/utils';
import {
  Box,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  Text,
  Title,
} from '@mantine/core';
import {
  type Address,
  formatAddress,
  getCountryByIsoCode,
  getStateByIsoCode,
  getTenantAddress,
  validateTin,
} from '@vrplatform/ui-common';
import { useId, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { TeamDeleteButton } from './TeamDeleteButton';
import { SettingsTitle, SettingsViewContainer } from './_components';

function useTeamData() {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();

  const {
    isLoading: loading,
    data: team,
    refetch,
    error,
  } = useQuery(
    (q, args) => {
      if (!args.teamId) return null;

      const team = q.tenantById({ id: args.teamId });

      const address = getTenantAddress(team).values;

      return ensure<{
        id: string;
        startAt: string | undefined;
        values: FormValues;
      }>({
        id: team?.id,
        startAt: team.statementStartAt
          ? utc(team.statementStartAt).yyyymmdd()
          : undefined,
        values: {
          teamName: team?.name || '',
          taxId: team?.companyTaxCode || null,
          companyName: team?.companyName,
          phone: team?.supportPhone || '',
          email: args.GL ? (team.email ?? '') : team?.supportEmail || '',
          address: {
            ...address,
            stateCode: address.stateCode || '',
            countryCode: address.countryCode || '',
          },
        },
      });
    },
    {
      skip: !teamId,
      variables: { teamId, GL },
    }
  );

  return {
    loading,
    refetch,
    team,
    error,
  };
}

type FormValues = {
  teamName: string;
  companyName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  taxId: string | null;
  address: Address;
};

export const SettingsTeamGeneralView = () => {
  const { isTeamAdmin } = useTeamRole();

  return (
    <SettingsViewContainer
      sx={{
        position: 'relative',
      }}
    >
      <SettingsTitle type="view-title">General</SettingsTitle>

      <TeamInputSection />

      {isTeamAdmin && <DeleteTeamSection />}

      <VrpAdminSection />
    </SettingsViewContainer>
  );
};

const VrpAdminSection = () => {
  const [{ status, id: teamId }] = useTeam();

  const [newValue, setNewValue] = useState<string | null | undefined>(
    undefined
  );

  const { loading: loadingQuery, team: teamData } = useTeamData();

  const notifyId = useId();

  const { GL } = useEnabledFeatures();

  const { mutateAsync, isPending: loadingMutation } = useApiMutation(
    'put',
    '/teams/{id}',
    {
      onError: (err) => {
        showErrorNotification({
          id: notifyId,
          title: 'Failed to update GL start date',
          message:
            err?.message ||
            err.issues?.[0]?.message ||
            'Failed to update GL start date',
        });
      },
    }
  );

  const value = newValue !== undefined ? newValue : teamData?.startAt;

  const loading = loadingQuery || loadingMutation;

  const updateStartAt = async (startAt: string | null) => {
    return mutateAsync({
      params: {
        path: { id: teamId },
      },
      body: {
        statementStartAt: startAt,
      },
    }).then((x) => setNewValue(x.statementStartAt ?? null));
  };

  return (
    <HiddenFeatureIndicator permission="vrp-admin" mt="xl" p="sm">
      <SettingsTitle type="heading" mb="md">
        VRP-admins only
      </SettingsTitle>
      <Text fw={500}>
        Status:{' '}
        <Badge color={status === 'active' ? 'green' : 'orange'}>
          {toTitleCase(status)}
        </Badge>
      </Text>

      {GL && (
        <InputWrapper
          mt="lg"
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          label="GL Start Date"
          description="Decide the date that the General Ledger will start from & the date that the first owner statements will be generated from. "
          required
        >
          <InputDay
            value={value ? day(value).toDate() : undefined}
            placeholder="GL Start At"
            loading={loading}
            onChange={(v) => updateStartAt(v ? day(v).yyyymmdd() : null)}
          />
        </InputWrapper>
      )}
    </HiddenFeatureIndicator>
  );
};

const trapSpacesForRequiredFields = (value: string | undefined) =>
  !!value?.trim() || 'This field is required';

const TeamInputSection = () => {
  const { loading: loadingQuery, team: teamData, refetch } = useTeamData();
  const { track } = useBrowserTracking();
  const { GL } = useEnabledFeatures();

  const [taxDisabled, setTaxDisabled] = useState(false);

  const methods = useForm<FormValues>({
    values: teamData?.values,
  });

  const { mutate, loading: loadingTrpc } = useTrpcMutation('updateTenant', {
    successMessage: {
      title: 'Team updated',
      message: 'Team was updated successfully.',
    },
  });

  const { mutateAsync: mutateApi, isPending: loadingApi } = useApiMutation(
    'put',
    '/teams/{id}'
  );

  const loading = loadingTrpc || loadingApi;

  const submit = (values: FormValues) => {
    if (!teamData?.id) {
      refetch();
      return showWarnNotification({
        title: 'Team not found',
        message:
          'Trying to refetch team info, please try again after the loading screen disappears.',
      });
    }

    if (GL) {
      try {
        const hasAddress = !!values.address.line1?.trim();

        if (
          hasAddress &&
          (!values.address.stateCode || !values.address.countryCode)
        ) {
          return showErrorNotification({
            title: 'Validation error',
            message: 'Please select a country and state.',
            icon: <AlertTriangleIcon size={20} />,
          });
        }

        const country =
          (values.address.countryCode &&
            getCountryByIsoCode(values.address.countryCode)?.name) ||
          '';

        const state =
          (values.address.countryCode &&
            values.address.stateCode &&
            getStateByIsoCode(
              values.address.stateCode,
              values.address.countryCode
            )?.name) ||
          '';

        return mutateApi({
          params: {
            path: { id: teamData.id },
          },
          body: {
            companyName: values.companyName?.trim() || null,
            email: values.email?.trim() || null,
            name: values.teamName.trim(),
            phone: values.phone?.trim() || null,
            taxId: values.taxId?.trim() || null,
            billingAddress: hasAddress
              ? {
                  full: formatAddress({ ...values.address, state, country }),
                  line1: values.address.line1.trim(),
                  line2: values.address.line2?.trim(),
                  city: values.address.city.trim(),
                  countryCode: values.address.countryCode!,
                  stateCode: values.address.stateCode!,
                  postalCode: values.address.postcode.trim(),
                  state,
                }
              : null,
          },
        }).then(() => {
          methods.reset();
          refetch();
          track('team_info_updated', {
            tenantId: teamData.id,
          });
        });
      } catch (error) {
        showApiErrorNotification({
          title: 'Found an issue',
          error,
          defaultMessage:
            'We failed to update your team. Please try again and contact support if the issue persists.',
        });
      }
    } else {
      const state =
        getStateByIsoCode(values.address.stateCode, values.address.countryCode)
          ?.name || '';
      const country =
        getCountryByIsoCode(values.address.countryCode)?.name || '';

      const hasAddress = !!values.address.line1;

      if (hasAddress && (!state || !country))
        return showWarnNotification({
          title: 'Invalid address',
          message:
            'Failed to find state and country. Please enter the address again to save your changes.',
        });

      return mutate({
        tenantId: teamData.id,
        input: {
          address: hasAddress
            ? {
                ...values.address,
                line2: values.address.line2?.trim() || '',
                country,
                state,
              }
            : null,
          companyName: values.companyName || null,
          email: values.email || null,
          name: values.teamName,
          phone: values.phone || null,
          taxId: values.taxId || null,
        },
      }).then(() => {
        methods.reset();
        refetch();
        track('team_info_updated', {
          tenantId: teamData.id,
        });
      });
    }
  };

  return (
    <>
      <Box
        mt="lg"
        component="form"
        onSubmit={methods.handleSubmit(submit)}
        onReset={() => methods.reset(teamData?.values)}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.md,
        })}
      >
        <FormProvider {...methods}>
          <Title order={4} mt="md">
            Team
          </Title>

          <Controller
            control={methods.control}
            name="teamName"
            rules={{
              required: 'Team name is required',
              validate: trapSpacesForRequiredFields,
            }}
            render={({ field, fieldState: { error } }) => (
              <InputWrapper label="Team name" error={error?.message} required>
                <Input
                  {...field}
                  placeholder="Team name"
                  autoComplete="off"
                  error={!!error}
                />
              </InputWrapper>
            )}
          />

          <Title order={4} mt="md">
            Company
          </Title>

          <Controller
            control={methods.control}
            name="companyName"
            render={({ field, fieldState: { error } }) => (
              <InputWrapper label="Company name" error={error?.message}>
                <Input
                  {...field}
                  placeholder="Company name"
                  autoComplete="off"
                  error={!!error}
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={methods.control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <InputWrapper label="Email" error={error?.message}>
                <Input
                  {...field}
                  placeholder="Email"
                  type="email"
                  autoComplete="off"
                  error={!!error}
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={methods.control}
            name="phone"
            render={({ field, fieldState: { error } }) => (
              <InputWrapper label="Phone" error={error?.message}>
                <Input
                  {...field}
                  placeholder="Phone"
                  autoComplete="off"
                  error={!!error}
                />
              </InputWrapper>
            )}
          />

          <Box>
            <Controller
              control={methods.control}
              name="taxId"
              rules={{
                required: taxDisabled
                  ? undefined
                  : 'Tax Identification Number is required',
                validate: taxDisabled ? undefined : (v) => validateTin(v),
              }}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <InputWrapper
                  label="TIN (Tax Identification Number)"
                  error={error?.message}
                  mb="sm"
                  required={!taxDisabled}
                >
                  <Input
                    {...field}
                    value={field.value || ''}
                    placeholder="Tax Identification Number"
                    autoComplete="off"
                    error={!!error}
                    disabled={taxDisabled}
                  />
                </InputWrapper>
              )}
            />

            <Checkbox
              label="I don't have TIN provided"
              size="xs"
              checked={taxDisabled}
              onChange={() => {
                setTaxDisabled((e) => !e);
                methods.setValue('taxId', '');
                methods.clearErrors('taxId');
              }}
            />
          </Box>

          <Title order={4} mt="md">
            Address
          </Title>

          <AddressFormInputs />

          <Group justify="right">
            <Button
              type="reset"
              disabled={loading || !methods.formState.isDirty}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!methods.formState.isDirty}
              loading={loading}
            >
              Save changes
            </Button>
          </Group>
        </FormProvider>
      </Box>
      <LoadingOverlay visible={loadingQuery} />
    </>
  );
};

const DeleteTeamSection = () => {
  const loading = false;

  return (
    <>
      <Divider my="xl" />
      <SettingsTitle type="heading">Delete Team</SettingsTitle>
      {loading ? (
        <LoadingIndicator mt="md" size="sm" />
      ) : (
        <>
          <Text component="p" my="md">
            If you want to delete this organization and all of its data
            permanently, you can do so.
          </Text>
          <TeamDeleteButton />
        </>
      )}
    </>
  );
};
