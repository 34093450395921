import { CrossIcon } from '@finalytic/icons';
import { CheckIcon } from '@mantine/core';
import {
  type NotificationData,
  hideNotification as hide,
  showNotification,
  updateNotification,
} from '@mantine/notifications';

export { showNotification, updateNotification };

const iconColor = '#fff';
const iconSize = 14;

export const showLoadingNotification = ({ id }: { id: string }) =>
  showNotification({
    id,
    loading: true,
    title: 'Loading...',
    message: 'We will update you shortly.',
    autoClose: false,
    radius: 10,
  });

export const hideNotification = ({ id }: { id: string }) => hide(id);

export const showErrorNotification: typeof showNotification = ({
  title,
  message,
  ...props
}) =>
  showNotification({
    title,
    message,
    color: 'red',
    icon: <CrossIcon size={iconSize} color={iconColor} />,
    radius: 10,
    autoClose: 3000,
    className: 'success-notification',
    ...props,
  });

export const updateWarnNotification = ({
  title,
  message,
  id,
  ...props
}: NotificationData & { id: string }) =>
  updateNotification({
    id,
    title,
    message,
    color: 'yellow',
    autoClose: 3000,
    radius: 10,
    icon: null,
    ...props,
  });

export const showWarnNotification: typeof showNotification = ({
  title,
  message,
  ...props
}) =>
  showNotification({
    title,
    message,
    color: 'yellow',
    autoClose: 3000,
    radius: 10,
    ...props,
  });

export const updateErrorNotification = ({
  title,
  message,
  id,
  ...props
}: NotificationData & { id: string }) =>
  updateNotification({
    title,
    message,
    color: 'red',
    icon: <CrossIcon size={iconSize} color={iconColor} />,
    radius: 10,
    loading: false,
    autoClose: 3000,
    ...props,
    id,
  });

export const showSuccessNotification: typeof showNotification = ({
  title,
  message,
  ...props
}) =>
  showNotification({
    title,
    message,
    color: 'teal',
    icon: <CheckIcon size={iconSize} color={iconColor} />,
    radius: 10,
    autoClose: 3000,
    id: 'success-notification',
    classNames: {
      body: 'success-notification-body',
    },
    ...props,
  });

export const updateSuccessNotification = ({
  title,
  message,
  ...props
}: NotificationData & { id: string }) =>
  updateNotification({
    title,
    message,
    color: 'teal',
    icon: <CheckIcon size={iconSize} color={iconColor} />,
    radius: 10,
    classNames: {
      body: 'success-notification-body',
    },
    loading: false,
    autoClose: 3000,
    ...props,
  });
