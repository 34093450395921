import {
  useDashboard,
  useEnabledFeatures,
  useMe,
  useTeamId,
} from '@finalytic/data';
import { hasValue } from '@finalytic/utils';
import { whereListings } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useListingFilter } from './ListingFilter';

export function useWhereListings(opts: {
  filterByAutomationId: string | undefined;
}) {
  const { id: meId } = useMe();
  const { GL } = useEnabledFeatures();
  const filter = useFormattedListingFilter();

  const filterByAutomationId = opts?.filterByAutomationId;

  return useMemo(() => {
    return whereListings({
      ...filter,
      meId,
      disabledByAutomationId:
        filterByAutomationId || filter.disabledByAutomationId,
      GL,
    });
  }, [filter, meId, filterByAutomationId, GL]);
}

export function useFormattedListingFilter() {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();

  const { filter } = useListingFilter();

  return useMemo(
    () => ({
      dashboard,
      search: filter.search?.trim(),
      tenantId: teamId,
      status: filter.status as any,
      ownerIds: filter.ownerIds,
      disabledByAutomationId: filter.automationIds?.filter(hasValue),
      collectionId: filter.collectionId,
      pmsConnectionId: filter.pmsConnectionId,
    }),
    [filter, teamId, dashboard]
  );
}
