import { Collapse } from '@finalytic/components';
import { showApiErrorNotification, useApiClient } from '@finalytic/data';
import { LazyTable, type MRT_ColumnDef } from '@finalytic/table';
import {
  type Maybe,
  ensure,
  formatCurrency,
  groupBy,
  sum,
  toTitleCase,
} from '@finalytic/utils';
import {
  Anchor,
  Box,
  Center,
  Group,
  HoverCard,
  List,
  LoadingOverlay,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import {
  keepPreviousData,
  useQuery as useTanstackQuery,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { getReservationFinancialGroup } from '../../../../../drawers/reservation-drawers/_utils';
import { FEE_TEMPLATES } from '../../../overview/_constants';
import { useFeeForm } from '../../useFeeForm';
import { useFeeAccountsTableFilter } from '../FeeAccountsTable';
import type { FeeReservationPreview } from './useFeeReservationPreviewQuery';

export type PreviewRow = {
  id: string;
  type: 'financial' | 'fee' | 'deposit' | 'expense';
  title: string;
  centTotal: number;
  accounts: { id: string; centTotal: number }[];
  subRows?: PreviewRow[];
};

function usePreviewQuery(reservationId: Maybe<string>) {
  const $api = useApiClient();
  const methods = useFeeForm();

  const recurringFeeId = useWatch({
    control: methods.control,
    name: 'id',
  });

  const linkedAccountIds = useWatch({
    control: methods.control,
    name: 'linkedAccountIds',
  });
  const title = useWatch({
    control: methods.control,
    name: 'title',
  });
  const type = useWatch({
    control: methods.control,
    name: 'type',
  });

  const defaultRateBasePoints = useWatch({
    control: methods.control,
    name: 'defaultRateBasePoints',
  });

  const bookingChannelsFilter = useWatch({
    control: methods.control,
    name: 'bookingChannelsFilter',
  });

  const creditAccountId = useWatch({
    control: methods.control,
    name: 'creditAccountId',
  });

  const debitAccountId = useWatch({
    control: methods.control,
    name: 'debitAccountId',
  });

  const revenueRecognition = useWatch({
    control: methods.control,
    name: 'revenueRecognition',
  });

  const statusFilter = useWatch({
    control: methods.control,
    name: 'statusFilter',
  });

  const taxRateId = useWatch({
    control: methods.control,
    name: 'taxRateId',
  });

  return useTanstackQuery({
    enabled: !!reservationId,
    placeholderData: keepPreviousData,
    queryKey: [
      'fee-reservation-preview',
      reservationId,
      linkedAccountIds,
      title,
      defaultRateBasePoints,
      type,
      bookingChannelsFilter,
      creditAccountId,
      debitAccountId,
      revenueRecognition,
      statusFilter,
      taxRateId,
    ],
    queryFn: async () => {
      if (!reservationId) return null;

      const res = await $api.POST('/recurring-fees/preview', {
        body: {
          reservationId,
          type,
          defaultRate: defaultRateBasePoints,
          formula: FEE_TEMPLATES[type].config.formulaTemplate.replace(
            'accounts',
            linkedAccountIds.map((x) => `"${x}"`).join(' + ')
          ),
          creditAccountId,
          debitAccountId,
          bookingChannelsFilter:
            bookingChannelsFilter === 'all-channels'
              ? null
              : bookingChannelsFilter,
          revenueRecognition,
          statusFilter,
          taxRateId,
          id: recurringFeeId ?? undefined,
        },
      });

      if (res.error) {
        showApiErrorNotification({
          error: res.error,
          title: 'Failed to preview fee',
          defaultMessage:
            'We failed to preview the fee. Please select a different reservation and try again. Contact support if the issue persists.',
        });

        throw new Error('Failed to preview fee');
      }

      return res.data;
    },
  });
}

type LineGroup = ReturnType<typeof getReservationFinancialGroup> | '-';
type PreviewLine = NonNullable<
  ReturnType<typeof usePreviewQuery>['data']
>['lines'][number] & {
  subRows?: (PreviewLine & { group: LineGroup })[];
  group: LineGroup;
};

export const FeeReservationPreviewTable = ({
  reservation,
}: {
  reservation: FeeReservationPreview;
}) => {
  const { setFilter } = useFeeAccountsTableFilter();
  const methods = useFeeForm();
  const {
    data: preview,
    isFetching,
    isInitialLoading,
  } = usePreviewQuery(reservation?.id);

  const type = useWatch({
    control: methods.control,
    name: 'type',
  });

  const currency = reservation?.currency ?? 'usd';

  const lines = useMemo(() => {
    const filtered =
      preview?.lines
        .filter((x) => {
          const d =
            x.party === 'owners' ||
            (x.party === 'manager' && x.type?.includes('deposit'));

          if (type === 'managementFee')
            return d && x.recurringFee?.type !== 'managementFee';

          return d;
        })
        .sort((a, b) => {
          const order: Record<NonNullable<(typeof a)['type']> | '-', number> = {
            reservation: 0,
            reservation_line: 1,
            reservation_adjustment: 2,
            reservation_adjustment_cancellation: 3,
            reservation_fee: 4,
            reservation_fee_adjustment: 5,
            reservation_fee_tax: 6,
            transaction_deposit: 7,
            transaction_deposit_line: 8,
            transaction_deposit_reserve: 9,
            transaction_deposit_refund: 10,
            transaction_deposit_channelFee: 11,
            transaction_deposit_merchantFee: 12,
            transaction_deposit_openingBalance: 13,
            transaction_deposit_vat: 14,
            transaction_expense: 15,
            transaction_expense_line: 16,
            transaction_expense_payment: 17,
            transaction_expense_markup: 18,
            transaction_expense_markup_tax: 19,
            transaction_transfer: 20,
            transaction_transfer_line: 21,
            '-': 1000,
          };

          return (order[a.type ?? '-'] ?? 0) - (order[b.type ?? '-'] ?? 0);
        }) ?? [];

    return Object.entries(
      groupBy(filtered, (x) => {
        const group = getReservationFinancialGroup(x);

        if (!group) console.log(x);

        return group || '-';
      })
    ).map<PreviewLine>(([t, lines]) => {
      const group = t as LineGroup;

      const name = (() => {
        if (group === 'feeAndCommission') return 'Fee & Commissions';
        if (group === 'financial') return 'Financials';
        return toTitleCase(group);
      })();

      return {
        amount: sum(lines, 'amount'),
        subRows: lines.map((x) => ({ ...x, group })),
        fee: sum(lines, 'fee'),
        name,
        group,
        status: lines.some((x) => x.status === 'active')
          ? 'active'
          : 'inactive',
        type: lines[0].type,
        account: null,
        lineType: null,
        recurringFee: null,
        transaction: null,
      };
    });
  }, [preview?.lines, type]);

  const columns = useMemo<MRT_ColumnDef<PreviewLine>[]>(
    () => [
      {
        header: 'Item',
        Header: '',
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        mantineTableBodyCellProps: {
          px: 'xs',
        },
        Footer: () => (
          <Text span fw={500}>
            Total charged:
          </Text>
        ),
        AggregatedCell: ({ row }) => {
          const title = row.original.name;
          return <Text fw={500}>{title}</Text>;
        },
        Cell: ({ row }) => {
          const title = row.original.name;
          const fw = !row.parentId ? 500 : undefined;
          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          const accounts = row.original.account ? [row.original.account] : [];

          const isAdjustment =
            row.original.type &&
            ensure<NonNullable<(typeof row)['original']['type']>[]>([
              'reservation_adjustment',
              'reservation_adjustment_cancellation',
              'reservation_fee_adjustment',
            ]).includes(row.original.type);

          return (
            <HoverCard
              shadow="md"
              withArrow
              withinPortal
              position="top"
              radius="md"
            >
              <HoverCard.Target>
                <Box>
                  <Text td={td} fw={fw}>
                    {title}
                  </Text>
                  {isAdjustment && (
                    <Text td={td} fw={400} size="xs" c="gray">
                      Adjustment
                    </Text>
                  )}
                </Box>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text fw={500} mb={rem(5)}>
                  Accounts
                </Text>
                {accounts.length ? (
                  <List icon={null} size="sm">
                    {accounts.map((x) => (
                      <List.Item key={x.id}>
                        <Anchor
                          component="button"
                          onClick={() => setFilter({ search: x.name })}
                          c="dark"
                        >
                          {x.name}
                        </Anchor>
                      </List.Item>
                    ))}
                  </List>
                ) : (
                  <Text c="gray">No account found</Text>
                )}
              </HoverCard.Dropdown>
            </HoverCard>
          );
        },
      },
      {
        header: 'Fee',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
          }),
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
          }),
        },
        mantineTableHeadCellProps: {
          align: 'right',
          pr: 'xs',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
            fontWeight: 400,
            color: theme.colors.gray[7],
          }),
        },
        AggregatedCell: ({ row }) => {
          const fee = row.original.fee;

          if (!fee) return '-';

          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          return (
            <Text td={td} fw={500}>
              {formatCurrency(fee / 100, currency)}
            </Text>
          );
        },
        Cell: ({ row }) => {
          const fee = row.original.fee;

          if (!fee) return '-';

          const amount = formatCurrency(fee / 100, currency);

          const fw = !row.parentId ? 500 : undefined;

          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          return (
            <Text td={td} fw={fw}>
              {amount}
            </Text>
          );
        },
        Footer: () => (
          <Text span fw={500} ta="right" display={'block'}>
            {formatCurrency((preview?.totals.fee ?? 0) / 100, currency)}
          </Text>
        ),
      },
      {
        header: 'Total',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableHeadCellProps: {
          align: 'right',
          pr: 'xs',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.amount || 0) / 100,
            currency
          );

          const fw = !row.parentId ? 500 : undefined;

          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          return (
            <Text td={td} fw={fw}>
              {amount}
            </Text>
          );
        },
        AggregatedCell: ({ row }) => {
          return (
            <Text fw={500}>
              {formatCurrency(row.original.amount / 100, currency)}
            </Text>
          );
        },
        // Footer: () => (
        //   <Text span fw={500} ta="right" display={'block'}>
        //     {formatCurrency((preview?.totals.owner ?? 0) / 100, currency)}
        //   </Text>
        // ),
      },
    ],
    [currency, preview?.totals.fee, preview?.totals.guest, setFilter]
  );

  return (
    <Box pos="relative">
      <Group
        mb="md"
        justify="space-between"
        wrap="nowrap"
        sx={(theme) => ({
          padding: theme.spacing.xs,
          backgroundColor: theme.colors.yellow[0],
          borderRadius: theme.radius.md,
        })}
      >
        <Title />
        <Text fw={500} ta="right">
          {formatCurrency((preview?.totals.fee ?? 0) / 100, currency)}
        </Text>
      </Group>

      <Collapse
        title={
          <Text component="span" size="sm">
            Preview
          </Text>
        }
        rightSection={null}
        minHeight={30}
        defaultOpened
        hideToggle
      >
        <Box>
          <LoadingOverlay visible={isFetching && !isInitialLoading} />
          <LazyTable
            table={{
              columns,
              hideHeader: true,
              hideTopBar: true,
              emptyRowsFallback: () => (
                <Center>
                  <Text size="sm" c="gray">
                    No lines found
                  </Text>
                </Center>
              ),
              // onRowClick: {
              //   disabled: (row) => row.original.accounts?.length !== 1,
              //   handler: (row) => {
              //     const account = props.accounts.find((x) =>
              //       row.original.accounts.map((y) => y.id).includes(x.id)
              //     );

              //     if (account) setFilter({ search: account.title });
              //   },
              // },
            }}
            subRows={{
              getRowCanExpand: (row) => !!row.original.subRows?.length,
              defaultExpanded: true,
              getSubRows: (row) => row.subRows ?? [],
            }}
            data={{
              rows: lines,
              rowCount: lines.length,
              error: null,
              loading: isInitialLoading,
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

const Title = () => {
  const methods = useFeeForm();
  const title = useWatch({
    control: methods.control,
    name: 'title',
  });

  return (
    <Text ta="left" fw={500}>
      {title}
    </Text>
  );
};
