import { ChevronIcon, CrossIcon } from '@finalytic/icons';
import { IconButton, LoadingIndicator } from '@finalytic/ui';
import {
  Box,
  type FloatingPosition,
  Group,
  Popover,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { type Address, formatAddress } from '@vrplatform/ui-common';
import { forwardRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../button';
import { AddressFormInputs } from './AddressFormInputs';

type BaseInputAddressProps = {
  value: Address | null;
  onChange: (value: Address | null) => void;
  required?: boolean;
};

type InputAddressProps = {
  inputProps?: TargetProps;
  dropdownProps?: {
    width?: number | 'target';
    withinPortal?: boolean;
    position?: FloatingPosition;
    allowClear?: boolean;
  };
};
export const InputAddress = ({
  onChange,
  value,
  inputProps,
  dropdownProps,
  required,
}: BaseInputAddressProps & InputAddressProps) => {
  const [opened, setOpened] = useState(false);

  return (
    <Popover
      onChange={(v) => setOpened(v)}
      opened={opened}
      width={dropdownProps?.width || 'target'}
      withinPortal={dropdownProps?.withinPortal}
      position={dropdownProps?.position}
      closeOnClickOutside={false}
    >
      <Popover.Target>
        <Target
          opened={opened}
          toggleOpened={() => setOpened((v) => !v)}
          onChange={onChange}
          value={value}
          {...inputProps}
        />
      </Popover.Target>
      <Popover.Dropdown>
        <Dropdown
          address={value}
          closeModal={() => setOpened(false)}
          onSubmit={onChange}
          required={!!required}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

type BaseTargetProps = {
  opened: boolean;
  toggleOpened: () => void;
};

type TargetProps = {
  width?: number | string;
  withBorder?: boolean;
  withClearButton?: boolean;
  disabled?: boolean;
  loadingMutation?: boolean;
  loadingQuery?: boolean;
  placeholder?: string;
  error?: boolean;
};

const Target = forwardRef<
  HTMLDivElement,
  BaseInputAddressProps & BaseTargetProps & TargetProps
>(
  (
    {
      opened,
      disabled,
      width,
      withBorder = true,
      loadingMutation,
      loadingQuery,
      value,
      onChange,
      withClearButton,
      toggleOpened,
      placeholder = 'Address',
      error,
    },
    ref
  ) => {
    const { colors } = useMantineTheme();

    const hasValue = Object.entries(value || {})
      .filter(([key]) => key !== 'teamName')
      .some(([, value]) => !!value);

    return (
      <Group
        ref={ref}
        wrap="nowrap"
        justify="space-between"
        onClick={toggleOpened}
        sx={(theme) => ({
          width: width || undefined,
          border: '1px solid',
          borderColor: opened
            ? theme.colors[theme.primaryColor][6]
            : withBorder
              ? error
                ? theme.colors.red[5]
                : theme.colors.gray[4]
              : 'transparent',
          boxShadow: opened
            ? `0px 0px 0px 2px ${theme.colors[theme.primaryColor][4]}40`
            : undefined,
          borderRadius: theme.radius.sm,
          padding: `2px ${theme.spacing.sm}`,
          minHeight: '2.25rem',
          cursor: disabled ? undefined : 'pointer',
          backgroundColor:
            loadingMutation || loadingQuery || disabled
              ? theme.colors.neutral[0]
              : 'transparent',
        })}
      >
        {loadingQuery && value ? (
          <LoadingIndicator size="xs" />
        ) : (
          <>
            <Text
              component="span"
              size="sm"
              color={!hasValue ? colors.gray[5] : undefined}
            >
              {hasValue
                ? formatAddress({
                    city: value!.city,
                    line1: value!.line1,
                    line2: value!.line2,
                    postcode: value!.postcode,
                    stateCode: value!.stateCode,
                    countryCode: value!.countryCode,
                    state: undefined,
                    country: undefined,
                  }) || placeholder
                : placeholder}
            </Text>
            {loadingMutation ? (
              <LoadingIndicator size="xs" />
            ) : withClearButton && value && !disabled ? (
              <IconButton
                w={18}
                p={0}
                sx={{
                  flexShrink: 0,
                  minWidth: 0,
                }}
                ref={(ref: HTMLButtonElement) => {
                  if (!ref) return;

                  ref.onclick = (e) => {
                    e.stopPropagation(); // this works

                    // remove value
                    onChange(null);
                  };
                }}
              >
                <CrossIcon size={18} color={colors.neutral[4]} />
              </IconButton>
            ) : (
              !disabled && (
                <ChevronIcon
                  size={18}
                  color={colors.gray[4]}
                  sx={{
                    flexShrink: 0,
                  }}
                />
              )
            )}
          </>
        )}
      </Group>
    );
  }
);

type FormInputs = {
  address: Address;
};

const Dropdown = ({
  address,
  onSubmit,
  closeModal,
  required,
}: {
  address: Address | null;
  onSubmit: (value: Address) => void;
  closeModal: () => void;
  required: boolean;
}) => {
  const methods = useForm<FormInputs>({
    values: address ? { address } : undefined,
  });

  const { reset, handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onReset={() => {
          closeModal();
          reset(address ? { address } : undefined);
        }}
      >
        <AddressFormInputs alwaysRequired={required} />

        <Group mt="lg" justify="right">
          <Button variant="light" type="button" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={handleSubmit(({ address }) => {
              onSubmit(address);
              closeModal();
            })}
          >
            Save changes
          </Button>
        </Group>
      </Box>
    </FormProvider>
  );
};
