import { Button, ConfirmModal } from '@finalytic/components';
import {
  showApiErrorNotification,
  useApiMutation,
  useBrowserTracking,
  useInvalidateQueries,
  useQuery,
  useTeam,
  useTeamId,
} from '@finalytic/data';
import { toTitleCase } from '@finalytic/utils';
import { Box, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { deleteCheckTenant } from '@vrplatform/ui-common';
import { useNavigate } from 'react-router';

export const TeamDeleteButton = () => {
  const [opened, handlers] = useDisclosure(false);
  const [, setTeamId] = useTeamId();
  const [{ id: teamId }, _refetchTeam] = useTeam();
  const goto = useNavigate();
  const { track } = useBrowserTracking();

  const invalidate = useInvalidateQueries(['teams']);

  const { mutateAsync: mutate, isPending: loading } = useApiMutation(
    'delete',
    '/teams/{id}',
    {
      onError: (error) =>
        showApiErrorNotification({
          error,
          defaultMessage:
            'Failed to delete team. Please try again and contact support if the issue persists.',
          title: 'Failed to delete team',
        }),
    }
  );

  const submit = async () =>
    mutate({
      params: {
        path: {
          id: teamId,
        },
      },
    }).then(() => {
      handlers.close();
      invalidate();
      track('team_deleted', {
        tenantId: teamId,
      });
      setTeamId(null);
      goto('/');
    });

  const { data, isLoading: loading2 } = useQuery(
    (q, { tenantId }: { tenantId: string }) => {
      return deleteCheckTenant(q, { tenantId });
    },
    {
      variables: { tenantId: teamId },
    }
  );

  const canDelete = !!data?.canDelete;
  const object = Object.entries(data || {}).filter(
    ([, value]) => typeof value === 'number' && value > 0
  ) as [string, number][];

  return (
    <>
      <Tooltip
        withArrow
        offset={15}
        label={`Please remove ${toTitleCase(
          object?.[0]?.[0]
        )?.toLowerCase()} first.`}
        disabled={canDelete}
      >
        <Box sx={{ display: 'inline' }}>
          <Button
            onClick={handlers.open}
            loading={loading || loading2}
            disabled={!canDelete}
            color="red"
            variant="filled"
          >
            Delete Team
          </Button>
        </Box>
      </Tooltip>
      <ConfirmModal
        type="delete"
        size={400}
        opened={opened}
        closeModal={handlers.close}
        onSubmit={submit}
        loading={loading}
        title="Are you sure you would like to delete this team?"
        subtitle="The team will be deleted immediately including all its data. This action cannot be undone."
      />
    </>
  );
};
