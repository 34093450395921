import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
} from '@finalytic/ui';
import { Box, Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router';
import { EllipsisMenuSourceDownloadItem } from '../../../components';
import { useDepositDetailDrawer } from '../../../drawers';
import { useExtractMutation } from '../../../hooks';
import { useJournalEntriesModal } from '../../../modals';
import type { DepositRow } from './useDepositsTableQuery';

export const DepositEllipsisMenuItems = ({
  deposit,
  openDeleteModal,
  openUnreconcileModal,
  isDrawer,
}: {
  deposit: DepositRow;
  openDeleteModal: () => void;
  openUnreconcileModal: () => void;
  isDrawer?: boolean;
}) => {
  const goto = useNavigate();
  const { open: openJournalEntriesModal } = useJournalEntriesModal();
  const { open: openQuickDetail } = useDepositDetailDrawer();
  const { mutate, loading: loadingExtract } = useExtractMutation();

  const connectionId = deposit.connection.id;
  const isSynced = !!connectionId;

  return (
    <>
      {!isDrawer && (
        <EllipsisMenuItem
          onClick={() => openQuickDetail(deposit.id)}
          customIcon={<Icon icon={'EyeIcon'} size={16} />}
        >
          Quick view
        </EllipsisMenuItem>
      )}

      {deposit.isReconciled && (
        <>
          <EllipsisMenuItem
            onClick={openUnreconcileModal}
            customIcon={<Icon icon={'CrossCircleIcon'} size={16} />}
          >
            Unmatch
          </EllipsisMenuItem>
          <EllipsisMenuDivider />
        </>
      )}

      {!isSynced && (
        <Tooltip
          label="This deposit is already matched and cannot be edited."
          disabled={!deposit.isReconciled}
        >
          <Box>
            <EllipsisMenuItem
              onClick={() => goto(`/deposit/${deposit.id}/edit`)}
              customIcon={<Icon icon={'Edit3Icon'} size={16} />}
              disabled={deposit.isReconciled}
            >
              Edit
            </EllipsisMenuItem>
          </Box>
        </Tooltip>
      )}

      {isSynced && !!connectionId && (
        <EllipsisMenuItem
          customIcon={<Icon icon={'RefreshCwIcon'} size={16} />}
          loading={loadingExtract}
          disabled={!deposit.uniqueRef}
          onClick={() =>
            deposit.uniqueRef &&
            mutate({
              connectionId,
              extractor: 'deposit',
              forceUpdate: true,
              params: {
                uniqueRef: deposit.uniqueRef,
                date: deposit.date,
              },
            })
          }
        >
          Refetch
        </EllipsisMenuItem>
      )}

      <EllipsisMenuItem
        customIcon={<Icon icon="ListUnorderedIcon" size={16} />}
        onClick={() =>
          openJournalEntriesModal({
            transactionId: {
              _eq: deposit.id,
            },
          })
        }
      >
        Journal Entries
      </EllipsisMenuItem>

      <EllipsisMenuDivider />

      <Tooltip
        label="This action is not available for synced deposits."
        withArrow
        disabled={!isSynced}
      >
        <Box>
          <EllipsisMenuDangerItem onClick={openDeleteModal} disabled={isSynced}>
            Delete
          </EllipsisMenuDangerItem>
        </Box>
      </Tooltip>

      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuCopyItem value={deposit.id} />

        {isSynced && (
          <EllipsisMenuSourceDownloadItem
            fileName={deposit.uniqueRef || 'deposit'}
            where={{
              transaction: {
                id: { _eq: deposit.id },
              },
            }}
          />
        )}
      </HiddenFeatureIndicator>
    </>
  );
};
