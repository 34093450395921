import { useInfiniteQuery, useTeamId } from '@finalytic/data';
import type {
  Maybe,
  activeStatus_enum,
  rate_bool_exp,
} from '@finalytic/graphql';

export type TaxRateFilterInput = {
  search?: Maybe<string>;
  status?: Maybe<activeStatus_enum>;
};

export function useTaxRatesTableQuery(filter: TaxRateFilterInput) {
  const [teamId] = useTeamId();
  return useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where: rate_bool_exp = {
        tenantId: { _eq: args.teamId },
        name: args.search ? { _ilike: `%${args.search}%` } : undefined,
        type: { _eq: 'tax' },
        status: { _eq: args.status || 'active' },
      };

      const list = q
        .rates({
          where,
          limit,
          offset,
          order_by: [{ name: 'asc' }],
        })
        .map((taxRate) => ({
          id: taxRate.id as string,
          title: taxRate.name!,
          accountId: (taxRate.accountId || null) as string | null,
          accountTitle: taxRate.account?.title,
          rateBasePoints: taxRate.basisPoints ?? 0,
          behavior: taxRate.behavior || null,
          status: taxRate.status || 'active',
          countryCode: taxRate.countryCode ?? null,
          currency: taxRate.currency ?? null,
        }));

      const aggregate = q.rateAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        teamId,
        search: filter.search?.trim(),
        status: filter.status,
      },
      queryKey: ['taxRates'],
    }
  );
}

export type TaxRateRow = NonNullable<
  ReturnType<typeof useTaxRatesTableQuery>['data']
>['pages'][0]['list'][number];
