import type {
  activeStatus_enum,
  paidStatus_enum,
  timestamptz_comparison_exp,
  transactionType_enum,
  transaction_bool_exp,
} from '@finalytic/graphql';
import { type Maybe, day } from '@finalytic/utils';

type Props = {
  type: transactionType_enum;
  tenantId: string;
  search?: Maybe<string>;
  listingIds?: Maybe<string[]>;
  date?: Maybe<string>;
  accountId?: Maybe<(string & {}) | 'non-trust'>;
  bankAccountId?: Maybe<string>;
  connectionId?: Maybe<(string & {}) | 'manual'>;
  contactId?: Maybe<string>;
  status?: Maybe<activeStatus_enum>;
  paidStatus?: Maybe<paidStatus_enum>;
  reconciled?: Maybe<boolean>;
};

export const whereTransactions = ({
  tenantId,
  date,
  listingIds,
  search,
  type,
  status,
  accountId,
  paidStatus,
  bankAccountId,
  reconciled,
  contactId,
  connectionId,
}: Props): transaction_bool_exp => {
  const getDate = (): timestamptz_comparison_exp | undefined => {
    if (!date) return undefined;

    const [start, end] = date.split('...');

    if (!end)
      return {
        _gte: start,
        _lt: day(start).add(1, 'day').yyyymmdd(),
      };

    return {
      _gte: start,
      _lte: end,
    };
  };

  const isNonTrustAccount = accountId === 'non-trust';

  const _and: transaction_bool_exp['_and'] = [
    {
      _or: [
        {
          uniqueRef: { _nilike: 'openingBalance_%' },
        },
        {
          uniqueRef: { _is_null: true },
        },
      ],
    },
  ];

  if (search) {
    _and.push({
      _or: [
        {
          description: { _ilike: `%${search}%` },
        },
        {
          uniqueRef: { _ilike: `%${search}%` },
        },
        {
          connection: {
            name: { _ilike: `%${search}%` },
          },
        },
        {
          journalEntries: {
            listingOwnershipPeriod: {
              listing: {
                calculated_title: { _ilike: `%${search}%` },
              },
            },
          },
        },
        {
          journalEntries: {
            listingOwnershipPeriod: {
              members: {
                contact: {
                  _or: [
                    {
                      firstName: { _ilike: `%${search}%` },
                    },
                    {
                      name: { _ilike: `%${search}%` },
                    },
                  ],
                },
              },
            },
          },
        },
      ],
    });
  }

  if (contactId) {
    _and.push({
      _or: [
        { contactId: { _eq: contactId } },
        {
          journalEntries: {
            listingOwnershipPeriod: {
              members: {
                contactId: { _eq: contactId },
              },
            },
          },
        },
      ],
    });
  }

  if (bankAccountId || typeof reconciled === 'boolean') {
    if (bankAccountId || reconciled === true) {
      _and.push({
        bankRecordPayments: {
          bankRecord: bankAccountId
            ? {
                bankAccountId: { _eq: bankAccountId },
              }
            : undefined,
        },
      });
    }

    if (reconciled === false) {
      _and.push({
        _not: {
          bankRecordPayments: {},
        },
      });
    }
  }

  return {
    tenantId: { _eq: tenantId },
    type: { _eq: type },
    date: getDate(),
    status: { _eq: status || 'active' },
    journalEntries: {},
    connectionId: connectionId
      ? {
          _eq: connectionId === 'manual' ? undefined : connectionId,
          _is_null: connectionId === 'manual' ? true : undefined,
        }
      : undefined,
    accountId: accountId
      ? isNonTrustAccount
        ? { _is_null: true }
        : { _eq: accountId }
      : undefined,
    paidStatus: paidStatus
      ? { _eq: paidStatus }
      : isNonTrustAccount
        ? { _eq: 'paid' }
        : undefined,
    lines: listingIds?.length
      ? {
          listingId: {
            _in: listingIds,
          },
        }
      : undefined,
    _and,
  };
};
