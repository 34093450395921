import { Button, IconButton, Select } from '@finalytic/components';
import {
  type gqlV2,
  useDashboard,
  useInfiniteQuery,
  useMe,
  useMutation,
  useTeamId,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import type { SelectItem } from '@finalytic/ui';
import { utc } from '@finalytic/utils';
import {
  formatUserName,
  getListingName,
  whereListings,
  whereOwnerUsers,
} from '@vrplatform/ui-common';
import { type ComponentProps, useMemo, useState } from 'react';
import { useAddUserAccessDrawer } from '../../user-access-drawers';

export const AddEntityToOwnerButton = ({
  ownerId,
  type,
  title,
}: { ownerId: string; type: 'statement' | 'user'; title?: string }) => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const [search, setSearch] = useState('');
  const { id: meId } = useMe();

  const { open: openInviteOwnerUserDrawer } = useAddUserAccessDrawer();

  const queryData = useInfiniteQuery(
    (
      q,
      { teamId, dashboard, search, ownerId, type, meId },
      { limit, offset }
    ) => {
      if (type === 'user') {
        const where: gqlV2.user_bool_exp = {
          ...whereOwnerUsers({
            teamId,
            search,
          }),
          _not: {
            ownerAccesses: {
              contactId: { _eq: ownerId },
              contact: {
                tenantId: { _eq: teamId },
              },
            },
          },
        };

        const list = q
          .user({
            where,
            limit,
            offset,
            order_by: [{ firstName: 'asc_nulls_last' }],
          })
          .map<SelectItem>((user) => ({
            label: formatUserName(user),
            value: user.id,
            description: user.email,
          }));

        const aggregate = q.userAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      if (type === 'statement') {
        const where: gqlV2.owner_statement_bool_exp = {
          tenantId: { _eq: teamId },
          listing: {
            ownerships: {
              role: {
                _eq: 'spectator',
              },
              ownerId: { _eq: ownerId },
            },
            _or: search
              ? [
                  { title: { _ilike: `%${search}%` } },
                  { name: { _ilike: `%${search}%` } },
                  { address: { _ilike: `%${search}%` } },
                ]
              : undefined,
          },
          _not: {
            owners: {
              newOwnerId: {
                _eq: ownerId,
              },
            },
          },
        };

        const list = q
          .ownerStatements({
            where,
            limit,
            offset,
            order_by: [{ startAt: 'desc' }],
          })
          .map<SelectItem>((statement) => ({
            label: `${getListingName(statement.listing!)} - ${utc(
              statement.startAt
            ).format('MMM YYYY')}`,
            value: statement.id,
          }));

        const aggregate =
          q.ownerStatementAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }

      const where: gqlV2.listing_bool_exp = {
        ...whereListings({
          tenantId: teamId,
          dashboard,
          search,
          meId,
          GL: true,
        }),
        _not: {
          ownerships: {
            newOwnerId: {
              _eq: ownerId,
            },
          },
        },
      };

      const list = q
        .listings({
          where,
          limit,
          offset,
          order_by: [
            {
              calculated_title: 'asc_nulls_last',
            },
          ],
        })
        .map<SelectItem>((listing) => ({
          label: getListingName(listing),
          value: listing.id,
        }));
      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        ownerId,
        type,
        meId,
      },
    }
  );

  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        entityId: string;
        ownerId: string;
        type: 'statement' | 'user';
      }
    ) => {
      if (args.type === 'statement') {
        return q.insertOwnerStatementOwner({
          object: {
            statementId: args.entityId,
            newOwnerId: args.ownerId,
            role: 'spectator',
          },
        })?.id as string | undefined;
      }

      if (args.type === 'user') {
        return q.insertOwnerUserAccess({
          object: {
            userId: args.entityId,
            contactId: args.ownerId,
          },
        })?.id as string | undefined;
      }

      return null;
    },
    {
      invalidateQueryKeys: ['owners', 'tenantUsers'],
      errorMessage: {
        message: (error) =>
          error?.message?.includes('owner_user_access_user_id_contact_id_key')
            ? 'This owner already has this user assigned. Please refresh the page.'
            : error?.message ||
              'Please reach out to support if the issue persists.',
      },
    }
  );

  const inviteOwnerOption = useMemo<
    ComponentProps<typeof Select>['customBottomActions']
  >(
    () => [
      {
        id: 'invite-owner',
        label: 'Invite new user',
        description:
          'Invite a user to access the owner portal. They will receive an email to join.',
        icon: <Icon icon="EmailIcon" size={16} />,
        onSubmit: () =>
          openInviteOwnerUserDrawer({ ownerId, updateType: 'push' }),
      },
    ],
    [ownerId, openInviteOwnerUserDrawer]
  );

  return (
    <Select
      infiniteData={{ ...queryData, setSearch }}
      type="single"
      value={null}
      setValue={(value) => {
        if (!value) return;
        const entityId = value.value;
        mutate({ args: { entityId, ownerId, type } });
      }}
      customBottomActions={inviteOwnerOption}
      dropdownProps={{
        position: 'bottom-end',
        width: 300,
        noOptionsText:
          type === 'user' ? 'No users' : 'No owner statements available',
      }}
      inputProps={{
        loadingMutation: loading,
      }}
    >
      {({ loadingMutation }) => {
        if (title) {
          return (
            <Button
              variant="light"
              leftIcon="PlusIcon"
              loading={loadingMutation}
            >
              {title}
            </Button>
          );
        }

        return (
          <IconButton
            onClick={undefined}
            loading={loadingMutation}
            icon="PlusIcon"
            size={18}
          />
        );
      }}
    </Select>
  );
};
