import { Button, InputWrapper } from '@finalytic/components';
import {
  type QueryKeyUnion,
  captureSentryError,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { getCsvUrl } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import type { RouterInput } from '@finalytic/trpc-api';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarnNotification,
  useAppName,
} from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import {
  Anchor,
  Box,
  Card,
  Collapse,
  Modal,
  ScrollArea,
  Stack,
  Text,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, type FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { useDisclosure } from '@mantine/hooks';
import {
  type ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { CsvRowError } from './_types';

type FormInputs = {
  file: FileWithPath;
};

type ImportUniqueRef = RouterInput['importCsv']['input']['uniqueRef'];

const transformFile = async (file: FileWithPath) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
};

type BaseRow = Record<string, string | null | boolean | number>;

type Props = {
  defaultRows: BaseRow[];
  opened: boolean;
  closeModal: () => void;
  successMessage: {
    title: string;
    message: string;
  };
  modal: {
    title: string;
    documentationUrl?: string;
    uniqueRef: ImportUniqueRef;
  };
  invalidateQueryKeys: (QueryKeyUnion | (string & {}))[];
  bankAccountId?: string;
  connectionId: string | undefined;
};

export const BaseCsvImportModal = ({
  defaultRows: initialRows,
  opened,
  closeModal,
  successMessage,
  modal,
  invalidateQueryKeys,
  bankAccountId,
  connectionId,
}: Props) => {
  const [teamId] = useTeamId();
  const { mutate } = useTrpcMutation('importCsv', {
    invalidateQueryKeys,
  });

  const defaultRows = useMemo(() => initialRows, [initialRows]);

  const handleResult = useCallback<
    ComponentProps<typeof ModalBase>['handleResult']
  >(
    async (fileData: string) => {
      if (!connectionId) {
        const title = 'Failed to find connection ID for this import.';
        captureSentryError(title);
        showWarnNotification({
          title,
          message: 'Please contact support if this issue persists.',
        });

        return {
          errors: [],
        };
      }

      const result = await mutate({
        tenantId: teamId,
        input: {
          fileData,
          connectionId,
          uniqueRef: modal.uniqueRef,
          params: bankAccountId ? { bankAccountId } : undefined,
        },
      });

      if (result === undefined) {
        showSuccessNotification({
          title: successMessage.title,
          message: successMessage.message,
        });

        return {};
      }

      return {
        errors: result.error.context.errors,
      };
    },
    [
      mutate,
      successMessage.title,
      successMessage.message,
      teamId,
      modal.uniqueRef,
      bankAccountId,
      connectionId,
    ]
  );

  return (
    <ModalBase
      closeModal={closeModal}
      opened={opened}
      title={modal.title}
      type={modal.uniqueRef}
      handleResult={handleResult}
      defaultRows={defaultRows}
    />
  );
};

const ModalBase = <TRow extends BaseRow>({
  closeModal: cl,
  opened,
  title,
  type,
  defaultRows,
  handleResult,
  documentationUrl,
}: {
  opened: boolean;
  closeModal: () => void;
  title: string;
  type: ImportUniqueRef;
  handleResult: (
    fileData: string
  ) => Promise<{ errors?: CsvRowError[]; data?: TRow[] }>;
  defaultRows: TRow[];
  documentationUrl?: string;
}) => {
  const [fileErrors, setFileErrors] = useState<CsvRowError[]>([]);
  const { appName } = useAppName();

  const theme = useMantineTheme();
  const methods = useForm<FormInputs>();

  const templateHref = useMemo(() => {
    return getCsvUrl(defaultRows);
  }, [defaultRows]);

  const closeModal = useCallback(() => {
    methods.reset({
      file: undefined,
    });
    methods.clearErrors();
    setFileErrors([]);
    cl();
  }, [methods, cl]);

  const submit = useCallback(
    async (data: FormInputs) => {
      try {
        const fileData = await transformFile(data.file);

        if (typeof fileData !== 'string') {
          throw new Error('Failed to parse CSV to string');
        }

        const res = await handleResult(fileData);

        if (res.errors?.length) {
          setFileErrors(res.errors);

          return;
        }

        closeModal();
      } catch (error: any) {
        captureSentryError(error);
        showWarnNotification({
          title: 'Invalid file',
          message: error?.message || 'Failed to parse CSV file.',
        });
      }
    },
    [handleResult, closeModal]
  );

  useEffect(() => {
    if (!opened)
      methods.reset({
        file: undefined,
      });
  }, [opened, methods.reset]);

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={title}
      size={500}
      centered
      styles={{
        title: {
          fontWeight: 500,
          fontSize: theme.fontSizes.lg,
        },
        content: {
          padding: theme.spacing.sm,
        },
      }}
    >
      <Text component="p" c="neutral" mb="xs">
        Upload a CSV file to add {type} to {appName}. Your CSV should include
        header and follow specified structure.
      </Text>

      <Text component="p" c="neutral" mb="md">
        <Anchor href={templateHref} download={`${type}_import_template.csv`}>
          Download CSV Template
        </Anchor>
        {documentationUrl && (
          <>
            {' '}
            or read{' '}
            <Anchor
              href={documentationUrl}
              target="_blank"
              rel="noopener,noreferrer"
            >
              documentation
            </Anchor>
            .
          </>
        )}
      </Text>

      <Box
        component="form"
        onSubmit={methods.handleSubmit(submit)}
        onReset={closeModal}
      >
        <Controller
          control={methods.control}
          name="file"
          rules={{
            required: 'Please upload a file.',
            validate: (value) => {
              if (!value) return 'Please upload a file.';
              if (value.type !== MIME_TYPES.csv) return 'File must be CSV.';
              return true;
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <InputWrapper
                label={'Upload CSV'}
                error={fieldState.error?.message}
              >
                <DropzoneInput
                  type={type}
                  setFileErrors={setFileErrors}
                  onChange={field.onChange}
                  fileErrors={fileErrors}
                  resetFile={() =>
                    methods.reset({
                      file: undefined,
                    })
                  }
                  file={field.value}
                  error={!!fieldState.error}
                />
              </InputWrapper>
            );
          }}
        />

        <Box
          mt="lg"
          sx={(theme) => ({
            display: 'flex',
            flexWrap: 'nowrap',
            gap: theme.spacing.sm,
          })}
        >
          <Button type="reset" disabled={methods.formState.isSubmitting}>
            Cancel
          </Button>
          <Button
            disabled={!methods.formState.isValid}
            loading={methods.formState.isSubmitting}
            type="submit"
            variant="primary"
            sx={{
              flex: 1,
            }}
          >
            Import {toTitleCase(type).toLowerCase()}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const DropzoneInput = ({
  type,
  onChange,
  error,
  file,
  resetFile,
  fileErrors,
  setFileErrors,
}: {
  type: ImportUniqueRef;
  onChange: (file: FileWithPath) => void;
  error: boolean;
  resetFile: () => void;
  fileErrors: CsvRowError[];
  setFileErrors: (fileErrors: CsvRowError[]) => void;
  file: FileWithPath | undefined;
}) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const [loading, setLoading] = useState(false);
  const [opened, handlers] = useDisclosure(false);

  const iconSize = 30;
  const inputHeight = 105;

  const resetDropZone = () => {
    resetFile();
  };

  return (
    <>
      <Dropzone
        loading={loading}
        onFileDialogOpen={resetDropZone}
        onDrop={(files) => {
          const file = files[0];
          setLoading(true);
          onChange(file);
          setFileErrors([]);
          setLoading(false);
        }}
        onReject={(files) => {
          const file = files[0];
          console.log('rejected file', file);
          const message =
            file.errors[0]?.message || 'Please upload a valid CSV file.';
          captureSentryError(message);
          showErrorNotification({
            title: 'Invalid file',
            message,
          });
        }}
        maxSize={3 * 1024 ** 2}
        accept={[MIME_TYPES.csv]}
        multiple={false}
        sx={(theme) => {
          const errorBorderColor =
            theme.colors.red[colorScheme === 'dark' ? 4 : 6];
          const defaultBorderColor =
            theme.colors.neutral[colorScheme === 'dark' ? 4 : 2];

          return {
            borderColor: error ? errorBorderColor : defaultBorderColor,
            borderWidth: '2px',
            borderRadius: rem(6),
            borderStyle: 'dashed',
            paddingBlock: theme.spacing.md,
            marginTop: rem(5),
            '&[data-accept]': {
              borderColor: theme.colors[theme.primaryColor][6],
              backgroundColor:
                theme.colors.neutral[colorScheme === 'dark' ? 3 : 0],
            },
            '&[data-reject]': {
              borderColor: errorBorderColor,
            },
          };
        }}
      >
        <Stack
          gap="md"
          align="center"
          justify="center"
          style={{ minHeight: rem(inputHeight), pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <Icon
              size={iconSize}
              strokeWidth={1}
              icon="CheckCircleIcon"
              color={
                theme.colors[theme.primaryColor][colorScheme === 'dark' ? 4 : 6]
              }
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <Icon
              size={iconSize}
              strokeWidth={1}
              icon="AlertTriangleIcon"
              color={theme.colors.red[colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <Icon
              icon={file ? 'FileTextIcon' : 'ImportIcon'}
              size={iconSize}
              strokeWidth={1}
            />
          </Dropzone.Idle>

          {file ? (
            <div>
              <Text
                size="sm"
                ta="center"
                fw={500}
                c={theme.colors[theme.primaryColor][6]}
              >
                {file.name}
                <br />
              </Text>
            </div>
          ) : (
            <div>
              <Text size="sm" c="dimmed" mt={7} ta="center">
                Drag and drop {toTitleCase(type).toLowerCase()} CSV file
                <br />
                or{' '}
                <Text
                  c={theme.colors[theme.primaryColor][6]}
                  component="span"
                  display="inline"
                >
                  select file on disk
                </Text>
              </Text>
            </div>
          )}
        </Stack>
      </Dropzone>

      {!!fileErrors.length && (
        <Card
          mt="sm"
          sx={(theme) => ({
            backgroundColor: theme.colors.red[colorScheme === 'dark' ? 3 : 0],
          })}
        >
          <Text component="p" m={0} fw={500} size="sm" mb="xs">
            Found {fileErrors.length} errors in the file
          </Text>
          <Button
            type="button"
            size="xs"
            onClick={(e) => {
              e.preventDefault();
              handlers.toggle();
            }}
            leftIcon={'FileTextIcon'}
            sx={{
              backgroundColor: 'transparent',
              ':hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            View errors
          </Button>
          <Collapse in={opened}>
            <ScrollArea
              h={rem(inputHeight + 50)}
              mt="xs"
              px={'xs'}
              sx={{
                backgroundColor: '#ffffff80',
              }}
            >
              {fileErrors.map((error, index) => (
                <Text key={index} color="neutral" component="p" size="xs" m={0}>
                  {error.rowNumber
                    ? error.rowNumber === 0
                      ? 'Header: '
                      : `Row ${error.rowNumber}: `
                    : ''}
                  {error.message}
                </Text>
              ))}
            </ScrollArea>
          </Collapse>
        </Card>
      )}
    </>
  );
};
