import _Intercom, {
  boot,
  hide,
  onHide,
  onShow,
  onUnreadCountChange,
  show,
  showArticle,
  showNewMessage,
  shutdown,
  trackEvent,
  update,
} from '@intercom/messenger-js-sdk';
import type {
  InitType,
  IntercomSettings,
} from '@intercom/messenger-js-sdk/dist/types';
import { useEffect, useMemo, useRef } from 'react';
import { create } from 'zustand';

const INTERCOM_APP_ID = 'ec8spyh1';

let isReady = false;
let hasUserHash = false;

const useIntercomStore = create<{
  unreadCount?: number;
  setUnreadCount: (count: number) => void;
}>()((set, _get) => ({
  unreadCount: undefined,
  setUnreadCount: (count) => set({ unreadCount: count }),
}));

export const Intercom = (props: Partial<InitType>) => {
  isReady = true;

  _Intercom({ app_id: INTERCOM_APP_ID, ...props });
};

export const useIntercom = () => {
  const isBooted = useRef(false);
  const [unreadMessageCount, setUnread] = useIntercomStore((state) => [
    state.unreadCount,
    state.setUnreadCount,
  ]);
  useEffect(() => {
    if (isReady && hasUserHash) onUnreadCountChange(setUnread);
  }, [isReady, hasUserHash]);

  return useMemo<{
    trackEvent: typeof trackEvent;
    update: typeof update;
    hide: typeof hide;
    onHide: typeof onHide;
    show: typeof show;
    onShow: typeof onShow;
    showArticle: typeof showArticle;
    showNewMessage: typeof showNewMessage;
    shutdown: typeof shutdown;
    boot: (arg: Omit<IntercomSettings, 'app_id'>) => void;
    unreadMessageCount: number | undefined;
  }>(
    () => ({
      unreadMessageCount,
      // Functions
      trackEvent: (...args) => {
        if (!isBooted.current || !isReady) return;
        trackEvent(...args);
      },
      update: (...args) => {
        if (!isBooted.current || !isReady) return;
        update(...args);
      },
      hide: () => {
        if (!isBooted.current || !isReady) return;
        hide();
      },
      onHide: (...args) => {
        if (!isBooted.current || !isReady) return;
        onHide(...args);
      },
      show: () => {
        if (!isBooted.current || !isReady) return;
        show();
      },
      onShow: (...args) => {
        if (!isBooted.current || !isReady) return;
        onShow(...args);
      },
      showArticle: (...args) => {
        if (!isBooted.current || !isReady) return;
        showArticle(...args);
      },
      // modified
      showNewMessage: (...args) => {
        if (!isBooted.current || !isReady) return;
        showNewMessage(...args);
      },
      boot: (arg: Omit<IntercomSettings, 'app_id'>) => {
        hasUserHash = !!arg.user_hash;
        if (!isReady || !hasUserHash) return;

        isBooted.current = true;
        boot({ app_id: INTERCOM_APP_ID, ...arg });
      },
      shutdown: () => {
        hasUserHash = false;
        isBooted.current = false;

        shutdown();
      },
    }),
    [unreadMessageCount]
  );
};
