import type {
  CalendarFilterDateType,
  CheckInOutType,
} from '@finalytic/components';
import { day, ensure, hasValue } from '@finalytic/utils';
import { useReservationFilter } from './ReservationFilter';

import {
  useDashboard,
  useEnabledFeatures,
  useMe,
  useTeamId,
} from '@finalytic/data';
import {
  type ReservationsFilterParams,
  whereReservations,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';

export function useWhereReservations(opts: {
  filterByAutomationId: string | undefined;
  hideCancellations: boolean;
}) {
  const filter = useFormattedReservationFilter(opts);

  const onlyEnabledListingByAutomationId = opts.filterByAutomationId;

  return useWhereReservationsBase(
    onlyEnabledListingByAutomationId
      ? {
          ...filter,
          onlyEnabledListingByAutomationId,
        }
      : filter
  );
}

export function useFormattedReservationFilter({
  hideCancellations,
}: {
  hideCancellations: boolean;
}) {
  const { filter } = useReservationFilter();

  const getDate = (date: string | undefined | null) => {
    if (!date) return undefined;

    const [start, end] = date.split('...');

    return ensure<CalendarFilterDateType>([
      start ? day(start).toDate() : null,
      end ? day(end).toDate() : null,
    ]);
  };

  return {
    bookingChannels: filter.bookingChannels?.filter(hasValue) || undefined,
    connectionIds: filter.connectionIds?.filter(hasValue) || undefined,
    date: getDate(filter.date),
    dateType: (filter.dateType as CheckInOutType) || 'all',
    listingIds: filter.listingIds?.filter(hasValue) || undefined,
    search: filter.search || undefined,
    listingStatus: (filter.listingStatus as any) || undefined,
    status: hideCancellations ? 'booked' : filter.status || undefined,
    listingCollectionId: filter.listingCollectionId || undefined,
    onlyEnabledListingByAutomationId: undefined,
    paidStatus: (filter.paidStatus ||
      undefined) as ReservationsFilterParams['paidStatus'],
  };
}

export function useWhereReservationsBase(filter: ReservationsFilterParams) {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { id: meId } = useMe();
  const { GL } = useEnabledFeatures();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const where = useMemo(
    () =>
      whereReservations({
        tenantId: teamId,
        dashboard,
        includeLines: false,
        meId,
        GL,
        status: '',
        ...filter,
      }),
    [JSON.stringify(filter), teamId, dashboard, meId, GL]
  );

  return where;
}
