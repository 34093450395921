import { useTeamRole } from '@finalytic/data';
import { useEnabledFeatures } from '@finalytic/data';
import { useLegacyColors } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import {
  Box,
  type BoxProps,
  type FloatingPosition,
  Tooltip,
} from '@mantine/core';
import type { PropsWithChildren } from 'react';

type Permission = 'super-admin' | 'partner-admin' | 'vrp-admin';

type FeatureKey = keyof ReturnType<typeof useEnabledFeatures>;

type Props = {
  permission: Permission | FeatureKey;
  tooltipPosition?: FloatingPosition;
  hideTooltip?: boolean;
  customTooltip?: React.ReactNode;
};

function useHiddenFeature(permission: Permission | FeatureKey) {
  const { isPartnerAdmin, isSuperAdmin, isVrpAdmin } = useTeamRole();
  const features = useEnabledFeatures();

  if (permission === 'super-admin' && !isSuperAdmin) return false;
  if (permission === 'partner-admin' && !isPartnerAdmin) return false;
  if (permission === 'vrp-admin' && !isVrpAdmin) return false;
  if (
    Object.keys(features).includes(permission) &&
    !features[permission as FeatureKey]
  )
    return false;
  return true;
}

export const HiddenFeatureIndicator = ({
  permission,
  children,
  sx,
  tooltipPosition,
  hideTooltip,
  customTooltip,
  ...boxProps
}: PropsWithChildren<Props & BoxProps>) => {
  const { purple, themeColors } = useLegacyColors();
  const isEnabled = useHiddenFeature(permission);

  if (!isEnabled) return null;

  const permissionColors: Record<Permission | (string & {}), string> = {
    'partner-admin': purple.base,
    'super-admin': themeColors.orange[4],
    'vrp-admin': themeColors.yellow[4],
  };

  const permissionLabels: Record<Permission | (string & {}), string> = {
    'partner-admin': `${toTitleCase(permission)} only`,
    'super-admin': `${toTitleCase(permission)} only`,
    'vrp-admin': `${toTitleCase(permission)} only`,
  };

  const borderColor = permissionColors[permission] || 'gold';
  const tooltipLabel =
    customTooltip || permissionLabels[permission] || 'Upcoming Feature';

  return (
    <Tooltip
      label={tooltipLabel}
      withArrow
      withinPortal
      position={tooltipPosition}
      disabled={hideTooltip}
    >
      <Box
        sx={{
          border: '1.5px solid',
          borderColor,
          borderRadius: '6px',
          ...(sx as any),
        }}
        {...boxProps}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
