import { InputWrapper } from '@finalytic/components';
import { useTeam } from '@finalytic/data';
import { Avatar, Group, Text, rem } from '@mantine/core';
import { useAccountingConnection } from '../hooks';
import { useStatementAutomation } from '../views/statements/_hooks';
import { AutomationMappingEditor } from './automation-mappings';

type Props = {
  ownerId: string;
  withLabel?: boolean;
};

export const VendorMappingSelect = ({ ownerId, withLabel = true }: Props) => {
  const { accounting } = useAccountingConnection();
  const accountingName = accounting?.name;
  const {
    automation: { accountingConnectionId, automation, finalyticConnectionId },
  } = useVendorInfo();

  if (!accountingConnectionId || !finalyticConnectionId || !automation)
    return null;

  if (!withLabel) return <Select ownerId={ownerId} />;

  return (
    <InputWrapper
      label={
        <Group wrap="nowrap" gap={rem(8)}>
          <Text component="span">
            Select vendor from {accountingName || 'accounting'}
          </Text>
          {accounting?.icon && <Avatar size="xs" src={accounting?.icon} />}
        </Group>
      }
      description="Select the vendor from your accounting system that corresponds to this owner."
      inputWrapperOrder={['label', 'input', 'description']}
      mb="xs"
      styles={{
        description: {
          marginTop: rem(4),
        },
      }}
    >
      <Select ownerId={ownerId} />
    </InputWrapper>
  );
};

function useVendorInfo() {
  const [{ finalyticConnectionId }] = useTeam();
  const { accounting } = useAccountingConnection();
  const { billAutomation: automation } = useStatementAutomation();
  const accountingConnectionId = accounting?.id;

  const rightType = `${accounting?.appId}.vendor`;
  const leftType = 'finalytic.owner';
  const key = 'vendor';
  return {
    mapping: {
      rightType,
      leftType,
      key,
      rightParams: automation?.mappings?.[key]?.right?.params || {},
      leftParams:
        automation?.mappings?.[key]?.left?.params ||
        automation?.mappings?.[key]?.params ||
        {},
    },
    automation: {
      automation,
      finalyticConnectionId,
      accountingConnectionId,
    },
    accounting,
  };
}

const Select = ({ ownerId }: Props) => {
  const {
    automation: { accountingConnectionId, automation, finalyticConnectionId },
    mapping: { key, leftType, rightType, rightParams, leftParams },
    accounting,
  } = useVendorInfo();

  if (!automation || !accounting || !accountingConnectionId) return null;

  return (
    <AutomationMappingEditor
      automation={{
        automationId: automation.automationId,
        leftConnectionId: finalyticConnectionId,
        rightConnectionId: accountingConnectionId,
        leftAppId: automation.leftConnection?.appId,
        rightAppId: automation.rightConnection?.appId,
        templateId: automation.templateId,
      }}
      mapping={{
        isLocal: false,
        label: 'Vendor User Mapping',
        leftType,
        rightType,
        settingKey: key,
        altLeftTypes: [],
        exceptionFilters: undefined,
        rightParams,
        leftParams,
      }}
      targetId={ownerId}
      parentSetting={null}
    />
  );
};
