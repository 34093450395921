import {
  captureSentryError,
  showApiErrorNotification,
  useApiMutation,
  useInvalidateQueries,
} from '@finalytic/data';
import type { party_enum } from '@finalytic/graphql';
import { showWarnNotification } from '@finalytic/ui';
import { bankersRound } from '@finalytic/utils';
import { useCallback } from 'react';

export type ReservationAdjustmentAddInputs = {
  description: string;
  lineType: string | null;
  centTotal?: number;
  party: party_enum;
};

export function useReservationAdjustmentMutation(
  reservationId: string | null,
  onSuccess: () => void
) {
  const invalidate = useInvalidateQueries(['reservations']);

  const { mutateAsync, isPending: loading } = useApiMutation(
    'put',
    '/reservations/{id}',
    {
      onSuccess: () => {
        invalidate();
      },
    }
  );

  const submit = useCallback(
    async (
      args:
        | { data: ReservationAdjustmentAddInputs; type: 'add' }
        | {
            data: {
              id: string;
            };
            type: 'remove';
          }
    ) => {
      if (!reservationId) {
        captureSentryError('Missing reservationId');
        return showWarnNotification({
          title: 'Missing reservation',
          message: 'Please reach out to support if the issue persists.',
        });
      }

      try {
        await mutateAsync({
          params: {
            path: {
              id: reservationId,
            },
          },
          body: {
            adjustments_create:
              args.type === 'add'
                ? [
                    {
                      description: args.data.description.trim() || null,
                      type: (args.data.lineType || undefined) as any,
                      amount: bankersRound(args.data.centTotal ?? 0),
                      party: args.data.party ?? 'owners',
                    },
                  ]
                : undefined,
            adjustments_delete:
              args.type === 'remove' ? [args.data.id] : undefined,
          },
        });

        onSuccess();
      } catch (error: any) {
        showApiErrorNotification({
          title: 'Failed to update reservation',
          defaultMessage:
            'We failed to update the reservation. Please try again later and if the problem persists, contact support.',
          error,
        });
      }
    },
    [reservationId, onSuccess, mutateAsync]
  );

  return {
    loading,
    submit,
  };
}
