import { useEnabledFeatures, useQuery, useTeamId } from '@finalytic/data';
import type { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import {
  formatOwnerName,
  formatUserName,
  getOwnerStatus,
  getUserAddress,
} from '@vrplatform/ui-common';

export type UserAccessDetail = NonNullable<
  ReturnType<typeof useUserAccessDetailDrawerQuery>['data']
>;

export function useUserAccessDetailDrawerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .user({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((user) => {
            const { status, isReinvite, tenantUser } = getOwnerStatus(
              user,
              args.teamId
            );

            const address = getUserAddress(user).values;

            return {
              id: user.id,
              taxId: user.taxId,
              addressId: user.address_id,
              status,
              isReinvite,
              email: user.email,
              secondaryEmails: user.secondaryEmails(),
              phone: user.phone,
              name: formatUserName(user),
              firstName: user.firstName,
              lastName: user.lastName,
              companyName: user.companyName,
              teamId: tenantUser.tenantId,
              address,
              ownerAccesses: user
                .ownerAccesses({
                  where: args.GL
                    ? {
                        contact: { tenantId: { _eq: args.teamId } },
                      }
                    : {
                        owner: { tenantId: { _eq: args.teamId } },
                      },
                  order_by: [
                    {
                      createdAt: 'asc_nulls_last',
                    },
                  ],
                })
                .map((access) => {
                  if (args.GL)
                    return {
                      id: access.id,
                      ownerId: access.contactId,
                      name: formatOwnerName(access.contact),
                      type: access.contact?.companyType
                        ? 'company'
                        : 'individual',
                      role: access.role,
                    };

                  return {
                    id: access.id,
                    ownerId: access.ownerId,
                    name: formatOwnerName(access.owner),
                    type: access.owner?.type || 'individual',
                    role: access.role,
                  };
                }),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'owners',
      variables: {
        id,
        teamId,
        GL,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}
