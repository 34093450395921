import { useTeam } from '@finalytic/data';
import { useMemo } from 'react';
import { useAccountingConnection } from '../../../hooks';
import { useAutomationsQuery } from '../../../queries';

export function useStatementAutomation() {
  const [{ id: teamId }] = useTeam();
  const { data: automations = [] } = useAutomationsQuery();

  const { accounting } = useAccountingConnection();

  const { billAutomation, connection, statementAutomation } = useMemo(() => {
    // Owner statement automation
    const templateAutomations = automations.filter((automation) =>
      automation.template.uniqueRef?.toLowerCase().endsWith('_ownerstatements')
    );

    const statementAutomation = templateAutomations.find((a) =>
      Object.values(a.connections).includes(accounting?.id || '')
    );

    const connection = Object.entries(
      statementAutomation?.connections || {}
    ).filter(([appId]) => appId !== 'finalytic')[0];

    // Owner statement bill automation
    const billAutomations = automations.filter((automation) =>
      automation.template.uniqueRef
        ?.toLowerCase()
        .endsWith('_ownerstatementbills')
    );
    const billAutomation = billAutomations.find((a) =>
      Object.values(a.connections).includes(accounting?.id || '')
    );

    return {
      statementAutomation,
      billAutomation,
      connection,
    };
  }, [automations, accounting, teamId]);

  return {
    automation: statementAutomation,
    billAutomation,
    connectionId: connection?.[1] as string | undefined,
    appId: connection?.[0] as string | undefined,
  };
}
