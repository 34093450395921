import { TaxId } from '@finalytic/components';
import { Badge } from '@finalytic/components';
import { type gqlV2, useEnabledFeatures } from '@finalytic/data';
import type { activeStatus_enum } from '@finalytic/graphql';
import {
  BriefcaseIcon,
  CreditCardOutcomeIcon,
  EmailIcon,
  HashtagIcon,
  LoaderIcon,
  PhoneIcon,
  PinIcon,
} from '@finalytic/icons';
import { Icon } from '@finalytic/icons';
import { useAppName } from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { Box, Center, Group, Tooltip, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';
import { formatAddress } from '@vrplatform/ui-common';
import type { ComponentProps } from 'react';
import { DrawerInfoCard } from '../../_components';
import type { OwnerDetail } from '../useOwnerDetailDrawerQuery';

type Props = {
  owner: {
    companyType: string | undefined;
    status: Maybe<activeStatus_enum>;
    pmsStatus: Maybe<activeStatus_enum>;
    address: OwnerDetail['address'];
    email: Maybe<string>;
    phone: Maybe<string>;
    taxId: Maybe<string>;
    payoutAccountName: Maybe<string>;
  };
  withPlaceholder?: boolean;
  defaultPayoutAccountName: Maybe<string>;
};

export const OwnerInfoCard = ({
  owner,
  withPlaceholder,
  defaultPayoutAccountName,
}: Props) => {
  const { GL } = useEnabledFeatures();

  return (
    <DrawerInfoCard
      labelWidth={GL ? 130 : undefined}
      rows={[
        {
          icon: LoaderIcon,
          title: 'Status',
          text: (owner.status === 'inactive' ||
            owner.pmsStatus === 'inactive') && <StatusBadge {...owner} />,
        },
        {
          icon: BriefcaseIcon,
          title: 'Type',
          text: owner.companyType,
        },
        {
          icon: HashtagIcon,
          title: 'Tax ID',
          text: owner.taxId?.trim() ? <TaxId taxId={owner.taxId} /> : null,
        },
        {
          icon: EmailIcon,
          title: 'Email',
          text: owner.email || (withPlaceholder ? '-' : null),
        },
        {
          icon: PhoneIcon,
          title: 'Phone',
          text: owner.phone || (withPlaceholder ? '-' : null),
        },
        {
          icon: PinIcon,
          title: 'Address',
          text: formatAddress(owner.address) || (withPlaceholder ? '-' : null),
        },
        {
          icon: CreditCardOutcomeIcon,
          title: 'Payout account',
          text: !GL
            ? null
            : owner.payoutAccountName ||
              (defaultPayoutAccountName ? (
                <Text>
                  Default
                  <Text span c="gray">
                    {' '}
                    ・ {defaultPayoutAccountName}
                  </Text>
                </Text>
              ) : (
                <Badge color="red">Missing team default payout account</Badge>
              )),
        },
      ]}
    />
  );
};

const StatusBadge = (data: {
  pmsStatus: Maybe<gqlV2.owner_pms_status_enum>;
  status: Maybe<gqlV2.owner_status_enum>;
}) => {
  if (data.pmsStatus === 'inactive' && !data.status) return <PmsStatus />;

  return <OwnerStatus {...data} />;
};

const PmsStatus = () => {
  return (
    <Badge
      color="orange"
      leftIcon={
        <Icon
          icon="AlertTriangleIcon"
          size={12}
          color={(theme) => theme.colors.orange[8]}
        />
      }
    >
      Disabled in PMS
    </Badge>
  );
};

const OwnerStatus = (data: ComponentProps<typeof StatusBadge>) => {
  const { colors } = useMantineTheme();
  const { appName } = useAppName();

  const isDisabledInPms = data.pmsStatus === 'inactive';

  if (data.status === 'inactive') {
    return (
      <Group wrap="nowrap" gap={'xs'}>
        {isDisabledInPms && (
          <Tooltip label="Archived in PMS" withinPortal withArrow>
            <Center>
              <Icon
                icon="AlertTriangleIcon"
                size={12}
                color={colors.orange[7]}
              />
            </Center>
          </Tooltip>
        )}

        <Box>
          <Badge
            color="yellow"
            leftIcon={
              <Icon
                icon="CrossCircleIcon"
                size={12}
                color={(theme) => theme.colors.yellow[8]}
              />
            }
          >
            Archived
          </Badge>
        </Box>
      </Group>
    );
  }

  return (
    <Group wrap="nowrap" gap={'xs'}>
      {isDisabledInPms && (
        <Tooltip label="Archived in PMS" withinPortal withArrow>
          <Center>
            <Icon icon="AlertTriangleIcon" size={12} color={colors.orange[7]} />
          </Center>
        </Tooltip>
      )}

      <Box>
        <Badge color="green">Active on {appName}</Badge>
      </Box>
    </Group>
  );
};
