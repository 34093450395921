import { Select } from '@finalytic/components';
import { useDashboard } from '@finalytic/data';
import { ChevronIcon } from '@finalytic/icons';
import { IconButton, type SelectItem } from '@finalytic/ui';
import { Box, Group, Stack, Text } from '@mantine/core';
import { forwardRef, useEffect, useMemo } from 'react';
import { useAccountingConnection, useNavbarExpanded } from '../../../hooks';

export const NavbarAccountingSwitch = () => {
  const { accountingPlatforms } = useAccountingConnection();
  const [dashboard] = useDashboard();
  const isPmDashboard = dashboard !== 'owner';

  if (isPmDashboard && accountingPlatforms.length > 1) {
    return <Switch />;
  }

  return null;
};

const Switch = () => {
  const { accountingPlatforms, setAccounting, accounting } =
    useAccountingConnection();

  const { isDesktopExpanded } = useNavbarExpanded();

  const options = useMemo<SelectItem[]>(
    () =>
      accountingPlatforms.map((acc) => ({
        label: acc.name || 'Missing name',
        value: acc.id,
      })),
    [accountingPlatforms]
  );

  const value: SelectItem | null = accounting
    ? { label: accounting.name || 'Missing name', value: accounting.id }
    : null;

  useEffect(() => {
    if (!accounting && accountingPlatforms.length) {
      setAccounting(accountingPlatforms[0].id);
    }
  }, [accounting, setAccounting, accountingPlatforms]);

  if (accountingPlatforms.length < 2) return null;

  return (
    <Box
      sx={(theme) => ({
        borderBottom: '1px solid #ffffff20',
        paddingBottom: theme.spacing.sm,
      })}
    >
      <Stack
        gap={0}
        sx={{
          color: '#fff',
          flex: isDesktopExpanded ? 1 : 0,
        }}
      >
        {isDesktopExpanded && (
          <Text ml={5} sx={{ opacity: 0.8 }} size="xs">
            Accounting Integration
          </Text>
        )}
        <Select
          type="single"
          setValue={(value) => value?.value && setAccounting(value?.value)}
          data={{
            options: options || [],
          }}
          dropdownProps={{
            withinPortal: true,
            position: isDesktopExpanded ? 'bottom' : 'bottom-start',
            width: 260,
          }}
          value={value}
        >
          {({ value }) => {
            return (
              <PopoverButton
                canSwitch={true}
                isNavigationExpanded={isDesktopExpanded}
                label={(value as SelectItem | null)?.label}
              />
            );
          }}
        </Select>
      </Stack>
    </Box>
  );
};

const PopoverButton = forwardRef<
  HTMLDivElement,
  {
    label?: string;
    isNavigationExpanded: boolean;
    canSwitch: boolean;
  }
>(({ isNavigationExpanded, canSwitch, label }, ref) => (
  <Group
    wrap="nowrap"
    justify="space-between"
    gap={0}
    ref={ref}
    pb={4}
    sx={{
      borderRadius: 7,
      maxWidth: 255,
      display: !isNavigationExpanded && !canSwitch ? 'none' : undefined,
      cursor: canSwitch ? 'pointer' : 'default',
      ':hover': {
        backgroundColor: canSwitch ? '#5C617840' : undefined,
      },
      '&, *': {
        overflowY: 'hidden',
      },
    }}
  >
    {isNavigationExpanded && (
      <Text
        component="span"
        mb={-4}
        ml={5}
        sx={{
          maxWidth: '100%',
          display: 'block',
          height: 20,
          textOverflow: 'ellipsis',
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
          lineHeight: 'normal',
        }}
      >
        {label}
      </Text>
    )}

    {canSwitch && (
      <IconButton
        component="span"
        mb={-5}
        sx={{
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ChevronIcon size={17} color={'#ffffff80'} />
      </IconButton>
    )}
  </Group>
));
