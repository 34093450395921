import {
  CalendarEventIcon,
  CircleDollarIcon,
  FolderOpenIcon,
  HashtagIcon,
  NoteTextIcon,
  ShareIcon,
} from '@finalytic/icons';
import { day, ensure, formatCurrency, hasValue } from '@finalytic/utils';
import { Avatar, Group } from '@mantine/core';
import { Text } from '@mantine/core';
import { type ComponentProps, useMemo } from 'react';
import type { getDeposit } from '../../views/deposits/list/useDepositsTableQuery';
import { DrawerInfoCard } from '../_components';
import { DepositInfoCardBankAccountSelect } from './DepositInfoCardBankAccountSelect';

type Deposit = ReturnType<typeof getDeposit>;

type Props = {
  deposit: Deposit;
  type: 'drawer' | 'page';
};

export const DepositInfoCard = ({ deposit, type }: Props) => {
  const rows = useMemo(
    () =>
      ensure<
        (ComponentProps<typeof DrawerInfoCard>['rows'][number] | undefined)[]
      >([
        type === 'drawer'
          ? undefined
          : {
              icon: ShareIcon,
              title: 'Connection',
              text: deposit.connection.id ? (
                <Group gap="xs">
                  {deposit.connection.logo && (
                    <Avatar
                      src={deposit.connection.logo}
                      size="sm"
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[2]}`,
                      })}
                    />
                  )}
                  <Text>{deposit.connection.name}</Text>
                </Group>
              ) : null,
            },
        type === 'page'
          ? undefined
          : {
              icon: CircleDollarIcon,
              title: 'Amount',
              text: formatCurrency(
                (deposit.centTotal || 0) / 100,
                deposit.currency
              ),
            },
        {
          icon: CalendarEventIcon,
          title: 'Date',
          text: day(deposit.date).format('MMM D, YYYY'),
        },
        {
          icon: FolderOpenIcon,
          title: 'Bank/CC account',
          text:
            deposit.connection.id && !deposit.isReconciled ? (
              <DepositInfoCardBankAccountSelect
                bankAccount={deposit.bankAccount}
                depositId={deposit.id}
              />
            ) : (
              deposit.bankAccount?.title
            ),
        },
        {
          icon: HashtagIcon,
          title: 'Reference',
          text: deposit.uniqueRef,
        },
        {
          icon: NoteTextIcon,
          title: 'Description',
          text: deposit.description,
        },
      ]).filter(hasValue),
    [
      deposit.bankAccount,
      deposit.bankAccount?.title,
      deposit.id,
      deposit.centTotal,
      deposit.connection.name,
      deposit.connection.logo,
      deposit.connection.id,
      deposit.currency,
      deposit.date,
      deposit.description,
      deposit.uniqueRef,
      type,
    ]
  );

  return <DrawerInfoCard rows={rows} />;
};
