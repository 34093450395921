import { useQuery, useTeamId } from '@finalytic/data';
import { Icon, type IconDefinition } from '@finalytic/icons';
import { Carousel } from '@mantine/carousel';
import { Center, Group, Paper, Skeleton, Title, rem } from '@mantine/core';
import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { useRef } from 'react';
import '@mantine/carousel/styles.css';
import type { recurringFeeType_enum } from '@finalytic/graphql';
import type { FeeOverviewRoute } from '../_types';

function useFeeAggregateQuery(listingId?: string) {
  const [teamId] = useTeamId();

  return useQuery(
    (
      q,
      args
    ): Record<
      FeeOverviewRoute,
      {
        aggregate: number;
        icons?: string[];
      }
    > => {
      const fees = q
        .recurringFees({
          where: {
            tenantId: { _eq: args.teamId },
            status: { _eq: 'active' },
            listingSubscriptions: args.listingId
              ? {
                  listingId: { _eq: args.listingId },
                }
              : undefined,
          },
          order_by: [{ type: 'asc_nulls_last' }],
        })
        .map((fee) => ({
          id: fee.id,
          type: fee.type,
          // icon: fee.icon,
        }));

      return {
        'booking-channel-fees': {
          aggregate: fees.filter((x) => x.type === 'bookingChannelFee').length,
          icons: [],
        },
        'merchant-fees': {
          aggregate: fees.filter((x) => x.type === 'merchantFee').length,
          icons: [],
        },
        'cleaning-fees': {
          aggregate: fees.filter((x) => x.type === 'cleaningFee').length,
          icons: [],
        },
        other: {
          aggregate: fees.filter((x) => x.type === 'additionalFee').length,
          icons: [],
        },
        commissions: {
          aggregate: fees.filter((x) => x.type === 'managementFee').length,
          icons: [],
        },
      };
    },
    {
      variables: {
        teamId,
        listingId,
      },
      keepPreviousData: true,
      queryKey: ['customFees'],
    }
  );
}

export const FEE_TYPES: Record<
  FeeOverviewRoute,
  {
    title: string;
    icon: IconDefinition;
    type: recurringFeeType_enum;
  }
> = {
  commissions: {
    title: 'Management Commissions',
    icon: 'PercentageCircleIcon',
    type: 'managementFee',
  },
  'booking-channel-fees': {
    title: 'Booking Channel Fees',
    icon: 'CalendarDatesIcon',
    type: 'bookingChannelFee',
  },
  'merchant-fees': {
    title: 'Merchant Fees',
    icon: 'CashIcon',
    type: 'merchantFee',
  },
  'cleaning-fees': {
    title: 'Cleaning Fees',
    icon: 'BroomIcon',
    type: 'cleaningFee',
  },
  other: {
    title: 'Other Fees',
    icon: 'InfinityIcon',
    type: 'additionalFee',
  },
};

type Props = {
  feeType: FeeOverviewRoute | (string & {});
  onChange: (FeeOverviewRoute: FeeOverviewRoute) => void;
  listingId?: string;
};

export const FeeTypeCarousel = ({ feeType, onChange, listingId }: Props) => {
  const isTablet = useMediaQuery('(max-width: 1024px)');
  const isLargeDesktop = useMediaQuery('(min-width: 1400px)');
  const emblaRef = useRef(WheelGesturesPlugin());
  const queryData = useFeeAggregateQuery(listingId);

  return (
    <Carousel
      mb="xl"
      // height={100}
      slideSize={isLargeDesktop ? '20%' : isTablet ? '60%' : '33%'}
      slideGap="md"
      align="start"
      plugins={[emblaRef.current]}
      containScroll="trimSnaps"
      sx={{
        '.mantine-Carousel-control': {
          display: isLargeDesktop ? 'none' : 'flex',
        },
        '.mantine-Carousel-control[data-inactive]': {
          opacity: 0,
          cursor: 'default',
        },
        '.mantine-Carousel-container': {
          paddingBottom: 3,
        },
      }}
    >
      {Object.entries(FEE_TYPES).map(([t, { title, icon }]) => {
        const type = t as FeeOverviewRoute;

        const isActive = feeType === type;

        const data = queryData?.data?.[type];

        return (
          <Carousel.Slide key={type}>
            <Paper
              p="sm"
              radius="md"
              component="button"
              onClick={() => onChange(type)}
              sx={(theme) => ({
                cursor: 'pointer',
                minHeight: 90,
                border: `1px solid ${
                  isActive
                    ? theme.colors[theme.primaryColor][5]
                    : theme.colors.gray[3]
                }`,
                backgroundColor: isActive
                  ? theme.colors[theme.primaryColor][0]
                  : theme.white,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: 'calc(100% - 3px)',
                justifyContent: 'space-between',
                boxShadow: isActive
                  ? '0px 1px 2px 0px rgba(42, 59, 81, 0.12), 0px 0px 0px 1px rgba(18, 55, 105, 0.08)'
                  : theme.shadows.xs,
              })}
            >
              <Group
                justify="space-between"
                align="center"
                gap="xs"
                wrap="nowrap"
                w="100%"
              >
                {typeof icon === 'string' ? (
                  <Icon
                    icon={(icon as IconDefinition) || 'AlertCircleIcon'}
                    color={(theme) => theme.colors[theme.primaryColor][6]}
                    size={22}
                  />
                ) : (
                  icon
                )}
                {queryData.isInitialLoading ? (
                  <Skeleton h={25} w={25} radius="xl" />
                ) : (
                  <Center
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colors[isActive ? theme.primaryColor : 'neutral'][
                          isActive ? 2 : 1
                        ] + (isActive ? '40' : ''),
                      borderRadius: theme.radius.xl,
                      padding: rem(4),
                      paddingInline: rem(10),
                    })}
                  >
                    <Text
                      span
                      size="xs"
                      sx={(theme) => ({
                        color: theme.colors.neutral[5],
                      })}
                    >
                      {data?.aggregate ?? 0}
                    </Text>
                  </Center>
                )}
              </Group>
              <Title
                order={3}
                ta="left"
                size="md"
                sx={(theme) => ({
                  color: isActive
                    ? theme.colors[theme.primaryColor][6]
                    : theme.colors.neutral[6],
                })}
              >
                {title}
              </Title>
            </Paper>
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
};
