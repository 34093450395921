import { Button, Input } from '@finalytic/components';
import { captureSentryError, useTeamRole } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { Box, ScrollArea } from '@mantine/core';
import { type ChangeEventHandler, useState } from 'react';
import { Link } from 'react-router-dom';
import { IntegrationList, type OnboardingIntegration } from '../components';
import { useIntegrationHover, useOnboardingDevMode } from '../hooks';

type ConnectViewProps = {
  onIntegrationSubmit: (integration: OnboardingIntegration) => void;
  onSearch: (searchInput: string) => void;
  onMissingIntegrationSubmit: () => void;
  integrations: OnboardingIntegration[];
  loading: boolean;
  GL: boolean;
} & (
  | {
      type: 'Accounting';
      handleSkipOnboarding: () => Promise<void>;
    }
  | {
      type: 'PMS';
    }
);

export const ConnectView = ({
  onIntegrationSubmit,
  integrations,
  onMissingIntegrationSubmit,
  loading,
  GL,
  ...props
}: ConnectViewProps) => {
  const [search, setSearch] = useState('');
  const [loadingSkip, setLoadingSkip] = useState(false);

  const { isVrpAdmin } = useTeamRole();

  const isDevMode = useOnboardingDevMode();

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const s = event.target.value;

    setSearch(s);
    props.onSearch(s);
  };

  const { setAccounting, setPms } = useIntegrationHover();

  const setIntegration = (integration: OnboardingIntegration | undefined) => {
    if (props.type === 'Accounting') {
      setAccounting(integration);
    } else {
      setPms(integration);
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {/* Input */}
      <Box mb="lg">
        <Input
          value={search}
          onChange={onInputChange}
          name="integrationSearch"
          placeholder={`Search for your ${props.type}...`}
          leftSection={<Icon icon="SearchIcon" size={18} />}
          type="text"
        />
      </Box>
      {/* Applist */}
      <ScrollArea mah={320} type="auto">
        <IntegrationList
          integrations={integrations}
          notListedTitle={
            props.type === 'Accounting'
              ? 'My Accounting is not listed'
              : 'My PMS is not listed'
          }
          loading={loading}
          setIntegration={setIntegration}
          handleIntegrationSubmit={onIntegrationSubmit}
          handleMissingIntegration={onMissingIntegrationSubmit}
        />
      </ScrollArea>

      {props.type === 'PMS' && (isDevMode || isVrpAdmin) && (
        <Button
          component={Link}
          data-testid="skip-pms"
          to={
            GL
              ? `/start-date${location.search}`
              : `/connect-accounting${location.search}`
          }
          sx={{
            width: '100%',
            marginTop: 70,
          }}
        >
          Skip this step
        </Button>
      )}

      {props.type === 'Accounting' && (
        <Button
          id="skip-onboarding"
          data-testid="skip-onboarding"
          variant="primary"
          sx={{
            width: '100%',
            marginTop: 70,
          }}
          loading={loadingSkip}
          onClick={async () => {
            try {
              setLoadingSkip(true);
              await props.handleSkipOnboarding?.();
            } catch (error: any) {
              console.error(error);
              captureSentryError(error);
            } finally {
              setLoadingSkip(false);
            }
          }}
        >
          Skip and finish onboarding
        </Button>
      )}
    </Box>
  );
};
