import { z } from 'zod';

export const expenses_csv_import_schema = z
  .object({
    'Bill ID': z.string({ message: 'Bill ID is required' }),
    'Issue Date': z.coerce.string({ message: 'Issue Date is required' }),
    'Payment Date': z.coerce.string({
      message: 'Payment Date is required',
    }),
    'Bank/CC account': z.coerce.string().optional(),
    Status: z.enum(['Paid', 'Unpaid'], {
      message: 'Status is required, please input either Paid or Unpaid',
    }),
    Currency: z.string({ message: 'Currency is required' }),
    Contact: z.string({ message: 'Contact is required' }),
    'Line Description': z.string({ message: 'Line Description is required' }),
    Reservation: z.coerce.string().optional(),
    Listing: z.coerce.string().optional(),
    'Expense account': z.string({ message: 'Account is required' }),
    Markup: z.coerce.string().optional(),
    'Markup Tax': z.coerce.string().optional(),
    Description: z.string({ message: 'Description is required' }),
    'Bill to Owner': z.coerce.boolean({
      message: 'Bill to Owner is required',
      invalid_type_error: 'Bill to Owner must be a boolean',
    }),
    'Applied amount': z
      .number({
        message: 'Amount is required',
        invalid_type_error: 'Amount must be a number',
      })
      .transform((z) => Number(z) || 0),
  })
  .refine((v) => v['Bill to Owner'] === true && !v.Listing, {
    message: 'Listing is required when Bill to Owner is true',
    path: ['Listing'],
  });
