import { type Query, useQuery, useTeamId } from '@finalytic/data';
import type { SelectItem } from '@finalytic/ui';

export const getTaxRatesQuery = (
  q: Query,
  args: {
    teamId: string;
  }
) =>
  q
    .rates({
      order_by: [
        {
          name: 'asc',
        },
      ],
      where: {
        tenantId: { _eq: args.teamId },
        type: { _eq: 'tax' },
        status: { _eq: 'active' },
      },
    })
    .map<SelectItem & { percentageBasePoints: number }>((rate) => {
      return {
        label: rate.name || 'No name',
        value: rate.id,
        percentageBasePoints: rate.basisPoints ?? 0,
      };
    });

export function useTaxRatesQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      return getTaxRatesQuery(q, args);
    },
    {
      keepPreviousData: true,
      variables: {
        teamId,
      },
      queryKey: ['taxRates'],
    }
  );
}
