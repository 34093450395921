import { CheckIcon, Icon, SearchIcon } from '@finalytic/icons';
import type { SelectItem } from '@finalytic/ui';
import {
  Box,
  Center,
  type FloatingPosition,
  Group,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { type Virtualizer, useVirtualizer } from '@tanstack/react-virtual';
import {
  type ElementRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Divider } from '../Divider';
import { Input } from '../input';
import type { CustomAction, PinnedItems, SelectValueProps } from './_types';

export type DropdownProps = {
  position?: FloatingPosition;
  preventCloseOnSelect?: boolean;
  withinPortal?: boolean;
  noOptionsText?: string;
  width?: number | 'target';
  searchPlaceholder?: string;
  zIndex?: number;
  hideSearch?: boolean;
};

export type InfiniteDataProps<TSelectValue extends string = string> = {
  data:
    | {
        pages: { list: SelectItem<TSelectValue>[]; aggregate: number }[];
        pageParams: unknown[];
      }
    | undefined;
  error: Error | null;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => Promise<any>;
  isFetching: boolean;
  setSearch: (search: string) => void;
};

export const SelectDropdown = <TSelectValue extends string = string>({
  value: v,
  setValue,
  data,
  error,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  setSearch,
  noOptionsText = 'Nothing found ...',
  searchPlaceholder = 'Search ...',
  customBottomActions,
  customActionTop,
  hideSearch,
  pinnedItems: pinned,
  isFetching,
}: {
  value: SelectValueProps<TSelectValue>['value'];
  setValue: SelectValueProps<TSelectValue>['setValue'];
} & InfiniteDataProps<TSelectValue> &
  DropdownProps &
  CustomAction &
  PinnedItems) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const [debouncedSearch, setDebouncedSearch] = useState('');

  const pinnedItems = useMemo(() => {
    if (!pinned?.length) return [];

    if (!debouncedSearch?.trim()) return pinned;

    return pinned.filter((x) =>
      x.label.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
  }, [pinned, debouncedSearch]);

  const rows = useMemo(() => {
    const list = data?.pages.flatMap((x) => x.list) || [];

    if (!pinnedItems?.length) return list;

    return [...pinnedItems, ...list];
  }, [data?.pages, pinnedItems]);

  const parentRef = useRef<ElementRef<'div'>>(null);

  const virtualizer = useVirtualizer({
    count: hasNextPage ? rows.length + 1 : rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 38,
    overscan: 5,
  });

  const items = virtualizer.getVirtualItems();

  useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= rows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, rows.length, isFetchingNextPage, items]);

  const defaultHeight = 300;

  const listHeight = virtualizer.getTotalSize();

  const showSkeletons = isFetching && !isFetchingNextPage;

  const getParentHeight = () => {
    if (showSkeletons) return 80;

    return listHeight > defaultHeight ? defaultHeight : listHeight || 50;
  };

  const parentHeight = getParentHeight();

  // this fixes page jump when autoFocus is used
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 300);
  }, []);

  const { eventListenerRef, highlightedIndex, buttonRefs } =
    useKeyboardNavigation({
      customBottomActions,
      customTopActionSubmit: customActionTop?.onSubmit,
      items: rows,
      virtualizer,
      search: debouncedSearch,
    });

  const hasGroups = rows.some((x) => x.group);

  return (
    <Box ref={eventListenerRef}>
      {!hideSearch && (
        <Box
          p={'0.1rem'}
          sx={(theme) => ({
            borderBottom: `1px solid ${
              theme.colors.gray[colorScheme === 'dark' ? 7 : 2]
            }`,
          })}
        >
          <Input
            ref={inputRef}
            leftSection={<SearchIcon color={colors.neutral[5]} size={19} />}
            placeholder={searchPlaceholder}
            value={debouncedSearch}
            setValue={(s) => {
              setDebouncedSearch(s);
              setSearch(s);
            }}
            // autoFocus // this results in page jump when input is positioned below initial 100vh
            styles={{
              input: {
                border: 'none',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                '&:focus': {
                  outline: 'none',
                  boxShadow: 'none!important',
                },
              },
            }}
          />
        </Box>
      )}
      {customActionTop && (
        <Box
          sx={(theme) => ({
            borderBottom: `1px solid ${
              theme.colors.gray[colorScheme === 'dark' ? 7 : 2]
            }`,
          })}
        >
          <SelectButton
            onClick={() => customActionTop.onSubmit(debouncedSearch)}
            isFocused={highlightedIndex === 0}
            isActive={false}
            value={{ ...customActionTop, value: 'customAction' }}
            disabled={undefined}
          />
        </Box>
      )}
      <div
        ref={parentRef}
        className="List"
        style={{
          height: parentHeight,
          width: '100%',
          overflowY: 'auto',
          contain: 'strict',
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
          >
            {showSkeletons ? (
              <Stack gap={'xs'} p={5}>
                <Skeleton height={15} w="100%" />
                <Skeleton height={15} w="100%" />
                <Skeleton height={15} w="100%" />
              </Stack>
            ) : error ? (
              <Group
                sx={{ height: '100%' }}
                p={5}
                pt="sm"
                wrap="nowrap"
                justify="center"
              >
                <Center>
                  <Icon
                    icon="AlertTriangleIcon"
                    color={(theme) => theme.colors.red[6]}
                    size={18}
                  />
                </Center>
                <Text component="span" size="sm" c="red">
                  {error.message}
                </Text>
              </Group>
            ) : !items.length ? (
              <>
                <Center sx={{ height: '100%' }} p={5} pt="sm">
                  <Text component="span" size="sm" c="gray">
                    {noOptionsText}
                  </Text>
                </Center>
              </>
            ) : (
              items.map((virtualRow) => {
                const index = virtualRow.index;
                const selectIndex = index + (customActionTop ? 1 : 0);

                const isLoaderRow = index > rows.length - 1;
                const item = rows[index];

                const defaultGroup = 'Missing Group';
                const previousItem = rows[index - 1];
                const nextItem = rows[index + 1];
                const previousGroup = previousItem?.group || defaultGroup;
                const currentGroup = item?.group || defaultGroup;

                const isFirstInGroup = hasGroups
                  ? previousGroup !== currentGroup
                  : false;

                const isPinnedItem = !!pinnedItems?.some(
                  (x) => x.value === item?.value
                );
                const isNextItemPinned =
                  !!nextItem &&
                  !!pinnedItems?.some((x) => x.value === nextItem?.value);

                return (
                  <div
                    key={virtualRow.key}
                    data-index={index}
                    ref={virtualizer.measureElement}
                  >
                    {isLoaderRow ? (
                      hasNextPage ? (
                        <Box px={'xs'} pb={5}>
                          <Skeleton height={15} w="100%" />
                        </Box>
                      ) : null
                    ) : (
                      <>
                        {isFirstInGroup && item && (
                          <Text
                            component="span"
                            fw={500}
                            px="xs"
                            pt={10}
                            ml={-3}
                            pb={5}
                            size="sm"
                            display="block"
                            sx={(theme) => ({
                              borderBottom: `1px solid ${
                                theme.colors.gray[
                                  colorScheme === 'dark' ? 7 : 2
                                ]
                              }`,
                            })}
                          >
                            {item.group || 'Missing Group'}
                          </Text>
                        )}
                        <ListItem
                          {...item}
                          activeValue={v}
                          value={item}
                          isFocused={highlightedIndex === selectIndex}
                          setValue={setValue as any}
                          ref={(ref) => {
                            if (ref) {
                              buttonRefs.current[item.value] = ref;
                            }
                          }}
                        />
                        {isPinnedItem && nextItem && !isNextItemPinned && (
                          <Divider />
                        )}
                      </>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {!!customBottomActions?.length && (
        <Box
          sx={(theme) => ({
            borderTop: `1px solid ${
              theme.colors.gray[colorScheme === 'dark' ? 7 : 2]
            }`,
          })}
        >
          {customBottomActions.map(({ id, ...action }, index) => (
            <SelectButton
              onClick={() => action.onSubmit(debouncedSearch)}
              isFocused={highlightedIndex === -(index + 1)}
              isActive={false}
              value={{ ...action, value: 'customAction' }}
              disabled={undefined}
              key={id}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

type ListItemProps = {
  setValue: SelectValueProps<string>['setValue'];
  isFocused: boolean;
  value: SelectItem;
  activeValue: SelectItem | SelectItem[] | null;
};
const ListItem = forwardRef<HTMLButtonElement, ListItemProps>(
  ({ activeValue, setValue, isFocused, value, value: item }, ref) => {
    const isActive = useMemo<boolean>(() => {
      const v = activeValue;
      let values: SelectItem[] = [];

      if (Array.isArray(v)) values = v;
      else values = v ? [v] : [];

      return values.some((v) => v.value === item?.value);
    }, [activeValue, item]);

    const handleSetValue = useCallback(() => {
      if (Array.isArray(activeValue)) {
        const included = activeValue.some((v) => v.value === item.value);

        if (included)
          setValue(activeValue.filter((v) => v.value !== item.value) as any, {
            type: 'removed',
            value: item,
          });
        else
          setValue(activeValue.concat(item) as any, {
            type: 'added',
            value: item,
          });
      } else {
        if (activeValue?.value === item.value)
          setValue(null as any, { type: 'removed', value: item });
        else setValue(item as any, { type: 'added', value: item });
      }
    }, [item, activeValue, setValue]);

    if (!item) return null;

    return (
      <SelectButton
        ref={ref}
        isActive={isActive}
        isFocused={isFocused}
        value={value}
        onClick={handleSetValue}
        disabled={item.disabled}
      />
    );
  }
);

const SelectButton = forwardRef<
  HTMLButtonElement,
  {
    value: SelectItem;
    onClick: () => void;
    isFocused: boolean;
    isActive: boolean;
    disabled: boolean | string | undefined;
  }
>(({ onClick, value, isActive, isFocused, disabled }, ref) => {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  if (!value?.value) return null;

  const { label, description, icon } = value;

  return (
    <Tooltip
      label={disabled}
      disabled={typeof disabled !== 'string'}
      withinPortal
      position="right"
      withArrow
    >
      <Box
        component="button"
        disabled={!!disabled}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onClick();
        }}
        ref={ref}
        type="button"
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingInline: theme.spacing.xs,
          paddingBlock: 5,
          border: 'none',
          width: '100%',
          textAlign: 'left',
          cursor: disabled ? undefined : 'pointer',
          backgroundColor:
            isFocused && !disabled ? theme.colors.neutral[1] : 'transparent',
          ':hover': {
            backgroundColor:
              colorScheme === 'dark'
                ? theme.colors.neutral[7]
                : theme.colors.neutral[1],
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            gap: rem(8),
            flexWrap: 'nowrap',
            width: '100%',
          }}
        >
          {icon}
          <Text
            component="span"
            size="sm"
            sx={{
              wordBreak: 'break-word',
              flex: 1,
              lineHeight: description ? 1.2 : undefined,
            }}
          >
            {label}
            {description && (
              <>
                <br />
                <Text
                  size="xs"
                  c={
                    disabled
                      ? undefined
                      : colorScheme === 'dark'
                        ? theme.colors.neutral[4]
                        : 'gray'
                  }
                  component="span"
                >
                  {description}
                </Text>
              </>
            )}
          </Text>
        </Box>
        {isActive && (
          <CheckIcon
            size={18}
            color={theme.colors.neutral[5]}
            sx={{ flexShrink: 0 }}
          />
        )}
      </Box>
    </Tooltip>
  );
});

function useKeyboardNavigation<TSelectValue extends string>({
  items,
  virtualizer,
  customTopActionSubmit,
  customBottomActions,
  search,
}: {
  items: SelectItem<TSelectValue>[];
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  customTopActionSubmit:
    | NonNullable<CustomAction['customActionTop']>['onSubmit']
    | undefined;
  customBottomActions:
    | NonNullable<CustomAction['customBottomActions']>
    | undefined;
  search: string;
}) {
  const [highlightedIndex, setHighlightedIndex] = useState<number | undefined>(
    undefined
  );

  const eventListenerRef = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<Record<string, HTMLButtonElement>>({});

  const scrollTo = (index: number) => {
    setHighlightedIndex(index);
    virtualizer.scrollToIndex(index);
  };

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      switch (e.code) {
        case 'Enter':
          if (highlightedIndex !== undefined) {
            if (highlightedIndex < 0) {
              customBottomActions?.[Math.abs(highlightedIndex) - 1].onSubmit(
                search
              );
            } else {
              if (highlightedIndex === 0 && customTopActionSubmit) {
                customTopActionSubmit(search);
                break;
              }

              const buttonIndex =
                highlightedIndex - (customTopActionSubmit ? 1 : 0);

              const activeButton = buttonRefs.current[items[buttonIndex].value];
              activeButton.click();
            }
          }
          break;
        case 'ArrowUp': {
          if (highlightedIndex === undefined || highlightedIndex === 0) {
            if (customBottomActions?.length) {
              setHighlightedIndex(-customBottomActions.length);
            } else {
              setHighlightedIndex(undefined);
            }
            break;
          }

          // when custom action is selected
          if (highlightedIndex < 0) {
            if (highlightedIndex === -1) {
              if (items.length) {
                scrollTo(0); // scroll to first item
              } else {
                setHighlightedIndex(undefined); // reset when no items given
              }
            } else {
              scrollTo(highlightedIndex + 1);
            }
            break;
          }

          scrollTo(highlightedIndex - 1);
          break;
        }
        case 'ArrowDown': {
          // when nothing is selected OR custom action is selected
          if (highlightedIndex === undefined) {
            scrollTo(0);
            break;
          }

          if (highlightedIndex < 0) {
            if (
              !customBottomActions?.length ||
              highlightedIndex === -customBottomActions.length
            ) {
              scrollTo(0);
            } else {
              scrollTo(highlightedIndex - 1);
            }
            break;
          }

          if (highlightedIndex >= items.length - 1) {
            if (customBottomActions?.length) {
              setHighlightedIndex(-1);
            }

            break;
          }

          scrollTo(highlightedIndex + 1);
          break;
        }
      }
    };
    eventListenerRef.current?.addEventListener('keydown', handler);

    return () => {
      eventListenerRef.current?.removeEventListener('keydown', handler);
    };
  }, [
    items,
    highlightedIndex,
    customTopActionSubmit,
    customBottomActions,
    search,
    scrollTo,
  ]);

  useEffect(() => {
    setHighlightedIndex(undefined);
  }, [search]);

  return {
    buttonRefs,
    eventListenerRef,
    highlightedIndex,
  };
}
