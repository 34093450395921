import { InputSelect, Select } from '@finalytic/components';
import {
  type gqlV2,
  useEnabledFeatures,
  useInfiniteQuery,
  useMutation,
  useTeamId,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  CopyIcon,
  Edit3Icon,
  EmailIcon,
  EyeIcon,
  HashtagIcon,
  Icon,
  LoaderIcon,
  PhoneIcon,
  PinIcon,
  PlusIcon,
} from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuDangerItem,
  EllipsisMenuItem,
  IconButton,
  type SelectItem,
  showSuccessNotification,
} from '@finalytic/ui';
import { type Maybe, ensure } from '@finalytic/utils';
import { Box, Center, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import {
  formatAddress,
  formatOwnerName,
  whereContacts,
  whereOwners,
} from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { OwnerUserStatusBadge } from '../../components';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useOwnerDetailDrawer } from '../owner-drawers/useOwnerDetailDrawer';
import { OwnerUserEditForm } from './AddUserAccessDrawer';
import { UserAccessEllipsisMenuItems } from './UserAccessEllipsisMenuItems';
import { UserAccessEllipsisMenuModals } from './UserAccessEllipsisMenuModals';
import { useUserAccessDetailDrawer } from './useUserAccessDetailDrawer';
import {
  type UserAccessDetail,
  useUserAccessDetailDrawerQuery,
} from './useUserAccessDetailDrawerQuery';

export const UserAccessDetailDrawer = () => {
  const { opened, close, ownerId, view, setView } = useUserAccessDetailDrawer();

  const {
    isLoading,
    data: user,
    refetch,
  } = useUserAccessDetailDrawerQuery(ownerId);

  const [modalOpened, setModalOpened] = useState<
    'delete' | 'invite' | 'revoke-access' | 'archive' | 'copyInvite' | null
  >(null);

  const closeModal = () => setModalOpened(null);

  const { mutate: editMutate } = useMutation(
    (
      q,
      args: {
        userId: string;
        firstName: string;
        lastName: string;
        email: string;
      }
    ) => {
      return q.updateUserById({
        pk_columns: {
          id: args.userId,
        },
        _set: {
          firstName: args.firstName,
          lastName: args.lastName,
          email: args.email.toLowerCase().trim(),
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['owners'],
      errorMessage: {
        title: 'Failed to save user',
        message: (error) => {
          if (
            error?.message?.includes(
              'duplicate key value violates unique constraint "user_email_key"'
            )
          ) {
            return 'User with this email already exists';
          }

          return (
            error?.message ||
            'We failed to save the user. Please try again and contact our support if the issue persists.'
          );
        },
      },
    }
  );

  return (
    <>
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={user?.name || 'Missing name'}
          type="User"
          loading={isLoading}
          menuItems={
            view === 'overview' &&
            user && (
              <UserAccessEllipsisMenuItems
                handlers={{
                  setInviteOpen: () => setModalOpened('invite'),
                  setCopyInviteOpen: () => setModalOpened('copyInvite'),
                  setDeleteOpen: () => setModalOpened('delete'),
                  setRevokeAccessOpen: () => setModalOpened('revoke-access'),
                }}
                isReinvite={user.isReinvite}
                owner={user}
                refetch={refetch}
              />
            )
          }
        />
        {!user && !isLoading ? (
          <Center
            flex={1}
            sx={(theme) => ({
              flexDirection: 'column',
              gap: theme.spacing.md,
            })}
          >
            <Icon
              icon="AlertTriangleIcon"
              size={32}
              color={(theme) => theme.colors.gray[4]}
            />
            <Text>User not found</Text>
          </Center>
        ) : view === 'edit' && user ? (
          <>
            <OwnerUserEditForm
              handleSubmit={async (values) => {
                return editMutate({
                  args: {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    userId: user.id,
                  },
                }).then(() => setView('overview'));
              }}
              initialValues={{
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                email: user.email!,
                ownerAccess: [],
              }}
              onReset={() => setView('overview')}
              submitButtonLabel="Save changes"
              isEmailDisabled={user.status === 'active'}
              isEdit
            />
          </>
        ) : (
          <UserDetail user={user} isLoading={isLoading} />
        )}
        <UserAccessEllipsisMenuModals
          owner={user || null}
          inviteModal={{
            closeModal,
            opened: modalOpened === 'invite',
          }}
          copyInviteModal={{
            closeModal,
            opened: modalOpened === 'copyInvite',
          }}
          deleteModal={{
            closeModal,
            opened: modalOpened === 'delete',
          }}
          revokeAccessModal={{
            closeModal,
            opened: modalOpened === 'revoke-access',
          }}
          refetch={refetch}
        />
      </Drawer>
    </>
  );
};

const UserDetail = ({
  user,
  isLoading,
}: { user: Maybe<UserAccessDetail>; isLoading: boolean }) => {
  if (!user) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <OwnerUserStatusBadge status={user.status} />,
          },
          {
            icon: EmailIcon,
            title: 'Email',
            text: user.secondaryEmails.length ? (
              <>
                {user.email}
                <br />
                <Text c="gray" component="span">
                  {user.secondaryEmails.join(', ')}
                </Text>
              </>
            ) : (
              user.email
            ),
          },
        ]}
      />

      {(user.phone || user.taxId || formatAddress(user.address)) && (
        <HiddenFeatureIndicator permission="partner-admin">
          <Text c="gray" size="xs">
            Legacy owner info (for debugging during migration only)
          </Text>
          <DrawerInfoCard
            rows={[
              {
                icon: HashtagIcon,
                title: 'Tax ID',
                text: user.taxId,
              },
              {
                icon: PhoneIcon,
                title: 'Phone',
                text: user.phone,
              },
              {
                icon: PinIcon,
                title: 'Address',
                text: formatAddress(user.address),
              },
            ]}
          />
        </HiddenFeatureIndicator>
      )}

      <Owners owners={user.ownerAccesses} userId={user.id} />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const Owners = ({
  owners,
  userId,
}: { owners: UserAccessDetail['ownerAccesses']; userId: string }) => {
  const { open } = useOwnerDetailDrawer();

  const columns = useMemo<
    MRT_ColumnDef<UserAccessDetail['ownerAccesses'][number]>[]
  >(
    () => [
      {
        header: 'Owner',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <Group wrap="nowrap" gap={5}>
            <Center>
              <Icon
                icon={
                  row.original.type === 'company' ? 'OfficeIcon' : 'UserIcon'
                }
                size={18}
              />
            </Center>
            <Text component="span" display="block" size="sm">
              {row.original.name}
            </Text>
          </Group>
        ),
      },
      {
        header: 'Role',
        accessorKey: 'role',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableHeadCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const [newRole, setNewRole] = useState<string>();

          const { mutate, loading } = useMutation(
            (
              q,
              args: {
                userAccessId: string;
                role: gqlV2.owner_user_access_role_enum;
              }
            ) => {
              return q.updateOwnerUserAccesses({
                pk_columns: {
                  id: args.userAccessId,
                },
                _set: {
                  role: args.role,
                },
              })?.role;
            }
          );

          const options = ensure<
            SelectItem<gqlV2.owner_user_access_role_enum>[]
          >([
            {
              label: 'Editor',
              value: 'admin',
              icon: <Edit3Icon size={16} />,
            },
            {
              label: 'Viewer',
              value: 'viewer',
              icon: <EyeIcon size={16} />,
            },
          ]);

          const value = newRole || row.original.role;
          const formatted = options.find((o) => o.value === value) || null;

          return (
            <Box w={130}>
              <InputSelect
                data={{
                  options,
                }}
                type="single"
                setValue={(value) => {
                  if (!value?.value) return;

                  mutate({
                    args: {
                      role: value.value as gqlV2.owner_user_access_role_enum,
                      userAccessId: row.original.id,
                    },
                  }).then((role) => setNewRole(role));
                }}
                value={formatted || null}
                inputProps={{
                  loadingMutation: loading,
                }}
                dropdownProps={{
                  withinPortal: true,
                  position: 'bottom-end',
                  width: 200,
                }}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title="Owner Access"
      rightSection={<AddOwnerToUser userId={userId} />}
      rowData={owners}
      columns={columns}
      onRowClick={{
        disabled: (row) => !row.original.ownerId,
        handler: (row) =>
          row.original.ownerId &&
          open(row.original.ownerId, 'overview', 'push'),
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No owners found
          </Text>
        </Center>
      )}
      rowMenu={{
        menuItems: ({ row }) => {
          const { copy, copied } = useClipboard();

          const accessId = row.original.id;

          const { mutate, loading } = useMutation(
            (q, args: { accessId: string }) => {
              return q.deleteOwnerUserAccess({
                id: args.accessId,
              })?.id;
            },
            {
              invalidateQueryKeys: ['owners'],
            }
          );

          useEffect(() => {
            if (copied)
              showSuccessNotification({
                message: 'Access ID copied to clipboard',
              });
          }, [copied]);

          return (
            <>
              <EllipsisMenuDangerItem
                loading={loading}
                onClick={() =>
                  mutate({
                    args: {
                      accessId,
                    },
                  })
                }
              >
                Remove user access
              </EllipsisMenuDangerItem>

              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuItem
                  customIcon={<CopyIcon size={18} />}
                  onClick={() => copy(accessId)}
                >
                  Copy user access ID
                </EllipsisMenuItem>
              </HiddenFeatureIndicator>
            </>
          );
        },
      }}
    />
  );
};

const AddOwnerToUser = ({ userId }: { userId: string }) => {
  const [teamId] = useTeamId();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, search, userId, GL }, { limit, offset }) => {
      if (GL) {
        const where: gqlV2.contact_bool_exp = {
          ...whereContacts({
            search,
            tenantId: teamId,
            type: 'owner',
          }),
          _not: {
            userAccess: {
              userId: {
                _eq: userId,
              },
            },
          },
        };

        const list = q
          .contacts({
            where,
            limit,
            offset,
            order_by: [
              {
                companyType: 'asc_nulls_first',
              },
              {
                name: 'asc_nulls_last',
                firstName: 'asc_nulls_last',
              },
            ],
          })
          .map<SelectItem>((contact) => ({
            label: formatOwnerName(contact) || '',
            value: contact.id,
            icon: (
              <Icon
                icon={!contact.companyType ? 'UserIcon' : 'OfficeIcon'}
                size={contact.companyType ? 16 : 14}
              />
            ),
          }));

        const aggregate = q.contactAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }
      const where: gqlV2.owner_bool_exp = {
        ...whereOwners({
          teamId,
          search,
        }),
        _not: {
          userAccesses: {
            userId: {
              _eq: userId,
            },
          },
        },
      };

      const list = q
        .owners({
          where,
          limit,
          offset,
          order_by: [
            {
              firstName: 'asc_nulls_first',
            },
            {
              name: 'asc_nulls_first',
            },
          ],
        })
        .map<SelectItem>((owner) => ({
          label: formatOwnerName(owner) || '',
          value: owner.id,
        }));
      const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        teamId,
        search: search?.trim(),
        userId,
        GL,
      },
    }
  );

  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        userId: string;
        ownerId: string;
        GL: boolean;
      }
    ) => {
      return q.insertOwnerUserAccess({
        object: {
          userId: args.userId,
          ownerId: args.GL ? undefined : args.ownerId,
          contactId: args.GL ? args.ownerId : undefined,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['owners'],
      errorMessage: {
        message: (error) =>
          error?.message?.includes('owner_user_access_user_id_contact_id_key')
            ? 'This owner already has this user assigned. Please refresh the page.'
            : error?.message ||
              'Please reach out to support if the issue persists.',
      },
    }
  );

  return (
    <Select
      infiniteData={{ ...queryData, setSearch }}
      type="single"
      value={null}
      setValue={(value) => {
        if (!value) return;
        const ownerId = value.value;
        mutate({ args: { userId, ownerId, GL } });
      }}
      dropdownProps={{
        position: 'bottom-end',
        noOptionsText: 'No owner statements available',
      }}
    >
      {() => {
        return (
          <IconButton loading={loading}>
            <PlusIcon size={18} />
          </IconButton>
        );
      }}
    </Select>
  );
};
