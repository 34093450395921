import { Select } from '@finalytic/components';
import {
  showApiErrorNotification,
  useApiMutation,
  useInvalidateQueries,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { ChevronIcon, MinusCircleIcon } from '@finalytic/icons';
import { LoadingIndicator, type SelectItem } from '@finalytic/ui';
import { hasValue, toTitleCase } from '@finalytic/utils';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { IssueBadge } from '../../components';

type Props = {
  depositId: string;
  bankAccount: {
    title: string;
    accountId: string;
  } | null;
};

export const DepositInfoCardBankAccountSelect = (props: Props) => {
  const { depositId } = props;
  const [teamId] = useTeamId();

  const [newBankAccount, setNewBankAccount] = useState<
    Props['bankAccount'] | undefined
  >(undefined);

  const invalidate = useInvalidateQueries(['deposits']);

  const { data, isLoading: loadingQuery } = useQuery(
    (q, args) => {
      return q
        .bankAccounts({
          where: {
            tenantId: {
              _eq: args.teamId,
            },
            status: { _eq: 'active' },
            accountId: { _is_null: false },
          },
          order_by: [
            {
              category: 'desc_nulls_last',
            },
            {
              account: {
                title: 'asc_nulls_last',
              },
            },
          ],
        })
        ?.map<SelectItem>((bankAccount) => ({
          label: `${bankAccount.name || bankAccount.account?.title || '-'} (${bankAccount.lastDigits || '-'})`,
          value: bankAccount.accountId!,
          group: toTitleCase(bankAccount.category || '-'),
        }));
    },
    {
      queryKey: 'accounts',
      variables: {
        teamId,
      },
    }
  );

  const { mutateAsync, isPending: loadingMutation } = useApiMutation(
    'put',
    '/transactions/{id}'
  );

  const updateBankAccount = async (accountId: string | null) =>
    mutateAsync({
      params: {
        path: {
          id: depositId,
        },
      },
      body: {
        accountId,
      },
    })
      .then((result) => {
        const account = result.account;

        if (account) {
          const title = data?.find((x) => x.value === account?.id)?.label;

          setNewBankAccount({
            accountId: account.id,
            title: title ?? account.name,
          });
        } else {
          setNewBankAccount(null);
        }
        invalidate();
      })
      .catch((error: any) => {
        showApiErrorNotification({
          title: 'Failed to update deposit',
          defaultMessage:
            'We failed to update the deposit. Please try again later and if the problem persists, contact support.',
          error,
        });
      });

  const bankAccount =
    newBankAccount !== undefined ? newBankAccount : props.bankAccount;

  return (
    <>
      <Select
        data={{
          options: data || [],
          loading: loadingQuery,
        }}
        type="single"
        setValue={(value) => value?.value && updateBankAccount(value.value)}
        value={
          bankAccount?.accountId
            ? { label: bankAccount.title, value: bankAccount.accountId }
            : null
        }
        inputProps={{
          loadingMutation,
          loadingQuery,
        }}
        dropdownProps={{
          withinPortal: true,
          position: 'bottom-start',
          zIndex: 200,
        }}
        customBottomActions={[
          bankAccount?.accountId
            ? {
                label: 'Remove bank account',
                icon: <MinusCircleIcon size={16} mt={2} />,
                onSubmit: () => updateBankAccount(null),
                id: 'remove-bank-account',
              }
            : null,
        ].filter(hasValue)}
      >
        {({ value, type, opened, loadingMutation }) => {
          const { primaryColor, colors, radius } = useMantineTheme();

          if (type !== 'single') return <div />;

          return (
            <Group
              wrap="nowrap"
              gap={8}
              display="inline-flex"
              sx={{
                cursor: 'pointer',
                border: '1px solid',
                borderColor: opened ? colors[primaryColor][6] : 'transparent',
                borderRadius: radius.sm,
                boxShadow: opened
                  ? `0px 0px 0px 2px ${colors[primaryColor][4]}40`
                  : undefined,
                color: colors[primaryColor][7],
                // paddingBlock: `2px ${spacing.sm}`,
              }}
            >
              {!value && <IssueBadge status="error" />}
              <Text lineClamp={1}>{value?.label || 'Select bank account'}</Text>
              {loadingMutation ? (
                <LoadingIndicator size={'0.75rem'} />
              ) : (
                <ChevronIcon color={colors[primaryColor][7]} size={16} />
              )}
            </Group>
          );
        }}
      </Select>
    </>
  );
};
