import type { tenant_bool_exp } from '@finalytic/graphql';
import { ensure, hasValue, utc } from '@finalytic/utils';
import { z } from 'zod';
import { VRP_TENANT_ID } from '../consts';
import type { Dashboard } from '../types';

const tenantsFilterInput = {
  search: z.string().nullish(),
  tenantId: z.string().nullish(),
  featureIds: z.string().nullish().array().nullish(),
  billingPartnerId: z.string().nullish(),
  type: z.enum(['propertyManager', 'partner']).nullish(),
  billingSubscriptionStatus: z
    .enum([
      'active',
      'pending',
      'paused',
      'cancelled',
      'pendingCancellation',
      'draft',
      'voided',
      'errored',
      'missing',
      'viaPartner',
    ])
    .nullish(),
  status: z.enum(['active', 'inactive', 'onboarding']).nullish(),
};

const tenantsFilter = z.object(tenantsFilterInput);

export type TenantsFilterParams = z.infer<typeof tenantsFilter>;

type BaseWhere = {
  partnerId: string | undefined | null;
  dashboard: Dashboard;
};

export function whereTenants({
  search: s,
  partnerId,
  status,
  dashboard,
  billingPartnerId,
  billingSubscriptionStatus,
  tenantId,
  type,
  featureIds,
}: BaseWhere & TenantsFilterParams) {
  const isOverviewDashboard = dashboard === 'overview';
  const search = s?.trim();
  const searchQuery = search
    ? ensure<tenant_bool_exp[]>([
        {
          name: { _ilike: `%${search}%` },
        },
        {
          companyName: { _ilike: `%${search}%` },
        },
      ])
    : undefined;

  const fIds = featureIds?.filter(hasValue) ?? [];

  return ensure<tenant_bool_exp>({
    id: tenantId ? { _eq: tenantId } : { _neq: VRP_TENANT_ID },
    partnerId: isOverviewDashboard ? undefined : { _eq: partnerId },
    status: status !== 'onboarding' ? { _eq: status } : undefined,
    enabledFeatures: fIds.length ? { featureId: { _in: fIds } } : undefined,
    billingSubscriptionStatus: billingSubscriptionStatus
      ? billingSubscriptionStatus === 'missing' ||
        billingSubscriptionStatus === 'viaPartner'
        ? { _is_null: true }
        : billingSubscriptionStatus === 'pendingCancellation'
          ? { _eq: 'active' }
          : { _eq: billingSubscriptionStatus }
      : undefined,
    isOnboarding:
      status === 'onboarding' || status === 'active'
        ? { _eq: status === 'onboarding' }
        : undefined,
    cancelledAt:
      billingSubscriptionStatus === 'pendingCancellation'
        ? { _gt: utc().yyyymmdd() }
        : billingSubscriptionStatus === 'active'
          ? { _is_null: true }
          : undefined,
    type: type ? { _eq: type } : { _nin: ['partner', 'admin'] },
    _or: searchQuery,
    _and: [
      { calculatedBillingPartnerId: { _eq: billingPartnerId } },
      {
        calculatedIsBillable: billingSubscriptionStatus
          ? {
              _eq: billingSubscriptionStatus !== 'viaPartner',
            }
          : undefined,
      },
    ],
  });
}
