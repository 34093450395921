import { Button, Collapse, IconButton } from '@finalytic/components';
import { useInvalidateQueries } from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  AlertTriangleIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  Loader2Icon,
  LoaderIcon,
  LogIn2Icon,
  LogOut2Icon,
  RefreshCwIcon,
  UserIcon,
} from '@finalytic/icons';
import { LazyTable } from '@finalytic/table';
import {
  IconButton as LegacyIconButton,
  LoadingIndicator,
  Logo,
  showSuccessNotification,
  useAppName,
} from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  Stack,
  Tabs,
  Text,
  Title,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { getActionMessage } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { DrawerHeader, DrawerInfoCard } from '../_components';
import {
  AutomationHistoryRerunButtons,
  AutomationHistoryStatusBadge,
  SyncedFromTable,
  SyncedToTable,
} from './_components';
import { useSyncedFromToTableColumns } from './_hooks';
import {
  type Workflow as WorkflowQuery,
  useAutomationHistoryDrawerQuery,
} from './useAutomationHistoryDrawerQuery';
import {
  type WorkflowSubscription,
  useAutomationHistoryDrawerSubscription,
} from './useAutomationHistoryDrawerSubscription';

type Workflow = WorkflowQuery & Partial<WorkflowSubscription>;

export const SingleWorkflow = ({
  workflowId,
  closeDrawer,
  hasMultiple,
  resetActiveWorkflowId,
}: {
  workflowId: string;
  closeDrawer: () => void;
  hasMultiple: boolean;
  resetActiveWorkflowId: () => void;
}) => {
  const { data: queryData, isLoading: loadingQuery } =
    useAutomationHistoryDrawerQuery([workflowId]);

  const { data: subscriptionData, isInitialLoading } =
    useAutomationHistoryDrawerSubscription([workflowId], {
      onlyErrors: false,
    });

  const isLoading = loadingQuery || isInitialLoading;

  const invalidate = useInvalidateQueries(['actions', 'tasks', 'jobPlans']);

  const [tab, setTab] = useState('overview');

  const workflow = useMemo(() => {
    const a = queryData?.workflows?.[0];
    const b = subscriptionData?.[0];

    if (!a) return undefined;

    return {
      ...a,
      ...b,
    };
  }, [queryData?.workflows?.[0], subscriptionData?.[0]]);

  const title = useMemo<string>(() => {
    if (workflow?.isExtractWorkflow) return 'Fetching data';

    return workflow?.automation.name || '';
  }, [workflow]);

  return (
    <Box
      px="sm"
      pb="sm"
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Group>
        <DrawerHeader
          closeDrawer={closeDrawer}
          title={
            <Group>
              <Title order={3} display="block">
                {title}
              </Title>
              {['started', 'queued'].includes(workflow?.status || '') && (
                <AutomationHistoryStatusBadge status={workflow?.status} />
              )}
            </Group>
          }
          loading={isLoading}
          type="Sync History"
          onReturnClick={
            hasMultiple ? () => resetActiveWorkflowId() : undefined
          }
        >
          {workflow?.hypervisorRef === 'trigger' && (
            <HiddenFeatureIndicator
              permission="super-admin"
              // customTooltip="Trigger workflow"
            >
              <Group gap={rem(5)}>
                <IconButton
                  icon="CopyIcon"
                  size={16}
                  display="inline"
                  disabled={!workflow?.triggerRef}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(workflow?.triggerRef || '')
                      .then(() =>
                        showSuccessNotification({
                          message: 'Trigger ref copied to clipboard',
                        })
                      )
                  }
                />
                <IconButton
                  icon="RocketIcon"
                  size={16}
                  display="inline"
                  onClick={() =>
                    window.open(
                      `https://cloud.trigger.dev/orgs/vrplatform-1369/projects/v3/vrplatform-0jEN/runs/${workflow?.triggerRef}`,
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                />
              </Group>
            </HiddenFeatureIndicator>
          )}
          <Button
            onClick={() => invalidate()}
            variant="light"
            leftIcon={RefreshCwIcon}
          >
            Refresh data
          </Button>
          <AutomationHistoryRerunButtons workflowId={workflowId} />
        </DrawerHeader>
      </Group>
      {isLoading || (workflowId && !workflow) ? (
        <LoadingIndicator size="md" isFullPageLoading />
      ) : !workflowId || !workflow ? (
        <Center mih="7vh">
          <h1>History not found</h1>
        </Center>
      ) : (
        <Content
          workflowId={workflowId}
          workflow={workflow}
          setTab={setTab}
          tab={tab}
          overwrites={queryData?.messageOverwrites || []}
        />
      )}
    </Box>
  );
};

type ContentProps = {
  workflowId: string;
  workflow: Workflow;
  overwrites: Parameters<typeof getActionMessage>[1];
  tab: string;
  setTab: (tab: string) => void;
};

const Content = ({
  workflow,
  workflowId,
  setTab,
  tab,
  overwrites,
}: ContentProps) => {
  const { errors, syncFrom, syncTo } = useMemo(() => {
    return {
      errors: workflow.errors?.aggregate ?? 0,
      syncTo: workflow.aggregateSyncedTo ?? 0,
      syncFrom: workflow.aggregateSyncedFrom ?? 0,
    };
  }, [
    workflow.errors?.aggregate,
    workflow.aggregateSyncedTo,
    workflow.aggregateSyncedFrom,
  ]);

  const isLegacy = workflow.hypervisorRef === 'legacy';

  const goToErrors = () => setTab('errors');

  const isWorkflowPending = ['started', 'queued', 'pending'].includes(
    workflow.status || ''
  );

  return (
    <Tabs
      value={tab}
      onChange={(tab) => tab && setTab(tab)}
      px="sm"
      keepMounted={false}
      styles={(theme) => ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        },
        panel: {
          height: '100%',
          flex: 1,
          marginTop: theme.spacing.lg,
          maxWidth: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      })}
    >
      <Tabs.List>
        <Tabs.Tab value="overview">Overview</Tabs.Tab>
        {isLegacy ? null : (
          <Tabs.Tab value="sync-from">Synced from ({syncFrom})</Tabs.Tab>
        )}
        {isLegacy ? null : (
          <Tabs.Tab value="sync-to">Synced to ({syncTo})</Tabs.Tab>
        )}
        {!!errors && <Tabs.Tab value="errors">Errors ({errors})</Tabs.Tab>}
      </Tabs.List>

      <Tabs.Panel value="overview">
        <Overview
          workflow={workflow}
          goToErrors={goToErrors}
          overwrites={overwrites}
        />
      </Tabs.Panel>
      <Tabs.Panel value="sync-from">
        <SyncedFromTable
          workflowId={workflowId}
          isWorkflowPending={isWorkflowPending}
          overwrites={overwrites}
        />
      </Tabs.Panel>
      <Tabs.Panel value="sync-to">
        <SyncedToTable
          workflowId={workflowId}
          type="success"
          isWorkflowPending={isWorkflowPending}
        />
      </Tabs.Panel>
      <Tabs.Panel value="errors">
        <SyncedToTable
          workflowId={workflowId}
          type="failed"
          isWorkflowPending={isWorkflowPending}
        />
      </Tabs.Panel>
    </Tabs>
  );
};

const Overview = ({
  workflow,
  goToErrors,
  overwrites,
}: {
  workflow: Workflow;
  goToErrors: () => void;
  overwrites: Parameters<typeof getActionMessage>[1];
}) => {
  if (!workflow) return null;

  return (
    <>
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <AutomationHistoryStatusBadge status={workflow.status} />,
          },
          {
            icon: Loader2Icon,
            title: 'Current step',
            text:
              workflow.status === 'started' &&
              workflow.isExtractWorkflow &&
              workflow?.title ? (
                <Text>{getActionMessage(workflow.title, overwrites)}</Text>
              ) : null,
          },
          {
            icon: UserIcon,
            title: 'Triggered by',
            text: workflow.triggeredBy,
          },
          {
            icon: LogIn2Icon,
            title: 'Synced from',
            text: (
              <AppIcon
                {...(workflow.isExtractWorkflow
                  ? workflow.extractConnection
                  : workflow.leftConnection)}
              />
            ),
          },
          {
            icon: LogOut2Icon,
            title: 'Synced to',
            text: (
              <AppIcon
                {...(workflow.isExtractWorkflow
                  ? {
                      id: 'finalytic',
                      icon: undefined,
                      name: '',
                    }
                  : workflow.rightConnection)}
              />
            ),
          },
        ]}
      />
      <OverviewErrorTable
        {...workflow}
        goToErrors={goToErrors}
        overwrites={overwrites}
      />
    </>
  );
};

const OverviewErrorTable = ({
  errors,
  goToErrors,
  title,
  status,
  hypervisorRef,
  overwrites,
}: Workflow & {
  goToErrors?: () => void;
  overwrites: Parameters<typeof getActionMessage>[1];
}) => {
  const { primaryColor } = useMantineTheme();

  const columns = useSyncedFromToTableColumns(overwrites);

  const isShowMoreErrors =
    (errors?.aggregate ?? 0) > (errors?.rowData?.length ?? 0);

  const isTrigger = hypervisorRef === 'trigger';

  return (
    <Box mt="lg">
      <Collapse
        title={
          <Text component="span" size="sm">
            Errors
            <Text c="gray" ml="xs" component="span">
              {errors?.aggregate ?? 0}
            </Text>
          </Text>
        }
        rightSection={
          !!errors?.aggregate &&
          goToErrors && (
            <LegacyIconButton onClick={goToErrors}>
              <ArrowRightIcon size={18} />
            </LegacyIconButton>
          )
        }
        minHeight={30}
        defaultOpened
      >
        {!errors?.rowData.length ? (
          <TablePlaceholder
            status={status}
            errorMessage={
              status === 'failed'
                ? getActionMessage(title || '', overwrites)
                : undefined
            }
            successMessage={
              status === 'completed' && isTrigger ? title : undefined
            }
          />
        ) : (
          <>
            <LazyTable
              table={{
                columns,
                hideHeader: true,
                hideTopBar: true,
              }}
              data={{
                rowCount: errors.aggregate,
                rows: errors.rowData,
                error: null,
                loading: false,
              }}
            />
            {isShowMoreErrors &&
              (!goToErrors ? (
                <Text size="xs" c="gray" ta="center" pt="md">
                  {errors.aggregate - errors.rowData.length} additional errors
                </Text>
              ) : (
                <Button
                  onClick={goToErrors}
                  color={primaryColor}
                  variant="light"
                  sx={(theme) => ({
                    marginInline: 'auto',
                    display: 'block',
                    marginTop: theme.spacing.sm,
                  })}
                  rightIcon={ArrowRightIcon}
                >
                  Show more
                </Button>
              ))}
          </>
        )}
      </Collapse>
    </Box>
  );
};

const AppIcon = ({
  icon,
  id,
  name,
}: { icon: Maybe<string>; id: Maybe<string>; name: Maybe<string> }) => {
  const { appName } = useAppName();

  const isFinalyticConnection = id === 'finalytic';

  if (!id) return null;

  return (
    <Group gap="xs">
      <Avatar size="sm" radius="xl">
        <Logo
          img={isFinalyticConnection ? undefined : icon}
          width={isFinalyticConnection ? 24 : 22}
        />
      </Avatar>
      <Text component="span">{isFinalyticConnection ? appName : name}</Text>
    </Group>
  );
};

const OPTIONS: Record<string, string> = {
  completed: 'No errors, all parts are running smoothly.',
  failed: 'Something went wrong, please try rerunning.',
  started: 'Syncing data...',
  queued: 'Waiting for queue to finish...',
};

const TablePlaceholder = ({
  status,
  errorMessage,
  successMessage,
}: {
  status: Maybe<string>;
  errorMessage: Maybe<string>;
  successMessage: Maybe<string>;
}) => {
  const { colors } = useMantineTheme();

  const default_message = OPTIONS[status || 'started'];
  const message = (() => {
    if (status === 'failed') return errorMessage || default_message;
    return default_message;
  })();

  return (
    <Stack mih={200} align="center" justify="center">
      {status === 'started' || status === 'queued' ? (
        <LoadingIndicator size="sm" />
      ) : status === 'completed' ? (
        <CheckCircleIcon color={colors.green[5]} size={30} />
      ) : (
        <AlertTriangleIcon color={colors.red[5]} size={30} />
      )}
      {successMessage && (
        <Text c="neutral" ta="center" fw={500}>
          {successMessage}
        </Text>
      )}
      <Text c="neutral" ta="center">
        {message}
      </Text>
    </Stack>
  );
};
