import { type gqlV2, useQuery, useTeamId } from '@finalytic/data';
import { type Maybe, day, ensure, sortBy } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import {
  formatOwnerName,
  formatUserName,
  getListingName,
  getOwnerAddress,
  getOwnerCompanyType,
  whereListingPeriod,
} from '@vrplatform/ui-common';

type Ownership = {
  id: string;
  listing: {
    id: string;
    name: string;
    address: string | undefined;
  };
  split: number | undefined;
};

export function useLegacyOwnerDetailDrawerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .owners({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((owner) => {
            const address = getOwnerAddress(owner).values;

            const currentPeriodMemberships = owner
              .ownershipPeriodMemberships({
                where: {
                  period: whereListingPeriod({
                    date: day().yyyymmdd(),
                  }),
                },
              })
              .map((member) => ({
                id: member.id,
                listingName: getListingName(member.period.listing),
                listingId: member.period.listingId,
                split: member.split as Maybe<number>,
              }));

            const ownerships = owner
              .ownerships({
                order_by: [
                  {
                    createdAt: 'asc_nulls_last',
                  },
                ],
              })
              .map<Ownership>((ownership) => ({
                id: ownership.id,
                listing: {
                  id: ownership.listing.id,
                  name: getListingName(ownership.listing),
                  address: ownership.listing.address,
                },
                split: ownership.split,
              }));

            const getIsArchived = () => {
              if (owner.status === 'inactive') {
                return true;
              }

              if (!owner.status && owner.pmsStatus === 'inactive') {
                return true;
              }

              return false;
            };

            return {
              id: owner.id,
              taxId: owner.taxId,
              status: owner.status,
              is1099PostalDelivery: owner.is1099PostalDelivery ?? true,
              currentPeriodMemberships,
              defaultAccountId: undefined,
              pmsStatus: owner.pmsStatus,
              isArchived: getIsArchived(),
              tenantId: owner.tenantId,
              addressId: owner.addressId,
              email: owner.email,
              phone: owner.phone,
              type: owner.type || 'individual',
              companyType: getOwnerCompanyType(owner.companyType),
              name: formatOwnerName(owner),
              firstName: owner.firstName,
              lastName: owner.name,
              companyName: owner.name,
              teamId: owner.tenantId,
              address,
              ownerships,
              hasOwnerships: ownerships.length > 0,
              userAccesses: sortBy(
                owner
                  .userAccesses({
                    order_by: [
                      {
                        user: {
                          firstName: 'asc_nulls_last',
                        },
                      },
                    ],
                    where: {
                      user: {
                        memberships: {
                          tenantId: { _eq: args.teamId },
                        },
                      },
                    },
                  })
                  .map((access) => {
                    return {
                      id: access.id,
                      userId: access.userId,
                      name: formatUserName(access.user),
                      email: access.user.email,
                      role: access.role,
                      status:
                        access.user.memberships({
                          where: {
                            tenantId: { _eq: args.teamId },
                          },
                          limit: 1,
                        })[0]?.status || 'inactive',
                    };
                  }),
                'name'
              ),
              ownerStatements: owner
                .statementOwnerships({
                  order_by: [
                    {
                      statement: {
                        startAt: 'desc_nulls_last',
                      },
                    },
                  ],
                })
                .map((statementOwner) => ({
                  id: statementOwner.statementId,
                  startAt: statementOwner.statement.startAt,
                  status: statementOwner.statement.status,
                  listing: {
                    id: statementOwner.statement?.listing?.id,
                    name: statementOwner.statement?.listing
                      ? getListingName(statementOwner.statement?.listing)
                      : '',
                  },
                  statementOwner: {
                    id: statementOwner.id,
                    role: ensure<gqlV2.listing_owner_role_enum | 'company'>(
                      statementOwner.owner?.type === 'company'
                        ? 'company'
                        : statementOwner.role || 'owner'
                    ),
                    name: formatUserName(statementOwner.owner),
                  },
                })),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: ['owners', id || ''],
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type LegacyOwnerDetail = NonNullable<
  ReturnType<typeof useLegacyOwnerDetailDrawerQuery>['data']
>;
