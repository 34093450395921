import { useEnabledFeatures, useQuery, useTeamId } from '@finalytic/data';
import type { listing_owner_role_enum } from '@finalytic/graphql';
import { type Maybe, day, ensure } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import {
  formatOwnerName,
  getListingAddress,
  getListingDisabledAutomations,
  getListingName,
  whereListingPeriod,
} from '@vrplatform/ui-common';

export function useListingDetailDrawerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return q
        .listings({
          where: {
            id: { _eq: args.id },
          },
        })
        .map((listing) => {
          const { disabledAutomations } =
            getListingDisabledAutomations(listing);

          const currentPeriod = !args.GL
            ? undefined
            : listing
                .ownershipPeriods({
                  limit: 1,
                  where: whereListingPeriod({
                    date: day().yyyymmdd(),
                  }),
                })
                .map((period) => ({
                  id: period.id,
                  startAt: period.startAt,
                  endAt: period.endAt,
                  members: period
                    .members({
                      order_by: [
                        {
                          contact: {
                            name: 'asc_nulls_last',
                          },
                        },
                      ],
                    })
                    .map((owner) => ({
                      id: owner.id as string,
                      ownerId: owner.contactId as string,
                      ownerName: formatOwnerName(owner.contact),
                      ownerSplitBasePoints: owner.split as
                        | number
                        | undefined
                        | null,
                      ownerType: owner.contact?.companyType || 'individual',
                    })),
                }))[0];

          const listingConnections = GL
            ? [
                {
                  id: listing.connectionId,
                  name: listing.connection?.name,
                  icon: listing.connection?.app.iconRound,
                  app: listing.connection?.app.name,
                },
              ]
            : listing
                .connections({
                  order_by: [
                    {
                      name: 'asc_nulls_last',
                    },
                  ],
                })
                .map((listingConnection) => ({
                  id: listingConnection.id,
                  name: listingConnection.name,
                  icon: listingConnection.connection.app.iconRound,
                  app: listingConnection.connection.app.name,
                }));

          return {
            id: listing.id,
            address: getListingAddress(listing),
            currentPeriod,
            addressId: listing.address_id,
            status: listing.status,
            sourceStatus: listing.source?.status,
            currency: listing.defaultCurrency,
            pmsStatus: listing.pmsStatus,
            name: getListingName(listing),
            collection: {
              id: listing.collectionId,
              name: listing.collection?.name || '',
            },
            pmsName: listing.name,
            title: listing.title,
            disabledAutomations,
            tenantId: teamId,
            isPmsListing: !!listing.connectionId,
            ownerships: args.GL
              ? []
              : listing
                  .ownerships({
                    where: {
                      newOwnerId: {
                        _is_null: false,
                      },
                    },
                    order_by: [
                      {
                        createdAt: 'asc_nulls_last',
                      },
                    ],
                  })
                  .map((ownership) => {
                    return {
                      id: ownership.id,
                      split: ownership.split,
                      role: ensure<listing_owner_role_enum>(
                        ownership.newOwner?.type === 'company'
                          ? 'company'
                          : 'owner'
                      ),
                      owner: {
                        id: ownership.newOwner?.id,
                        type: ownership.newOwner?.type,
                        name: formatOwnerName(ownership.newOwner),
                      },
                    };
                  })
                  .filter((x) => x.owner.id),
            ownerStatements: listing
              .ownerStatements({
                order_by: [
                  {
                    startAt: 'desc_nulls_last',
                  },
                ],
              })
              .map((statement) => ({
                id: statement.id,
                startAt: statement.startAt,
                status: statement.status,
                listing: {
                  id: statement?.listing?.id,
                  name: statement?.listing
                    ? getListingName(statement?.listing)
                    : '',
                },
              })),
            listingConnections,
          };
        })[0];
    },
    {
      skip: !id,
      queryKey: 'listings',
      variables: {
        id,
        teamId,
        GL,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type Listing = NonNullable<
  ReturnType<typeof useListingDetailDrawerQuery>['data']
>;
