import {
  type QueryKeyUnion,
  type gqlV2,
  useMutation,
  useTeam,
} from '@finalytic/data';
import type { Mutation, setting_insert_input } from '@finalytic/graphql';

type ExtraMutation = (q: Mutation) => any;

type Setting = {
  id?: string;
  leftType?: string;
  rightType?: string;
  value?: string;
  target?: string;
  key?: string;
  lineClassification?: string;
  // automationId?: string;
  listingId?: string;
  sourceId?: string;
  teamId: string;
  partner?: string;
  leftConnectionId?: string;
  rightConnectionId?: string;
  parentSettingId?: string;
  localAutomationId?: string;
};

export type SettingMutationInput =
  | {
      type: 'upsert';
      setting: Setting;
    }
  | {
      type: 'upsert_many';
      settings: Setting[];
    }
  | {
      type: 'remove';
      settingId: string;
    };

type MutationArgs = SettingMutationInput & { extraMutation?: ExtraMutation };

export function useSettingByIdMutation(opts?: {
  successMessage?: string;
  invalidate?: (QueryKeyUnion | (string & {}))[];
}) {
  const { invalidate, successMessage = 'Updated setting successfully.' } =
    opts || {};

  const { loading, mutate } = useMutation(
    (q, args: MutationArgs) => {
      let ok = false;

      if (args.type === 'remove') {
        const res = q.delete_setting_by_pk({ id: args.settingId });

        ok = !!res?.id;
      }

      const formatSettingInput = (
        setting: Setting
      ): gqlV2.setting_insert_input => ({
        id: setting.id,
        key: setting.key,
        leftType: setting.leftType,
        group: setting.leftType,
        rightType: setting.rightType,
        value: setting.value,
        target: setting.target,
        // automationId: setting.automationId,
        tenant_id: setting.teamId,
        lineClassification: setting.lineClassification,
        listingId: setting.listingId,
        partner: setting.partner,
        leftConnectionId: setting.leftConnectionId,
        rightConnectionId: setting.rightConnectionId,
        parentSettingId: setting.parentSettingId,
        localAutomationId: setting.localAutomationId,
        sourceId: setting.sourceId,
      });

      if (args.type === 'upsert') {
        const res = q.insert_setting_one({
          on_conflict: {
            constraint: 'setting_pkey',
            update_columns: [
              'value',
              'group',
              'leftType',
              'rightType',
              'lineClassification',
              'listingId',
              'target',
            ],
          },
          object: formatSettingInput(args.setting),
        });

        ok = !!res?.id;
      }

      if (args.type === 'upsert_many') {
        const res = q.insert_setting({
          on_conflict: {
            constraint: 'setting_pkey',
            update_columns: [
              'value',
              'group',
              'leftType',
              'rightType',
              'lineClassification',
              'listingId',
              'target',
            ],
          },
          objects: args.settings.map(formatSettingInput),
        });

        ok = !!(res?.affected_rows || 0);
      }

      if (args.extraMutation) {
        const id = args.extraMutation(q);
        return {
          id,
          ok,
        };
      }

      return {
        ok,
      };
    },
    {
      successMessage: successMessage
        ? {
            message: successMessage,
          }
        : undefined,
      invalidateQueryKeys: invalidate || ['settings'],
    }
  );

  return {
    loading,
    mutate: (args: MutationArgs) =>
      mutate({
        args,
      }),
  };
}

export const useDisableListingMutation = (listingName?: string) => {
  const [{ finalyticConnectionId }] = useTeam();

  const { mutate, loading } = useMutation(
    (
      q,
      args:
        | { type: 'remove'; settingId: string }
        | {
            type: 'upsert';
            setting: setting_insert_input;
          }
    ) => {
      if (args.type === 'remove') {
        const res = q.delete_setting_by_pk({ id: args.settingId });

        return {
          ok: !!res?.id,
        };
      } else {
        if (args.setting.id) {
          const res = q.update_setting_by_pk({
            pk_columns: { id: args.setting.id },
            _set: args.setting,
          });

          return {
            ok: !!res?.id,
          };
        } else {
          const res = q.insert_setting_one({ object: args.setting });

          return {
            ok: !!res?.id,
          };
        }
      }
    },
    {
      successMessage: {
        title: 'Updated settings',
        message: listingName
          ? `Successfully updated ${listingName}.`
          : 'Successfully updated listing.',
      },
    }
  );

  const toggle = ({
    settingId,
    listingId,
    teamId,
    automationId,
    rightConnectionId,
  }: {
    listingId: string;
    teamId: string;
    settingId: string | undefined;
    automationId: string;
    rightConnectionId: string;
  }) =>
    settingId
      ? mutate({
          args: {
            type: 'remove',
            settingId,
          },
        })
      : mutate({
          args: {
            type: 'upsert',
            setting: {
              id: settingId,
              listingId: listingId,
              key: 'exclude',
              value: 'true',
              target: listingId,
              leftType: 'finalytic.listing',
              group: 'finalytic.listing',
              rightType: 'schema.boolean',
              leftConnectionId: finalyticConnectionId,
              rightConnectionId,
              tenant_id: teamId,
              automationId,
            },
          },
        });

  return {
    loading,
    toggle,
  };
};
