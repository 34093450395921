import type { recurringFeeType_enum } from '@finalytic/graphql';
import type { FeeFormInputs } from '../edit/useFeeForm';

const DEFAULT_FORMULA = '(accounts) * "rate"';

export const FEE_TEMPLATES: Record<
  recurringFeeType_enum,
  {
    formInputs: FeeFormInputs;
    config: {
      accounts: string[];
      formulaTemplate: string;
    };
  }
> = {
  managementFee: {
    formInputs: {
      // icon: 'PercentageIcon',
      creditAccountId: '4160',
      debitAccountId: '5000',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'percentage',
      // formulaId: undefined,
      id: undefined,
      title: 'Management Commission',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'managementFee',
      bookingChannelsFilter: 'all-channels',
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 2000,
      linkedAccountIds: [],
    },
    config: {
      accounts: ['4000', '4020', '5010', '5020'],
      formulaTemplate: DEFAULT_FORMULA,
    },
  },
  merchantFee: {
    // STRIPE
    formInputs: {
      // icon: 'StripeIcon',
      creditAccountId: '4110',
      debitAccountId: '5020',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'flat',
      // formulaId: undefined,
      id: undefined,
      title: 'Merchant Fees',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'merchantFee',
      bookingChannelsFilter: [],
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 10000,
      linkedAccountIds: [],
    },
    config: {
      accounts: ['5020'],
      formulaTemplate: `-(accounts) * "rate"`,
    },
  },
  bookingChannelFee: {
    // BOOKING.COM
    formInputs: {
      // icon: 'BookingComIcon',
      creditAccountId: '4040',
      debitAccountId: '5010',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'percentage',
      // formulaId: undefined,
      id: undefined,
      title: 'Booking Channel Fee',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'bookingChannelFee',
      bookingChannelsFilter: [],
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 500,
      linkedAccountIds: [],
    },
    config: {
      accounts: ['4000', '4010'],
      formulaTemplate: DEFAULT_FORMULA,
    },
  },
  cleaningFee: {
    formInputs: {
      // icon: 'BedIcon',
      creditAccountId: '4010',
      debitAccountId: '4010',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'flat',
      // formulaId: undefined,
      id: undefined,
      title: 'Cleaning Fee',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'cleaningFee',
      bookingChannelsFilter: 'all-channels',
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 10000,
      linkedAccountIds: [],
    },
    config: {
      accounts: ['4010'],
      formulaTemplate: DEFAULT_FORMULA,
    },
  },
  additionalFee: {
    formInputs: {
      // icon: 'PercentageIcon',
      creditAccountId: 'create_new',
      debitAccountId: 'create_new',
      // expression: '',
      // expressionUnformatted: '',
      creditParty: 'manager',
      debitParty: 'owners',
      // rateType: 'percentage',
      // formulaId: undefined,
      id: undefined,
      title: 'Custom Fee',
      // trigger: 'reservation',
      revenueRecognition: 'checkIn',
      type: 'additionalFee',
      bookingChannelsFilter: 'all-channels',
      statusFilter: null,
      taxRateId: null,
      taxRateBehavior: 'excluded',
      defaultRateBasePoints: 2500,
      linkedAccountIds: [],
    },
    config: {
      accounts: [],
      formulaTemplate: DEFAULT_FORMULA,
    },
  },
};
