import { Badge, Button } from '@finalytic/components';
import {
  useInfiniteQuery,
  useInvalidateQueries,
  useMutation,
  useTeamId,
} from '@finalytic/data';
import { InfiniteTable } from '@finalytic/table';
import { showErrorNotification } from '@finalytic/ui';
import { day } from '@finalytic/utils';
import { Group, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { SettingsTitle } from '../_components';
import { ApiKeyModal } from './ApiKeyModal';

function useApiMutation() {
  const { mutate, loading } = useMutation(
    (q, args: { teamId: string; apiToken: string }) => {
      const token = q.insertOneToken({
        object: {
          tenantId: args.teamId,
          type: 'API_TOKEN',
          nanoId: args.apiToken,
          payload: {
            hint: args.apiToken.slice(0, 4),
            role: 'editor',
            scope: '*',
          },
        },
      });

      return {
        id: token?.id,
      };
    },
    {
      invalidateQueryKeys: ['token'],
      successMessage: {
        title: 'API Token Created',
        message: 'Successfully created Api Token',
      },
      errorMessage: {
        title: "We couldn't fetch your API key.",
        message: 'Please reach out to us if this error persists.',
      },
    }
  );

  return {
    mutate,
    loading,
  };
}

const AddTokenButton = () => {
  const { loading: loadingMutation, mutate: insertApiToken } = useApiMutation();
  const [opened, handlers] = useDisclosure(false);
  const [teamId] = useTeamId();

  const [apiTokenId, setApiTokenId] = useState<string | undefined>();

  const showErrorMessage = () =>
    showErrorNotification({
      title: "We couldn't fetch your API key.",
      message: 'Please reach out to us if this error persists.',
    });
  const createToken = async () => {
    const apiToken = nanoid(25);
    const token = await insertApiToken({ args: { teamId, apiToken } });

    if (!token?.id) return showErrorMessage();

    setApiTokenId(token?.id);

    handlers.open();
  };

  const invalidate = useInvalidateQueries(['token']);

  return (
    <>
      <Button
        leftIcon={'PlusIcon'}
        loading={loadingMutation}
        onClick={createToken}
      >
        Create API Token
      </Button>

      <ApiKeyModal
        opened={opened}
        onClose={handlers.close}
        id={apiTokenId}
        refetchTable={() => invalidate()}
      />
    </>
  );
};

export const SettingsIntegrationApiView = () => {
  const { primaryColor } = useMantineTheme();
  const invalidate = useInvalidateQueries();

  return (
    <>
      <Group mb="sm" justify="space-between">
        <SettingsTitle type="view-title">API Tokens</SettingsTitle>

        <Group>
          <Button
            component={'a'}
            href="https://api.vrplatform.app/spec"
            target="_blank"
            rightIcon={'ArrowRightIcon'}
            variant="transparent"
            rel="noopener,noreferrer"
            color={primaryColor}
          >
            API Reference
          </Button>

          <Button
            leftIcon={'RefreshCwIcon'}
            variant="light"
            onClick={() => invalidate(['token'])}
          >
            Refresh data
          </Button>
          <AddTokenButton />
        </Group>
      </Group>
      <TokenTable />
    </>
  );
};

const TokenTable = () => {
  const [teamId] = useTeamId();
  const [apiTokenId, setApiTokenId] = useState<string | undefined>();

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where = {
        tenantId: { _eq: args.teamId },
        type: { _eq: 'API_TOKEN' },
      };

      const aggregate = q.tokenAggregate({ where }).aggregate?.count() || 0;

      const list = q
        .token({
          where,
          order_by: [{ createdAt: 'desc_nulls_last' }],
          offset,
          limit,
        })
        .map((token) => ({
          id: token.id,
          createdAt: token.createdAt,
          payload: token.payload(),
        }));

      return {
        aggregate,
        list,
      };
    },
    {
      queryKey: 'token',
      variables: {
        teamId,
      },
    }
  );

  return (
    <>
      <InfiniteTable
        columns={[
          {
            header: 'Created At',
            accessorKey: 'createdAt',
            Cell: ({ cell }) =>
              day(cell.getValue<string>()).format('MMMM D, YYYY h:mm A'),
          },

          {
            header: 'Payload Hint',
            accessorKey: 'payload.hint',
          },

          {
            header: 'Payload Scope',
            accessorKey: 'payload.scope',
          },

          {
            header: 'Payload Role',
            Cell: () => <Badge color="green">Editor</Badge>,
          },
        ]}
        table={{
          hideTopBar: true,
          onRowClick: {
            handler: (row) => setApiTokenId(row.original.id),
          },
        }}
        queryData={queryData}
      />
      <ApiKeyModal
        opened={!!apiTokenId}
        onClose={() => setApiTokenId(undefined)}
        id={apiTokenId}
        refetchTable={() => queryData.refetch()}
      />
    </>
  );
};
