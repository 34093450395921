import { useQuery, useTeamId } from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Edit3Icon, FolderOpenIcon, Icon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
} from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Box, Tooltip } from '@mantine/core';
import { deleteCheckNewOwner } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useOwnerActivityDrawer } from '../../../views/owners/owners/activity';
import { useOwnerDetailDrawer } from '../useOwnerDetailDrawer';

type Props = {
  ownerId: string;
  isArchived: boolean;
  openDeleteModal: () => void;
  openArchiveModal: () => void;
};

export const OwnerEllipsisMenuItems = ({
  ownerId,
  openDeleteModal,
  openArchiveModal,
  isArchived,
}: Props) => {
  const [teamId] = useTeamId();
  const { open } = useOwnerDetailDrawer();
  const { open: openActivity } = useOwnerActivityDrawer();

  const { data, isLoading: loading } = useQuery(
    (q, { ownerId, tenantId }) => deleteCheckNewOwner(q, { ownerId, tenantId }),
    {
      queryKey: 'owners',
      variables: {
        ownerId,
        tenantId: teamId,
      },
    }
  );

  const canBeDeleted = data?.canDelete;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const deleteTooltip = useMemo(() => {
    const items = Object.entries(data || {})
      .filter((x) => x[1])
      .map((x) => toTitleCase(x[0]).toLowerCase())
      .join(', ');

    if (data?.isPmsOwner) {
      return 'This owner is linked to a PMS and cannot be deleted.';
    }

    return `This owner cannot be deleted with existing ${items}.`;
  }, [JSON.stringify(data)]);

  return (
    <>
      <EllipsisMenuItem
        onClick={() => open(ownerId, 'edit')}
        customIcon={<Edit3Icon size={18} />}
      >
        Edit
      </EllipsisMenuItem>

      <EllipsisMenuItem
        onClick={() => openActivity(ownerId)}
        customIcon={<Icon icon="HistoryIcon" size={18} />}
      >
        Activity
      </EllipsisMenuItem>

      <EllipsisMenuDivider />

      <EllipsisMenuItem
        onClick={openArchiveModal}
        customIcon={<FolderOpenIcon size={16} />}
      >
        {isArchived ? 'Unarchive' : 'Archive'}
      </EllipsisMenuItem>

      <Tooltip
        disabled={canBeDeleted || loading}
        label={deleteTooltip}
        withArrow
        multiline
        withinPortal
        maw={400}
      >
        <Box>
          <EllipsisMenuDangerItem
            onClick={openDeleteModal}
            loading={loading}
            disabled={!canBeDeleted}
          >
            Delete
          </EllipsisMenuDangerItem>
        </Box>
      </Tooltip>

      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuCopyItem value={ownerId} />
      </HiddenFeatureIndicator>
    </>
  );
};
