import { Badge, IconButton } from '@finalytic/components';
import { useMutation, useQuery, useTeamId } from '@finalytic/data';
import { ArrowRightIcon, Icon } from '@finalytic/icons';
import {
  InfiniteTable,
  type MRT_ColumnDef,
  type MRT_SortingState,
} from '@finalytic/table';
import { EllipsisMenuItem } from '@finalytic/ui';
import { ensure, hasValue, isUUID, toTitleCase } from '@finalytic/utils';
import { Box, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { formatPaymentLineType } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { useAccountsConfig } from '../useAccountsConfig';
import { DefaultRevenueRecognitionInput } from './DefaultRevenueRecognitionInput';
import {
  LineTypeMappingEditors,
  useLineTypeMappingQuery,
} from './LineTypeMappingEditors';
import { LineTypeMappingExceptionModal } from './LineTypeMappingExceptionModal';
import {
  LineTypeMappingFilter,
  type LineTypeType,
  useLineTypeMappingFilter,
} from './LineTypeMappingFilter';
import type { LineTypeMappingRow } from './useLineTypeMappingTableQuery';
import { useLineTypeMappingTableQuery } from './useLineTypeMappingTableQuery';

type Props = {
  type: LineTypeType;
};

export const LineTypeMappingTable = ({ type }: Props) => {
  const { reset } = useLineTypeMappingFilter();
  const { isMasterList } = useAccountsConfig();
  const [lineType, setLineType] = useState<LineTypeMappingRow | null>(null);

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'name', desc: false },
  ]);
  const queryData = useLineTypeMappingTableQuery({
    sorting,
    type,
  });

  const columns = useMemo<MRT_ColumnDef<LineTypeMappingRow>[]>(
    () =>
      ensure<(MRT_ColumnDef<LineTypeMappingRow> | undefined)[]>([
        {
          header: 'Name',
          Cell: ({ row }) => {
            const { app, line } = formatPaymentLineType(row.original.name);

            return (
              <Box>
                {app && (
                  <Text c="gray" size="xs">
                    {app}
                  </Text>
                )}
                <Text>{line}</Text>
              </Box>
            );
          },
        },
        {
          header: 'ID',
          accessorKey: 'name',
          Cell: ({ cell }) => (
            <Box>
              <Text c="gray" size="xs">
                {toTitleCase(cell.row.original.type)}
              </Text>
              <Text
                component="p"
                m={0}
                sx={{
                  wordBreak: 'break-all',
                }}
              >
                {cell.getValue<string>()}
              </Text>
            </Box>
          ),
        },
        isMasterList
          ? {
              header: 'Account assignment',
              minSize: 220,
              size: 220,
              maxSize: 0,
              grow: false,
              Cell: ({ row }) => {
                if (!row.original.name) return '-';

                return (
                  <LineTypeMappingEditors.AccountAssignment
                    lineType={row.original.name}
                    disabled={row.original.type === 'reservation_line'}
                  />
                );
              },
            }
          : undefined,
        {
          header: 'Account',
          minSize: 220,
          size: 220,
          maxSize: 0,
          grow: false,
          Cell: ({ row }) => {
            if (!row.original.name) return '-';

            return (
              <LineTypeMappingEditors.Account lineType={row.original.name} />
            );
          },
        },
        {
          header: 'Revenue Recognition',
          minSize: 220,
          size: 220,
          maxSize: 0,
          grow: false,
          Cell: ({ row }) => {
            if (!row.original.name) return '-';

            return (
              <LineTypeMappingEditors.RecognitionRecognition
                lineType={row.original.name}
                disabled={row.original.type === 'payment_line'}
              />
            );
          },
        },
      ]).filter(hasValue),
    [isMasterList]
  );

  return (
    <>
      <LineTypeMappingExceptionModal
        lineType={lineType}
        closeModal={() => setLineType(null)}
      />

      <InfiniteTable
        columns={columns}
        table={{
          emptyRowsFallback: () => (
            <Text component="p" c="gray" ta="center" my="xl" m={0}>
              No accounts found
            </Text>
          ),
        }}
        queryData={queryData}
        sorting={{
          sorting,
          setSorting,
        }}
        rowMenu={{
          menuItems: ({ row }) => {
            const lineType = row.original.name;

            const { data } = useLineTypeMappingQuery(lineType || '');

            if (!!data?.isRecommendation || !data?.mapping?.value) return null;

            return (
              <EllipsisMenuItem
                onClick={() => setLineType(row.original)}
                customIcon={<Icon icon="Edit3Icon" size={18} />}
              >
                Edit exceptions
              </EllipsisMenuItem>
            );
          },
        }}
        subRows={{
          defaultExpanded: true,
          getRowCanExpand: (row) =>
            !!row.original.bookingChannelExceptions?.length,
          renderDetailPanel: ({ row, table }) => {
            const [teamId] = useTeamId();
            const exceptions = row.original.bookingChannelExceptions || [];

            const tableDensity = table.getState().density;

            const queryData = useQuery(
              (q, args) => {
                if (!args.accountIds.length) return [];

                return q
                  .accounts({
                    where: {
                      id: { _in: args.accountIds },
                      tenantId: { _eq: args.teamId },
                    },
                    order_by: [{ title: 'asc' }],
                  })
                  .map((x) => ({
                    id: x.id as string,
                    title: x.title!,
                  }));
              },
              {
                variables: {
                  accountIds: exceptions.map((x) => x.accountId).filter(isUUID),
                  teamId,
                },
                keepPreviousData: true,
              }
            );

            if (!exceptions.length) return null;

            return (
              <Box
                pr={tableDensity}
                sx={() => ({
                  paddingLeft: 70,
                  width: '100%',
                  minWidth: '100%',
                  position: 'relative',
                })}
              >
                <LoadingOverlay
                  visible={queryData.isLoading}
                  loaderProps={{
                    size: 'sm',
                  }}
                />
                <Stack>
                  {exceptions.map((child, index) => {
                    return (
                      <Group
                        key={child.bookingChannel}
                        justify="space-between"
                        sx={{
                          justifyContent: 'flex-start',
                        }}
                        wrap="nowrap"
                      >
                        <Text>{index + 1}.</Text>
                        <Text>{toTitleCase(child.bookingChannel)}</Text>
                        <ArrowRightIcon size={20} />
                        {child.accountId === 'exclude' ? (
                          <Badge color="orange">Excluded</Badge>
                        ) : (
                          <Text>
                            {queryData.isLoading
                              ? '...'
                              : queryData.data?.find(
                                  (x) => x.id === child.accountId
                                )?.title || 'Missing account'}
                          </Text>
                        )}
                        <DeleteExceptionIconButton id={child.id} />
                      </Group>
                    );
                  })}
                </Stack>
              </Box>
            );
          },
        }}
        resetFilter={reset}
      >
        <Group justify="space-between" flex={1}>
          <LineTypeMappingFilter />
          {!isMasterList && <DefaultRevenueRecognitionInput />}
        </Group>
      </InfiniteTable>
    </>
  );
};

const DeleteExceptionIconButton = ({
  id,
}: {
  id: string;
}) => {
  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        id: string;
      }
    ) => {
      return q.deleteAccountReservationLineType({
        id: args.id,
      }).id;
    },
    {
      invalidateQueryKeys: ['lineTypeMappings'],
    }
  );

  return (
    <IconButton
      icon="TrashIcon"
      onClick={() => mutate({ args: { id } })}
      loading={loading}
      size={16}
      tooltip="Remove exception"
    />
  );
};
