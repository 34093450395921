import {
  AddressFormInputs,
  Button,
  Input,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  type gqlV2,
  useApiMutation,
  useBrowserTracking,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeam,
  useTeamId,
  useTeamRole,
  useTrpcMutation,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import type { tenant_bool_exp } from '@finalytic/graphql';
import { AlertTriangleIcon } from '@finalytic/icons';
import {
  BooleanParam,
  Drawer,
  type SelectItem,
  StringParam,
  showErrorNotification,
  showSuccessNotification,
  useAppName,
  useQueryParam,
} from '@finalytic/ui';
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Group,
  ScrollArea,
  Stack,
} from '@mantine/core';
import {
  DEMO_TEST_PARTNER_TENANT_ID,
  VRP_TENANT_ID,
  formatAddress,
  getCountryByIsoCode,
  getStateByIsoCode,
  validateTin,
  whereTenants,
} from '@vrplatform/ui-common';
import { useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useNavigate } from 'react-router';
import { DrawerHeader, DrawerSectionTitle } from '../_components';

export function useAddTeamDrawer() {
  const [opened, setOpened] = useQueryParam('add-team-drawer', BooleanParam);
  const [teamName, setTeamName] = useQueryParam('add-team-name', StringParam);

  return {
    opened: !!opened,
    teamName,
    open: (teamName?: string) => {
      setTeamName(teamName);
      setOpened(true);
    },
    close: () => {
      setOpened(false);
      setTeamName(undefined);
    },
  };
}

type FormInputs = {
  teamName: string;
  companyName: string;
  email: string;
  phone: string;
  taxId: string;
  address: Parameters<typeof formatAddress>[0];
  partnerId: string;
  type: 'propertyManager' | 'partner';
  enableGL: boolean;
  copyChartOfAccountFromTeamId: string | null;
};

export const AddTeamDrawer = () => {
  const { opened, close, teamName } = useAddTeamDrawer();
  const [taxDisabled, setTaxDisabled] = useState(false);
  const { isPartnerAdmin, isVrpAdmin } = useTeamRole();
  const navigate = useNavigate();
  const { track } = useBrowserTracking();
  const { id: meId } = useMe();
  const [{ partnerId, id: teamId }] = useTeam();
  const [, switchTeam] = useTeamId();

  const methods = useForm<FormInputs>({
    values: {
      companyName: '',
      email: '',
      partnerId: isVrpAdmin ? VRP_TENANT_ID : partnerId,
      phone: '',
      enableGL: false,
      copyChartOfAccountFromTeamId: null,
      type: 'propertyManager',
      taxId: '',
      teamName: teamName || '',
      address: {
        city: '',
        countryCode: 'US',
        state: '',
        line1: '',
        line2: '',
        stateCode: 'AL',
        country: '',
        postcode: '',
      },
    },
  });

  const teamType = methods.watch('type');
  const isEnableGL = methods.watch('enableGL');

  const { mutate: insert } = useTrpcMutation('addTeam', {
    successMessage: {
      title: 'Team Created',
      message: 'Your team was successfully created.',
    },
  });

  const { mutateAsync: apiAddTeam } = useApiMutation('post', '/teams', {
    onSuccess: () => {
      showSuccessNotification({
        title: 'Team Created',
        message: 'Your team was successfully created.',
      });
    },
    onError: (error: any) => {
      showErrorNotification({
        title: 'Team Creation Failed',
        message: error?.message || 'Failed to create team.',
      });
    },
  });

  const submit = async (data: FormInputs) => {
    const hasAddress = !!data.address.line1?.trim();

    if (hasAddress && (!data.address.stateCode || !data.address.countryCode)) {
      return showErrorNotification({
        title: 'Validation error',
        message: 'Please select a country and state.',
        icon: <AlertTriangleIcon size={20} />,
      });
    }

    const country =
      (data.address.countryCode &&
        getCountryByIsoCode(data.address.countryCode)?.name) ||
      '';
    const state =
      (data.address.countryCode &&
        data.address.stateCode &&
        getStateByIsoCode(data.address.stateCode, data.address.countryCode)
          ?.name) ||
      '';

    let newTeamId = '';

    if (data.enableGL) {
      const partnerId = DEMO_TEST_PARTNER_TENANT_ID;

      const res = await apiAddTeam({
        body: {
          name: data.teamName.trim(),
          partnerId,
          isOnboarding: true,
          companyName: data.companyName.trim(),
          phone: data.phone.trim(),
          email: data.email.trim(),
          taxId: data.taxId.trim(),
          generalLedger: {
            copyFromTeamId: data.copyChartOfAccountFromTeamId || VRP_TENANT_ID,
          },
          billingAddress: hasAddress
            ? {
                full: formatAddress(data.address),
                line1: data.address.line1.trim(),
                line2: data.address.line2?.trim(),
                city: data.address.city.trim(),
                countryCode: data.address.countryCode!,
                stateCode: data.address.stateCode!,
                postalCode: data.address.postcode.trim(),
                state,
                // country,
              }
            : undefined,
          addMembers: [
            {
              userId: meId,
              role: 'admin',
            },
          ],
        },
        headers: {
          'x-team-id': partnerId,
        },
      });
      if (res.id) {
        newTeamId = res.id;
      }
    } else {
      const result = await insert({
        tenantId: teamId,
        input: {
          name: data.teamName.trim(),
          partnerId: data.partnerId,
          companyName: data.companyName.trim(),
          phone: data.phone.trim(),
          email: data.email.trim(),
          taxId: data.taxId.trim(),
          type: data.type,
          address: hasAddress
            ? {
                city: data.address.city.trim(),
                countryCode: data.address.countryCode!,
                state,
                line1: data.address.line1.trim(),
                line2: data.address.line2?.trim(),
                stateCode: data.address.stateCode!,
                country,
                postcode: data.address.postcode.trim(),
              }
            : undefined,
        },
      });
      newTeamId = result.id;
    }

    if (newTeamId) {
      track('team_added', {
        tenantId: newTeamId,
        tenantName: data.teamName.trim(),
        partnerId: data.enableGL ? DEMO_TEST_PARTNER_TENANT_ID : data.partnerId,
        userType: isPartnerAdmin ? 'partner' : 'user',
      });

      switchTeam(newTeamId);
      navigate('/');
      window.location.reload();
    } else {
      showErrorNotification({
        title: 'Team Creation Failed',
        message: 'Failed to create team.',
      });
    }
  };

  return (
    <Drawer opened={opened} onClose={close} zIndex={300}>
      <DrawerHeader
        title="Add New Team"
        closeDrawer={close}
        containerSx={{
          paddingBottom: 0,
        }}
      />
      <Divider
        sx={(theme) => ({
          // marginInline: `-${theme.spacing.xs}`,
          borderTopColor: theme.colors.gray[2],
        })}
      />
      <Box
        component="form"
        onSubmit={methods.handleSubmit(submit)}
        onReset={() => {
          methods.reset();
          close();
        }}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'hidden',
          overflowX: 'unset',
        }}
      >
        <FormProvider {...methods}>
          <ScrollArea
            // pb="md"
            sx={{
              flex: 1,
            }}
            styles={(theme) => ({
              viewport: {
                paddingBottom: theme.spacing.md,
              },
            })}
          >
            <Stack gap="md" mt="sm">
              <Controller
                name="teamName"
                defaultValue=""
                control={methods.control}
                rules={{
                  required: 'Team name is required',
                  validate: (value) =>
                    !!value.trim() || 'Team name is required',
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper
                    label="Team name"
                    required
                    error={error?.message}
                  >
                    <Input
                      {...field}
                      placeholder="Team name"
                      error={error?.message}
                    />
                  </InputWrapper>
                )}
              />

              {!isEnableGL && (
                <>
                  <HiddenFeatureIndicator permission="vrp-admin">
                    <Controller
                      name="type"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => {
                        const options = [
                          {
                            value: 'propertyManager',
                            label: 'Property Manager',
                          },
                          {
                            value: 'partner',
                            label: 'Partner',
                          },
                        ];

                        const value =
                          options.find((i) => i.value === field.value) || null;

                        return (
                          <InputWrapper
                            label="Team type"
                            required
                            error={error?.message}
                          >
                            <InputSelect
                              value={value}
                              type="single"
                              setValue={(value) =>
                                value?.value && field.onChange(value.value)
                              }
                              data={{
                                options,
                              }}
                              dropdownProps={{
                                withinPortal: true,
                                width: 'target',
                                zIndex: 400,
                              }}
                              inputProps={{
                                placeholder: 'Select team type',
                                error: !!error?.message,
                              }}
                            />
                          </InputWrapper>
                        );
                      }}
                    />
                  </HiddenFeatureIndicator>
                  <PartnerSelect />
                </>
              )}
            </Stack>

            <DrawerSectionTitle>Company</DrawerSectionTitle>

            <Stack gap="sm">
              <Controller
                name="companyName"
                defaultValue=""
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper label="Company name" error={error?.message}>
                    <Input
                      {...field}
                      placeholder="Company name"
                      error={error?.message}
                    />
                  </InputWrapper>
                )}
              />
              <Controller
                name="email"
                defaultValue=""
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper label="Email" error={error?.message}>
                    <Input
                      {...field}
                      placeholder="Email"
                      error={error?.message}
                    />
                  </InputWrapper>
                )}
              />
              <Controller
                name="phone"
                defaultValue=""
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper label="Phone" error={error?.message}>
                    <Input
                      {...field}
                      placeholder="Phone"
                      error={error?.message}
                    />
                  </InputWrapper>
                )}
              />

              <Box>
                <Controller
                  control={methods.control}
                  name="taxId"
                  defaultValue=""
                  rules={{
                    required: taxDisabled
                      ? undefined
                      : 'Tax Identification Number is required',
                    validate: taxDisabled ? undefined : (v) => validateTin(v),
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputWrapper
                      label="TIN (US only)"
                      error={error?.message}
                      mb="sm"
                      required={!taxDisabled}
                    >
                      <Input
                        {...field}
                        placeholder="Tax Identification Number"
                        autoComplete="off"
                        error={!!error}
                        disabled={taxDisabled}
                      />
                    </InputWrapper>
                  )}
                />

                <Checkbox
                  label="I do not have a TIN"
                  size="xs"
                  mb="sm"
                  checked={taxDisabled}
                  onChange={() => {
                    setTaxDisabled((e) => !e);
                    methods.clearErrors('taxId');
                    methods.setValue('taxId', '');
                  }}
                />
              </Box>
            </Stack>

            <DrawerSectionTitle>Address</DrawerSectionTitle>
            <AddressFormInputs />
          </ScrollArea>
          <Box mb="sm">
            <Divider mb={teamType === 'partner' ? 'sm' : 5} />
            {teamType !== 'partner' && (
              <HiddenFeatureIndicator permission="vrp-admin" mb="sm">
                <Box mb={8} px={4}>
                  <InputWrapper label="General Ledger Testing">
                    <Controller
                      control={methods.control}
                      name="enableGL"
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          label="Create a GL test team starting in onboarding"
                          size="xs"
                          checked={field.value}
                          onChange={() => {
                            field.onChange(!field.value);
                            methods.clearErrors('email');
                          }}
                        />
                      )}
                    />
                  </InputWrapper>
                  <Collapse in={isEnableGL}>
                    <ChartOfAccountCopySelect />
                  </Collapse>
                </Box>
              </HiddenFeatureIndicator>
            )}
            <Group justify="right">
              <Button type="reset" disabled={methods.formState.isSubmitting}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={methods.formState.isSubmitting}
                sx={{
                  flex: 1,
                }}
              >
                Create team
              </Button>
            </Group>
          </Box>
        </FormProvider>
      </Box>
    </Drawer>
  );
};

const ChartOfAccountCopySelect = () => {
  const methods = useFormContext<FormInputs>();
  const selectedPartnerId = methods.watch('partnerId');
  const [search, setSearch] = useState('');
  const [{ partnerId }] = useTeam();

  const { appName } = useAppName();

  const [teamId] = useTeamId();

  const queryData = useInfiniteQuery(
    (q, args) => {
      const where: tenant_bool_exp = {
        ...whereTenants({
          search: args.search,
          dashboard: 'propertyManager',
          partnerId: args.partnerId,
        }),
        id: args.teamId ? { _neq: args.teamId } : undefined,
      };

      const list = q
        .tenant({
          where,
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map((tenant) => ({
          value: tenant.id,
          label: tenant.name || '',
        }));

      const aggregate =
        q
          .tenantAggregate({
            where,
          })
          .aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        teamId,
        search: search.trim(),
        partnerId: selectedPartnerId || partnerId,
      },
    }
  );

  return (
    <Controller
      control={methods.control}
      name="copyChartOfAccountFromTeamId"
      render={({ field }) => {
        const { error, isLoading, data } = useQuery(
          (q, args) => {
            if (!args.id) return null;

            return (
              q
                .tenant({
                  where: {
                    id: { _eq: args.id },
                  },
                })
                .map((tenant) => ({
                  value: tenant.id,
                  label: tenant.name || '',
                }))[0] || undefined
            );
          },
          {
            skip: !field.value,
            variables: {
              id: field.value,
            },
            keepPreviousData: true,
          }
        );

        const defaultLabel = `${appName} - Default chart of accounts`;
        const defaultValue = { value: null, label: defaultLabel };
        const value = field.value ? data || defaultValue : defaultValue;

        return (
          <InputWrapper
            label="Copy chart of accounts from"
            // description="Team options are filtered down to currently selected partner"
            description="This will be available later for partners to copy from different teams"
            mt="sm"
            inputWrapperOrder={['label', 'input', 'description', 'error']}
          >
            <InputSelect
              type="single"
              value={value.value ? value : null}
              infiniteData={{ ...queryData, setSearch }}
              setValue={(v) => field.onChange(v?.value || null)}
              inputProps={{
                loadingQuery: isLoading,
                error: error?.message,
                disabled: true, // TODO remove lter
              }}
              customActionTop={{
                label: defaultLabel,
                onSubmit: () => field.onChange(null),
              }}
              dropdownProps={{
                withinPortal: true,
                position: 'top',
                zIndex: 2000,
                width: 'target',
              }}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

const PartnerSelect = () => {
  const methods = useFormContext<FormInputs>();
  const teamType = methods.watch('type');

  const [search, setSearch] = useState<string>('');

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where: gqlV2.tenant_bool_exp = {
        type: { _eq: 'partner' },
        name: args.search ? { _ilike: `%${args.search}%` } : undefined,
      };

      const aggregate = q.tenantAggregate({ where })?.aggregate?.count() || 0;
      const allPartnerCount =
        q
          .tenantAggregate({
            where: { type: { _eq: 'partner' } },
          })
          ?.aggregate?.count() || 0;

      const list = q
        .tenant({
          order_by: [{ type: 'asc_nulls_last' }, { name: 'asc' }],
          where,
          limit,
          offset,
        })
        .map<SelectItem>((i) => ({
          value: i.id,
          label: i.name || '',
        }));

      return {
        list,
        aggregate,
        allPartnerCount,
      };
    },
    {
      variables: {
        search: search.trim(),
      },
    }
  );

  const hasAccessToMultiplePartnes =
    (queryData.data?.pages[0].allPartnerCount || 0) > 0;

  if (!hasAccessToMultiplePartnes) return null;

  return (
    <Collapse in={teamType === 'propertyManager'}>
      <Controller
        control={methods.control}
        name="partnerId"
        render={({ field }) => {
          const options = queryData.data?.pages.flatMap((i) => i.list) || [];
          const value = options.find((i) => i.value === field.value) || null;

          return (
            <InputWrapper label="Partner" required>
              <InputSelect
                infiniteData={{ ...queryData, setSearch }}
                type="single"
                value={value}
                setValue={(value) => {
                  if (!value?.value) return;
                  field.onChange(value.value);
                }}
                inputProps={{
                  placeholder: 'Partner team',
                }}
                dropdownProps={{
                  withinPortal: true,
                  width: 'target',
                  zIndex: 400,
                }}
              />
            </InputWrapper>
          );
        }}
      />
    </Collapse>
  );
};
