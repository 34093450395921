import { Filter } from '@finalytic/components';
import { AutomationIcon, type SelectItem } from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { useMemo } from 'react';
import { useAccountingConnection } from '../hooks';
import { useAutomationsQuery } from '../queries';

type Props = {
  automationIds: Maybe<Maybe<string>[]>;
  setAutomationIds: (ids: string[]) => void;
};

export const AutomationFilter = ({
  automationIds,
  setAutomationIds,
}: Props) => {
  const {
    data: automations = [],
    isLoading: loading,
    error,
  } = useAutomationsQuery();
  const { accounting } = useAccountingConnection();

  const options = useMemo(() => {
    const filtered = automations.filter((automation) => {
      const isAccountingAutomation = automation.template.uniqueRef
        ?.toLowerCase()
        ?.includes('accounting');
      if (isAccountingAutomation)
        return [
          automation.leftConnectionId,
          automation.rightConnectionId,
        ].includes(accounting?.id || '');

      return true;
    });

    return filtered.map<SelectItem>((automation) => ({
      label: automation.title || '',
      value: automation.automationId,
      icon: (
        <AutomationIcon templateType={automation.template.type} size={16} />
      ),
    }));
  }, [automations, accounting]);

  const value = useMemo(
    () => options.filter((a) => automationIds?.includes(a.value)) || [],
    [automationIds, options]
  );

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setAutomationIds(v.map((i) => i.value))}
      label="Automation"
      type="multiple"
      withinPortal
      data={{
        options,
        loading,
        error,
      }}
    />
  );
};
