import { useDashboard, useQuery, useTeam } from '@finalytic/data';
import type { Maybe } from '@finalytic/utils';
import { getReservationTableRow } from '../../../../reservations/useReservationTableQuery';

type Props = {
  reservationId: Maybe<string>;
};

export type FeeReservationPreview = NonNullable<
  ReturnType<typeof useFeeReservationPreviewQuery>['data']
>;

export const useFeeReservationPreviewQuery = (props: Props) => {
  const [{ id: teamId, partnerId }] = useTeam();
  const [dashboard] = useDashboard();

  return useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .reservations({
            where: {
              id: {
                _eq: args.id,
              },
              tenantId: { _eq: args.tenantId },
            },
            limit: 1,
          })
          .map((reservation) =>
            getReservationTableRow(reservation, {
              GL: true,
              ...args,
            })
          )[0] ?? null
      );
    },
    {
      keepPreviousData: true,
      skip: !props.reservationId,
      variables: {
        id: props.reservationId ?? null,
        tenantId: teamId,
        partnerId,
        dashboard,
      },
    }
  );
};
