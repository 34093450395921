import { Filter } from '@finalytic/components';
import { Icon } from '@finalytic/icons';
import type { SelectItem } from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import type { JournalEntryRelation } from '@vrplatform/ui-common';
import { useMemo } from 'react';

type Props = {
  value: Maybe<string>;
  setValue: (value: Maybe<JournalEntryRelation>) => void;
};

export const JournalEntryTypeFilter = ({ setValue, value: initial }: Props) => {
  const options = useMemo<SelectItem<JournalEntryRelation>[]>(
    () => [
      {
        label: 'Reservation',
        value: 'reservation',
        icon: <Icon icon="CalendarEventIcon" size={16} />,
      },
      {
        label: 'Deposit',
        value: 'deposit',
        icon: <Icon icon="PlusCircleIcon" size={16} />,
      },
      {
        label: 'Expense',
        value: 'expense',
        icon: <Icon icon="MinusCircleIcon" size={16} />,
      },
      {
        label: 'Fee & Commission',
        value: 'recurringFee',
        icon: <Icon icon="PercentageIcon" size={16} />,
      },
      {
        label: 'Transfer',
        value: 'transfer',
        icon: <Icon icon="ShuffleIcon" size={14} />,
      },
    ],
    []
  );

  const value = options.find((i) => i.value === initial) || null;

  return (
    <Filter.Select
      value={value}
      label="Type"
      setValue={(v) => setValue(v?.value)}
      type="single"
      data={{
        options,
      }}
      hideIcon
    />
  );
};
