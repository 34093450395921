import type { SelectItem } from '@finalytic/ui';
import { COUNTRIES } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { InputSelect } from '../InputSelect';

type Props = {
  disabled?: boolean;
  value: string | null;
  setValue: (value: SelectItem | null) => void;
  error?: string | boolean;
  placeholder?: string;
  clearable?: boolean;
};

export const useInputCountryOptions = () => {
  return useMemo(() => {
    const pinnedCountries = ['US', 'UK', 'FR', 'DE', 'GB'];

    const pinned = COUNTRIES.filter((country) =>
      pinnedCountries.includes(country.isoCode)
    ).map<SelectItem>((country) => ({
      value: country.isoCode,
      label: country.isoCode === 'GB' ? 'United Kingdom' : country.name,
    }));

    const countries = COUNTRIES.filter(
      (country) => !pinnedCountries.includes(country.isoCode)
    ).map<SelectItem>((country) => ({
      value: country.isoCode,
      label: country.isoCode === 'GB' ? 'United Kingdom' : country.name,
    }));

    return {
      countries,
      pinned,
      all: [...pinned, ...countries],
    };
  }, []);
};

export const InputCountry = ({
  disabled,
  setValue,
  value,
  error,
  placeholder = 'Country',
  clearable = true,
}: Props) => {
  const options = useInputCountryOptions();

  const selected = disabled
    ? null
    : options.all.find(
        (option) => option.value.toLowerCase() === value?.toLowerCase()
      ) || null;

  return (
    <InputSelect
      data={{
        options: options.countries,
      }}
      pinnedItems={options.pinned}
      type="single"
      value={selected}
      setValue={setValue}
      dropdownProps={{
        width: 'target',
        withinPortal: true,
        zIndex: 400,
      }}
      inputProps={{
        disabled,
        placeholder,
        error,
        withClearButton: clearable,
      }}
    />
  );
};
