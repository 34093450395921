import {
  type Maybe,
  bankersRoundToCents,
  formatCurrency,
} from '@finalytic/utils';
import {
  Box,
  Group,
  NumberInput,
  Popover,
  SegmentedControl,
  type SegmentedControlItem,
  Stack,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  type AmountFilterNumberObject,
  type AmountFilterNumberString,
  formatAmountFilterToString,
  formatStringToAmountFilter,
} from '@vrplatform/ui-common';
import { type ReactNode, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../button';
import { FilterPill } from './FilterPill';

type Props = {
  value: Maybe<AmountFilterNumberString>;
  setValue: (
    v: {
      text: Maybe<AmountFilterNumberString>;
      amount: Maybe<AmountFilterNumberObject>;
    } | null
  ) => void;
  debounce?: number;
  placeholder?: string;
  clearable?: boolean;
};

const isEmptyAmount = (amount: AmountFilterNumberObject | null): boolean => {
  if (!amount) return true;

  if (amount.type !== 'between') {
    return typeof amount.centTotal !== 'number';
  }

  return (
    typeof amount.gtCentTotal !== 'number' ||
    typeof amount.ltCentTotal !== 'number'
  );
};

const possibleFilterValues: SegmentedControlItem[] = [
  { label: 'Equals', value: 'eq' },
  { label: 'Less', value: 'lt' },
  { label: 'Greater', value: 'gt' },
  { label: 'Between', value: 'between' },
] as const;

export const AmountFilter = (props: Props) => {
  const [opened, handlers] = useDisclosure(false);

  const { value, setValue, placeholder = 'Amount', clearable = true } = props;

  const isDefault = !props.value;

  const resetFilter = useCallback(() => {
    setValue(null);
    handlers.close();
  }, [handlers, setValue]);

  const label = useMemo(() => {
    if (!value) return placeholder;

    if (value.startsWith('eq')) {
      return formatCurrency(Number(value.slice(2)) / 100);
    }

    if (value.startsWith('gt')) {
      return `> ${formatCurrency(Number(value.slice(2)) / 100)}`;
    }

    if (value.startsWith('lt')) {
      return `< ${formatCurrency(Number(value.slice(2)) / 100)}`;
    }

    const [gt, lt] = value.split('...').map((v) => Number(v) / 100);

    return `${formatCurrency(gt)} ~ ${formatCurrency(lt)}`;
  }, [value, placeholder]);

  return (
    <Popover
      position="bottom-start"
      shadow="md"
      withinPortal
      opened={opened}
      onClose={handlers.close}
      onOpen={handlers.open}
    >
      <Popover.Target>
        <Box>
          <FilterPill
            opened={opened}
            isDefault={isDefault}
            label={label}
            onClick={handlers.toggle}
            resetFilter={!clearable ? undefined : resetFilter}
          />
        </Box>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <Dropdown {...props} value={value} closeModal={handlers.close} />
      </Popover.Dropdown>
    </Popover>
  );
};

const Dropdown = ({
  value,
  closeModal: cl,
  setValue,
}: Props & {
  closeModal: () => void;
}) => {
  const methods = useForm<AmountFilterNumberObject>({
    values: value ? formatStringToAmountFilter(value) : undefined,
    defaultValues: {
      type: 'eq',
    },
  });

  const type = methods.watch('type');

  const closeModal = useCallback(
    (data?: AmountFilterNumberObject | null) => {
      cl();
      methods.reset(
        data === undefined
          ? formatStringToAmountFilter(value)
          : (data ?? undefined)
      );
    },
    [cl, methods, value]
  );

  const onSubmit = (amount: AmountFilterNumberObject) => {
    const isEmpty = isEmptyAmount(amount);

    if (isEmpty) {
      setValue(null);
      closeModal(null);
      return;
    }

    setValue({
      amount,
      text: formatAmountFilterToString(amount),
    });
    closeModal(amount);
  };

  const handleSubmit = methods.handleSubmit(onSubmit);

  return (
    <Stack sx={{ maxWidth: '300px' }} gap="xs" p={'xs'}>
      <Controller
        name="type"
        defaultValue="eq"
        control={methods.control}
        render={({ field }) => {
          return (
            <SegmentedControl
              data={possibleFilterValues}
              {...field}
              sx={{ width: '280px' }}
              size="xs"
            />
          );
        }}
      />

      {type !== 'between' ? (
        <Controller
          name="centTotal"
          control={methods.control}
          render={({ field, fieldState }) => {
            return (
              <Input
                error={fieldState.error?.message}
                value={field.value ?? ''}
                name={field.name}
                onChange={(v) =>
                  field.onChange(
                    v === undefined ? null : bankersRoundToCents(v)
                  )
                }
                handleSubmit={handleSubmit}
              />
            );
          }}
        />
      ) : (
        <Group wrap="nowrap" gap="xs">
          <Controller
            name="gtCentTotal"
            control={methods.control}
            render={({ field, fieldState }) => {
              return (
                <Input
                  error={fieldState.error?.message}
                  value={field.value ?? ''}
                  name={field.name}
                  onChange={(v) =>
                    field.onChange(
                      v === undefined ? null : bankersRoundToCents(v)
                    )
                  }
                  handleSubmit={handleSubmit}
                />
              );
            }}
          />
          <Controller
            name="ltCentTotal"
            control={methods.control}
            render={({ field, fieldState }) => {
              return (
                <Input
                  error={fieldState.error?.message}
                  value={field.value ?? ''}
                  autoFocus={false}
                  name={field.name}
                  onChange={(v) =>
                    field.onChange(
                      v === undefined ? null : bankersRoundToCents(v)
                    )
                  }
                  handleSubmit={handleSubmit}
                />
              );
            }}
          />
        </Group>
      )}
      <Group justify="flex-end">
        <Button size="xs" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button size="xs" variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
      </Group>
    </Stack>
  );
};

const Input = ({
  value,
  onChange,
  error,
  name,
  autoFocus = true,
  handleSubmit,
}: {
  value: Maybe<number | ''>;
  onChange: (v: number | undefined) => void;
  error: ReactNode;
  name: string;
  autoFocus?: boolean;
  handleSubmit: () => void;
}) => {
  return (
    <NumberInput
      value={typeof value === 'number' ? value / 100 : (value ?? '')}
      onChange={(v) => onChange(v === '' ? undefined : Number(v))}
      hideControls
      name={name}
      autoFocus={autoFocus}
      placeholder={formatCurrency(0)}
      size="xs"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          handleSubmit();
        }
      }}
      decimalScale={2}
      thousandSeparator
      sx={(theme) => ({
        height: '100%',
        'input:focus': {
          boxShadow: `0px 0px 0px 2px ${
            theme.colors[error ? 'red' : theme.primaryColor][4]
          }40`,
        },
        'input:disabled': {
          color: 'unset',
        },
      })}
      error={error}
    />
  );
};
