import {
  useInvalidateQueries,
  useTeam,
  useTrpcMutation,
} from '@finalytic/data';
import {
  ConfirmModal,
  type ConfirmResultType,
  type SubmitConfirmModalType,
} from '@finalytic/data-ui';
import {
  Logo,
  StringParam,
  useAppName,
  useLegacyColors,
  useQueryParamSet,
} from '@finalytic/ui';
import { type Maybe, toTitleCase } from '@finalytic/utils';
import {
  type IconDefinition,
  faArchive,
  faPaperPlane,
  faUser,
  faUserMinus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Text } from '@mantine/core';
import type { EmotionSx as Sx } from '@mantine/emotion';
import { useClipboard, useDebouncedValue } from '@mantine/hooks';
import { formatUserName } from '@vrplatform/ui-common';
import type { PropsWithChildren } from 'react';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  ownerName: string;
  ownerId: string;
  teamId: string;
  refetch: () => void;
}

export const UserAccessEllipsisMenuModals = ({
  deleteModal,
  owner: initial,
  inviteModal,
  revokeAccessModal,
  copyInviteModal,
  refetch: re,
}: {
  owner: {
    id: Maybe<string>;
    email: Maybe<string>;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    companyName: Maybe<string>;
    teamId: Maybe<string>;
    isReinvite: boolean;
  } | null;
  refetch: () => void;
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  revokeAccessModal: {
    opened: boolean;
    closeModal: () => void;
  };
  inviteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  copyInviteModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const invalidate = useInvalidateQueries(['owners']);
  const owner = initial || debounced;

  if (!owner) return null;

  const formattedName = formatUserName(owner);
  const { isReinvite, id: ownerId, teamId, email } = owner;
  if (!ownerId || !teamId || !email) return null;

  const refetch = () => {
    re();
    invalidate();
  };

  return (
    <>
      <InviteUserModal
        email={email}
        name={formattedName}
        isReinvite={isReinvite}
        refetchTable={refetch}
        opened={inviteModal.opened}
        closeModal={inviteModal.closeModal}
      />

      <RevokeOwnerAccessModal
        closeModal={revokeAccessModal.closeModal}
        opened={revokeAccessModal.opened}
        ownerId={ownerId}
        teamId={teamId}
        ownerName={formattedName}
        refetch={refetch}
      />

      <DeleteOwnerUserModal
        closeModal={deleteModal.closeModal}
        opened={deleteModal.opened}
        ownerId={ownerId}
        teamId={teamId}
        ownerName={formattedName}
        refetch={refetch}
      />
      <CopyOwnerInvitationModal
        closeModal={copyInviteModal.closeModal}
        opened={copyInviteModal.opened}
        ownerId={ownerId}
        teamId={teamId}
        ownerName={formattedName}
        refetch={refetch}
      />
    </>
  );
};

const CopyOwnerInvitationModal = ({
  ownerId,
  teamId,
  closeModal,
  opened,
  ownerName,
}: ModalProps) => {
  const { copy } = useClipboard();
  const { mutate: mutateInvitationUrl } = useTrpcMutation('copyInvitationUrl', {
    successMessage: {
      title: 'Copied to clipboard!',
      message: 'Successfully received invitation url.',
    },
  });

  if (!ownerId || !teamId) return null;

  const receiveInvitationUrl = async () => {
    try {
      const res = await mutateInvitationUrl({
        teamId,
        userId: ownerId,
      });

      if (res.signedInvitationUrl) {
        copy(res.signedInvitationUrl);
      }
      return {
        isSuccess: !!res.signedInvitationUrl,
      };
    } catch (error: any) {
      return {
        isSuccess: false,
        errorMessage: error?.message || 'Failed to copy invite link.',
      };
    }
  };

  return (
    <BaseModal
      opened={opened}
      closeModal={closeModal}
      onSubmit={receiveInvitationUrl}
      title={'Copy invitation link'}
      submitButtonTitle="Submit"
      icon={faPaperPlane}
    >
      <Text component="p" fw={500}>
        Would you like to copy the invite link for {ownerName || 'this owner'}?
      </Text>
      <Text component="p">
        Invitations are valid once and expire after 7 days.
      </Text>
    </BaseModal>
  );
};

const RevokeOwnerAccessModal = ({
  ownerId,
  teamId,
  closeModal,
  opened,
  ownerName,
  refetch,
}: ModalProps) => {
  const { mutate } = useTrpcMutation('revokeOwnerUserAccess', {
    successMessage: {
      message: 'Owner portal access was revoked successfully.',
      title: 'Success!',
    },
  });

  const revokeOwnerAccess: SubmitConfirmModalType = async () => {
    try {
      const result = await mutate({ ownerId, tenantId: teamId });
      refetch();

      return {
        isSuccess: !!result?.ok,
      };
    } catch (error: any) {
      return {
        isSuccess: false,
        errorMessage:
          error?.message ||
          "We couldn't archive this owner. Please try again later.",
      };
    }
  };

  return (
    <BaseModal
      opened={opened}
      closeModal={closeModal}
      onSubmit={revokeOwnerAccess}
      title="Revoke access"
      submitButtonTitle="Revoke access"
      icon={faUserMinus}
    >
      <Text component="p" fw={500}>
        Are you sure you want to revoke access for {ownerName || 'this owner'}?
      </Text>
      <Text component="p">
        The owner will continue to show in your dashboard, but they will no
        longer have access the owner portal.
      </Text>
    </BaseModal>
  );
};

const DeleteOwnerUserModal = ({
  ownerId,
  teamId,
  closeModal,
  opened,
  ownerName,
  refetch,
}: ModalProps) => {
  const setOwner = useQueryParamSet('owner', StringParam);
  const setOwnerUser = useQueryParamSet('owner-user', StringParam);

  const { mutate } = useTrpcMutation('deleteOwnerUser', {
    successMessage: {
      title: 'Success!',
      message: (data) => {
        if (data.type === 'access-removed') {
          return `${toTitleCase(
            type
          )} was deleted from this team. But remains active in other teams.`;
        }

        return `${toTitleCase(type)} was deleted successfully.`;
      },
    },
  });

  const type = 'user';

  const deleteOwner: SubmitConfirmModalType = async () => {
    try {
      const result = await mutate({
        ownerId,
        tenantId: teamId,
      });
      refetch();
      setOwner(undefined);
      setOwnerUser(undefined);

      return {
        isSuccess: !!result?.type,
      };
    } catch (error: any) {
      return {
        isSuccess: false,
        errorMessage:
          error?.message ||
          `We couldn't delete this ${type}. Please try again later.`,
      };
    }
  };

  return (
    <BaseModal
      opened={opened}
      closeModal={closeModal}
      onSubmit={deleteOwner}
      title={`Delete ${type}`}
      submitButtonTitle="Delete"
      icon={faArchive}
    >
      <Text component="p" fw={500}>
        Are you sure you want to delete {ownerName || `this ${type}`}?
      </Text>
      <Text component="p">
        The {type} will be entirely removed from your team. If the {type} is
        still active in other teams, they will keep their account and only lose
        access to your team. Otherwise, their account will be deleted.
      </Text>
    </BaseModal>
  );
};

type BaseProps = {
  opened: boolean;
  closeModal: () => void;
};

interface BaseModalProps extends BaseProps {
  icon: IconDefinition;
  onSubmit: SubmitConfirmModalType;
  title: string;
  submitButtonTitle: string;
}

const BaseModal = ({
  closeModal,
  icon,
  onSubmit,
  opened,
  children,
  submitButtonTitle,
  title,
}: PropsWithChildren<BaseModalProps>) => {
  const { gray } = useLegacyColors();

  return (
    <ConfirmModal
      opened={opened}
      size="md"
      onClose={closeModal}
      onSubmit={onSubmit}
      title={title}
      withCloseButton
      submitButtonTitle={submitButtonTitle}
      autoCloseOnSuccess
      icon={<FontAwesomeIcon icon={icon} size="3x" color={gray.dark} />}
      subtitle={children}
    />
  );
};

type InviteOwnerModalProps = {
  name: string;
  email: string;
  isReinvite: boolean;
  refetchTable: () => void;
  opened: boolean;
  closeModal: () => void;
};

const InviteUserModal = ({
  email,
  name,
  isReinvite,
  refetchTable,
  opened,
  closeModal,
}: InviteOwnerModalProps) => {
  const { black } = useLegacyColors();

  const [{ id: teamId, partnerId }] = useTeam();
  const { appName } = useAppName();

  const { mutate } = useTrpcMutation('inviteUser', {
    successMessage: {
      title: 'Owner invited to platform.',
      message:
        'An email has been sent to the owner inviting them to the platform.',
    },
    invalidateQueryKeys: ['owners'],
  });

  const invite = async (): Promise<ConfirmResultType> => {
    const result = await mutate({
      role: 'owner',
      email: email,
      teamId,
      partnerId,
    });

    const isSuccess = !!result.ok;

    if (isSuccess) {
      refetchTable();
    }

    return {
      isSuccess,
      message: isSuccess
        ? 'Owner was successfully invited to the platform.'
        : result?.message || 'Failed to invite owner. Please try again.',
    };
  };

  const iconStyling: Sx = {
    border: '6px solid #fff',
  };

  const dropShadow =
    'drop-shadow(0px 74px 45px rgba(0, 0, 0, 0.02)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.05)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))';

  return (
    <ConfirmModal
      title={isReinvite ? 'Resend Invitation' : 'Invite to app'}
      subtitle={
        <>
          You're about to invite {name}, they will get an E-Mail invite to join{' '}
          {appName}.<br />
          <br />
          Invitations are valid once and expire after 7 days.
        </>
      }
      opened={opened}
      onClose={closeModal}
      onSubmit={invite}
      icon={
        <Avatar.Group
          spacing={30}
          sx={{
            borderRadius: 100,
            filter: dropShadow,
            msFilter: dropShadow,
            MozFilter: dropShadow,
            WebkitFilter: dropShadow,
          }}
        >
          <Avatar
            size={100}
            radius={100}
            sx={
              {
                ...iconStyling,
                '.mantine-Avatar-placeholder': {
                  backgroundColor: black.base,
                },
              } as any
            }
          >
            <Logo width={100} color="#fff" />
          </Avatar>
          <Avatar
            size={100}
            radius={100}
            sx={{
              ...iconStyling,
            }}
          >
            <FontAwesomeIcon icon={faUser} />
          </Avatar>
        </Avatar.Group>
      }
    />
  );
};
