import { day } from '@finalytic/utils';
import type {
  bank_records_csv_import_schema,
  deposits_csv_import_schema,
  expenses_csv_import_schema,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';
import type { z } from 'zod';
import { useBankAccountId } from '../../views/reconciliation/bank-accounts/view/useBankAccountId';
import { BaseCsvImportModal } from './BaseCsvImportModal';

type Props = {
  uniqueRef: 'expense' | 'payout' | 'bankRecords' | 'contacts';
  opened: boolean;
  closeModal: () => void;
  connectionId: string | undefined;
};

export const CsvImportModal = (props: Props) => {
  if (props.uniqueRef === 'payout') {
    return <Deposits {...props} />;
  }

  if (props.uniqueRef === 'expense') {
    return <Expenses {...props} />;
  }

  if (props.uniqueRef === 'contacts') {
    return <Contacts {...props} />;
  }

  if (props.uniqueRef === 'bankRecords') {
    return <BankRecords {...props} />;
  }

  return null;
};

type Expense = z.infer<typeof expenses_csv_import_schema>;

const DEFAULT_EXPENSES: Expense[] = [
  {
    'Issue Date': day().format('YYYY-01-01'),
    'Payment Date': day().format('YYYY-01-01'),
    Description: 'Test',
    Status: 'Paid',
    'Bank/CC account': '',
    Contact: 'John Doe',
    'Bill ID': '123',
    Currency: 'USD',
    Listing: 'LIST-456',
    Reservation: 'RES-123',
    'Line Description': '',
    'Expense account': 'Trust',
    'Bill to Owner': true,
    'Applied amount': 1000,
    Markup: '0',
    'Markup Tax': '',
  },
];

const Expenses = ({ opened, closeModal, connectionId, uniqueRef }: Props) => {
  return (
    <BaseCsvImportModal
      closeModal={closeModal}
      opened={opened}
      invalidateQueryKeys={['expenses', 'accounts']}
      successMessage={{
        title: 'Expenses imported',
        message: 'Your expenses have been imported successfully',
      }}
      modal={{
        title: 'Import Expenses',
        uniqueRef,
      }}
      defaultRows={DEFAULT_EXPENSES}
      connectionId={connectionId}
    />
  );
};

type Deposit = z.infer<typeof deposits_csv_import_schema>;

const Deposits = ({ opened, closeModal, connectionId, uniqueRef }: Props) => {
  const defaultRows = useMemo<Deposit[]>(() => {
    return [
      {
        Date: day().yyyymmdd(),
        Reference: '123',
        'Bank/CC Account': 'Trust Account',
        Description: 'Manual deposit',
        Currency: 'USD',
        Reservation: '3156698',
        Contact: null,
        Listing: null,
        Account: null,
        'Line Description': 'Reservation payment',
        'Applied Amount': 1000,
        'Merchant Fee': 10,
        'Channel Fee': 20,
        Reserve: 30,
      },
      {
        Date: day().yyyymmdd(),
        Reference: '123',
        'Bank/CC Account': 'Trust Account',
        Description: 'Manual deposit',
        Currency: 'USD',
        Reservation: null,
        Contact: 'John Doe',
        Listing: null,
        Account: null,
        'Line Description': 'Non-reservation line',
        'Applied Amount': 500,
        'Merchant Fee': 20,
        'Channel Fee': 10,
        Reserve: 40,
      },
    ];
  }, []);

  return (
    <BaseCsvImportModal
      connectionId={connectionId}
      closeModal={closeModal}
      opened={opened}
      invalidateQueryKeys={['payments', 'deposits']}
      successMessage={{
        title: 'Deposits imported',
        message: 'Your deposits have been imported successfully',
      }}
      modal={{
        title: 'Import Deposits',
        uniqueRef,
      }}
      defaultRows={defaultRows}
    />
  );
};

type Contact = {
  Name: string;
  'First Name': string;
  'Company Type': string;
  Type: string;
  Email: string;
  Phone: string;
  'Tax ID': string;
  Address: string;
  Unit: string;
  City: string;
  Zip: string;
  Country: string;
  State: string;
};

const DEFAULT_CONTACTS: Contact[] = [
  {
    Name: 'Doe',
    'First Name': 'John',
    'Company Type': '',
    Type: 'vendor',
    Email: '',
    Phone: '',
    'Tax ID': '',
    Address: '',
    Unit: '',
    City: '',
    Zip: '',
    Country: 'US',
    State: 'AK',
  },
  {
    Name: 'Apple Inc.',
    'First Name': '',
    'Company Type': 'c_corporation',
    Type: 'vendor',
    Email: '',
    Phone: '',
    'Tax ID': '',
    Address: '',
    Unit: '',
    City: '',
    Zip: '',
    Country: 'US',
    State: 'AK',
  },
  {
    Name: 'Google',
    'First Name': '',
    'Company Type': 's_corporation',
    Type: 'vendor',
    Email: '',
    Phone: '',
    'Tax ID': '',
    Address: '',
    Unit: '',
    City: '',
    Zip: '',
    Country: 'US',
    State: 'AK',
  },
  {
    Name: 'Meta Platforms',
    'First Name': '',
    'Company Type': 'partnership',
    Type: 'vendor',
    Email: '',
    Phone: '',
    'Tax ID': '',
    Address: '',
    Unit: '',
    City: '',
    Zip: '',
    Country: 'US',
    State: 'AK',
  },
  {
    Name: 'Tesla Inc.',
    'First Name': '',
    'Company Type': 'trust_estate',
    Type: 'vendor',
    Email: '',
    Phone: '',
    'Tax ID': '',
    Address: '',
    Unit: '',
    City: '',
    Zip: '',
    Country: 'US',
    State: 'AK',
  },
  {
    Name: 'LinkedIn',
    'First Name': '',
    'Company Type': 'limited_liability_company',
    Type: 'vendor',
    Email: '',
    Phone: '',
    'Tax ID': '',
    Address: '',
    Unit: '',
    City: '',
    Zip: '',
    Country: 'US',
    State: 'AK',
  },
];

const Contacts = ({ opened, closeModal, connectionId, uniqueRef }: Props) => {
  return (
    <BaseCsvImportModal
      closeModal={closeModal}
      opened={opened}
      modal={{
        title: 'Import Contacts',
        uniqueRef,
      }}
      successMessage={{
        title: 'Contacts imported',
        message: 'Your contacts have been imported successfully.',
      }}
      defaultRows={DEFAULT_CONTACTS}
      invalidateQueryKeys={['contacts']}
      connectionId={connectionId}
    />
  );
};

type BankRecord = z.infer<typeof bank_records_csv_import_schema>;

const BankRecords = ({
  opened,
  closeModal,
  connectionId,
  uniqueRef,
}: Props) => {
  const bankAccountId = useBankAccountId();

  const defaultRows = useMemo<BankRecord[]>(() => {
    return [
      {
        Date: day().format('YYYY-01-01'),
        Reference: '',
        Description: '',
        Amount: 1000,
        Balance: 0,
      },
    ];
  }, []);

  return (
    <BaseCsvImportModal
      connectionId={connectionId}
      closeModal={closeModal}
      opened={opened}
      modal={{
        uniqueRef,
        title: 'Import Bank Records',
      }}
      successMessage={{
        title: 'Bank records imported',
        message: 'Your bank records have been imported successfully.',
      }}
      invalidateQueryKeys={['bankRecords', 'accounts']}
      bankAccountId={bankAccountId}
      defaultRows={defaultRows}
    />
  );
};
