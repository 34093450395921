import { useSignIn } from '@clerk/clerk-react';
import { Button } from '@finalytic/components';
import { captureSentryError } from '@finalytic/data';
import { LoadingIndicator, StringParam, useQueryParam } from '@finalytic/ui';
import { Center, Text } from '@mantine/core';
import { Loader } from '@mantine/core';
// Handle magic link verification results
import {
  type ReactNode,
  startTransition,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { EmptyViewWrapper } from '../../components';

type VerifyTokenViewProps = {
  isAuthenticated?: boolean;
};

export const VerifyTokenView = ({ isAuthenticated }: VerifyTokenViewProps) => {
  if (isAuthenticated) return <Authenticated />;

  return <Verify />;
};

const AuthContainer = ({
  bgColor,
  children,
  loading,
}: {
  bgColor: string;
  children?: ReactNode;
  loading: boolean;
}) => {
  return (
    <Center
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundColor: bgColor,
      }}
    >
      {loading ? <LoadingIndicator isFullPageLoading={false} /> : children}
    </Center>
  );
};

const Authenticated = () => {
  return <Navigate to="/" />;
};

const Verify = () => {
  const goto = useNavigate();

  const isVerifying = useRef(false);
  const [verification, setVerification] = useState<{
    status: 'loading' | 'verified' | 'failed';
    message?: string;
  }>({ status: 'loading', message: 'Test' });

  const [token] = useQueryParam('token', StringParam);
  const [searchParams] = useSearchParams();

  const { signIn, setActive } = useSignIn();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    async function verify() {
      try {
        await signIn
          ?.create({
            strategy: 'ticket',
            ticket: token || '',
          })
          .then(async (result) => {
            if (result.status === 'complete') {
              await setActive({
                session: result.createdSessionId,
              });
              setVerification({ status: 'verified' });
            } else {
              console.log(result);
            }
          });
      } catch (err: any) {
        console.error(err);
        // Verification has failed.
        const message =
          err?.message ||
          'We failed to process your request. Please contact support if the issue persists.';

        captureSentryError(err);
        setVerification({ status: 'failed', message });
      }
    }
    if (token && !isVerifying.current) {
      isVerifying.current = true;
      verify();
    }
  }, [token]);

  if (!token)
    return (
      <EmptyViewWrapper
        title={'Verification failed'}
        description={'Please contact support if the issue persists.'}
        actions={
          <Button
            variant="primary"
            onClick={() => startTransition(() => goto('/'))}
          >
            Return to login
          </Button>
        }
      />
    );

  if (verification.status === 'loading') {
    return (
      <EmptyViewWrapper>
        <Loader type="dots" size="md" />
        <Text c="gray" ta="center" mt="lg" size="lg">
          Verifying...
        </Text>
      </EmptyViewWrapper>
    );
  }

  if (verification.status === 'failed') {
    return (
      <EmptyViewWrapper
        title={'Verification failed'}
        description={
          verification.message ||
          'Please contact support if the issue persists.'
        }
        actions={
          <Button
            variant="primary"
            onClick={() => startTransition(() => goto('/'))}
          >
            Return to login
          </Button>
        }
      />
    );
  }

  if (verification.status === 'verified') {
    const path = searchParams.get('path');
    const search = searchParams.toString();
    const to = path && path !== '/' ? `${path || ''}?${search}` : `/?${search}`;

    return <Navigate to={to} />;
  }

  return (
    <AuthContainer bgColor="#fff" loading={false}>
      <Text>Something went wrong</Text>
    </AuthContainer>
  );
};
