import { useQuery, useTeam, useTeamId } from '@finalytic/data';
import { useLocalStorage } from '@mantine/hooks';
import { whereConnectionStatusDefault } from '@vrplatform/ui-common';
import { useEffect } from 'react';

function useAccountingConnectionQuery() {
  const [teamId] = useTeamId();

  return useQuery(
    (q, args) => {
      return q
        .connection({
          where: {
            status: whereConnectionStatusDefault,
            tenantId: { _eq: args.teamId },
            app: { category: { _eq: 'accountingPlatform' } },
          },
          order_by: [{ appId: 'asc_nulls_first' }],
        })
        .map((connection) => ({
          appId: connection.appId as string,
          name: connection.name,
          type: connection.app.type,
          category: connection.app.category,
          id: connection.id as string,
          icon: connection.app.iconRound,
        }));
    },
    {
      skip: !teamId,
      queryKey: ['connections'],
      keepPreviousData: true,
      variables: {
        teamId,
      },
    }
  );
}

export function useAccountingConnection() {
  const [{ id: teamId }] = useTeam();
  const { data: accountingPlatforms = [], ...queryData } =
    useAccountingConnectionQuery();

  const [accounting, setAccounting] = useLocalStorage<string | undefined>({
    key: `${teamId}_acc`,
    defaultValue: accountingPlatforms[0]?.id,
  });

  useEffect(() => {
    if (!accountingPlatforms.find((a) => a.id === accounting)) {
      setAccounting(accountingPlatforms[0]?.id);
    }
  }, [accountingPlatforms, accounting, setAccounting]);

  return {
    accounting: accountingPlatforms.find((a) => a.id === accounting),
    setAccounting,
    accountingPlatforms,
    loading: queryData.isLoading,
  };
}
