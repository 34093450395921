import type { journalEntry_bool_exp } from '@finalytic/graphql';
import { createStore, useStore } from 'zustand';
import type { useGeneralLedgerDetailFilter } from '../../views/general-ledger/GeneralLedgerDetailFilter';

type QueryFilter = ReturnType<typeof useGeneralLedgerDetailFilter>['filter'];

type StateFilter = {
  [key in keyof QueryFilter]: any;
};

type State = {
  where: journalEntry_bool_exp | null;
  filter: StateFilter;
};

type Actions = {
  open: (where: journalEntry_bool_exp) => void;
  close: () => void;
  setFilter: (filter: Partial<StateFilter>) => void;
};

const initialState: State = {
  where: null,
  filter: {
    search: undefined,
    listingId: undefined,
    party: undefined,
    type: undefined,
    accountClassification: undefined,
    accountId: undefined,
    date: undefined,
    status: undefined,
    subType: undefined,
  },
};

export const journalEntriesModalStore = createStore<State & Actions>(
  (set, get) => ({
    close: () =>
      set({ where: initialState.where, filter: initialState.filter }),
    open: (where) => set({ where }),
    setFilter: (filter) => set({ filter: { ...get().filter, ...filter } }),
    ...initialState,
  })
);

export const useJournalEntriesModal = () => {
  const open = useStore(journalEntriesModalStore, (state) => state.open);
  const close = useStore(journalEntriesModalStore, (state) => state.close);

  return {
    open,
    close,
  };
};

export const useJournalEntriesModalFilter = () => {
  const filter = useStore(journalEntriesModalStore, (state) => state.filter);
  const setFilter = useStore(
    journalEntriesModalStore,
    (state) => state.setFilter
  );

  return {
    filter,
    setFilter,
  };
};

export const useJournalEntriesModalWhere = () => {
  const where = useStore(journalEntriesModalStore, (state) => state.where);

  return where;
};
