import { type Query, useQuery, useTeamId } from '@finalytic/data';
import type { automation_bool_exp } from '@finalytic/graphql';
import type {
  AutomationMappings,
  AutomationRunSettings,
  AutomationViewSettings,
  InputFormSchema,
} from '@vrplatform/ui-common';

export const getAutomations = (
  q: Query,
  args: { teamId: string },
  opts?: {
    limit?: number;
    where?: automation_bool_exp;
  }
) => {
  return q
    .automations({
      order_by: [{ title: 'asc_nulls_last' }],
      where: {
        tenantId: { _eq: args.teamId },
        ...opts?.where,
      },
      limit: opts?.limit,
    })
    .map((automation) => ({
      automationId: automation.id,
      status: automation.status,
      scheduleStatus: automation.scheduleStatus,
      createdAt: automation.createdAt,
      templateId: automation.ttemplate?.id!,
      viewSettings: (automation.viewSettings() || {}) as AutomationViewSettings,
      runSettings: (automation.runSettings() || {}) as AutomationRunSettings,
      template: {
        uniqueRef: automation.ttemplate?.uniqueRef,
        type: automation.ttemplate?.type,
        title: automation.ttemplate?.title,
        input: automation.ttemplate?.input,
        params: automation.ttemplate?.params(),
        visibility: automation.ttemplate?.visibility,
      },
      title: automation?.title || automation.ttemplate?.title,
      mappings: (automation.ttemplate?.mappings() || {}) as AutomationMappings,
      settings: (automation.ttemplate?.settings() || {}) as InputFormSchema,
      leftConnectionId: automation.leftConnectionId!,
      leftConnection: {
        id: automation.leftConnectionId!,
        name: automation.leftConnection?.name,
        icon: automation.leftConnection?.app?.iconRound,
        appId: automation.leftConnection?.appId,
      },
      rightConnectionId: automation.rightConnectionId!,
      rightConnection: {
        id: automation.rightConnectionId!,
        name: automation.rightConnection?.name,
        icon: automation.rightConnection?.app?.iconRound,
        appId: automation.rightConnection?.appId,
      },
      connections: {
        [automation.leftConnection?.appId || '']: automation?.leftConnectionId!,
        [automation.rightConnection?.appId || '']:
          automation?.rightConnectionId!,
      },
    }));
};

export function useAutomationsQuery(
  opts?: Parameters<typeof getAutomations>[2]
) {
  const [teamId] = useTeamId();

  return useQuery((q, { opts, ...args }) => getAutomations(q, args, opts), {
    keepPreviousData: true,
    variables: {
      teamId,
      opts,
    },
    queryKey: ['automations'],
  });
}
