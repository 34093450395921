import type { listing_bool_exp } from '@finalytic/graphql';
import { day, ensure, hasValue } from '@finalytic/utils';
import { z } from 'zod';
import type { Dashboard } from '../types';
import { whereAddress } from './whereAddress';
import { whereListingIsActive } from './whereListingIsActive';
import { whereListingIsExcluded } from './whereListingIsExcluded';
import { whereListingPeriod } from './whereListingPeriod';

export const listingsFilterInput = {
  search: z.string().nullish(),
  ownerIds: z.array(z.string().nullish()).nullish(),
  status: z.enum(['inactive', 'active']).nullish(),
  collectionId: z.string().nullish(),
  pmsConnectionId: z.string().nullish(),
  disabledByAutomationId: z.union([z.string(), z.array(z.string())]).nullish(),
};

const listingsFilter = z.object(listingsFilterInput);

export type ListingsFilterParams = z.infer<typeof listingsFilter>;

type BaseWhere = {
  dashboard: Dashboard;
  tenantId: string;
  meId: string;
  GL: boolean;
};

export function whereListings({
  search: s,
  dashboard,
  tenantId,
  disabledByAutomationId,
  status,
  ownerIds: oids,
  meId,
  collectionId,
  pmsConnectionId,
  GL,
}: BaseWhere & ListingsFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<listing_bool_exp[]>([
        {
          address: { _ilike: `%${search}%` },
        },
        {
          uniqueRef: { _ilike: `%${search}%` },
        },
        {
          calculated_title: { _ilike: `%${search}%` },
        },
        {
          ownerships: {
            newOwner: {
              _or: [
                { firstName: { _ilike: `%${search}%` } },
                { name: { _ilike: `%${search}%` } },
              ],
            },
          },
        },
        {
          connections: {
            name: { _ilike: `%${search}%` },
          },
        },
        {
          addressRelation: whereAddress({ search }),
        },
      ])
    : undefined;

  const statusFilter: listing_bool_exp =
    status === 'inactive'
      ? {
          _or: [
            {
              // TODO: VRP-4868 remove enabled/disabled
              status: { _in: ['inactive', 'disabled'] },
            },
            {
              // TODO: VRP-4868 remove enabled/disabled
              pmsStatus: { _in: ['disabled', 'inactive'] },
              status: { _is_null: true },
            },
          ],
        }
      : {
          _or: [whereListingIsActive()],
        };

  const ownerIds = oids?.filter(hasValue);

  return ensure<listing_bool_exp>({
    tenantId: { _eq: tenantId },
    collectionId: collectionId
      ? collectionId === 'null'
        ? {
            _is_null: true,
          }
        : { _eq: collectionId }
      : undefined,
    ownerships: GL
      ? undefined
      : ownerIds?.length
        ? {
            newOwnerId: { _in: ownerIds },
          }
        : dashboard === 'owner'
          ? {
              newOwner: {
                userAccesses: {
                  userId: { _eq: meId },
                },
              },
            }
          : undefined,
    ownershipPeriods:
      GL && ownerIds?.length
        ? whereListingPeriod({
            date: day().yyyymmdd(),
            ownerIds: ownerIds,
          })
        : undefined,
    connectionId: pmsConnectionId ? { _eq: pmsConnectionId } : undefined,
    _and: disabledByAutomationId?.length
      ? [whereListingIsExcluded(disabledByAutomationId)]
      : [statusFilter],
    _or: searchQuery,
  });
}
