import { useQuery } from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Edit3Icon, Icon, RouteIcon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  useQueryParam,
  useQueryParamsSet,
} from '@finalytic/ui';
import { Box, Tooltip } from '@mantine/core';
import { deleteCheckListing } from '@vrplatform/ui-common';
import { type ReactNode, useMemo } from 'react';
import { EllipsisMenuSourceDownloadItem } from '../../../components';

export const ListingEllipsisMenuItems = ({
  listing: { isPmsListing, listingConnections, ownerStatements, listingId },
  handlers: { openDeleteModal, openMigrationModal },
  hideEdit = false,
}: {
  listing: {
    listingId: string;
    isPmsListing: boolean;
    ownerStatements: number;
    listingConnections: number;
  };
  handlers: {
    openDeleteModal: () => void;
    openMigrationModal: () => void;
  };
  hideEdit?: boolean;
}) => {
  const [, setListingId] = useQueryParam('listing_activity', StringParam);

  const setView = useQueryParamsSet({
    view: StringParam,
    listing: StringParam,
  });

  const { data, isLoading: loading } = useQuery(
    (q, { listingId }) => deleteCheckListing(q, { listingId }),
    {
      queryKey: 'listing',
      variables: {
        listingId,
      },
    }
  );

  const canBeDeleted = !!data?.canDelete;

  const deleteTooltipLabel = useMemo(() => {
    if (isPmsListing) {
      return 'This listing is synced with a PMS and cannot be deleted.';
    } else {
      if (ownerStatements && listingConnections)
        return 'This listing has owner statements and listing connections. Remove them first to delete this listing.';

      if (ownerStatements)
        return 'Remove the owner statements from this listing to delete it.';

      if (listingConnections)
        return 'Remove the listing connections from this listing to delete it.';

      return null;
    }
  }, [listingConnections, ownerStatements, isPmsListing]);

  const disableRemoving = !!listingConnections || isPmsListing || !canBeDeleted;

  return (
    <>
      {!hideEdit && (
        <EllipsisMenuItem
          onClick={() => setView({ listing: listingId, view: 'edit' })}
          customIcon={<Edit3Icon size={16} />}
        >
          Edit listing
        </EllipsisMenuItem>
      )}

      <EllipsisMenuItem
        customIcon={<Icon icon="HistoryIcon" size={16} />}
        onClick={() => setListingId(listingId, 'push')}
      >
        View activity
      </EllipsisMenuItem>

      <EllipsisMenuItem
        onClick={openMigrationModal}
        customIcon={<RouteIcon size={16} />}
      >
        Migrate Listing
      </EllipsisMenuItem>

      <EllipsisMenuDivider />

      <DisabledTooltip label={deleteTooltipLabel} disabled={!disableRemoving}>
        <Box>
          <EllipsisMenuDangerItem
            onClick={openDeleteModal}
            loading={loading}
            disabled={disableRemoving}
          >
            Delete listing
          </EllipsisMenuDangerItem>
        </Box>
      </DisabledTooltip>

      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuCopyItem value={listingId} />

        <EllipsisMenuSourceDownloadItem
          fileName={listingId}
          where={{ listing: { id: { _eq: listingId } } }}
        />
      </HiddenFeatureIndicator>
    </>
  );
};

const DisabledTooltip = ({
  disabled,
  label,
  children,
}: {
  disabled: boolean;
  label: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Tooltip label={label} withArrow withinPortal disabled={disabled}>
      <Box
        sx={{
          cursor: disabled ? 'not-allowed' : undefined,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
