import {
  DEMO_TEST_PARTNER_TENANT_ID,
  VRP_TENANT_ID,
  XIMPLIFI_TENANT_ID,
} from '@vrplatform/ui-common';
import { useContext, useMemo } from 'react';
import { teamContext } from './team';

export function useBilling() {
  const { team } = useContext(teamContext);

  const isVrpCustomer =
    [VRP_TENANT_ID, DEMO_TEST_PARTNER_TENANT_ID].includes(
      team.billingPartnerId || ''
    ) && team.id !== VRP_TENANT_ID;

  const isAccountingPartner =
    team.type === 'partner' &&
    team.id !== VRP_TENANT_ID &&
    team.id !== XIMPLIFI_TENANT_ID;

  const isXimplifiCustomer = team.billingPartnerId === XIMPLIFI_TENANT_ID;

  return useMemo(() => {
    // show billing for
    // 1. child teams of VRP partner team
    // 2. All partner teams that or not VRP
    const showBilling =
      (isVrpCustomer || isAccountingPartner || isXimplifiCustomer) &&
      !!team?.billingSubscriptionStatus;

    return {
      showBilling,
      billingStatus: team.billingSubscriptionStatus,
      subscriptionCancelledAt: team.subscriptionCancelledAt,
      isXimplifiCustomer,
    };
  }, [
    isVrpCustomer,
    isAccountingPartner,
    team.billingSubscriptionStatus,
    team.subscriptionCancelledAt,
    isXimplifiCustomer,
  ]);
}
