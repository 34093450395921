import { Filter } from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import type { account_bool_exp } from '@finalytic/graphql';
import { type Maybe, toTitleCase } from '@finalytic/utils';
import { orderByAccount, whereAccounts } from '@vrplatform/ui-common';

type Props = {
  value: Maybe<string>;
  setValue: (value: Maybe<string>) => void;
  view: 'all' | 'pm-statement';
};

export function useJournalEntryAccountFilterQuery({
  view,
}: {
  view: Props['view'];
}) {
  const [teamId] = useTeamId();

  return useQuery(
    (q, { teamId }) => {
      const baseWhere: account_bool_exp = whereAccounts({
        tenantId: teamId,
      });

      const where: account_bool_exp =
        view === 'all'
          ? baseWhere
          : {
              _and: [
                baseWhere,
                {
                  classification: { _nin: ['asset', 'liability'] },
                },
              ],
            };

      return q
        .accounts({
          where,
          order_by: orderByAccount,
        })
        .map((account) => ({
          label: account.title || 'No name',
          value: account.id,
          group: toTitleCase(account.classification || 'No classification'),
          classification: account.classification,
        }));
    },
    {
      skip: !teamId,
      keepPreviousData: true,
      queryKey: ['accounts'],
      variables: {
        teamId,
        view,
      },
    }
  );
}

export const JournalEntryAccountFilter = ({
  value: initial,
  setValue,
  view,
}: Props) => {
  const queryData = useJournalEntryAccountFilterQuery({
    view,
  });

  const value = queryData.data?.find((item) => item.value === initial) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setValue(v?.value)}
      type="single"
      label="Account"
      withinPortal
      data={{
        options: queryData.data || [],
        loading: queryData.isLoading,
        error: queryData.error,
      }}
    />
  );
};
