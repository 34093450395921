type SourceType = {
  name: string;
  namespace:
    | {
        name: string;
      }
    | string;
};

export function getSourceNamespaceAndType(
  sourceType?: string | SourceType | undefined | null
): Readonly<[string, string]> {
  if (!sourceType) return ['', ''];
  if (typeof sourceType === 'string') {
    return sourceType.includes('.')
      ? (sourceType.split('.') as any)
      : ['', sourceType];
  }
  if (sourceType.namespace) {
    if (typeof sourceType.namespace === 'string')
      return [sourceType.namespace, sourceType.name];
    return [sourceType.namespace.name, sourceType.name];
  }
  return ['', ''];
  // name, namespace.name
}
