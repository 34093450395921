import type { Maybe } from '@finalytic/utils';

export type AmountFilterNumberString =
  | `${number}...${number}`
  | `eq${number}`
  | `gt${number}`
  | `lt${number}`
  | (string & {});

export type AmountFilterNumberObject =
  | {
      type: 'eq';
      centTotal: number;
    }
  | {
      type: 'gt';
      centTotal: number;
    }
  | {
      type: 'lt';
      centTotal: number;
    }
  | {
      type: 'between';
      centTotal: undefined;
      ltCentTotal: number;
      gtCentTotal: number;
    };

export const formatAmountFilterToString = (
  amount: AmountFilterNumberObject | null
): AmountFilterNumberString | null => {
  if (!amount) return null;

  if (amount.type === 'eq') {
    return `eq${amount.centTotal}`;
  } else if (amount.type === 'gt') {
    return `gt${amount.centTotal}`;
  } else if (amount.type === 'lt') {
    return `lt${amount.centTotal}`;
  }
  return `${amount.gtCentTotal}...${amount.ltCentTotal}`;
};

export const formatStringToAmountFilter = (
  amount: Maybe<AmountFilterNumberString>
): AmountFilterNumberObject | undefined => {
  if (!amount) return undefined;

  if (amount.startsWith('eq')) {
    return {
      type: 'eq',
      centTotal: parseInt(amount.slice(2)),
    };
  } else if (amount.startsWith('gt')) {
    return {
      type: 'gt',
      centTotal: parseInt(amount.slice(2)),
    };
  } else if (amount.startsWith('lt')) {
    return {
      type: 'lt',
      centTotal: parseInt(amount.slice(2)),
    };
  }

  const [gt, lt] = amount.split('...').map(Number);
  return {
    type: 'between',
    centTotal: undefined,
    ltCentTotal: lt,
    gtCentTotal: gt,
  };
};
