import { useRegisterSW } from 'virtual:pwa-register/react';
import { type ReactNode, createContext, useContext, useMemo } from 'react';

// const isStaging =
//   window.location.hostname === 'staging.portal.vrplatform.app' ||
//   window.location.hostname === 'localhost';

// Add react context to pass down the needRefresh and updateServiceWorker to the app

type PwaContextType = {
  needRefresh: boolean;
  updateServiceWorker: ReturnType<typeof useRegisterSW>['updateServiceWorker'];
};

const PwaContext = createContext<PwaContextType>({
  needRefresh: false,
  updateServiceWorker: async () => {},
});

export const PwaProvider = ({ children }: { children: ReactNode }) => {
  const period = 1000 * 20; // 20 seconds

  const {
    // offlineReady: [_offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (period <= 0) return;
      if (r?.active?.state === 'activated') {
        registerPeriodicSync(period, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener('statechange', (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === 'activated') registerPeriodicSync(period, swUrl, r);
        });
      }
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  const value = useMemo(
    () => ({ needRefresh, updateServiceWorker }),
    [needRefresh, updateServiceWorker]
  );

  return <PwaContext.Provider value={value}>{children}</PwaContext.Provider>;
};

export const usePwaContext = () => useContext(PwaContext);

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 */
function registerPeriodicSync(
  period: number,
  swUrl: string,
  r: ServiceWorkerRegistration
) {
  if (period <= 0) return;

  setInterval(async () => {
    if ('onLine' in navigator && !navigator.onLine) return;

    const resp = await fetch(
      swUrl === '/sw.js' ? `${window.location.origin}${swUrl}` : swUrl,
      {
        cache: 'no-store',
        headers: {
          cache: 'no-store',
          'cache-control': 'no-cache',
        },
      }
    );

    if (resp?.status === 200) await r.update();
  }, period);
}
