import { showApiErrorNotification, useApiClient } from '@finalytic/data';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  HashtagIcon,
  HomeIcon,
  OfficeIcon,
  UserIcon,
} from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import { Drawer } from '@finalytic/ui';
import { type Maybe, day, formatCurrency, sum } from '@finalytic/utils';
import {
  Anchor,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Title,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useListingDetailDrawer } from '../../views/listings/drawer';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useOwnerDetailDrawer } from '../owner-drawers';
import { TransferBankAccountSelect } from './TransferBankAccountSelect';
import { TransferEllipsisMenuItems } from './TransferEllipsisMenuItems';
import { TransferEllipsisMenuModals } from './TransferEllipsisMenuModals';
import { useTransferDetailDrawer } from './useTransferDetailDrawer';

function useTransferQuery(id: Maybe<string>) {
  const $api = useApiClient();

  const query = useTanstackQuery({
    queryFn: async () => {
      if (!id) return null;

      const transfer = await $api.GET('/transactions/{id}', {
        params: {
          path: {
            id,
          },
        },
      });

      if (transfer.error) {
        showApiErrorNotification({
          error: transfer.error,
          title: 'Failed to fetch transfer',
          defaultMessage:
            'We failed to fetch the transfer. Please try again and contact support if the issue persists.',
        });

        throw new Error(transfer.error.message);
      }

      return transfer.data;
    },
    queryKey: ['transfers', id],
    enabled: !!id,
  });

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Transfer = NonNullable<ReturnType<typeof useTransferQuery>['data']>;

export const TransferDetailDrawer = () => {
  const { opened, close, transferId } = useTransferDetailDrawer();
  const { isLoading, data: transfer } = useTransferQuery(transferId);

  const [modal, setModal] = useState<'delete' | null>(null);

  return (
    <>
      <TransferEllipsisMenuModals
        transfer={transfer}
        deleteModal={{
          opened: modal === 'delete' && !!transfer,
          closeModal: () => setModal(null),
        }}
      />
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          loading={isLoading}
          type="Transfer"
          title={
            transfer && (
              <Group wrap="nowrap" gap="xs" mt={rem(5)}>
                <Box>
                  <Title order={4} fw={500} component="p" m={0}>
                    {transfer.description}
                  </Title>
                </Box>
              </Group>
            )
          }
          menuItems={
            transfer && (
              <TransferEllipsisMenuItems
                openDeleteModal={() => setModal('delete')}
                transfer={transfer}
              />
            )
          }
        />
        {!transfer && !isLoading ? (
          <Center>
            <Text c="gray" size="xs" ta="center">
              No details found
            </Text>
          </Center>
        ) : (
          <Content transfer={transfer} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const Content = ({
  transfer,
  isLoading,
}: { transfer: Maybe<Transfer>; isLoading: boolean }) => {
  const { open: openOwner } = useOwnerDetailDrawer();
  const { open: openListing } = useListingDetailDrawer();

  if (!transfer) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DrawerInfoCard
        rows={[
          {
            icon: CircleDollarIcon,
            title: 'Amount',
            text: formatCurrency(transfer.amount / 100, transfer.currency),
          },
          {
            icon: CalendarEventIcon,
            title: 'Date',
            text: day(transfer.date).format('MMM D, YYYY'),
          },
          {
            icon: OfficeIcon,
            title: 'Bank account',
            text: transfer.payment.bankRecordIds.length ? (
              transfer.account?.bankAccount?.name
            ) : (
              <TransferBankAccountSelect
                transferId={transfer.id}
                bankAccount={
                  transfer.account
                    ? {
                        accountId: transfer.account.id,
                        title: transfer.account.bankAccount?.name ?? '-',
                      }
                    : null
                }
              />
            ),
          },
          {
            icon: CalendarEventIcon,
            title: 'Paid At',
            text: transfer.payment.date
              ? day(transfer.payment.date).format('MMM D, YYYY')
              : null,
          },
          {
            icon: UserIcon,
            title: 'Contact',
            text: transfer.contact ? (
              <Anchor
                component="button"
                onClick={() =>
                  openOwner(transfer.contact!.id, 'overview', 'push')
                }
              >
                {transfer.contact.name}
              </Anchor>
            ) : null,
          },
          {
            icon: HomeIcon,
            title: 'Listing',
            text: transfer.lines[0].listing ? (
              <Anchor
                component="button"
                onClick={() =>
                  openListing(transfer.lines[0].listing!.id, 'overview', 'push')
                }
              >
                {transfer.lines[0].listing.name}
              </Anchor>
            ) : null,
          },
          {
            icon: HashtagIcon,
            title: 'Reference',
            text: transfer.uniqueRef,
          },
        ]}
      />

      <Lines lines={transfer.lines} currency={transfer.currency} />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type ExpenseLine = NonNullable<Transfer['lines']>[number];

const Lines = ({
  lines,
  currency,
}: {
  lines: ExpenseLine[];
  currency: string;
}) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const total = useMemo(
    () => formatCurrency(sum(lines, (x) => x.amount) / 100, currency),
    [currency]
  );

  const columns = useMemo<MRT_ColumnDef<ExpenseLine>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          return (
            <Box>
              <Text>{row.original.description}</Text>
              <Text size="xs">{row.original.account?.name}</Text>
            </Box>
          );
        },
      },
      {
        header: 'Split',
        accessorKey: 'centTotal',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          sx: (theme) => ({
            paddingRight: theme.spacing.xs,
            fontWeight: 'bold',
          }),
        },
        size: 100,
        Cell: ({ row }) => {
          return formatCurrency(row.original.amount / 100, currency);
        },
        Footer: !lines.length ? undefined : () => total,
      },
    ],
    [currency, lines.length, total]
  );

  return (
    <DrawerCollapsableTable
      title="Lines"
      rightSection={null}
      rowData={lines}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No lines found
          </Text>
        </Center>
      )}
    />
  );
};
