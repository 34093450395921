import {
  AddressFormInputs,
  Button,
  Input,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import {
  useEnabledFeatures,
  useMe,
  useMutation,
  useQuery,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import type { contact_set_input } from '@finalytic/graphql';
import { ChevronIcon, Icon, OfficeIcon, UserIcon } from '@finalytic/icons';
import { LoadingIndicator, showErrorNotification } from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import {
  Box,
  Center,
  Checkbox,
  Collapse,
  Divider,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
} from '@mantine/core';
import { Title, type TitleProps } from '@mantine/core';
import { Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  type Address,
  OWNER_COMPANY_TYPES,
  type OwnerCompanyTypeValue,
  formatOwnerName,
  getCountryByIsoCode,
  getStateByIsoCode,
  validateTin,
} from '@vrplatform/ui-common';
import { useState } from 'react';
import type { ReactNode } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { undefined } from 'zod';
import { SettingsTitle, SettingsViewContainer } from './_components';

type FormInputs = {
  firstName: string;
  lastName: string;
  taxId: string;
  address: Address;
  companyType: OwnerCompanyTypeValue | 'individual';
  is1099PostalDelivery: boolean;
};

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

type Owner = NonNullable<ReturnType<typeof useOwnerQuery>['data']>[0];

const useOwnerQuery = () => {
  const { id: meId } = useMe();
  const { GL } = useEnabledFeatures();

  return useQuery(
    (q, args) => {
      const owners = args.GL
        ? []
        : q
            .owners({
              where: {
                userAccesses: {
                  role: { _eq: 'admin' },
                  userId: { _eq: args.meId },
                },
              },
            })
            .map((owner) => ({
              id: owner.id,
              type: owner.type || 'individual',
              values: ensure<FormInputs>({
                firstName: owner.firstName || '',
                lastName: owner.name || '',
                taxId: owner.taxId || '',
                is1099PostalDelivery: owner.is1099PostalDelivery ?? true,
                companyType:
                  ((owner.companyType ??
                    null) as OwnerCompanyTypeValue | null) ?? 'individual',
                address: ensure<Address>({
                  city: owner.address?.city || '',
                  countryCode: owner.address?.countryCode || 'US',
                  line1: owner.address?.line || '',
                  line2: owner.address?.lineDetails,
                  postcode: owner.address?.postalCode || '',
                  stateCode: owner.address?.stateCode || 'AK',
                }),
              }),
              name: formatOwnerName(owner),
              addressId: owner.addressId,
              address: ensure<Address>({
                city: owner.address?.city || '',
                countryCode: owner.address?.countryCode || 'US',
                line1: owner.address?.line || '',
                line2: owner.address?.lineDetails,
                postcode: owner.address?.postalCode || '',
                stateCode: owner.address?.stateCode || 'AK',
              }),
            }));

      type Owner = (typeof owners)[0];

      const contacts = !args.GL
        ? []
        : q
            .contacts({
              where: {
                type: { _eq: 'owner' },
                userAccess: {
                  role: { _eq: 'admin' },
                  userId: { _eq: args.meId },
                },
              },
            })
            .map<Owner>((owner) => {
              const address = (owner.addressData() || {
                city: '',
                countryCode: 'US',
                line1: '',
                line2: undefined,
                postcode: '',
                stateCode: 'AK',
              }) as Address;

              return {
                id: owner.id,
                type: owner.companyType ? 'company' : 'individual',
                values: ensure<FormInputs>({
                  companyType: owner.companyType ?? 'individual',
                  firstName: owner.firstName || '',
                  lastName: owner.name || '',
                  taxId: owner.taxId || '',
                  address,
                  is1099PostalDelivery: owner.is1099PostalDelivery ?? true,
                }),
                name: formatOwnerName(owner),
                address,
                addressId: undefined as any,
              } satisfies Owner;
            });

      return args.GL ? contacts : owners;
    },
    {
      queryKey: 'owners',
      variables: {
        meId,
        GL,
      },
    }
  );
};

export const SettingsOwnerAddressView = () => {
  const { data: owners, isInitialLoading, isLoading } = useOwnerQuery();

  const isSingleOwner = owners?.length === 1;

  return (
    <SettingsViewContainer>
      <SettingsTitle type="view-title">Statement Details</SettingsTitle>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {isInitialLoading ? (
          <Center mih="30dvh">
            <LoadingIndicator size="md" mt="lg" />
          </Center>
        ) : isSingleOwner ? (
          <Form owner={owners[0]} isLast />
        ) : (
          <>
            <Divider color="rgba(0, 0, 0, 0.06)" mt="xl" />
            <Stack mt={5} gap={5}>
              {owners?.map((owner, index, arr) => {
                return (
                  <Box key={owner.id}>
                    <CollapsedForm
                      owner={owner}
                      isLast={arr.length === index + 1}
                    />
                  </Box>
                );
              })}
            </Stack>
          </>
        )}
        <LoadingOverlay visible={isLoading && !isInitialLoading} />
      </Box>
    </SettingsViewContainer>
  );
};

type Props = {
  owner: Owner;
  isLast: boolean;
};

const CollapsedForm = (props: Props) => {
  const [opened, handlers] = useDisclosure(false);

  return (
    <>
      <Button
        variant="light"
        onClick={handlers.toggle}
        rightIcon={() => (
          <Box
            sx={{
              transform: !opened ? 'rotate(-90deg)' : 'none',
              transition: 'all 200ms ease',
            }}
          >
            <ChevronIcon size={18} />
          </Box>
        )}
        leftIcon={props.owner.type === 'company' ? 'OfficeIcon' : 'UserIcon'}
        sx={(theme) => ({
          width: '100%',
          textAlign: 'left',
          '.mantine-Button-inner': {
            justifyContent: 'flex-start',
          },
          '.mantine-Button-label': {
            flex: 1,
          },

          '.mantine-Button-rightIcon': {
            marginLeft: 'auto',
          },
          backgroundColor: opened
            ? theme.colors[theme.primaryColor][0] + 20
            : 'inherit',
          '&:hover': {
            backgroundColor: opened
              ? theme.colors[theme.primaryColor][0] + 20
              : 'inherit',
          },
        })}
      >
        {formatOwnerName(props.owner)}
      </Button>

      <Collapse in={opened}>
        <Form {...props} />
      </Collapse>
    </>
  );
};

const Form = ({ owner, isLast }: Props) => {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();

  const [taxDisabled, setTaxDisabled] = useState(false);

  const methods = useForm<FormInputs>({
    values: owner.values,
  });

  const ownerType =
    methods.watch('companyType') === 'individual' ? 'individual' : 'company';

  const { mutate: mutateLegacyOwner } = useTrpcMutation('updateOwner', {
    invalidateQueryKeys: ['owners'],
  });

  const { mutate: mutateContact } = useMutation(
    (q, { id, ...input }: contact_set_input & { id: string }) => {
      return q.updateContact({
        pk_columns: {
          id: id,
        },
        _set: input,
      }).id as string;
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );

  const submit = async (values: FormInputs) => {
    if (!values.address.stateCode || !values.address.countryCode) {
      return showErrorNotification({
        message: 'Please select a country and state',
        color: 'yellow',
        icon: null,
      });
    }

    if (GL) {
      const country =
        (values.address?.countryCode &&
          getCountryByIsoCode(values.address?.countryCode)?.name) ||
        '';
      const state =
        (values.address?.countryCode &&
          values.address?.stateCode &&
          getStateByIsoCode(
            values.address?.stateCode,
            values.address?.countryCode
          )?.name) ||
        '';

      await mutateContact({
        args: {
          id: owner.id,
          firstName: values.firstName,
          name: values.lastName,
          companyType:
            values.companyType === 'individual' ? null : values.companyType,
          taxId: values.taxId?.trim() || null,
          is1099PostalDelivery: values.is1099PostalDelivery,
          addressData: !values.address.line1?.trim()
            ? null
            : {
                city: values.address!.city.trim(),
                line1: values.address!.line1.trim(),
                line2: values.address!.line2?.trim(),
                stateCode: values.address!.stateCode,
                state,
                countryCode: values.address!.countryCode,
                country,
                postcode: values.address!.postcode.trim(),
              },
        },
      });
    } else {
      await mutateLegacyOwner({
        ownerId: owner.id,
        tenantId: teamId,
        input: {
          addressId: owner.addressId,
          type: values.companyType === 'individual' ? 'individual' : 'company',
          companyType:
            values.companyType === 'individual' ? null : values.companyType,
          address: !values.address.line1?.trim()
            ? null
            : {
                line1: values.address.line1,
                line2: values.address.line2 || '',
                city: values.address.city,
                postcode: values.address.postcode,
                countryCode: values.address.countryCode,
                stateCode: values.address.stateCode || '',
                country:
                  getCountryByIsoCode(values.address.countryCode)?.name || '',
                state:
                  getStateByIsoCode(
                    values.address.stateCode,
                    values.address.countryCode
                  )?.name || '',
              },
          firstName: values.firstName,
          lastName: values.lastName,
          taxId: values.taxId?.trim() || null,
        },
      });
    }
  };

  if (!owner) return null;

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        sx={() => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
        onSubmit={methods.handleSubmit(submit)}
        onReset={() =>
          methods.reset(owner.values, { keepDirty: false, keepIsValid: false })
        }
      >
        <Box>
          <Controller
            control={methods.control}
            defaultValue="individual"
            name="companyType"
            rules={{
              required: 'Type is required',
            }}
            render={({ field, fieldState: { error } }) => {
              const individualOption = {
                label: 'Individual',
                value: 'individual',
                icon: <UserIcon size={16} />,
              };

              const options = [
                individualOption,
                ...OWNER_COMPANY_TYPES.map((x) => ({
                  ...x,
                  icon: <OfficeIcon size={16} />,
                })),
              ];

              return (
                <Box mt="xs">
                  <InputWrapper label="Type" required error={error?.message}>
                    <InputSelect
                      data={{
                        options,
                        sort: null,
                      }}
                      type="single"
                      value={
                        options.find((x) => x.value === field.value) || null
                      }
                      inputProps={{
                        placeholder: 'Company type',
                        error: !!error,
                      }}
                      dropdownProps={{ width: 'target' }}
                      setValue={(e) => {
                        if (e?.value) {
                          if (
                            (e.value === 'individual' &&
                              field.value !== 'individual') ||
                            (e.value !== 'individual' &&
                              field.value === 'individual')
                          ) {
                            methods.setValue('firstName', '');
                            methods.setValue('lastName', '');
                            methods.clearErrors('firstName');
                            methods.clearErrors('lastName');
                          }
                          field.onChange(e.value);
                        }
                      }}
                    />
                  </InputWrapper>
                </Box>
              );
            }}
          />

          <Stack mt="xs">
            {ownerType === 'company' ? (
              <Controller
                control={methods.control}
                name="lastName"
                rules={{
                  required: 'Company name is required',
                  validate: trapSpacesForRequiredFields,
                }}
                render={({ field, fieldState: { error } }) => (
                  <InputWrapper
                    label="Company name"
                    error={error?.message}
                    required
                  >
                    <Input
                      {...field}
                      placeholder="Company name"
                      autoComplete="off"
                      error={!!error}
                    />
                  </InputWrapper>
                )}
              />
            ) : (
              <Box
                sx={(theme) => ({
                  alignItems: 'flex-start',
                  justifyContent: 'stretch',
                  display: 'flex',
                  gap: theme.spacing.md,
                  '> div': {
                    flex: 1,
                  },
                })}
              >
                <Controller
                  control={methods.control}
                  name="firstName"
                  rules={{
                    required: 'First name is required',
                    validate: trapSpacesForRequiredFields,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputWrapper
                      label="First name"
                      error={error?.message}
                      required
                    >
                      <Input
                        {...field}
                        placeholder="First name"
                        autoComplete="off"
                        error={!!error}
                      />
                    </InputWrapper>
                  )}
                />
                <Controller
                  control={methods.control}
                  name="lastName"
                  rules={{
                    required: 'Last name is required',
                    validate: trapSpacesForRequiredFields,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputWrapper
                      label="Last name"
                      error={error?.message}
                      required
                    >
                      <Input
                        {...field}
                        placeholder="Last name"
                        autoComplete="off"
                        error={!!error}
                      />
                    </InputWrapper>
                  )}
                />
              </Box>
            )}
          </Stack>

          <DrawerSectionTitle>Address</DrawerSectionTitle>

          <AddressFormInputs />

          <DrawerSectionTitle>Tax Information</DrawerSectionTitle>

          <Controller
            control={methods.control}
            name="taxId"
            rules={{
              required: taxDisabled
                ? false
                : 'Tax Identification Number is required',
              validate: (v) => {
                if (taxDisabled) return true;

                return validateTin(v);
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <InputWrapper
                label="TIN (US only)"
                error={error?.message}
                mb="sm"
                required={!taxDisabled}
              >
                <Input
                  {...field}
                  placeholder="Tax Identification Number"
                  autoComplete="off"
                  error={!!error}
                  disabled={taxDisabled}
                />
              </InputWrapper>
            )}
          />

          <Checkbox
            label="I do not have a TIN"
            size="xs"
            checked={taxDisabled}
            onChange={() => {
              setTaxDisabled((e) => !e);
              methods.clearErrors('taxId');
              methods.setValue('taxId', '');
            }}
          />

          <Controller
            control={methods.control}
            name="is1099PostalDelivery"
            disabled={taxDisabled}
            render={({ field, fieldState: { error } }) => (
              <InputWrapper
                label="1099 Delivery"
                error={error?.message}
                mt="lg"
                mb="sm"
                required={!taxDisabled}
              >
                <Box>
                  <SegmentedControl
                    fullWidth
                    data={[
                      {
                        label: (
                          <Group justify="center">
                            <Icon icon="EmailIcon" size={16} />
                            <Text>Postal + E-Delivery</Text>
                          </Group>
                        ),
                        value: 'postal-and-e-delivery',
                      },
                      {
                        label: (
                          <Group justify="center">
                            <Icon icon="Send2Icon" size={16} />{' '}
                            <Text>E-Delivery only</Text>
                          </Group>
                        ),
                        value: 'e-delivery-only',
                      },
                    ]}
                    value={
                      field.value ? 'postal-and-e-delivery' : 'e-delivery-only'
                    }
                    disabled={field.disabled}
                    onChange={(e) =>
                      field.onChange(e === 'postal-and-e-delivery')
                    }
                  />
                </Box>
              </InputWrapper>
            )}
          />
        </Box>

        <Group
          mt="md"
          sx={(theme) => ({
            paddingTop: theme.spacing.sm,
            paddingBottom: theme.spacing.lg,
            borderBottom: isLast ? 'none' : `1px solid ${theme.colors.gray[2]}`,
            marginBottom: isLast ? 0 : theme.spacing.lg,
          })}
        >
          <Button type="reset" disabled={methods.formState.isSubmitting}>
            Reset
          </Button>
          <Button
            sx={{ flexGrow: '1!important' as any }}
            type="submit"
            disabled={!methods.formState.isDirty}
            loading={methods.formState.isSubmitting}
            variant="primary"
          >
            Save changes
          </Button>
        </Group>
      </Box>
    </FormProvider>
  );
};

const DrawerSectionTitle = ({
  children,
  ...props
}: { children: ReactNode } & TitleProps) => (
  <Title order={4} mt="xl" mb="xs" {...props}>
    {children}
  </Title>
);
