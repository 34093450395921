import {
  AuthenticateWithRedirectCallback,
  ClerkLoaded,
  ClerkProvider,
  SignedIn,
  SignedOut,
  useUser,
} from '@clerk/clerk-react';
import { TrpcProvider } from '@finalytic/data';
import { LoadingIndicator } from '@finalytic/ui';
import { Box } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { lazy, useEffect } from 'react';
import { useState } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import {
  SpotlightComponentProvider,
  SpotlightConfigProvider,
} from '../components/spotlight';
import { CLERK_PUBLISHABLE_KEY } from '../consts';
import { AppRoutes } from '../routes';
import { VerifyTokenView } from '../views/invite-redirect/VerifyTokenView';
import { DBProvider } from './DBProvider';
import { LiveBlocksProvider } from './LiveBlocksProvider';

const FailedInviteView = lazy(
  () => import('../views/invite-redirect/FailedInvite')
);
const InvitationRedirect = lazy(
  () => import('../views/invite-redirect/InvitationRedirect')
);
const SignInRoutes = lazy(
  () => import('../views/onboarding/views/sign-in-up/SignInRoutes')
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Auth = () => {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      publishableKey={CLERK_PUBLISHABLE_KEY}
      routerPush={(to: string) => navigate(to)}
      routerReplace={(to: string) => navigate(to, { replace: true })}
    >
      <SignedIn>
        <AuthNewUserDelay>
          <SignedInRoutes />
        </AuthNewUserDelay>
      </SignedIn>
      <SignedOut>
        <SignOutRoutes />
      </SignedOut>
    </ClerkProvider>
  );
};

const SignedInRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="hello" element={<Hello />} />

      <Route path="/invite-failed" element={<FailedInviteView />} />

      <Route
        path="/*"
        element={
          <SpotlightConfigProvider>
            <TrpcProvider queryClient={queryClient}>
              <QueryClientProvider client={queryClient}>
                <DBProvider>
                  <SpotlightComponentProvider>
                    <LiveBlocksProvider>
                      <Outlet />
                    </LiveBlocksProvider>
                  </SpotlightComponentProvider>
                </DBProvider>
              </QueryClientProvider>
            </TrpcProvider>
          </SpotlightConfigProvider>
        }
      >
        <Route path="invitation" element={<InvitationRedirect />} />
        <Route path="*" element={<AppRoutes />} />
      </Route>
    </SentryRoutes>
  );
};

const SignOutRoutes = () => {
  return (
    <ClerkLoaded>
      <SentryRoutes>
        <Route path="token-verify" element={<VerifyTokenView />} />
        <Route path="/sso-callback" element={<SSOCallback />} />
        <Route path="/invite-failed" element={<FailedInviteView />} />
        <Route path="/invitation" element={<ValidateInvitation />} />
        <Route path="/*" element={<SignIn />} />
      </SentryRoutes>
    </ClerkLoaded>
  );
};

const SignIn = () => (
  <SpotlightConfigProvider>
    <TrpcProvider queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <SignInRoutes />
      </QueryClientProvider>
    </TrpcProvider>
  </SpotlightConfigProvider>
);

const ValidateInvitation = () => (
  <SpotlightConfigProvider>
    <TrpcProvider queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <InvitationRedirect />
      </QueryClientProvider>
    </TrpcProvider>
  </SpotlightConfigProvider>
);

function Hello() {
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 3000);
  });
  return (
    <Box mt={'30vh'}>
      <LoadingIndicator isFullPageLoading message="Redirecting" />
    </Box>
  );
}

// Callback for Social logins like Google etc.
function SSOCallback() {
  // Handle the redirect flow by rendering the
  // prebuilt AuthenticateWithRedirectCallback component.
  // This is the final step in the custom OAuth flow
  return <AuthenticateWithRedirectCallback />;
}

const AuthNewUserDelay = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const isReady = !!user?.publicMetadata?.allowed_ids;
  const [timer, setTimer] = useState<number>(0);

  const isError = timer > 10;

  useEffect(() => {
    if (!isReady && !isError) {
      const timeout = setInterval(() => {
        setTimer((o) => o++);
        user?.reload();
      }, 1000);
      return () => clearInterval(timeout);
    }
  }, [isReady, isError, user?.reload]);

  if (isError) return <div>Something went wrong, please try again ...</div>;
  else if (!isReady) {
    return (
      <Box mt={'30vh'}>
        <LoadingIndicator isFullPageLoading message="Redirecting ..." />
      </Box>
    );
  }

  return <>{children}</>;
};
