import {
  useDashboard,
  useEnabledFeatures,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { ArchiveIcon, Edit3Icon, HomeIcon, Icon } from '@finalytic/icons';
import {
  InfiniteTable,
  type MRT_ColumnDef,
  type MRT_GroupingState,
  type MRT_SortingState,
} from '@finalytic/table';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuItem,
  LoadingIndicator,
  StringParam,
  useQueryParamSet,
} from '@finalytic/ui';
import { type Maybe, day, ensure, hasValue } from '@finalytic/utils';
import {
  Center,
  Group,
  HoverCard,
  List,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
} from '@mantine/core';
import { whereRecurringFees } from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { InputListingOwners, OwnerBadge } from '../../../components';
import { type TableFilterType, useGenericTableStore } from '../../../stores';
import { DEFAULT_FEE_ICON } from '../../fees/edit/useFeeForm';
import { FEE_TYPES } from '../../fees/overview/_components';
import { getFeeTypeRouteFromRecurringFeeEnum } from '../../fees/overview/_utils';
import {
  ListingCollectionSelect,
  ListingEllipsisMenuItems,
  ListingEllipsisMenuModals,
  ListingGroupModals,
  ListingNameCell,
  ListingStatusSelect,
} from '../_components';
import { ListingFilter, useListingFilter } from './ListingFilter';
import { type ListingRow, useListingTableQuery } from './useListingTableQuery';

type Props = {
  // Options
  onRowClickType: 'detail' | 'select';
  hideRowMenu: boolean;
  disableInputs: boolean;

  // Filter
  defaultFilterByAutomationId: string | undefined;
  filterType: TableFilterType;
};

const RECURRING_FEE_LIMIT = 11;

export const ListingBaseTable = ({
  onRowClickType,
  hideRowMenu,
  disableInputs,
  defaultFilterByAutomationId: filterByAutomationId,
  filterType,
}: Props) => {
  const { colorScheme } = useMantineColorScheme();
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();
  const isOwnerPortal = dashboard === 'owner';
  const [opened, setOpened] = useState<{
    listing: ListingRow;
    modal: 'delete' | 'migrate';
  } | null>(null);

  const [openedGroup, setOpenedGroup] = useState<{
    group: { id: string; name: string };
    modal: 'delete' | 'rename';
  } | null>(null);

  const closeModal = () => setOpened(null);

  const goto = useNavigate();
  const [groupBy, setGroupBy] = useState<MRT_GroupingState>(['collectionId']);

  const { reset } = useListingFilter();

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'calculated_title', desc: false },
  ]);

  const setFilterType = useGenericTableStore((st) => st.setFilterType);
  const currentFilterType = useGenericTableStore((st) => st.filterType);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentFilterType !== filterType) setFilterType(filterType);
  }, [currentFilterType, filterType]);

  const rowSelection = useGenericTableStore((st) => st.selected);
  const allPagesSelected = useGenericTableStore((st) => st.allPagesSelected);
  const setRowSelection = useGenericTableStore((st) => st.setSelected);
  const setAllPagesSelected = useGenericTableStore(
    (st) => st.setAllPagesSelected
  );

  const setListing = useQueryParamSet('listing', StringParam);

  const { isLoading: loadingRecurringFees, data: recurringFees } =
    useRecurringFeesQuery({
      limit: RECURRING_FEE_LIMIT + 1,
      offset: 0,
    });

  const queryData = useListingTableQuery({
    sorting,
    type: isOwnerPortal ? 'ownerPortal' : 'pmDashboard',
    groupBy,
    filterByAutomationId,
  });

  type TableColumn = MRT_ColumnDef<ListingRow>;

  const columns = useMemo(
    () =>
      ensure<(TableColumn | undefined)[]>([
        {
          accessorKey: 'name',
          enableColumnDragging: false,
          header: 'Listing',
          minSize: 300,
          Cell: ({ row }) => <ListingNameCell {...row.original} />,
          AggregatedCell: ({ row }) => {
            const collection = row.original.collection;
            const isGrouped = !!collection?.id;

            return (
              <Group wrap="nowrap" gap="sm">
                {isGrouped ? <HomeIcon size={18} /> : <ArchiveIcon size={18} />}
                <Text component="p" size="sm" fw={500}>
                  {isGrouped ? row.original.collection?.name : 'Ungrouped'}
                </Text>
              </Group>
            );
          },
        },
        isOwnerPortal
          ? undefined
          : {
              id: 'collectionId',
              header: 'Listing Group',
              minSize: 200,
              enableColumnDragging: false,
              getGroupingValue: (row) => row.collection?.id || '',
              enableGrouping: true,
              GroupedCell: ({ row }) => row.original.collection?.name,
              maxSize: 70,
              Cell: ({ row }) => {
                const collection = row.original.collection;
                const listingId = row.original.id;

                if (!listingId) return null;

                return (
                  collection?.id && (
                    <ListingCollectionSelect
                      collection={collection}
                      listingId={listingId}
                    />
                  )
                );
              },
              enableHiding: false,
            },
        isOwnerPortal || GL || disableInputs
          ? undefined
          : {
              accessorKey: 'disabledAutomations',
              header: 'Automations',
              enableColumnDragging: false,
              Cell: ({ row }) => <ListingStatusSelect {...row.original} />,
              mantineTableBodyCellProps: {
                align: 'right',
              },

              mantineTableHeadCellProps: {
                align: 'right',
              },
            },
        isOwnerPortal
          ? undefined
          : {
              accessorKey: 'ownerships',
              header: GL ? 'Current Ownership' : 'Owners',
              enableSorting: false,
              enableColumnDragging: false,
              Cell: ({ row }) => {
                if (!row.original.id) return null;

                if (GL) {
                  const members =
                    row.original.currentOwnershipPeriod?.members || [];

                  return (
                    <Group gap={rem(5)}>
                      {members.map((member) => {
                        return (
                          <OwnerBadge
                            key={member.id}
                            ownerId={member.ownerId}
                            type={member.ownerType}
                            name={member.ownerName}
                            splitBasePoints={member.splitBasePoints}
                          />
                        );
                      })}
                    </Group>
                  );
                }

                return (
                  <InputListingOwners
                    table="listing"
                    ownerships={row.original.ownerships}
                    rowId={row.original.id}
                    companyOwnerships={[]}
                    inputDisabled={disableInputs}
                  />
                );
              },
            },
        ...(!GL || isOwnerPortal
          ? []
          : recurringFees
              ?.slice(0, RECURRING_FEE_LIMIT)
              ?.map<TableColumn>((fee, feeIndex) => {
                const showHoverCard =
                  feeIndex === RECURRING_FEE_LIMIT - 1 &&
                  recurringFees.length > RECURRING_FEE_LIMIT;

                return {
                  id: fee.id,
                  header: fee.title || 'Recurring Fee',
                  maxSize: 0,
                  minSize: 45,
                  enableColumnDragging: false,
                  enableSorting: false,
                  enableResizing: false,
                  mantineTableBodyCellProps: {
                    align: 'center',
                  },
                  mantineTableHeadCellProps: {
                    align: 'center',
                    sx: {
                      '&, *': {
                        paddingLeft: '2px!important',
                      },
                    },
                  },
                  Header: () => {
                    if (showHoverCard) return null;

                    return (
                      <Tooltip label={fee.title} withArrow withinPortal>
                        <Icon
                          icon={
                            FEE_TYPES[
                              getFeeTypeRouteFromRecurringFeeEnum(fee.type)
                            ].icon
                          }
                          size={18}
                          sx={{
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    );
                  },
                  Cell: ({ row }) => {
                    const subscriptions =
                      row.original.currentRecurringFeeSubscriptions;

                    const currentSubscription = subscriptions.find(
                      (sub) => sub.recurringFeeId === fee.id
                    );

                    if (showHoverCard) {
                      return (
                        <HoverCard keepMounted={false} width={400} shadow="md">
                          <HoverCard.Target>
                            <Center>
                              <Icon icon="ListUnorderedIcon" size={16} />
                            </Center>
                          </HoverCard.Target>
                          <HoverCard.Dropdown
                            sx={{
                              maxHeight: 300,
                              overflowY: 'scroll',
                            }}
                            px="sm"
                            py={5}
                          >
                            <RecurringFeeHoverCardContent
                              currentSubscription={currentSubscription}
                            />
                          </HoverCard.Dropdown>
                        </HoverCard>
                      );
                    }

                    return (
                      <RecurringFeeSusbcriptionStatusIcon
                        subscription={currentSubscription}
                        recurringFeeTitle={fee.title}
                      />
                    );
                  },
                };
              }) || []),
        // isOwnerPortal || !GL
        //   ? undefined
        //   : {
        //       header: 'To Team',
        //       Header: () => null,
        //       enableSorting: false,
        //       enableColumnDragging: false,
        //       mantineTableBodyCellProps: { align: 'right' },
        //       mantineTableHeadCellProps: { align: 'right' },
        //       maxSize: 20,
        //       Cell: ({ row }) => {
        //         return (
        //           <Tooltip label="Go to detail" withArrow withinPortal>
        //             <IconButton
        //               onClick={(event) => {
        //                 event.stopPropagation();
        //                 goto(`/listing/${row.original.id}`);
        //               }}
        //               variant="outline"
        //               sx={(theme) => ({
        //                 borderColor: theme.colors.gray[3],
        //               })}
        //             >
        //               <ArrowRightCircleIcon size={16} />
        //             </IconButton>
        //           </Tooltip>
        //         );
        //       },
        //     },
      ]).filter(hasValue),
    [isOwnerPortal, disableInputs, GL, recurringFees]
  );

  if (GL && loadingRecurringFees)
    return (
      <Center flex={1}>
        <LoadingIndicator />
      </Center>
    );

  return (
    <>
      <InfiniteTable
        table={{
          key: 'listings',
          hideExpandColumn: true,
          emptyRowsFallback: 'No listings found',
          onRowClick: isOwnerPortal
            ? undefined
            : {
                handler: (row) => {
                  if (onRowClickType === 'detail') {
                    if (GL) {
                      return goto(`/listing/${row.original.id}`);
                    }

                    return setListing(row.original.id);
                  }

                  if (onRowClickType === 'select') {
                    const canSelect = row.getCanSelect();

                    if (canSelect) {
                      row.toggleSelected();
                    }
                  }
                },
                disabled: (row) => row.getIsGrouped(),
              },
        }}
        columns={columns}
        queryData={{
          ...queryData,
          isLoading: loadingRecurringFees || queryData.isLoading,
        }}
        groupBy={
          isOwnerPortal
            ? undefined
            : {
                groupBy,
                setGroupBy,
                defaultExpanded: true,
                allowExpanding: false,
                menuItems: ({ row }) => {
                  const group = row.original.collection;

                  if (!group) return null;

                  return (
                    <>
                      <EllipsisMenuItem
                        customIcon={<Edit3Icon size={16} />}
                        onClick={() =>
                          setOpenedGroup({
                            group,
                            modal: 'rename',
                          })
                        }
                      >
                        Rename
                      </EllipsisMenuItem>
                      <EllipsisMenuDangerItem
                        onClick={() =>
                          setOpenedGroup({
                            group,
                            modal: 'delete',
                          })
                        }
                      >
                        Delete
                      </EllipsisMenuDangerItem>
                    </>
                  );
                },
              }
        }
        sorting={{
          sorting,
          setSorting,
        }}
        selecting={
          isOwnerPortal
            ? undefined
            : {
                rowSelection: {
                  rows: rowSelection,
                  allPagesSelected,
                },
                setAllPagesSelected,
                setRowSelection,
              }
        }
        styles={{
          row: ({ row }, theme) => {
            if (row.getIsGrouped())
              return {
                backgroundColor: `${theme.colors.neutral?.[colorScheme === 'dark' ? 9 : 1]}!important`,
                height: 60,
              };

            return {};
          },
        }}
        rowMenu={
          isOwnerPortal || hideRowMenu
            ? undefined
            : {
                menuItems: ({ row }) => {
                  const data = row.original;

                  const isPmsListing = !!data?.pmsConnectionId;

                  return (
                    <ListingEllipsisMenuItems
                      handlers={{
                        openDeleteModal: () =>
                          setOpened({ listing: data, modal: 'delete' }),
                        openMigrationModal: () =>
                          setOpened({ listing: data, modal: 'migrate' }),
                      }}
                      listing={{
                        isPmsListing,
                        listingConnections: data.connections.length,
                        listingId: data.id,
                        ownerStatements: data.statements,
                      }}
                    />
                  );
                },
              }
        }
        resetFilter={reset}
      >
        <ListingFilter
          hide={filterByAutomationId ? ['automation', 'status'] : undefined}
        />
      </InfiniteTable>
      <ListingEllipsisMenuModals
        listing={opened?.listing || null}
        deleteModal={{
          opened: opened?.modal === 'delete' && !!opened?.listing,
          closeModal,
        }}
        migrationModal={{
          opened: opened?.modal === 'migrate' && !!opened?.listing,
          closeModal,
        }}
      />
      <ListingGroupModals
        listingGroup={openedGroup?.group || null}
        deleteModal={{
          opened: openedGroup?.modal === 'delete',
          closeModal: () => setOpenedGroup(null),
        }}
        renameModal={{
          opened: openedGroup?.modal === 'rename',
          closeModal: () => setOpenedGroup(null),
        }}
      />
    </>
  );
};

function useRecurringFeesQuery({
  limit,
  offset,
}: { limit: number | undefined; offset: number }) {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();

  return useQuery(
    (q, args) => {
      if (!args.teamId || !args.GL || args.dashboard === 'owner') return [];

      return q
        .recurringFees({
          where: whereRecurringFees({
            tenantId: args.teamId,
          }),
          limit: args.limit,
          offset: args.offset,
          order_by: [
            {
              type: 'desc_nulls_last',
            },
            {
              title: 'asc_nulls_last',
            },
          ],
        })
        .map((fee) => ({
          id: fee.id,
          title: fee.title || 'No name',
          type: fee.type || 'additionalFee',
        }));
    },
    {
      keepPreviousData: true,
      queryKey: ['customFees'],
      skip: !teamId || !GL,
      variables: {
        teamId,
        GL,
        DEFAULT_FEE_ICON,
        limit,
        offset,
        dashboard,
      },
    }
  );
}

const RecurringFeeHoverCardContent = ({
  currentSubscription,
}: {
  currentSubscription:
    | {
        id: string;
        startAt: string;
        endAt: Maybe<string>;
        recurringFeeId: string;
      }
    | undefined;
}) => {
  const { data: fees = [], isLoading } = useRecurringFeesQuery({
    limit: undefined,
    offset: RECURRING_FEE_LIMIT - 1,
  });

  if (isLoading)
    return (
      <Center py="xl">
        <LoadingIndicator size="xs" />
      </Center>
    );

  return (
    <List center>
      {fees.map((fee) => (
        <List.Item
          key={fee.id}
          icon={
            <Icon
              icon={
                FEE_TYPES[getFeeTypeRouteFromRecurringFeeEnum(fee.type)].icon
              }
              size={18}
            />
          }
          styles={{
            itemLabel: {
              width: '100%',
              display: 'block',
            },
            itemWrapper: {
              width: '100%',
            },
          }}
        >
          <Group justify="space-between" w="100%" wrap="nowrap">
            <Text size="sm" fw={500}>
              {fee.title}
            </Text>

            <RecurringFeeSusbcriptionStatusIcon
              subscription={currentSubscription}
              recurringFeeTitle={fee.title}
            />
          </Group>
        </List.Item>
      ))}
    </List>
  );
};

const RecurringFeeSusbcriptionStatusIcon = ({
  subscription,
  recurringFeeTitle,
}: {
  subscription:
    | ListingRow['currentRecurringFeeSubscriptions'][0]
    | undefined
    | null;
  recurringFeeTitle: string;
}) => {
  const status = useMemo<'active' | 'inactive' | 'closing'>(() => {
    if (!subscription) return 'inactive';

    if (!subscription.endAt) return 'active';

    return 'closing';
  }, [subscription, subscription?.startAt, subscription?.endAt]);

  const hideTooltip = !subscription?.endAt;

  return (
    <Tooltip
      label={`${recurringFeeTitle} until ${subscription?.endAt ? day(subscription.endAt).format('MMM DD, YYYY') : 'forever'}`}
      disabled={hideTooltip}
      openDelay={100}
    >
      <Center
        sx={(theme) => ({
          padding: rem(4),
          borderRadius: theme.radius.md,
          ':hover': {
            backgroundColor: hideTooltip ? undefined : theme.colors.neutral[0],
          },
        })}
      >
        <Center
          sx={() => ({
            padding: rem(4),
          })}
        >
          {status === 'active' && (
            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.833496 8.00065C0.833496 4.04261 4.04212 0.833984 8.00016 0.833984C11.9582 0.833984 15.1668 4.04261 15.1668 8.00065C15.1668 11.9587 11.9582 15.1673 8.00016 15.1673C4.04212 15.1673 0.833496 11.9587 0.833496 8.00065ZM11.1972 6.53098C11.4901 6.23809 11.4901 5.76321 11.1972 5.47032C10.9043 5.17743 10.4294 5.17743 10.1365 5.47032L7.3335 8.27332L6.53049 7.47032C6.2376 7.17743 5.76273 7.17743 5.46983 7.47032C5.17694 7.76321 5.17694 8.23809 5.46983 8.53098L6.80317 9.86431C7.09606 10.1572 7.57093 10.1572 7.86383 9.86431L11.1972 6.53098Z"
                fill="#16A34A"
              />
            </svg>
          )}
          {status === 'inactive' && (
            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.833496 8.00065C0.833496 4.04261 4.04212 0.833984 8.00016 0.833984C11.9582 0.833984 15.1668 4.04261 15.1668 8.00065C15.1668 11.9587 11.9582 15.1673 8.00016 15.1673C4.04212 15.1673 0.833496 11.9587 0.833496 8.00065ZM6.53049 5.47032C6.2376 5.17743 5.76273 5.17743 5.46983 5.47032C5.17694 5.76321 5.17694 6.23809 5.46983 6.53098L6.9395 8.00065L5.46983 9.47032C5.17694 9.76321 5.17694 10.2381 5.46983 10.531C5.76273 10.8239 6.2376 10.8239 6.53049 10.531L8.00016 9.06131L9.46983 10.531C9.76273 10.8239 10.2376 10.8239 10.5305 10.531C10.8234 10.2381 10.8234 9.76321 10.5305 9.47032L9.06082 8.00065L10.5305 6.53098C10.8234 6.23809 10.8234 5.76321 10.5305 5.47032C10.2376 5.17743 9.76273 5.17743 9.46983 5.47032L8.00016 6.93999L6.53049 5.47032Z"
                fill="#DC2626"
              />
            </svg>
          )}
          {status === 'closing' && (
            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.833496 8.00065C0.833496 4.04261 4.04212 0.833984 8.00016 0.833984C11.9582 0.833984 15.1668 4.04261 15.1668 8.00065C15.1668 11.9587 11.9582 15.1673 8.00016 15.1673C4.04212 15.1673 0.833496 11.9587 0.833496 8.00065ZM8.75016 4.66732C8.75016 4.2531 8.41438 3.91732 8.00016 3.91732C7.58595 3.91732 7.25016 4.2531 7.25016 4.66732V8.66732C7.25016 9.08153 7.58595 9.41732 8.00016 9.41732C8.41438 9.41732 8.75016 9.08153 8.75016 8.66732V4.66732ZM8.41683 11.0007C8.41683 11.0945 8.38582 11.181 8.3335 11.2507V11.0007V10.7506C8.38582 10.8203 8.41683 10.9068 8.41683 11.0007ZM7.5835 11.0007C7.5835 11.0945 7.6145 11.181 7.66683 11.2507V11.0007V10.7506C7.6145 10.8203 7.5835 10.9068 7.5835 11.0007ZM8.00016 9.91732C7.40185 9.91732 6.91683 10.4023 6.91683 11.0007C6.91683 11.599 7.40185 12.084 8.00016 12.084C8.59847 12.084 9.0835 11.599 9.0835 11.0007C9.0835 10.4023 8.59847 9.91732 8.00016 9.91732Z"
                fill="#EAB308"
              />
            </svg>
          )}
        </Center>
      </Center>
    </Tooltip>
  );
};
