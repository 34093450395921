import { useLocalStorage } from '@mantine/hooks';
import type { Dashboard } from '@vrplatform/ui-common';
import { useMe, useTeam } from './team';

export function useDashboard(): [Dashboard, (val?: boolean) => void] {
  const { id: meId } = useMe();
  const [currentTeam] = useTeam();
  const [isOverview, setIsOverview] = useLocalStorage<boolean>({
    key: `${meId}_dt`,
  });

  const toggleIsOverview = (val?: boolean) =>
    setIsOverview(typeof val !== 'undefined' ? val : (e) => !e);

  if (isOverview) return ['overview', toggleIsOverview];

  const teamType = currentTeam?.type || 'propertyManager';

  const role = currentTeam?.role === 'owner' ? 'owner' : 'propertyManager';

  if (['partner', 'admin'].includes(teamType))
    return [teamType as Dashboard, toggleIsOverview];

  return [role, toggleIsOverview];
}
