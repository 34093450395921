import {
  type gqlV2,
  useInfiniteQuery,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import type {
  owner_statement_status_enum,
  tax_statement_status_enum,
} from '@finalytic/graphql';
import type { MRT_SortingState } from '@finalytic/table';
import {
  type Maybe,
  bankersRound,
  day,
  ensure,
  hasValue,
  sum,
  utc,
} from '@finalytic/utils';
import {
  type Address,
  type formatAddress,
  formatOwnerName,
  getListingName,
  getOwnerAddress,
  getOwnerCompanyType,
  getStatementDistributionByCollection,
  validateTin,
  whereTaxStatements,
} from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useTaxStatementListQueryFilter } from './TaxStatementListFilter';
import type { TaxStatementStatus } from './TaxStatementStatusSelect';
type Params = {
  sorting: MRT_SortingState;
  taxAccountCollectionId: string;
};

export type TaxStatementRow = NonNullable<
  ReturnType<typeof useTaxStatementListTableQuery>['data']
>['pages'][number]['list'][number];

export type TaxStatementMetadata = {
  is1099PostalDelivery: boolean;
  owner: {
    name: string;
    address: {
      city: string;
      line: string;
      lineDetails: string;
      postcode: string;
      country: string;
      countryCode: string;
      state: string;
      stateCode: string;
    };
    phone: string;
    taxId: string;
  };
  statuses?: {
    federal?:
      | 'CREATED'
      | 'TRANSMITTED'
      | 'SENT TO AGENCY'
      | 'ACCEPTED'
      | 'REJECTED'
      | 'ACCEPTED WITH ERRORS'
      | null;
    state?:
      | 'CREATED'
      | 'TRANSMITTED'
      | 'SENT TO AGENCY'
      | 'ACCEPTED'
      | 'REJECTED'
      | 'NOTCREATED'
      | null;
    postal?: 'CREATED' | 'SENT' | 'RECEIVED' | 'NOTCREATED' | null;
  };
};

export type TaxStatementError =
  | 'Missing first name'
  | 'Missing last name'
  | 'Missing company name'
  | 'Missing TIN'
  | 'Missing address'
  | 'Missing team phone number'
  | 'Missing team company name'
  | 'Missing team TIN'
  | 'Incorrect TIN'
  | 'Missing team address'
  | 'Found unpublished statements';

export function useWhereTaxStatements() {
  const [teamId] = useTeamId();
  const { filter } = useTaxStatementListQueryFilter();

  return useMemo(() => {
    return whereTaxStatements({
      tenantId: teamId,
      year: filter.year ? parseInt(filter.year) : day().year(),
      search: filter.search,
      status: filter.status as Maybe<tax_statement_status_enum>,
    });
  }, [teamId, filter.year, filter.search, filter.status]);
}

export const useTaxStatementListTableQuery = ({
  sorting,
  taxAccountCollectionId,
}: Params) => {
  const [teamId] = useTeamId();
  const { filter } = useTaxStatementListQueryFilter();
  const where = useWhereTaxStatements();

  return useInfiniteQuery(
    (
      q,
      { year: y, teamId, taxAccountCollectionId, where, sorting },
      { limit, offset }
    ) => {
      const year = Number(y);

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      if (!y || Number.isNaN(year))
        return {
          list: [],
          aggregate: 0,
        };

      const distinct_on: gqlV2.owner_statement_owner_select_column[] = [
        'newOwnerId',
      ];

      const aggregate =
        q
          .ownerStatementOwnerAggregate({ where, distinct_on })
          .aggregate?.count() || 0;

      const list = q
        .ownerStatementOwners({
          where,
          limit,
          offset,
          order_by,
          distinct_on,
        })
        .map((statementOwner) => {
          return getTaxStatement({
            owner: statementOwner.newOwner!,
            taxAccountCollectionId,
            teamId,
            year,
          });
        });

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: ['taxStatements', 'ownerStatements', 'owners'],
      skip: !filter.year || !taxAccountCollectionId,
      variables: {
        teamId,
        year: filter.year,
        taxAccountCollectionId,
        where,
        sorting,
      },
    }
  );
};

export const getTaxStatement = ({
  taxAccountCollectionId,
  year,
  teamId,
  owner,
}: {
  owner: gqlV2.owner;
  year: number;
  teamId: string;
  taxAccountCollectionId: string;
}) => {
  const taxStatement = owner
    .taxStatements({
      where: {
        year: { _eq: year },
      },
    })
    .map((statement) => ({
      id: statement.id,
      rentAmount: statement.centRentalRevenue,
      startAt: statement.startAt,
      endAt: statement.endAt,
      url: statement.url,
      status: statement.status,
      metadata: statement.metadata() as TaxStatementMetadata,
      pdfError: statement.error,
    }))[0];

  const hasPreviousTaxStatements = !!(
    owner
      .taxStatements_aggregate({
        where: {
          year: { _lt: year },
        },
      })
      .aggregate?.count() || 0
  );

  const getAmount = () => {
    if (typeof taxStatement?.rentAmount === 'number') {
      return taxStatement.rentAmount / 100;
    }

    return null;
  };

  const { status, error } = getTaxStatementStatus(owner, {
    tenantId: teamId,
    startAt: day().set('year', year).startOf('year').yyyymmdd(),
    endAt: day().set('year', year).endOf('year').yyyymmdd(),
    taxStatementStatus: taxStatement?.status,
  });

  const getOwnerInfo = () => {
    const type =
      owner.type === 'company' ? ('company' as const) : ('individual' as const);

    const hasPersistedData = !!taxStatement?.metadata?.owner;

    const metadataAddress = taxStatement?.metadata?.owner?.address;
    const ownerAddress = getOwnerAddress(owner).values;

    const currentData = {
      name: formatOwnerName(owner),
      phone: owner.phone,
      taxId: owner.taxId,
      address: ownerAddress,
      is1099PostalDelivery: owner.is1099PostalDelivery,
    };

    const address: Parameters<typeof formatAddress>[0] = {
      line1: metadataAddress?.line || ownerAddress.line1,
      line2: metadataAddress?.lineDetails || ownerAddress.line2,
      city: metadataAddress?.city || ownerAddress.city,
      postcode: metadataAddress?.postcode || ownerAddress.postcode,
      country: metadataAddress?.country || ownerAddress.country,
      countryCode: metadataAddress?.countryCode || ownerAddress.countryCode,
      state: metadataAddress?.state || ownerAddress.state,
      stateCode: metadataAddress?.stateCode || ownerAddress?.stateCode,
    };

    return {
      id: owner.id,
      email: owner.email,
      type,
      companyType: getOwnerCompanyType(owner.companyType),
      name: hasPersistedData
        ? formatOwnerName(taxStatement.metadata.owner, { showEmpty: true })
        : currentData.name,
      phone: hasPersistedData
        ? taxStatement.metadata.owner.phone
        : currentData.phone,
      taxId: hasPersistedData
        ? taxStatement.metadata.owner.taxId
        : currentData.taxId,
      address,
      persistedData: hasPersistedData ? taxStatement.metadata.owner : null,
      currentData,
      is1099PostalDelivery: hasPersistedData
        ? taxStatement.metadata.is1099PostalDelivery
        : currentData.is1099PostalDelivery,
    };
  };

  const o = getOwnerInfo();

  return {
    id: owner.id,
    currency: 'USD',
    status,
    errorMessage: error,
    pdfError: taxStatement?.pdfError,
    // errorMessage: error,
    startAt: taxStatement?.startAt,
    endAt: taxStatement?.endAt,
    year,
    url: taxStatement?.url,
    taxStatementId: taxStatement?.id,
    taxAccountCollectionId,
    owner: o,
    tenantId: teamId,
    amount: getAmount(),
    hasPreviousTaxStatements,
    is1099PostalDelivery: o.is1099PostalDelivery,
    governmentStatuses: taxStatement?.metadata?.statuses ?? {},
  };
};

export function useTaxStatementCalculatedAmountQuery(
  statement: Maybe<TaxStatementRow>
) {
  const taxAccountCollectionId = statement?.taxAccountCollectionId;

  const startDate =
    statement?.startAt ||
    utc()
      .set('year', statement?.year || new Date().getFullYear())
      .startOf('year')
      .yyyymmdd();
  const endDate =
    statement?.endAt ||
    utc()
      .set('year', statement?.year || new Date().getFullYear())
      .endOf('year')
      .yyyymmdd();

  return useQuery(
    (q, args) => {
      if (!args.ownerId || !args.taxAccountCollectionId) return null;

      return q
        .owners({
          where: {
            id: { _eq: args.ownerId },
          },
          limit: 1,
        })
        .map((owner) =>
          getTaxStatementCalculatedAmount(owner, {
            endDate: args.endDate,
            startDate: args.startDate,
            tenantId: args.tenantId!,
            taxAccountCollectionId: args.taxAccountCollectionId!,
            hasPreviousTaxStatements: !!args.hasPreviousTaxStatements,
          })
        )[0];
    },
    {
      skip: !statement || !taxAccountCollectionId,
      queryKey: ['taxStatements'],
      variables: {
        taxAccountCollectionId,
        year: statement?.year,
        tenantId: statement?.tenantId,
        startDate,
        endDate,
        ownerId: statement?.owner.id,
        hasPreviousTaxStatements: statement?.hasPreviousTaxStatements,
      },
    }
  );
}

export const getTaxStatementCalculatedAmount = (
  owner: gqlV2.owner,
  {
    tenantId,
    taxAccountCollectionId,
    endDate,
    startDate,
    hasPreviousTaxStatements,
  }: {
    tenantId: string;
    taxAccountCollectionId: string;
    startDate: string;
    endDate: string;
    hasPreviousTaxStatements: boolean;
  }
) => {
  const ownerStatements = owner
    .statementOwnerships({
      where: {
        statement: {
          tenantId: { _eq: tenantId },
          startAt: { _gte: startDate, _lte: endDate },
          status: { _in: ['posted', 'published'] },
        },
        role: { _in: ['owner', 'company'] },
      },
      order_by: [
        {
          statement: {
            listingId: 'asc_nulls_last',
          },
        },
        {
          statement: {
            startAt: 'asc_nulls_last',
          },
        },
      ],
    })
    .map((statementOwner, i, arr) => {
      const statement = statementOwner.statement;
      const listingId = statement.listingId;

      const previous = arr[i - 1];
      const prevListingId = previous?.statement.listingId;

      // Order by listingId is super important here!!
      const applyListingStartBalance =
        prevListingId !== listingId && !hasPreviousTaxStatements;

      const { dist, lines, listingStartBalance } =
        getStatementDistributionByCollection(statement, {
          collectionId: taxAccountCollectionId,
          gteDate: startDate,
          ltDate: endDate,
          // if previous tax statements exist, we don't need to add the listing starting balance
          applyListingStartBalance,
        });

      const distribution = dist.find(
        (x) => x.newOwnerId === statementOwner.newOwnerId
      );

      const amount = distribution?.centTotal || 0;
      const split = distribution?.split;

      const getListingStartBalance = () => {
        if (listingStartBalance) {
          if (split === 0) return 0;

          return listingStartBalance * ((split || 100) / 100);
        }

        return 0;
      };

      return {
        listingStartBalance: getListingStartBalance(),
        startAt: statement.startAt,
        amount: amount / 100,
        split,
        newOwnerId: statementOwner.newOwnerId,
        lines,
        listing: {
          id: statement.listingId,
          name: getListingName(statement.listing!),
        },
      };
    });

  const calculatedAmount = bankersRound(
    sum(ownerStatements.map((statement) => statement.amount))
  );

  return {
    calculatedAmount,
    ownerStatements,
    listingStartBalance: ownerStatements[0]?.listingStartBalance,
  };
};

// export function useTaxStatementStatusQuery(statement: Maybe<TaxStatementRow>) {
//   const year = statement?.year || day().year();
//   const defaultStart = utc().set('year', year).startOf('year').yyyymmdd();
//   const defaultEnd = utc().set('year', year).endOf('year').yyyymmdd();

//   return useQuery(
//     (q, args) => {
//       if (!args.ownerId) return null;

//       return (
//         q
//           .owners({
//             where: {
//               id: { _eq: args.ownerId },
//             },
//             limit: 1,
//           })
//           .map((owner) =>
//             getTaxStatementStatus(owner, {
//               tenantId: args.tenantId!,
//               startAt: args.startAt!,
//               endAt: args.endAt!,
//               taxStatementStatus: args.taxStatementStatus,
//             })
//           )[0] || null
//       );
//     },
//     {
//       skip: !statement || statement.status !== 'draft',
//       queryKey: ['taxStatements'],
//       variables: {
//         tenantId: statement?.tenantId,
//         startAt: statement?.startAt || defaultStart,
//         endAt: statement?.endAt || defaultEnd,
//         ownerId: statement?.owner.id,
//         taxStatementStatus: statement?.status,
//       },
//     }
//   );
// }

const getTaxStatementStatus = (
  owner: gqlV2.owner,
  {
    endAt,
    startAt,
    taxStatementStatus,
  }: {
    tenantId: string;
    startAt: string;
    endAt: string;
    taxStatementStatus: gqlV2.tax_statement_status_enum | undefined;
  }
): { status: TaxStatementStatus; error?: TaxStatementError } => {
  if (taxStatementStatus === 'submitted') return { status: taxStatementStatus };

  const tenant = owner.tenant;

  const tenantAddress: Address = {
    city: tenant.billingAddress?.city || '',
    line1: tenant.billingAddress?.line || '',
    line2: tenant.billingAddress?.lineDetails || '',
    stateCode: tenant.billingAddress?.stateCode || '',
    countryCode: tenant.billingAddress?.countryCode || '',
    postcode: tenant.billingAddress?.postalCode || '',
  };
  const ownerAddress: Address = {
    city: owner.address?.city || '',
    line1: owner.address?.line || '',
    line2: owner.address?.lineDetails || '',
    stateCode: owner.address?.stateCode || '',
    countryCode: owner.address?.countryCode || '',
    postcode: owner.address?.postalCode || '',
  };

  // check unpublished statements
  const statementStatuses = owner
    .statementOwnerships({
      where: {
        role: { _eq: 'owner' },
        statement: {
          startAt: {
            _gte: startAt,
          },
          endAt: {
            _lte: endAt,
          },
          status: { _is_null: false },
        },
      },
    })
    .map((statementOwner) => statementOwner.statement.status)
    .filter(hasValue);

  const hasOnlyPublishedOwnerStatements = statementStatuses.every((status) =>
    ensure<owner_statement_status_enum[]>(['posted', 'published']).includes(
      status!
    )
  );

  let error: undefined | TaxStatementError = undefined;

  // Tenant
  const checkedTenant = checkTenant(tenant);
  if (checkedTenant.isError) error = checkedTenant.message;

  const checkedTenantAddress = checkAddress(tenantAddress, 'team');
  if (checkedTenantAddress.isError) error = checkedTenantAddress.message;

  // Owner
  const checkedOwner = checkOwner(owner);
  if (checkedOwner.isError) error = checkedOwner.message;

  const checkedOwnerAddress = checkAddress(ownerAddress, 'owner');
  if (checkedOwnerAddress.isError) error = checkedOwnerAddress.message;

  if (!hasOnlyPublishedOwnerStatements) {
    error = 'Found unpublished statements';
  }

  return { status: error ? 'error' : taxStatementStatus || 'draft', error };
};

type CheckResult =
  | { isError: false; message: undefined }
  | { isError: true; message: TaxStatementError };

const checkAddress = (
  address: Address,
  type: 'owner' | 'team'
): CheckResult => {
  const city = address.city?.trim();
  const line1 = address.line1?.trim();
  const stateCode = address.stateCode?.trim();
  const countryCode = address.countryCode?.trim();
  const postcode = address.postcode?.trim();

  let res: CheckResult = {
    isError: false,
    message: undefined,
  };

  if (!city || !line1 || !stateCode || !countryCode || !postcode) {
    res = {
      isError: true,
      message: type === 'owner' ? 'Missing address' : 'Missing team address',
    };
  }

  return res;
};

const checkTenant = (tenant: gqlV2.tenant): CheckResult => {
  const phone = tenant.supportPhone;
  const taxCode = tenant.companyTaxCode;
  const companyName = tenant.companyName;

  if (!phone) {
    return {
      isError: true,
      message: 'Missing team phone number',
    };
  }

  if (!taxCode) {
    return {
      isError: true,
      message: 'Missing team TIN',
    };
  }

  if (!companyName) {
    return {
      isError: true,
      message: 'Missing team company name',
    };
  }

  return {
    isError: false,
    message: undefined,
  };
};

const checkOwner = (owner: gqlV2.owner): CheckResult => {
  const isCompany = owner.type === 'company';

  const firstName = owner.firstName?.trim();
  const lastNameOrCompanyName = owner.name?.trim();
  const taxId = owner.taxId?.trim();

  if (isCompany && !lastNameOrCompanyName) {
    return {
      isError: true,
      message: 'Missing company name',
    };
  }

  if (!isCompany) {
    if (!firstName) {
      return {
        isError: true,
        message: 'Missing first name',
      };
    }

    if (!lastNameOrCompanyName) {
      return {
        isError: true,
        message: 'Missing last name',
      };
    }
  }

  if (!taxId) {
    return {
      isError: true,
      message: 'Missing TIN',
    };
  }

  const isTinValid = validateTin(taxId);

  if (typeof isTinValid === 'string') {
    return {
      isError: true,
      message: 'Incorrect TIN',
    };
  }

  // if (!user.phone) {
  //   return {
  //     isError: true,
  //     message: 'Missing owner phone number',
  //   };
  // }

  return {
    isError: false,
    message: undefined,
  };
};
