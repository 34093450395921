import { type CalendarFilterDateType, Filter } from '@finalytic/components';
import { type gqlV2, useInfiniteQuery, useQuery } from '@finalytic/data';
import type { sourceOp_bool_exp, source_bool_exp } from '@finalytic/graphql';
import { InfiniteTable, type MRT_ColumnDef } from '@finalytic/table';
import type { SelectItem } from '@finalytic/ui';
import { day, toTitleCase } from '@finalytic/utils';
import { Box, Group, Text } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { useCallback, useMemo } from 'react';
import { AutomationActionStatusIcon } from '../../views/automations/components';
import type { WorkflowAction } from '../automation-history-drawer/_types';

type Props = {
  connectionId: string;
  tenantId: string;
};

type FilterState = {
  search: string;
  type: string | null;
  date: CalendarFilterDateType | undefined;
};

const initial: FilterState = {
  search: '',
  type: null,
  date: undefined,
};

export const ConnectionSyncedFromTable = (props: Props) => {
  const [filter, setFilter] = useSetState<FilterState>(initial);
  const resetFilter = useCallback(() => setFilter(initial), [setFilter]);

  const columns = useMemo<MRT_ColumnDef<WorkflowAction>[]>(
    () => [
      {
        accessorKey: 'uniqueRef',
        header: 'Title',
        maxSize: 100,

        Cell: ({ row }) => {
          const data = row.original;
          return (
            <Group wrap="nowrap" maw="100%" w="100%">
              <AutomationActionStatusIcon status={data.status} />
              <Box
                maw="100%"
                w="100%"
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                <Text component="p" m={0}>
                  {data.uniqueRef}
                </Text>
                {data.type && (
                  <Text component="p" m={0} color={'gray'}>
                    {data.type}
                  </Text>
                )}
              </Box>
            </Group>
          );
        },
      },
      {
        accessorKey: 'message',
        header: 'Message',
      },
      {
        accessorKey: 'createdAt',
        header: 'Actions',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        maxSize: 140,
        Cell: ({ row }) =>
          row.original.createdAt
            ? day(row.original.createdAt).format('MMM D, YYYY HH:mm')
            : '',
      },
    ],
    []
  );

  const infiniteData = useInfiniteQuery(
    (q, { connectionId, search, type, date, tenantId }, { limit, offset }) => {
      if (!connectionId)
        return {
          aggregate: 0,
          list: [],
        };

      const getDateFilter = ():
        | gqlV2.timestamptz_comparison_exp
        | undefined => {
        const start = date?.[0] ? day(date?.[0]).yyyymmdd() : undefined;
        const end = date?.[1] ? day(date?.[1]).yyyymmdd() : undefined;
        if (!start && !end) return undefined;

        if (!end) {
          return {
            _gte: start,
            _lt: day(start).add(1, 'day').yyyymmdd(),
          };
        }

        return {
          _gte: start,
          _lt: end,
        };
      };

      const where: sourceOp_bool_exp = {
        job: {
          tenantId: { _eq: tenantId },
          connectionId: { _eq: connectionId },
        },
        tenantId: { _eq: tenantId },
        createdAt: getDateFilter(),
        source: type
          ? {
              type: { _eq: type },
            }
          : undefined,
        _or: search
          ? [
              {
                source: {
                  _or: [
                    {
                      remoteId: { _ilike: `%${search}%` },
                    },
                    {
                      description: { _ilike: `%${search}%` },
                    },
                  ],
                },
              },
            ]
          : undefined,
      };

      const aggregate = q.aggregateSourceOps({ where }).aggregate?.count() || 0;

      const list = q
        .sourceOps({
          where,
          limit,
          offset,
          order_by: [{ createdAt: 'desc_nulls_last' }],
        })
        .map<WorkflowAction>((sourceOp) => {
          const uniqueRef = sourceOp.source.remoteId;

          return {
            id: sourceOp.id,
            jobId: sourceOp.jobId!,
            createdAt: sourceOp.createdAt,
            message: sourceOp.source?.description || '',
            status: sourceOp.kind,
            type: toTitleCase(sourceOp.source.type),
            syncSubtaskRef: undefined,
            title: uniqueRef,
            uniqueRef,
            externalHref: sourceOp.source?.dataHref,
            inputJson: undefined,
            outputJson: undefined,
            date: undefined,
            automationId: '',
            jobPlanId: '',
            links: { paymentIds: [], reservationIds: [] },
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        search: filter.search.trim(),
        type: filter.type,
        connectionId: props.connectionId,
        date: filter.date,
        tenantId: props.tenantId,
      },
      queryKey: 'actions',
    }
  );

  return (
    <InfiniteTable
      columns={columns}
      table={{
        // columns,
        hideHeader: true,
        emptyRowsFallback: 'Nothing was synced here.',
      }}
      queryData={infiniteData}
      resetFilter={resetFilter}
    >
      <Group>
        <Filter.Search
          value={filter.search}
          setValue={(s) => setFilter({ search: s })}
        />
        <TypeFilter
          {...props}
          type={filter.type}
          setType={(v) => setFilter({ type: v })}
        />
        <Filter.Date
          value={filter.date}
          setValue={(newDate) =>
            setFilter({
              date: newDate,
            })
          }
        />
      </Group>
    </InfiniteTable>
  );
};

const TypeFilter = ({
  connectionId,
  setType,
  tenantId,
  type,
}: Props & { type: string | null; setType: (v: string | null) => void }) => {
  const queryData = useQuery(
    (q, args) => {
      if (!args.connectionId || !args.tenantId)
        return {
          aggregate: 0,
          list: [],
        };

      const where: source_bool_exp = {
        connectionId: { _eq: args.connectionId },
        tenantId: { _eq: args.tenantId },
        type: { _is_null: false },
      };

      const list = q
        .source({
          where,
          order_by: [{ type: 'asc_nulls_last' }],
          distinct_on: ['type'],
        })
        .map<SelectItem>((source) => {
          return {
            value: source.type!,
            label: toTitleCase(source.type),
          };
        });

      return {
        list,
      };
    },
    {
      variables: {
        connectionId,
        tenantId,
      },
    }
  );

  const value = type ? { value: type, label: toTitleCase(type) } : null;

  return (
    <Filter.Select
      label="Type"
      type="single"
      value={value}
      setValue={(newValue) => {
        setType(newValue?.value || null);
      }}
      data={{
        options: queryData.data?.list ?? [],
        loading: queryData.isLoading,
        error: queryData.error,
      }}
    />
  );
};
