import { Icon } from '@finalytic/icons';
import type { ExtendedCustomColors } from '@finalytic/ui';
import { Box, Center, Text, Transition, useMantineTheme } from '@mantine/core';
import type { ReactNode } from 'react';
import { Button } from './button';

type Props = {
  mounted: boolean;

  type: 'error' | 'warning';
} & (
  | {
      children?: undefined;
      title: string;
      message: string;
      action?: {
        label: string;
        onClick: () => void;
      };
    }
  | {
      children: ReactNode;
      title?: undefined;
      message?: undefined;
      action?: undefined;
    }
);

export const Banner = ({
  mounted,
  children,
  type,
  action,
  message,
  title,
}: Props) => {
  const themeColor: ExtendedCustomColors = type === 'error' ? 'red' : 'orange';
  const { colors } = useMantineTheme();

  return (
    <Transition
      mounted={mounted}
      transition="fade"
      duration={400}
      timingFunction="ease"
    >
      {(styles) => (
        <Box
          mb="md"
          style={styles}
          sx={(theme) => ({
            backgroundColor: theme.colors[themeColor][0],
            border: `1px solid ${theme.colors[themeColor][3]}`,
            padding: theme.spacing.md,
            borderRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: theme.spacing.md,
            boxShadow: theme.shadows.sm,
            [`@media (max-width: ${theme.breakpoints.xs})`]: {
              flexDirection: 'column',
              alignItems: 'stretch',
              textAlign: 'center',
            },
          })}
        >
          {children ?? (
            <>
              <Center>
                <Icon
                  icon="AlertTriangleIcon"
                  size={30}
                  color={(theme) => theme.colors[themeColor][7]}
                />
              </Center>
              <Box sx={{ flex: 1 }}>
                {title && (
                  <Text size="lg" fw={500} c={colors[themeColor][7]}>
                    {title}
                  </Text>
                )}
                <Text size="sm" c="neutral" mb={children ? 'xs' : undefined}>
                  {message}
                </Text>
              </Box>
              {action && (
                <Button leftIcon="Edit3Icon" onClick={() => action.onClick()}>
                  {action.label}
                </Button>
              )}
            </>
          )}
        </Box>
      )}
    </Transition>
  );
};
