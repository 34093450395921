import {
  Button,
  ConfirmModal,
  InputDay,
  InputWrapper,
} from '@finalytic/components';
import {
  showApiErrorNotification,
  useApiMutation,
  useInvalidateQueries,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import {
  showLoadingNotification,
  showWarnNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { type Maybe, day } from '@finalytic/utils';
import { Box, Center, Group, Modal, Title } from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue, useId } from '@mantine/hooks';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ExpenseBankAccountInput } from '../../views/expenses/edit/_components';
import type { ExpenseRow } from '../../views/expenses/list/useExpenseTableQuery';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  expense: Maybe<ExpenseRow>;
}

export const ExpenseEllipsisMenuModals = ({
  deleteModal,
  paymentStatusModal,
  unreconcileModal,
  expense: initial,
}: {
  expense: ModalProps['expense'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  paymentStatusModal: {
    opened: boolean;
    closeModal: () => void;
  };
  unreconcileModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const expense = initial || debounced;

  return (
    <>
      <Delete {...deleteModal} expense={expense} />
      <PaymentStatusModal {...paymentStatusModal} expense={expense} />
      <Unreconcile {...unreconcileModal} expense={expense} />
    </>
  );
};

const Delete = ({ opened, closeModal, expense }: ModalProps) => {
  const expenseId = expense?.id;
  const notifyId = useId();

  const invalidate = useInvalidateQueries(['expenses', 'reservations']);

  const { mutateAsync: mutate, isPending: loading } = useApiMutation(
    'delete',
    '/transactions/{id}'
  );

  const submit = async () => {
    if (!expenseId) return showWarnNotification({ message: 'Missing expense' });

    showLoadingNotification({ id: notifyId });
    await mutate({
      params: {
        path: {
          id: expenseId,
        },
        query: {
          onLocked: 'archive',
        },
      },
    })
      .then((res) => {
        updateSuccessNotification({
          id: notifyId,
          title: 'Success!',
          message: `Expense was ${res.status} successfully.`,
        });
        closeModal();
        invalidate();
      })
      .catch((error: any) => {
        showApiErrorNotification({
          error,
          title: 'Failed to delete expense',
          defaultMessage:
            'We failed to delete the expense. Please try again later and if the problem persists, contact support.',
        });
      });
  };

  return (
    <ConfirmModal
      type="delete"
      opened={opened}
      closeModal={closeModal}
      loading={loading}
      onSubmit={submit}
      title={'Delete this expense?'}
      subtitle={
        'Are you sure you want to delete this expense? This action cannot be undone.'
      }
    />
  );
};

type FormInputs = {
  paidAt: string;
  accountId: (string & {}) | 'non-trust';
};

const PaymentStatusModal = ({ closeModal, opened, expense }: ModalProps) => {
  const methods = useForm<FormInputs>();

  const notifyId = useId();
  const invalidate = useInvalidateQueries(['expenses']);

  const { mutateAsync } = useApiMutation('put', '/transactions/{id}');

  const isPaid = expense?.paidStatus === 'paid';

  const submit = async (data: FormInputs) => {
    if (!expense) return showWarnNotification({ message: 'Missing expense' });

    showLoadingNotification({
      id: notifyId,
    });

    const accountId = data.accountId;

    await mutateAsync({
      params: {
        path: {
          id: expense.id,
        },
      },
      body: isPaid
        ? {
            accountId: null as any,
            payment: {
              date: null as any,
              status: 'unpaid',
            },
          }
        : {
            payment: {
              date: data.paidAt,
              status: 'paid',
            },
            accountId: accountId === 'non-trust' ? (null as any) : accountId,
          },
    })
      .then(() => {
        updateSuccessNotification({
          id: notifyId,
          title: 'Success!',
          message: 'Expense was updated successfully.',
        });
        invalidate();
        closeModal();
      })
      .catch((error: any) => {
        showApiErrorNotification({
          error,
          title: 'Failed to update expense',
          defaultMessage:
            'We failed to update the expense. Please try again and contact support if the issue persists.',
        });
      });
  };

  const title = `Mark as ${isPaid ? 'unpaid' : 'paid'}`;

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      centered
      title={isPaid ? undefined : title}
      styles={(theme) => ({
        title: {
          fontWeight: 500,
          fontSize: theme.fontSizes.md,
        },
      })}
      size={400}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.md,
        })}
      >
        {isPaid ? (
          <>
            <Center>
              <Icon
                icon="CircleDollarIcon"
                size={24}
                color={(theme) => theme.colors[theme.primaryColor][6]}
              />
            </Center>

            <Title order={3} mb="sm" ta="center">
              {title}
            </Title>
            <Text c="neutral" ta="center" size="sm">
              Reverting to unpaid will remove the payment date & the bank
              account from this expense.
            </Text>
          </>
        ) : (
          <FormProvider {...methods}>
            <Controller
              control={methods.control}
              name="paidAt"
              rules={{
                required: 'Date is required',
              }}
              render={({ field, fieldState }) => {
                const v = field.value ? day(field.value).toDate() : null;

                return (
                  <InputWrapper
                    label="Date when the expense was paid"
                    error={fieldState.error?.message}
                  >
                    <InputDay
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      required
                      value={v}
                      error={!!fieldState.error?.message}
                      placeholder={day().format('MMMM DD, YYYY')}
                      clearable
                      popoverProps={{
                        position: 'bottom-end',
                        withinPortal: true,
                      }}
                    />
                  </InputWrapper>
                );
              }}
            />
            <ExpenseBankAccountInput
              label="Bank/CC account"
              showRequired={false}
            />
          </FormProvider>
        )}

        <Group justify={isPaid ? 'stretch' : 'right'} mt="md" wrap="nowrap">
          <Button
            onClick={closeModal}
            disabled={methods.formState.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={methods.handleSubmit(submit, (err) => console.log(err))}
            loading={methods.formState.isSubmitting}
            sx={
              isPaid
                ? {
                    width: '100%',
                  }
                : undefined
            }
          >
            {title}
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

const Unreconcile = ({ opened, closeModal, expense }: ModalProps) => {
  const notifyId = useId();
  const bankRecordId = expense?.bankRecords?.[0]?.id;

  const invalidate = useInvalidateQueries(['expenses', 'bankRecords']);

  const { mutateAsync, isPending: loading } = useApiMutation(
    'put',
    '/bank-records/{id}'
  );

  const submit = async () => {
    if (!bankRecordId)
      return showWarnNotification({ message: 'Missing bank record' });

    showLoadingNotification({ id: notifyId });

    await mutateAsync({
      params: {
        path: {
          id: bankRecordId,
        },
      },
      body: {
        reconciledTransactionIds: null,
      },
    })
      .then(() => {
        updateSuccessNotification({
          id: notifyId,
          title: 'Success!',
          message: 'Expense was unmatched successfully.',
        });
        closeModal();
        invalidate();
      })
      .catch((error: any) => {
        showApiErrorNotification({
          id: notifyId,
          title: 'Failed to unmatch expense',
          defaultMessage:
            'We failed to unmatch the expense. Please try again later and if the problem persists, contact support.',
          error,
        });
      });
  };

  return (
    <ConfirmModal
      type="confirm"
      opened={opened}
      closeModal={closeModal}
      loading={loading}
      onSubmit={submit}
      title={'Are you sure you want to unmatch this expense?'}
      subtitle={
        'This action will remove the expense from the bank record and allow you to match it again.'
      }
    />
  );
};
