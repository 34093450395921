import { useAuth } from '@clerk/clerk-react';
import { useTrpcMutation, useTrpcQuery } from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import {
  CopyIcon,
  Edit3Icon,
  EmailIcon,
  EyeIcon,
  Link2Icon,
  RefreshCwIcon,
} from '@finalytic/icons';
import {
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  showSuccessNotification,
  useQueryParamsSet,
} from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { faUserMinus } from '@fortawesome/pro-solid-svg-icons';
import { Box, Tooltip } from '@mantine/core';
import type { getOwnerStatus } from '@vrplatform/ui-common';

type Props = {
  owner: {
    id: Maybe<string>;
    teamId: Maybe<string>;
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    companyName: Maybe<string>;
    email: Maybe<string>;
    status: ReturnType<typeof getOwnerStatus>['status'];
  };
  isReinvite: boolean;
  refetch: () => void;
};

function useOwnerHasPortalAccess({
  userId,
  teamId,
}: { userId: string; teamId: string }) {
  const { data, loading: loadingQuery } = useTrpcQuery(
    'fetchClerkUser',
    {
      teamId,
      userId: userId,
    },
    {
      skip: !userId || !teamId,
    }
  );

  const hasAccess = !!(
    (data?.publicMetadata?.allowed_ids || '') as string
  )?.includes(teamId);

  return {
    hasAccess,
    loadingQuery,
  };
}

export const UserAccessEllipsisMenuItems = ({
  isReinvite,
  owner: { id: userId, teamId, status },
  handlers: {
    setDeleteOpen,
    setInviteOpen,
    setRevokeAccessOpen,
    setCopyInviteOpen,
  },
}: Props & {
  handlers: {
    setInviteOpen: () => void;
    setCopyInviteOpen: () => void;
    setDeleteOpen: () => void;
    setRevokeAccessOpen: () => void;
  };
}) => {
  const setView = useQueryParamsSet({
    view: StringParam,
    owner: StringParam,
  });

  if (!userId || !teamId) return null;

  return (
    <>
      <EllipsisMenuItem
        onClick={() =>
          setView({
            view: 'edit',
          })
        }
        customIcon={<Edit3Icon size={18} />}
      >
        Edit user
      </EllipsisMenuItem>
      <EllipsisMenuDivider />

      <OwnerImpersonationEllipsisItem
        userId={userId}
        teamId={teamId}
        status={status}
      />

      <HiddenFeatureIndicator permission="partner-admin">
        <OwnerRevokeAccessMenuButton
          openModal={() => setRevokeAccessOpen()}
          userId={userId}
          teamId={teamId}
          status={status}
        />
      </HiddenFeatureIndicator>

      <EllipsisMenuDivider />
      <EllipsisMenuItem
        onClick={() => setInviteOpen()}
        customIcon={
          isReinvite ? <RefreshCwIcon size={18} /> : <EmailIcon size={18} />
        }
      >
        {isReinvite ? 'Resend email invitation' : 'Email invite to app'}
      </EllipsisMenuItem>

      {isReinvite && (
        <EllipsisMenuItem
          onClick={setCopyInviteOpen}
          customIcon={<Link2Icon size={18} />}
        >
          Copy invitation url
        </EllipsisMenuItem>
      )}

      <EllipsisMenuDivider />

      <EllipsisMenuDangerItem onClick={() => setDeleteOpen()}>
        Delete
      </EllipsisMenuDangerItem>

      <HiddenFeatureIndicator permission="super-admin">
        <EllipsisMenuItem
          customIcon={<CopyIcon size={18} />}
          disabled={!userId}
          onClick={() =>
            navigator.clipboard.writeText(`${userId}`).then(() =>
              showSuccessNotification({
                message: 'The ID was added to your clipboard.',
              })
            )
          }
        >
          Copy user ID
        </EllipsisMenuItem>
      </HiddenFeatureIndicator>
    </>
  );
};

type BaseMenuButtonProps = {
  userId: string;
  teamId: string;
  status: Props['owner']['status'];
};

interface OwnerRevokeAccessMenuButtonProps extends BaseMenuButtonProps {
  openModal: () => void;
}
const OwnerRevokeAccessMenuButton = ({
  openModal,
  userId,
  teamId,
}: OwnerRevokeAccessMenuButtonProps) => {
  const { hasAccess, loadingQuery } = useOwnerHasPortalAccess({
    userId,
    teamId,
  });

  return (
    <Tooltip
      disabled={hasAccess || loadingQuery}
      label="Owner does not currently have access to your team."
      withArrow
    >
      <Box>
        <EllipsisMenuItem
          onClick={openModal}
          icon={faUserMinus}
          loading={loadingQuery}
          disabled={!hasAccess}
        >
          Revoke Portal Access
        </EllipsisMenuItem>
      </Box>
    </Tooltip>
  );
};

const OwnerImpersonationEllipsisItem = ({
  userId,
  teamId,
  status,
}: BaseMenuButtonProps) => {
  const { signOut } = useAuth();

  const { hasAccess, loadingQuery } = useOwnerHasPortalAccess({
    userId,
    teamId,
  });

  const { mutate, loading } = useTrpcMutation('impersonateUser', {
    successMessage: {
      title: 'Owner Preview',
      message: 'Successfully created owner preview.',
    },
  });

  const createPreviewV2 = () =>
    mutate({ userId, tenantId: teamId }).then((res) => {
      signOut({
        redirectUrl: res.url,
      });
    });

  return (
    <Tooltip
      disabled={hasAccess || loadingQuery}
      label={
        status === 'unconfirmed'
          ? 'Owner invite is currently pending'
          : 'Owner has not been invited to the owner portal.'
      }
      withArrow
    >
      <Box>
        <EllipsisMenuItem
          customIcon={<EyeIcon size={18} />}
          onClick={createPreviewV2}
          loading={loading || loadingQuery}
          disabled={!hasAccess}
        >
          User Impersonation
        </EllipsisMenuItem>
      </Box>
    </Tooltip>
  );
};
