import { useInfiniteQuery, useQuery, useTeam } from '@finalytic/data';
import { AlertTriangleIcon } from '@finalytic/icons';
import type { SelectItem } from '@finalytic/ui';
import { type Maybe, ensure, isUUID } from '@finalytic/utils';
import { Center, Tooltip, useMantineTheme } from '@mantine/core';
import {
  getSourceDescription,
  getSourceNamespaceAndType,
  whereSources,
} from '@vrplatform/ui-common';

export function useAutomationMappingEditorSourcesQuery({
  sourceId,
  settingKey,
  search,
  customSkip,
  parentSettingSourceId,
  rightType,
  connectionId,
  rightParams,
}: {
  sourceId: string | undefined;
  parentSettingSourceId: Maybe<string>;
  settingKey: string | undefined;
  search: string | undefined;
  customSkip: boolean;
  rightType: string;
  rightParams: Record<string, any>;
  connectionId: string;
}) {
  const [{ id: teamId }] = useTeam();
  const { colors } = useMantineTheme();

  const trimmedSearch = search?.trim();
  const [, sourceType] = getSourceNamespaceAndType(rightType);

  const queryData = useInfiniteQuery(
    (q, { where }, { limit, offset }) => {
      const aggregate = q.sourceAggregate({ where })?.aggregate?.count() || 0;

      const list = q
        .source({
          where,
          order_by: [{ description: 'asc' }],
          limit,
          offset,
        })
        .map<SelectItem>((item) => ({
          label: getSourceDescription(item) || '',
          value: item.id,
        }));

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        where: Object.assign(
          whereSources({
            tenantId: teamId,
            connectionId,
            type: sourceType,
            search: trimmedSearch,
          }),
          rightParams?.where ?? {}
        ),
      },
      skip: customSkip || !teamId || !rightType || !settingKey,
      queryKey: 'sources',
    }
  );

  const { data: sourceById, isLoading: loadingSourceValue } = useQuery(
    (q, { sourceId }) => {
      let sourceById: (SelectItem & { isDeleted: boolean }) | undefined =
        undefined;

      if (sourceId && isUUID(sourceId)) {
        const source = q.sourceById({ id: sourceId });

        const isDeleted = ['deleted', 'archived'].includes(
          String(source.status)
        );

        sourceById = {
          label: getSourceDescription(source),
          value: source?.id,
          isDeleted,
        };
      }

      return {
        sourceById: sourceById?.value ? sourceById : undefined,
      };
    },
    {
      variables: {
        sourceId,
      },
      skip: customSkip || !teamId || !rightType || !settingKey,
      queryKey: 'sources',
    }
  );

  const { data: parentSource, isLoading: loading2 } = useQuery(
    (q, args) => {
      return q
        .source({
          where: {
            id: { _eq: args.parentSettingSourceId },
          },
          order_by: [{ description: 'asc_nulls_last' }],
          limit: 1,
        })
        .map((item) => ({
          label: getSourceDescription(item) || '',
          value: item.id,
        }))[0];
    },
    {
      skip: !parentSettingSourceId || !isUUID(parentSettingSourceId),
      keepPreviousData: true,
      queryKey: 'sources',
      variables: {
        parentSettingSourceId,
      },
    }
  );

  return {
    queryData,
    loadingParentSource: loading2,
    loadingSourceValue: loadingSourceValue,
    source: sourceById?.sourceById
      ? ensure<SelectItem>({
          ...sourceById.sourceById,
          icon: sourceById.sourceById.isDeleted ? (
            <Tooltip label="Item is deleted or archived" withinPortal withArrow>
              <Center h="100%">
                <AlertTriangleIcon color={colors.red[5]} size={18} />
              </Center>
            </Tooltip>
          ) : null,
        })
      : undefined,
    parentSource,
  };
}

// TYPES OF SETTINGS

// MAPPINGS
// ---
// 1. fallback
// target: *
// value: any
// key: mappingKey
// leftType: mapping.left.schema
// rightType: mapping.right.schema
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: null
// localAutomationId: automation.id | null

// 2. default exceptions
// target: any
// value: any
// key: mappingKey
// leftType: !== mapping.left.schema (finalytic.XXX)
// rightType: mapping.right.schema
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: null
// localAutomationId: automation.id | null

// 3. mappings
// target: any
// value: any
// key: mappingKey
// leftType: mapping.left.schema
// rightType: mapping.right.schema
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: null
// localAutomationId: automation.id | null

// 4. mapping child exceptions
// target: any
// value: any
// key: mappingKey
// leftType: !== mapping.left.schema (finalytic.XXX)
// rightType: mapping.right.schema
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: setting.id
// localAutomationId: automation.id | null

// AUTOMATION_SETTINGS
// ---
// 1. Global
// target: *
// value: any
// key: settingKey
// leftType: "automationSetting"
// rightType: null
// leftConnectionId: finalyticConnectionId
// rightConnectionId: accountingConnectionId
// parentSettingId: null

// 2. Local
// target: *
// value: any
// key: settingKey
// leftType: "automationSetting"
// rightType: null
// leftConnectionId: automation.leftConnectionId
// rightConnectionId: automation.rightConnectionId
// parentSettingId: null

// LISTING_DISABLE_SETTINGS
// ---
// target: listing.id
// value: "true"
// key: "exclude"
// leftType: "finalytic.listing"
// rightType: "schema.boolean"
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null
// automationId: // ! uses automationId as determinator currently

// PARTNER_LINE_MASTER
// ---
// 1. Accounting type
// target: lineClassification.id
// value: "invoice" | "journalEntry" | "exclude"
// group: "ximplifi" // ! uses legacy group
// key: "exclude"
// leftType: "finalytic.lineType"
// rightType: null
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null
// partner: partnerTeamName.toLowerCase()

// 2. Accounting type exceptions
// target: bookingChannel.id
// value: "invoice" | "journalEntry" | "exclude"
// group: "ximplifi" // ! uses legacy group
// key: "exclude"
// leftType: "finalytic.bookingChannel"
// rightType: "finalytic.accountingType"
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: setting.id
// partner: partnerTeamName.toLowerCase()

// 3. Description
// target: lineClassification.id
// value: description (TEXT)
// group: "ximplifi" // ! uses legacy group
// key: "exclude"
// leftType: "finalytic.lineType"
// rightType: null
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null
// partner: partnerTeamName.toLowerCase()

// TENANT_SETTINGS
// ---
// 1. Owner portal show reservations
// target: "showReservations"
// value: null
// key: "tenantSettings"
// group: "ownerPortal"
// leftType: "ownerPortal"
// rightType: null
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null

// 2. Owner portal show reservations
// target: "forceTwoFactorAuth"
// value: null
// key: "tenantSettings"
// group: "pmDashboard"
// leftType: "pmDashboard"
// rightType: null
// leftConnectionId: null
// rightConnectionId: null
// parentSettingId: null
