import { useQuery, useTeamId } from '@finalytic/data';
import { type Maybe, day, sortBy } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import {
  formatOwnerName,
  formatUserName,
  getListingName,
  getOwnerCompanyType,
  whereListingPeriod,
} from '@vrplatform/ui-common';

export function useOwnerDetailDrawerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .contacts({
            where: {
              id: { _eq: args.id },
              type: { _eq: 'owner' },
            },
          })
          .map((contact) => {
            const status = contact.status;
            const pmsStatus = contact.pmsStatus;

            const addressData = (contact.addressData() || null) as {
              line1: string;
              line2?: string;
              city: string;
              state?: string;
              postalCode: string;
              countryCode?: string;
              stateCode?: string;
              country?: string;
            } | null;

            const defaultPayoutAccountName =
              contact.tenant
                ?.accounts({
                  where: {
                    assignments: {
                      type: { _eq: 'transfer_ownerPayout' },
                      tenantId: { _eq: args.teamId },
                    },
                  },
                  limit: 1,
                })
                .map((x) => x.title)[0] ?? null;

            const currentPeriodMemberships = contact
              .listingPeriodMemberships({
                where: {
                  period: whereListingPeriod({
                    date: day().yyyymmdd(),
                  }),
                },
              })
              .map((member) => ({
                id: member.id,
                listingName: getListingName(member.period.listing),
                listingId: member.period.listingId,
                split: member.split as Maybe<number>,
              }));

            const getIsArchived = () => {
              if (status === 'inactive') {
                return true;
              }

              if (!status && pmsStatus === 'inactive') {
                return true;
              }

              return false;
            };

            return {
              id: contact.id,
              taxId: contact.taxId,
              status,
              currentPeriodMemberships,
              pmsStatus,
              isArchived: getIsArchived(),
              defaultAccountId: contact.defaultAccountId,
              tenantId: contact.tenantId,
              email: contact.email,
              phone: contact.phone,
              is1099PostalDelivery: contact.is1099PostalDelivery ?? true,
              type: contact.companyType ? 'company' : 'individual',
              companyType: getOwnerCompanyType(contact.companyType),
              name: formatOwnerName(contact),
              firstName: contact.firstName,
              lastName: contact.name,
              companyName: contact.name,
              teamId: contact.tenantId,
              payoutAccountName: contact.defaultAccount?.title,
              defaultPayoutAccountName,
              address: addressData
                ? {
                    line1: addressData.line1,
                    line2: addressData.line2,
                    city: addressData.city,
                    postcode: addressData.postalCode,
                    state: addressData.state,
                    stateCode: addressData.stateCode,
                    countryCode: undefined as string | undefined,
                    country: addressData.countryCode, // no country in addressData
                  }
                : {
                    line1: '',
                    line2: undefined,
                    city: '',
                    postcode: '',
                    state: undefined,
                    stateCode: undefined,
                    country: undefined,
                    countryCode: undefined,
                  },
              userAccesses: sortBy(
                contact
                  .userAccess({
                    order_by: [
                      {
                        user: {
                          firstName: 'asc_nulls_last',
                        },
                      },
                    ],
                    where: {
                      user: {
                        memberships: {
                          tenantId: { _eq: args.teamId },
                        },
                      },
                    },
                  })
                  .map((access) => {
                    return {
                      id: access.id,
                      userId: access.userId,
                      name: formatUserName(access.user),
                      email: access.user.email,
                      role: access.role,
                      status:
                        access.user.memberships({
                          where: {
                            tenantId: { _eq: args.teamId },
                          },
                          limit: 1,
                        })[0]?.status || 'inactive',
                    };
                  }),
                'name'
              ),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: ['owners', id || ''],
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type OwnerDetail = NonNullable<
  ReturnType<typeof useOwnerDetailDrawerQuery>['data']
>;
