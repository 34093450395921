import type { tenant as Tenant } from '@finalytic/graphql';
import { getCountryByIsoCode } from '../iso-3166';
import { type FormatAddressOptions, formatAddress } from './format-address';

// billing_address_data
// {
//   "city": "Cedar Key",
//   "full": "12619 Florida 24, Cedar Key, Florida, 32625, United States of America",
//   "line1": "12619 Florida 24",
//   "line2": "",
//   "state": "Florida",
//   "stateCode": "FL",
//   "postalCode": "32625",
//   "countryCode": "US"
// }

export const getTenantAddress = (
  tenant: Tenant,
  options?: FormatAddressOptions
) => {
  const json = tenant.billingAddressData() as {
    city: string;
    full: string;
    line1: string;
    line2: string;
    state: string;
    stateCode: string;
    postalCode: string;
    countryCode: string;
  } | null;

  const values: Parameters<typeof formatAddress>[0] = {
    line1:
      json?.line1 || tenant.billingAddress?.line || tenant.addressLine1 || '',
    line2: json?.line2 || tenant.billingAddress?.lineDetails || '',
    city: json?.city || tenant.billingAddress?.city || tenant.addressCity || '',
    postcode:
      json?.postalCode ||
      tenant.billingAddress?.postalCode ||
      tenant.addressPostcode ||
      '',
    state: json?.state || tenant.billingAddress?.state,
    stateCode: json?.stateCode || tenant.billingAddress?.stateCode,
    country:
      getCountryByIsoCode(json?.countryCode ?? '')?.name ||
      tenant.billingAddress?.country ||
      tenant.addressCountry,
    countryCode: json?.countryCode || tenant.billingAddress?.countryCode,
  };

  const full =
    formatAddress(values, options) ||
    tenant.billingAddress?.full ||
    tenant.address;

  return {
    values,
    full,
  };
};
