import { toTitleCase } from '@finalytic/utils';

export function formatPaymentLineType(lineType: string | null | undefined) {
  const type = lineType ?? '-';

  const hasApp = type.includes('_');
  const app = type.split('_')[0];
  const line = hasApp ? type.split('_')[1] : type;

  return {
    app: toTitleCase(app),
    line: toTitleCase(line),
    full: toTitleCase(type),
  };
}
