import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
} from '@finalytic/ui';
import { useJournalEntriesModal } from '../../modals';

export const TransferEllipsisMenuItems = ({
  transfer,
  openDeleteModal,
}: {
  transfer: {
    id: string;
  };
  openDeleteModal: () => void;
}) => {
  const { open: openJournalEntriesModal } = useJournalEntriesModal();

  return (
    <>
      <EllipsisMenuItem
        customIcon={<Icon icon="ListUnorderedIcon" size={16} />}
        onClick={() =>
          openJournalEntriesModal({
            transactionId: {
              _eq: transfer.id,
            },
          })
        }
      >
        Journal Entries
      </EllipsisMenuItem>

      <EllipsisMenuDivider />

      <EllipsisMenuDangerItem onClick={openDeleteModal}>
        Delete
      </EllipsisMenuDangerItem>

      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuCopyItem value={transfer.id} />
      </HiddenFeatureIndicator>
    </>
  );
};
