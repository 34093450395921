import { Button, Text } from '@mantine/core';
import { type JSX, type RefObject, forwardRef } from 'react';
import { useLegacyColors } from '../../../hooks';
import { LegacyChevronIcon } from '../_icons';

type PopoverButtonProps = {
  Icon?: ({ invertColor }: { invertColor?: boolean }) => JSX.Element;
  title: string;
  onClick: () => void;
  isActive: boolean;
};

export const PopoverButton = forwardRef(
  ({ title, Icon, onClick, isActive, ...props }: PopoverButtonProps, ref) => {
    const { gray } = useLegacyColors();

    const textColor = isActive ? '#ffffff' : gray.dark;

    return (
      <Button
        ref={ref as RefObject<HTMLButtonElement>}
        variant="white"
        px={10}
        sx={(theme) => {
          return {
            backgroundColor: isActive
              ? theme.colors[theme.primaryColor][8]
              : '#fff',
            color: isActive ? '#ffffff' : theme.colors.dark[3],
            borderRadius: 10,
            height: '27px',
            border: '1px solid',
            transition: 'all 0.3s ease-in-out',
            borderColor: isActive
              ? theme.colors[theme.primaryColor][8]
              : `${gray.dark}20`,
            boxShadow: `0px 2px 2px ${theme.colors[theme.primaryColor][7]}10`,
            ':hover': {
              backgroundColor: isActive
                ? theme.colors[theme.primaryColor][8]
                : '#fff',
              boxShadow: `0px 2px 2px ${theme.colors[theme.primaryColor][7]}10`,
            },
            textTransform: 'capitalize',
          };
        }}
        onClick={onClick}
        {...props}
      >
        {Icon && <Icon invertColor={isActive} />}
        <Text component="span" size="xs" ml={6} mr={4}>
          {title}
        </Text>
        <LegacyChevronIcon color={textColor} />
      </Button>
    );
  }
);

PopoverButton.displayName = 'PopoverButton';
