import { IconButton } from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import { Icon, type IconDefinition } from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import { Drawer } from '@finalytic/ui';
import { type Maybe, day, formatCurrency, sum } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Title,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ExpenseInfoCard,
  ExpenseLineAmountHoverCard,
} from '../../views/expenses/_components';
import { getExpense } from '../../views/expenses/list/useExpenseTableQuery';
import { useListingDetailDrawer } from '../../views/listings/drawer';
import { DrawerCollapsableTable, DrawerHeader } from '../_components';
import { useReservationDetailDrawer } from '../reservation-drawers';
import { ExpenseEllipsisMenuItems } from './ExpenseEllipsisMenuItems';
import { ExpenseEllipsisMenuModals } from './ExpenseEllipsisMenuModals';
import { useExpenseDetailDrawer } from './useExpenseDetailDrawer';

function useExpenseQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .transactions({
            where: {
              id: { _eq: args.id },
              type: { _eq: 'expense' },
            },
            limit: 1,
          })
          .map((expense) =>
            getExpense(expense, {
              includeDetails: true,
            })
          )[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'expenses',
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Expense = ReturnType<typeof getExpense>;

export const ExpenseDetailDrawer = () => {
  const { opened, close, expenseId } = useExpenseDetailDrawer();
  const { isLoading, data: expense } = useExpenseQuery(expenseId);
  const goto = useNavigate();

  const [openedModal, setOpenedModal] = useState<
    'delete' | 'edit' | 'payment-status' | 'unreconcile' | null
  >(null);

  const closeModal = () => setOpenedModal(null);

  return (
    <>
      {expenseId && (
        <ExpenseEllipsisMenuModals
          expense={expense}
          deleteModal={{
            closeModal,
            opened: openedModal === 'delete',
          }}
          paymentStatusModal={{
            closeModal,
            opened: openedModal === 'payment-status',
          }}
          unreconcileModal={{
            closeModal,
            opened: openedModal === 'unreconcile',
          }}
        />
      )}

      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          loading={isLoading}
          type="Expense"
          title={
            expense && (
              <Group wrap="nowrap" gap="xs" mt={rem(5)}>
                <Center w={20}>
                  {expense.connection.logo ? (
                    <Avatar
                      src={expense.connection.logo}
                      size="sm"
                      styles={{
                        placeholder: { visibility: 'hidden' },
                      }}
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[2]}`,
                      })}
                    />
                  ) : (
                    <Icon icon="UserIcon" size={20} />
                  )}
                </Center>
                <Box>
                  <Title order={4} fw={500} component="p" m={0}>
                    {expense.connection.name || 'Manual expense'}
                  </Title>
                </Box>
              </Group>
            )
          }
          menuItems={
            expense && (
              <ExpenseEllipsisMenuItems
                expense={expense}
                openDeleteModal={() => setOpenedModal('delete')}
                openPaymentStatusModal={() => setOpenedModal('payment-status')}
                openUnreconcileModal={() => setOpenedModal('unreconcile')}
                isDrawer
              />
            )
          }
        >
          {expense && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                goto(`/expense/${expense.id}`);
              }}
              icon="ArrowRightCircleIcon"
              variant="outline"
              tooltip="Go to detail"
            />
          )}
        </DrawerHeader>
        {!expense && !isLoading ? (
          'No expense details found'
        ) : (
          <Content expense={expense} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const Content = ({
  expense,
  isLoading,
}: { expense: Maybe<Expense>; isLoading: boolean }) => {
  if (!expense) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <ExpenseInfoCard expense={expense} />

      <Lines
        lines={expense.lines}
        currency={expense.currency}
        centTotal={expense.centTotal}
      />

      {!!expense.bankRecords.length && (
        <BankRecords
          currency={expense.currency}
          rowData={expense.bankRecords}
        />
      )}

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

type ExpenseLine = Expense['lines'][number];

const Lines = ({
  lines,
  currency,
  centTotal,
}: { lines: ExpenseLine[]; currency: string; centTotal: number }) => {
  const { open: openReservation } = useReservationDetailDrawer();
  const { open: openListing } = useListingDetailDrawer();

  const columns = useMemo<MRT_ColumnDef<ExpenseLine>[]>(
    () => [
      {
        header: 'Icon',
        accessorKey: 'id',
        size: 40,
        maxSize: 0,
        minSize: 40,
        grow: false,
        Cell: ({ row }) => {
          const icon = useMemo<IconDefinition>(() => {
            if (row.original.reservation.id) return 'CalendarEventIcon';
            if (row.original.listing.id) return 'HomeIcon';

            return 'HashtagIcon';
          }, [row.original.reservation.id, row.original.listing.id]);

          return <Icon icon={icon} size={20} />;
        },
      },
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const reservation = row.original.reservation;
          const listing = row.original.listing;

          if (!reservation.id && !listing.id) {
            return row.original.description;
          }

          if (!reservation.id)
            return (
              <Box>
                <Text component="span" display="block" size="sm">
                  {row.original.listing.name}
                </Text>
                <Text component="span" display="block" size="xs" c="gray">
                  {row.original.description}
                </Text>
              </Box>
            );

          return (
            <Box>
              <Text component="span" display="block" size="sm">
                {reservation.guestName} - {reservation.confirmationCode}
              </Text>
              <Text component="span" display="block" size="xs" c="gray">
                {day(reservation.checkIn).format('ll')} -{' '}
                {day(reservation.checkOut).format('ll')}
                {reservation.guests && ` - ${reservation.guests} guests`}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Total',
        accessorKey: 'centTotal',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => {
          return (
            <ExpenseLineAmountHoverCard
              line={row.original}
              currency={currency}
            />
          );
        },
      },
    ],
    [currency]
  );

  return (
    <DrawerCollapsableTable
      title="Expense lines"
      rightSection={
        <Text fw={500} size="sm">
          {formatCurrency(centTotal / 100, currency)}
        </Text>
      }
      onRowClick={{
        handler: (row) => {
          if (row.original.reservation.id)
            return openReservation(
              row.original.reservation.id,
              'overview',
              'push'
            );

          if (row.original.listing.id)
            return openListing(row.original.listing.id, 'overview', 'push');
        },
        disabled: (row) =>
          !row.original.reservation.id && !row.original.listing.id,
      }}
      rowData={lines}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No lines available
          </Text>
        </Center>
      )}
    />
  );
};

const BankRecords = ({
  rowData = [],
  currency,
}: {
  rowData: Expense['bankRecords'];
  currency: string;
}) => {
  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  const columns = useMemo<
    MRT_ColumnDef<NonNullable<Expense['bankRecords']>[0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <Box>
              <Text size="sm">{data.description}</Text>
              {data.bankAccount && (
                <Text size="xs" c="gray">
                  {data.bankAccount}
                </Text>
              )}
            </Box>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );
          return (
            <Box>
              <Text size="sm" ta="right">
                {amount}
              </Text>
              {row.original.date && (
                <Text size="xs" c="gray" ta="right">
                  {day(row.original.date).format('MMM DD, YYYY')}
                </Text>
              )}
            </Box>
          );
        },
      },
    ],
    [currency]
  );

  return (
    <DrawerCollapsableTable
      title={'Matched bank records'}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      defaultOpened
      emptyRowsFallback={'No matched bank records found'}
    />
  );
};
