import { useApiMutation, useInvalidateQueries } from '@finalytic/data';
import {
  DeleteModal,
  showLoadingNotification,
  showWarnNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { useId } from 'react';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  transfer: Maybe<{
    id: string;
  }>;
}

export const TransferEllipsisMenuModals = ({
  deleteModal,
  transfer: initial,
}: {
  transfer: ModalProps['transfer'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const transfer = initial || debounced;

  return (
    <>
      <Delete {...deleteModal} transfer={transfer} />
    </>
  );
};

const Delete = ({ opened, closeModal, transfer }: ModalProps) => {
  const transferId = transfer?.id;
  const notifyId = useId();

  const invalidate = useInvalidateQueries(['transfers', 'reservations']);

  const { mutateAsync: mutate } = useApiMutation(
    'delete',
    '/transactions/{id}',
    {
      onSuccess: () => {
        invalidate();
      },
    }
  );

  const submit = async () => {
    if (!transferId)
      return showWarnNotification({ message: 'Missing transfer' });

    try {
      showLoadingNotification({ id: notifyId });
      const res = await mutate({
        params: {
          path: {
            id: transferId,
          },
          query: {
            onLocked: 'archive',
          },
        },
      });

      updateSuccessNotification({
        id: notifyId,
        title: 'Success!',
        message: `Transfer was ${res.status} successfully.`,
      });
    } catch (error: any) {
      const message =
        error?.message ||
        'We failed to delete the transfer. Please try again later and if the problem persists, contact support.';

      updateErrorNotification({
        id: notifyId,
        title: 'Failed to delete transfer',
        message,
      });
    }
  };

  return (
    <DeleteModal
      opened={opened}
      onClose={closeModal}
      onSubmit={submit}
      title={'Delete this transfer?'}
      subtitle={
        'Are you sure you want to delete this transfer? This action cannot be undone.'
      }
    />
  );
};
