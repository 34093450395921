import { Button } from '@finalytic/components';
import { useDashboard, useTeam } from '@finalytic/data';
import { type Maybe, formatCurrency } from '@finalytic/utils';
import {
  Box,
  type CardProps,
  Group,
  Card as MantineCard,
  Text,
} from '@mantine/core';
import type {
  HyperlineAPI,
  HyperlineEntities,
} from '@vrplatform/hyperline-client';
import {
  DEMO_TEST_PARTNER_TENANT_ID,
  VRP_TENANT_ID,
} from '@vrplatform/ui-common';
import { type ReactNode, useState } from 'react';
import { SettingsTitle } from '../_components';
import { SubscriptionCancellationModal } from './SubscriptionCancellationModal';
import { SubscriptionReinstateModal } from './SubscriptionReinstateModal';

export const BillingCard = ({
  children,
  sx,
  ...props
}: { children: ReactNode } & CardProps) => (
  <MantineCard
    withBorder
    padding="sm"
    radius="md"
    display="flex"
    sx={{ flexDirection: 'column', ...sx } as any}
    {...props}
  >
    {children}
  </MantineCard>
);

const CardWithAction = ({
  title,
  children,
  action,
}: {
  title: ReactNode;
  action?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <BillingCard>
      <Group justify="space-between" mb="sm" mih={40}>
        <SettingsTitle type="sub-heading" m={0}>
          {title}
        </SettingsTitle>
        {action}
      </Group>

      <Group
        justify="space-between"
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
          padding: theme.spacing.sm,
          borderRadius: theme.radius.md,
          flex: 1,
        })}
      >
        {children}
      </Group>
    </BillingCard>
  );
};

type Subscription = Awaited<ReturnType<HyperlineAPI['getSubscription']>>;
type Customer = Awaited<ReturnType<HyperlineAPI['getCustomer']>>;

export const BillingPlanCard = ({
  subscription,
  loadingQuery,
  refreshView,
  // customer,
}: {
  subscription: Maybe<Subscription>;
  customer: Maybe<Customer>;
  loadingQuery: boolean;
  refreshView: () => void;
}) => {
  const [{ partnerId }] = useTeam();
  const [openedModal, setOpenedModal] = useState<
    'cancel' | 'reinstate' | 'reactivate' | null
  >(null);
  const [dashboard] = useDashboard();

  const closeModal = () => setOpenedModal(null);

  const showSubscriptionModals =
    [DEMO_TEST_PARTNER_TENANT_ID, VRP_TENANT_ID].includes(partnerId) &&
    dashboard === 'propertyManager';

  return (
    <>
      <SubscriptionCancellationModal
        closeModal={closeModal}
        opened={openedModal === 'cancel'}
        refetch={refreshView}
      />
      <SubscriptionReinstateModal
        opened={openedModal === 'reinstate'}
        closeModal={closeModal}
        subscriptionId={subscription?.id}
        refetch={refreshView}
      />
      <CardWithAction
        title="Current Plan"
        action={
          showSubscriptionModals &&
          subscription && (
            <>
              {subscription.status === 'active' &&
                (!subscription.cancel_at ? (
                  <Button
                    onClick={() => setOpenedModal('cancel')}
                    loading={loadingQuery}
                    data-testid="cancel-subscription-modal-button"
                  >
                    Cancel subscription
                  </Button>
                ) : (
                  <Button
                    onClick={() => setOpenedModal('reinstate')}
                    loading={loadingQuery}
                    data-testid="reinstate-subscription-modal-button"
                  >
                    Revert cancellation
                  </Button>
                ))}
              {/* {subscription.status === 'cancelled' &&
                customer?.current_payment_method_id && (
                  <Button
                    loading={loadingQuery}
                    onClick={() => setOpenedModal('reactivate')}
                    data-testid="reactivate-subscription-modal-button"
                  >
                    Reactivate subscription
                  </Button>
                )} */}
            </>
          )
        }
      >
        {subscription ? (
          <Group justify="space-between" w="100%">
            <Box>
              <Text size="md" fw={500}>
                {subscription.plan?.name || 'Custom plan'}
              </Text>
              <Text size="xs" color="gray">
                Billed monthly
                {subscription.renews_at
                  ? ` ・ Renews at ${subscription.renews_at}`
                  : ''}
              </Text>
            </Box>
            <Box>
              <Text size="xl" fw={500} ta="right">
                {formatCurrency(
                  subscription.live_billing?.total_amount
                    ? subscription.live_billing.total_amount / 100
                    : 0,
                  subscription.currency
                )}
              </Text>
              <Text size="xs" color="gray" ta="right">
                Upcoming
              </Text>
            </Box>
          </Group>
        ) : (
          <Text size="sm" color="gray">
            No active subscription
          </Text>
        )}
      </CardWithAction>
    </>
  );
};

export const BillingPaymentMethodCard = ({
  paymentMethod,
  portalUrl,
}: {
  paymentMethod: HyperlineEntities['PaymentMethod'];
  portalUrl: Maybe<string>;
}) => {
  const title = 'Payment Method';

  if (!paymentMethod) {
    return (
      <CardWithAction
        title={title}
        action={<UpdatePaymentMethodButton url={portalUrl} />}
      >
        <Box>
          <Text size="sm" color="gray">
            No active payment method
          </Text>
        </Box>
      </CardWithAction>
    );
  }

  if (
    paymentMethod.type === 'direct_debit_ach' ||
    paymentMethod.type === 'direct_debit' ||
    paymentMethod.type === 'direct_debit_bacs'
  ) {
    return (
      <CardWithAction
        title={title}
        action={<UpdatePaymentMethodButton url={portalUrl} />}
      >
        <Group justify="space-between" w="100%">
          <Box>
            <Text size="md" fw={500}>
              Direct debit
            </Text>
            <Text size="xs" color="gray">
              Ending in {paymentMethod.account_number_ending}
            </Text>
          </Box>
        </Group>
      </CardWithAction>
    );
  }

  if (paymentMethod.type === 'card') {
    return (
      <CardWithAction
        title={title}
        action={<UpdatePaymentMethodButton url={portalUrl} />}
      >
        <Group justify="space-between" w="100%">
          <Box>
            <Text size="md" fw={500}>
              {paymentMethod.type === 'card' ? 'Credit card' : 'Direct debit'}
            </Text>
            <Text size="xs" color="gray">
              {`Ending in ${paymentMethod.last_4_digits} ・ Expires ${paymentMethod.expiration_date}`}
            </Text>
          </Box>
          {paymentMethod.type === 'card' && paymentMethod.brand === 'visa' && (
            <VisaLogo />
          )}
        </Group>
      </CardWithAction>
    );
  }

  return (
    <CardWithAction
      title={title}
      action={<UpdatePaymentMethodButton url={portalUrl} />}
    >
      <Text>Payment method: {paymentMethod.type}</Text>
    </CardWithAction>
  );
};

const UpdatePaymentMethodButton = ({
  url,
}: {
  url: Maybe<string>;
}) => {
  return (
    <Button component="a" href={url || ''} disabled={!url}>
      Update
    </Button>
  );
};

const VisaLogo = () => (
  <svg
    width="53"
    height="33"
    viewBox="0 0 53 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.88 0.5H2.12C0.949156 0.5 0 1.45513 0 2.63333V30.3667C0 31.5449 0.949156 32.5 2.12 32.5H50.88C52.0508 32.5 53 31.5449 53 30.3667V2.63333C53 1.45513 52.0508 0.5 50.88 0.5Z"
      fill="#25459A"
    />
    <path
      d="M26.5708 14.5774C26.5509 16.0632 27.9731 16.8924 29.0445 17.3854C30.1453 17.8913 30.515 18.2156 30.5108 18.6679C30.5024 19.3602 29.6327 19.6657 28.8187 19.6776C27.3985 19.6985 26.5729 19.3156 25.9164 19.026L25.4049 21.2865C26.0635 21.5731 27.283 21.8231 28.5477 21.834C31.5161 21.834 33.4582 20.4503 33.4687 18.3049C33.4803 15.5822 29.4804 15.4314 29.5077 14.2144C29.5172 13.8454 29.8901 13.4516 30.7073 13.3514C31.1117 13.3008 32.2282 13.2622 33.4939 13.8127L33.9908 11.6256C33.3101 11.3915 32.4351 11.1673 31.3459 11.1673C28.5519 11.1673 26.5866 12.5698 26.5708 14.5774ZM38.7648 11.3558C38.2228 11.3558 37.7659 11.6543 37.5621 12.1126L33.3217 21.6733H36.288L36.8783 20.1329H40.5032L40.8456 21.6733H43.46L41.1786 11.3558H38.7648ZM39.1797 14.143L40.0357 18.0172H37.6913L39.1797 14.143ZM22.9743 11.3558L20.6361 21.6733L23.4627 21.6733L25.7998 11.3558H22.9743ZM18.7927 11.3558L15.8506 18.3783L14.6605 12.4072C14.5208 11.7406 13.9693 11.3558 13.357 11.3558H8.54724L8.48001 11.6553C9.46738 11.8577 10.5892 12.184 11.2688 12.5331C11.6847 12.7464 11.8034 12.9329 11.94 13.4397L14.1941 21.6733H17.1814L21.7611 11.3558H18.7927Z"
      fill="white"
    />
  </svg>
);
