import {
  Button,
  Input,
  InputAmount,
  InputSelect,
  InputWrapper,
} from '@finalytic/components';
import { useInfiniteQuery, useQuery, useTeamId } from '@finalytic/data';
import type { SelectItem } from '@finalytic/ui';
import { isUUID } from '@finalytic/utils';
import { Box, Group, Modal } from '@mantine/core';
import { whereRecurringFees } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FEE_TYPES } from '../../../views/fees/overview/_components';
import { getFeeTypeRouteFromRecurringFeeEnum } from '../../../views/fees/overview/_utils';
import {
  type ReservationFeeAddInputs,
  useReservationFeeMutation,
} from '../_hooks';

const trapSpacesForRequiredFields = (value: string) =>
  !!value.trim() || 'This field is required';

type Props = {
  reservationId: string;
  closeModal: () => void;
  recurringFee?: ReservationFeeAddInputs | null;
  zIndex?: number;
};

export const ReservationFeeAddModal = ({
  reservationId,
  closeModal: cl,
  recurringFee: initial,
  zIndex,
}: Props) => {
  const opened = !!initial;

  const methods = useForm<ReservationFeeAddInputs>({
    values: initial
      ? {
          centTotal: initial.centTotal,
          description: initial.description,
          recurringFeeId: initial.recurringFeeId,
        }
      : undefined,
  });

  const closeModal = () => {
    cl();
    methods.reset({});
  };

  const { submit } = useReservationFeeMutation(reservationId, closeModal);

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      title={'Add fee adjustment'}
      styles={{
        title: { fontWeight: 500 },
      }}
      zIndex={zIndex}
      centered
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.md,
        })}
      >
        <Controller
          control={methods.control}
          rules={{
            required: 'Please select a recurring fee',
          }}
          name="recurringFeeId"
          render={({ field, fieldState }) => {
            const [teamId] = useTeamId();
            const [search, setSearch] = useState('');
            const [newValue, setNewValue] = useState<
              SelectItem | undefined | null
            >(undefined);

            const queryData = useInfiniteQuery(
              (q, args, { limit, offset }) => {
                const where = whereRecurringFees({
                  tenantId: args.teamId,
                  search: args.search,
                });

                const aggregate =
                  q
                    .recurringFeeAggregate({
                      where,
                    })
                    ?.aggregate?.count() || 0;

                const list = q
                  .recurringFees({
                    where,
                    limit,
                    offset,
                    order_by: [
                      {
                        type: 'asc_nulls_last',
                      },
                      {
                        title: 'asc_nulls_last',
                      },
                    ],
                  })
                  .map<SelectItem>((fee) => {
                    return {
                      label: fee.title || '-',
                      value: fee.id,
                      group:
                        FEE_TYPES[
                          getFeeTypeRouteFromRecurringFeeEnum(
                            fee.type ?? 'additionalFee'
                          )
                        ].title,
                    };
                  });

                return {
                  aggregate,
                  list,
                };
              },
              {
                queryKey: ['customFees'],
                variables: {
                  search: search?.trim(),
                  teamId,
                },
              }
            );

            const initialQuery = useQuery(
              (q, args) => {
                if (!args.initialValue || !isUUID(args.initialValue))
                  return null;

                return (
                  q
                    .recurringFees({
                      where: {
                        id: {
                          _eq: args.initialValue,
                        },
                      },
                      limit: 1,
                    })
                    .map<SelectItem>((fee) => {
                      return {
                        label: fee.title || '-',
                        value: fee.id,
                      };
                    })[0] ?? null
                );
              },
              {
                queryKey: ['customFees'],
                variables: {
                  initialValue: initial?.recurringFeeId,
                },
              }
            );

            const value = useMemo(() => {
              if (!field.value) return null;

              if (newValue !== undefined) return newValue;

              return initialQuery.data ?? null;
            }, [field.value, initialQuery.data, newValue]);

            return (
              <InputWrapper
                label="Fee & Commission"
                error={fieldState.error?.message}
              >
                <InputSelect
                  value={value}
                  setValue={(value) => {
                    field.onChange(value?.value);
                    setNewValue(value);
                  }}
                  type="single"
                  inputProps={{
                    error: !!fieldState.error,
                    placeholder: 'Select fee',
                    withClearButton: true,
                    loadingQuery: initialQuery.isLoading,
                    disabled: !!initial?.recurringFeeId,
                  }}
                  infiniteData={{ ...queryData, setSearch }}
                />
              </InputWrapper>
            );
          }}
        />

        <Controller
          control={methods.control}
          rules={{
            required: 'Description is required',
            validate: trapSpacesForRequiredFields,
          }}
          name="description"
          render={({ field, fieldState }) => (
            <InputWrapper label="Description" error={fieldState.error?.message}>
              <Input
                value={field.value}
                setValue={field.onChange}
                error={!!fieldState.error}
                placeholder={'Fee Adjustment'}
              />
            </InputWrapper>
          )}
        />

        <Controller
          control={methods.control}
          rules={{
            validate: (value) => {
              if (typeof value !== 'number') {
                return 'Please enter a number';
              }

              if (value === 0) {
                return 'Please enter a non-zero amount';
              }

              return true;
            },
          }}
          name="centTotal"
          render={({ field, fieldState }) => (
            <InputWrapper label="Amount" error={fieldState.error?.message}>
              <InputAmount
                value={
                  field.value === undefined
                    ? ''
                    : Number(field.value || '0') / 100
                }
                setValue={(value) => field.onChange(Number(value || 0) * 100)}
                decimalScale={2}
                error={!!fieldState.error?.message}
                placeholder="0.00"
                leftSection={<span>$</span>}
              />
            </InputWrapper>
          )}
        />

        <Group justify="right" mt="md">
          <Button
            onClick={closeModal}
            disabled={methods.formState.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={methods.handleSubmit(
              async (data) => await submit({ data, type: 'add' }),
              (err) => console.log(err)
            )}
            loading={methods.formState.isSubmitting}
          >
            Add adjustment
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};
