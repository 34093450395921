import { Tag } from '@finalytic/components';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Edit3Icon, Icon } from '@finalytic/icons';
import {
  InfiniteTable,
  type MRT_ColumnDef,
  type MRT_GroupingState,
  type MRT_SortingState,
} from '@finalytic/table';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
} from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Anchor, Box, Group, Text, Tooltip, rem } from '@mantine/core';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAccountsConfig } from '../useAccountsConfig';
import { AccountEllipsisMenuModals } from './AccountEllipsisMenuModals';
import { AccountsFilter, useAccountsFilter } from './AccountsFilter';
import { TeamCurrencySelect } from './TeamCurrencySelect';
import {
  type AccountRow,
  useAccountsTableQuery,
} from './useAccountsTableQuery';

export const AccountsTable = () => {
  const { isMasterList } = useAccountsConfig();

  const { reset } = useAccountsFilter();

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'uniqueRef', desc: false },
  ]);
  const [groupBy, setGroupBy] = useState<MRT_GroupingState>([]);

  const [opened, setOpened] = useState<{
    account: AccountRow;
    modal: 'edit' | 'delete' | 'archive';
  } | null>(null);

  const queryData = useAccountsTableQuery({
    sorting,
  });

  const columns = useMemo<MRT_ColumnDef<AccountRow>[]>(
    () => [
      {
        header: 'Code',
        accessorKey: 'uniqueRef',
        enableSorting: true,
        enableColumnDragging: false,
        Header: '',
        maxSize: 0,
        minSize: 80,
        size: 80,
      },
      {
        header: 'Accounts',
        accessorKey: 'title',
        enableSorting: true,
        enableColumnDragging: false,
        AggregatedCell: ({ row, table }) => {
          const grouping = table.getState().grouping[0];
          if (!grouping) return null;

          const data = row.original;
          const group = grouping as keyof typeof data;

          if (
            group === 'assignments' ||
            group === 'offsetAccount' ||
            group === 'bankAccount'
          )
            return null;

          return (
            <Text component="p" m={0} size="sm" fw={500}>
              {toTitleCase(data[group])} ({row.subRows?.length || 0})
            </Text>
          );
        },
        Cell: ({ row }) => {
          const data = row.original;
          const isBankAccount = !!data.bankAccount?.id;
          const bankAccountLink = `/reconciliation/account/${data.bankAccount?.id}`;
          if (!data.offsetAccount)
            return isBankAccount ? (
              <Tooltip label={data.bankAccount?.name}>
                <Anchor component={Link} to={bankAccountLink}>
                  {data.title}
                </Anchor>
              </Tooltip>
            ) : (
              data.title
            );

          return (
            <Box>
              {isBankAccount ? (
                <Tooltip label={data.bankAccount?.name}>
                  <Anchor component={Link} to={bankAccountLink}>
                    {data.title}
                  </Anchor>
                </Tooltip>
              ) : (
                <Text>{data.title}</Text>
              )}
              <Text size="xs" c="gray">
                Offset: {data.offsetAccount.title}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        maxSize: 0,
        minSize: 200,
        size: 200,
        enableSorting: true,
        enableGrouping: true,
        enableColumnDragging: false,
        Cell: ({ row }) => toTitleCase(row.original.type),
      },
      {
        header: 'Classification',
        accessorKey: 'classification',
        maxSize: 0,
        minSize: 200,
        size: 200,
        enableSorting: true,
        enableGrouping: true,
        enableColumnDragging: false,
        Cell: ({ row }) => toTitleCase(row.original.classification),
      },
      {
        header: 'Assignments',
        accessorKey: 'assignments',
        maxSize: 0,
        minSize: 200,
        size: 200,
        enableSorting: true,
        enableGrouping: true,
        enableColumnDragging: false,
        Cell: ({ row }) => {
          return (
            <Group gap={rem(5)}>
              {row.original.assignments.map((assignment) => (
                <Tag key={assignment.id}>{assignment.title}</Tag>
              ))}
            </Group>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <InfiniteTable
        columns={columns}
        table={{
          key: 'accounts',
          emptyRowsFallback: () => (
            <Text component="p" color="gray" ta="center" my="xl" m={0}>
              No accounts found
            </Text>
          ),
        }}
        queryData={queryData}
        sorting={{
          sorting,
          setSorting,
        }}
        rowMenu={{
          menuItems: ({ row }) => {
            const account = row.original;

            const allowDeleteOrArchive =
              ['recurringFee', 'bank'].includes(account.type || '') === false;

            const isArchived = account.status === 'inactive';

            return (
              <>
                <EllipsisMenuItem
                  customIcon={<Edit3Icon size={16} />}
                  onClick={() =>
                    setOpened({
                      account: row.original,
                      modal: 'edit',
                    })
                  }
                >
                  Edit
                </EllipsisMenuItem>
                {allowDeleteOrArchive ? (
                  <>
                    <EllipsisMenuDivider />

                    {!isMasterList && (
                      <EllipsisMenuItem
                        onClick={() =>
                          setOpened({
                            account: row.original,
                            modal: 'archive',
                          })
                        }
                        customIcon={
                          <Icon
                            icon={isArchived ? 'RefreshCwIcon' : 'ArchiveIcon'}
                            size={16}
                          />
                        }
                      >
                        {isArchived ? 'Enable' : 'Archive'}
                      </EllipsisMenuItem>
                    )}

                    <EllipsisMenuDangerItem
                      onClick={() =>
                        setOpened({
                          account: row.original,
                          modal: 'delete',
                        })
                      }
                    >
                      Delete
                    </EllipsisMenuDangerItem>

                    <HiddenFeatureIndicator permission="vrp-admin">
                      <EllipsisMenuCopyItem value={row.original.id} />
                    </HiddenFeatureIndicator>
                  </>
                ) : (
                  <HiddenFeatureIndicator permission="vrp-admin">
                    <EllipsisMenuDivider />
                    <EllipsisMenuDangerItem
                      onClick={() =>
                        setOpened({
                          account: row.original,
                          modal: 'delete',
                        })
                      }
                    >
                      Delete
                    </EllipsisMenuDangerItem>
                    <EllipsisMenuCopyItem value={row.original.id} />
                  </HiddenFeatureIndicator>
                )}
              </>
            );
          },
        }}
        groupBy={{
          groupBy,
          setGroupBy,
        }}
        resetFilter={reset}
      >
        <Group justify="space-between" flex={1}>
          <AccountsFilter />
          {!isMasterList && <TeamCurrencySelect />}
        </Group>
      </InfiniteTable>
      <AccountEllipsisMenuModals
        account={opened?.account || null}
        archiveModal={{
          opened: opened?.modal === 'archive',
          closeModal: () => setOpened(null),
        }}
        deleteModal={{
          opened: opened?.modal === 'delete',
          closeModal: () => setOpened(null),
        }}
        editModal={{
          opened: opened?.modal === 'edit',
          closeModal: () => setOpened(null),
        }}
      />
    </>
  );
};
