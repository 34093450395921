import { Badge, IconButton, InputSelect } from '@finalytic/components';
import {
  type gqlV2,
  useApiClient,
  useBrowserTracking,
  useMutation,
  useTrpcMutation,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import type {
  contactCompanyType_enum,
  contact_set_input,
} from '@finalytic/graphql';
import { Edit3Icon, EyeIcon, Icon } from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuCopyItem,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  type SelectItem,
  StringParam,
  showErrorNotification,
  showWarnNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import { type Maybe, ensure, utc } from '@finalytic/utils';
import { Box, Center, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import {
  formatPercentage,
  getCountryByIsoCode,
  getStateByIsoCode,
} from '@vrplatform/ui-common';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { OwnerUserStatusBadge } from '../../components';
import { LedgerStatementStatusBadge } from '../../views/statements/_components';
import { DrawerCollapsableTable, DrawerHeader } from '../_components';
import { useUserAccessDetailDrawer } from '../user-access-drawers/useUserAccessDetailDrawer';
import { OwnerEditForm } from './AddOwnerDrawer';
import { AddEntityToOwnerButton } from './_components/AddEntityToOwnerButton';
import { OwnerEllipsisMenuItems } from './_components/OwnerEllipsisMenuItems';
import { OwnerEllipsisMenuModals } from './_components/OwnerEllipsisMenuModals';
import { OwnerInfoCard } from './_components/OwnerInfoCard';
import { useOwnerDetailDrawer } from './useOwnerDetailDrawer';
import {
  type OwnerDetail,
  useOwnerDetailDrawerQuery,
} from './useOwnerDetailDrawerQuery';

export const OwnerDetailDrawer = () => {
  const { opened, close, ownerId, view, setView } = useOwnerDetailDrawer();
  const { track } = useBrowserTracking();

  const [modalOpened, setModalOpened] = useState<{
    modal: 'delete' | 'archive';
    owner: OwnerDetail;
  } | null>(null);

  const { isLoading, data: owner } = useOwnerDetailDrawerQuery(ownerId);

  const { mutate: mutateLegacyOwner } = useTrpcMutation('updateOwner', {
    invalidateQueryKeys: ['owners'],
  });

  const goto = useNavigate();

  // TODO: use API instead
  const { mutate } = useMutation(
    (q, input: contact_set_input) => {
      if (input.id) {
        return q.updateContact({
          pk_columns: {
            id: input.id,
          },
          _set: input,
        }).id as string;
      }

      return q.insertContact({
        object: input,
      }).id as string;
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );

  // TODO: remove legacy owner input
  const updateContact = async (
    data: Parameters<typeof mutateLegacyOwner>[0]['input'] & {
      companyType: Maybe<contactCompanyType_enum | 'individual'>;
      defaultAccountId: string | null;
    }
  ) => {
    const hasAddress = !!data.address?.line1?.trim();

    if (
      hasAddress &&
      (!data.address?.stateCode || !data.address?.countryCode)
    ) {
      return showWarnNotification({
        title: 'Validation error',
        message: 'Please select a country and state.',
      });
    }

    const country =
      (data.address?.countryCode &&
        getCountryByIsoCode(data.address?.countryCode)?.name) ||
      '';
    const state =
      (data.address?.countryCode &&
        data.address?.stateCode &&
        getStateByIsoCode(data.address?.stateCode, data.address?.countryCode)
          ?.name) ||
      '';

    await mutate({
      args: {
        id: owner?.id,
        tenantId: owner?.tenantId,
        firstName: data.firstName?.trim(),
        defaultAccountId: data.defaultAccountId || null,
        name: data.lastName?.trim(),
        email: data.email?.toLowerCase().trim(),
        phone: data.phone?.trim(),
        taxId: data.taxId?.trim(),
        companyType:
          data.companyType === 'individual' ? null : data.companyType,
        type: 'owner',
        addressData: hasAddress
          ? {
              city: data.address!.city.trim(),
              line1: data.address!.line1.trim(),
              line2: data.address!.line2?.trim(),
              stateCode: data.address!.stateCode,
              state,
              countryCode: data.address!.countryCode,
              country,
              postalCode: data.address!.postcode.trim(),
            }
          : null,
      },
    });
  };

  const closeModal = () => setModalOpened(null);

  const showMenuItems = view === 'overview' && owner;

  const resetView = useCallback(() => {
    const path = window.location.pathname;

    if (path.startsWith('/contact/')) {
      close();
    } else {
      setView('overview');
    }
  }, [close, setView]);

  return (
    <>
      {ownerId && (
        <OwnerEllipsisMenuModals
          owner={owner}
          deleteModal={{
            closeModal,
            opened: modalOpened?.modal === 'delete' && !!modalOpened?.owner,
          }}
          archiveModal={{
            closeModal,
            opened: modalOpened?.modal === 'archive' && !!modalOpened?.owner,
          }}
        />
      )}
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={
            <Group gap="xs" wrap="nowrap">
              <Icon
                icon={owner?.type === 'company' ? 'OfficeIcon' : 'UserIcon'}
                size={22}
                color="gray"
              />
              <Text size="xl" fw={500} component="h3" m={0}>
                {owner?.name || 'Missing name'}
              </Text>
            </Group>
          }
          type="Owner"
          loading={isLoading}
          menuItems={
            showMenuItems && (
              <OwnerEllipsisMenuItems
                openDeleteModal={() =>
                  setModalOpened({
                    modal: 'delete',
                    owner,
                  })
                }
                openArchiveModal={() =>
                  setModalOpened({
                    modal: 'archive',
                    owner,
                  })
                }
                ownerId={owner.id}
                isArchived={owner.isArchived}
              />
            )
          }
          containerSx={
            view === 'edit'
              ? {
                  marginBottom: 0,
                }
              : undefined
          }
        >
          {showMenuItems && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                goto(`/contact/${owner.id}`);
              }}
              variant="outline"
              icon="ArrowRightCircleIcon"
              tooltip="Go to detail"
            />
          )}
        </DrawerHeader>
        {!owner && !isLoading ? (
          'No owner found'
        ) : view === 'edit' && owner ? (
          <>
            <OwnerEditForm
              isOwnerAddModal={false}
              handleSubmit={async (values) => {
                if (!values.address.stateCode || !values.address.countryCode) {
                  return showErrorNotification({
                    message: 'Please select a country and state',
                    color: 'yellow',
                    icon: null,
                  });
                }

                const isIndividual = values.type === 'individual';

                const input: Parameters<typeof updateContact>[0] = {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phone: values.phone,
                  taxId: values.taxId,
                  type: isIndividual ? 'individual' : 'company',
                  companyType: isIndividual ? null : values.type,
                  defaultAccountId: values.defaultAccountId,
                  is1099PostalDelivery: values.is1099PostalDelivery,
                  address: !values.address.line1?.trim()
                    ? null
                    : {
                        line1: values.address.line1,
                        line2: values.address.line2 || '',
                        city: values.address.city,
                        postcode: values.address.postcode,
                        countryCode: values.address.countryCode,
                        stateCode: values.address.stateCode || '',
                        country:
                          getCountryByIsoCode(values.address.countryCode)
                            ?.name || '',
                        state:
                          getStateByIsoCode(
                            values.address.stateCode,
                            values.address.countryCode
                          )?.name || '',
                      },
                };

                await updateContact(input);
                track('owner_updated', {
                  tenantId: owner.teamId,
                  ownerId: owner.id,
                });
                resetView();
              }}
              initialValues={{
                firstName: owner.firstName || '',
                lastName: owner.lastName || '',
                is1099PostalDelivery: owner.is1099PostalDelivery,
                companyName: '', // legacy
                email: owner.email!,
                defaultAccountId: owner.defaultAccountId ?? null,
                phone: owner.phone || '',
                taxId: owner.taxId || '',
                type:
                  owner.type === 'individual'
                    ? 'individual'
                    : owner.companyType?.value || 'individual',
                address: owner.address,
                addUserOption: false,
              }}
              onReset={resetView}
              submitButtonLabel="Save changes"
              isEmailDisabled={false}
            />
          </>
        ) : (
          <Content owner={owner} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const Content = ({
  owner,
  isLoading,
}: { owner: Maybe<OwnerDetail>; isLoading: boolean }) => {
  if (!owner) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <OwnerInfoCard
        owner={{
          ...owner,
          companyType: owner.companyType?.label,
        }}
        defaultPayoutAccountName={owner.defaultPayoutAccountName}
        withPlaceholder
      />

      <UserAccesses userAccesses={owner.userAccesses} ownerId={owner.id} />

      <ListingPeriod memberships={owner.currentPeriodMemberships} />

      <OwnerStatements ownerId={owner.id} />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const ListingPeriod = ({
  memberships: rows,
}: { memberships: OwnerDetail['currentPeriodMemberships'] }) => {
  const setListing = useQueryParamSet('listing', StringParam);

  return (
    <DrawerCollapsableTable
      title="Current Ownerships"
      rightSection={null}
      rowData={rows}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const member = row.original;

            return (
              <Badge
                key={member.id}
                color="gray"
                leftIcon={
                  <Center>
                    <Icon
                      icon={'HomeIcon'}
                      size={14}
                      color={(theme) => theme.colors.gray[6]}
                    />
                  </Center>
                }
              >
                {member.listingName}
              </Badge>
            );
          },
        },
        {
          header: 'Split',
          accessorKey: 'split',
          maxSize: 150,
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            const data = row.original;
            return formatPercentage(data.split || 0, 'base-points');
          },
        },
      ]}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No listing ownerships
          </Text>
        </Center>
      )}
      onRowClick={{
        handler: (row) => setListing(row.original.listingId, 'push'),
      }}
    />
  );
};

const UserAccesses = ({
  userAccesses,
  ownerId,
}: { userAccesses: OwnerDetail['userAccesses']; ownerId: string }) => {
  const { open } = useUserAccessDetailDrawer();

  const columns = useMemo<MRT_ColumnDef<OwnerDetail['userAccesses'][number]>[]>(
    () => [
      {
        header: 'Listing',
        accessorKey: 'name',
        Cell: ({ row }) => {
          return (
            <Box>
              <Text component="span" display="block" size="sm">
                {row.original.name}{' '}
                <OwnerUserStatusBadge status={row.original.status} />
              </Text>
              <Text component="span" display="block" size="xs" c="gray">
                {row.original.email}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Role',
        accessorKey: 'role',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableHeadCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const [newRole, setNewRole] = useState<string>();

          const { mutate, loading } = useMutation(
            (
              q,
              args: {
                userAccessId: string;
                role: gqlV2.owner_user_access_role_enum;
              }
            ) => {
              return q.updateOwnerUserAccesses({
                pk_columns: {
                  id: args.userAccessId,
                },
                _set: {
                  role: args.role,
                },
              })?.role;
            }
          );

          const options = ensure<
            SelectItem<gqlV2.owner_user_access_role_enum>[]
          >([
            {
              label: 'Editor',
              value: 'admin',
              icon: <Edit3Icon size={16} />,
            },
            {
              label: 'Viewer',
              value: 'viewer',
              icon: <EyeIcon size={16} />,
            },
          ]);

          const value = newRole || row.original.role;
          const formatted = options.find((o) => o.value === value) || null;

          return (
            <Box w={130}>
              <InputSelect
                data={{
                  options,
                }}
                type="single"
                setValue={(value) => {
                  if (!value?.value) return;

                  mutate({
                    args: {
                      role: value.value as gqlV2.owner_user_access_role_enum,
                      userAccessId: row.original.id,
                    },
                  }).then((role) => setNewRole(role));
                }}
                value={formatted || null}
                inputProps={{
                  loadingMutation: loading,
                }}
                dropdownProps={{
                  withinPortal: true,
                  position: 'bottom-end',
                  width: 200,
                }}
              />
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title={'User Access'}
      rightSection={<AddEntityToOwnerButton ownerId={ownerId} type="user" />}
      rowData={userAccesses}
      columns={columns}
      onRowClick={{
        handler: (row) => open(row.original.userId, 'overview', 'push'),
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No users with access found
          </Text>
        </Center>
      )}
      rowMenu={{
        menuItems: ({ row }) => {
          const accessId = row.original.id;

          const { mutate, loading } = useMutation(
            (q, args: { accessId: string }) => {
              return q.deleteOwnerUserAccess({
                id: args.accessId,
              })?.id;
            },
            {
              invalidateQueryKeys: ['owners'],
            }
          );

          return (
            <>
              <EllipsisMenuDangerItem
                loading={loading}
                onClick={() =>
                  mutate({
                    args: {
                      accessId,
                    },
                  })
                }
              >
                Remove access
              </EllipsisMenuDangerItem>

              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuDivider />
                <EllipsisMenuCopyItem value={accessId}>
                  Copy user access ID
                </EllipsisMenuCopyItem>
              </HiddenFeatureIndicator>
            </>
          );
        },
      }}
    />
  );
};

const OwnerStatements = ({
  ownerId,
}: {
  ownerId: string;
}) => {
  const goto = useNavigate();

  const $api = useApiClient();

  const {
    data: ownerStatements = [],
    error,
    isLoading: isInitialLoading,
  } = useTanstackQuery({
    queryKey: ['owners', ownerId, 'ownerStatements'],
    queryFn: async () => {
      return await $api
        .GET('/statements', {
          params: {
            query: {
              ownerIds: ownerId,
              // status: '',
            },
          },
        })
        .then((res) => {
          if (res.error) throw new Error(res.error.message);

          return (res.data?.data || []).map((s) => ({
            ...s,
            id: s.id ?? s.uniqueRef,
          }));
        });
    },
  });

  const columns = useMemo<MRT_ColumnDef<(typeof ownerStatements)[number]>[]>(
    () => [
      {
        header: 'Owner',
        accessorKey: 'id',
        Cell: ({ row }) => {
          const statement = row.original;

          return (
            <Box>
              <Text component="span" display="block" size="sm">
                {utc(statement.startAt).format('MMM YYYY')}
              </Text>
              {statement.listing.name && (
                <Text component="span" display="block" size="xs" color="gray">
                  {statement.listing.name}
                </Text>
              )}
            </Box>
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        maxSize: 150,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const statement = row.original;

          return (
            <LedgerStatementStatusBadge disabled status={statement.status} />
          );
        },
      },
    ],
    []
  );

  return (
    <DrawerCollapsableTable
      title="Owner Statements"
      rightSection={null}
      rowData={error ? [] : ownerStatements}
      columns={columns}
      loading={isInitialLoading}
      defaultOpened={!!error || !!ownerStatements.length}
      onRowClick={{
        handler: ({ original }) => {
          original.ownership &&
            goto(
              `/statement/${original.ownership.id}?date=${utc(
                original.startAt
              ).format('YYYY-MM-01')}&currency=${original.currency}`
            );
        },
      }}
      emptyRowsFallback={() => {
        if (error) {
          return (
            <Center
              sx={{
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <Icon
                icon="AlertTriangleIcon"
                color={(theme) => theme.colors.red[6]}
                size={18}
              />
              <Text size="sm" c="red">
                {error.message}
              </Text>
            </Center>
          );
        }

        return (
          <Center>
            <Text size="sm" c="gray">
              No owner statements
            </Text>
          </Center>
        );
      }}
    />
  );
};
