import { Filter } from '@finalytic/components';
import {
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import type { listing_bool_exp } from '@finalytic/graphql';
import {
  ArrayParam,
  type SelectItem,
  StringParam,
  useQueryParams,
} from '@finalytic/ui';
import { day, hasValue } from '@finalytic/utils';
import { Box, Group } from '@mantine/core';
import { getListingName, whereListings } from '@vrplatform/ui-common';
import { type ComponentProps, useMemo, useState } from 'react';
import {
  useJournalEntriesModalFilter,
  useJournalEntriesModalWhere,
} from '../../modals';
import { JournalEntryAccountFilter } from './JournalEntryAccountFilter';
import { JournalEntryClassificationFilter } from './JournalEntryClassificationFilter';
import { JournalEntryPartyFilter } from './JournalEntryPartyFilter';
import { JournalEntrySubTypeFilter } from './JournalEntrySubTypeFilter';
import { JournalEntryTypeFilter } from './JournalEntryTypeFilter';

export const useGeneralLedgerDetailFilter = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    search: StringParam,
    listingId: StringParam,
    party: StringParam,
    type: StringParam,
    subType: ArrayParam,
    date: StringParam,
    status: StringParam,
    accountId: StringParam,
    accountClassification: ArrayParam,
  });

  const { filter: state, setFilter: setState } = useJournalEntriesModalFilter();
  const where = useJournalEntriesModalWhere();
  const isModalOpened = !!where;

  const isFiltered = isModalOpened
    ? Object.values((state as object) ?? {}).some(hasValue)
    : Object.values(queryParams).some(hasValue);

  return {
    filter: isModalOpened ? (state as typeof queryParams) : queryParams,
    isFiltered,
    setFilter: (filter: Partial<typeof state>) => {
      if (isModalOpened) {
        setState(filter);
      } else {
        setQueryParams(filter as Partial<typeof queryParams>);
      }
    },
    reset: () => {
      const initial = {
        search: undefined,
        listingId: undefined,
        party: undefined,
        date: undefined,
        type: undefined,
        subType: undefined,
        accountClassification: undefined,
        accountId: undefined,
        status: undefined,
      };

      if (isModalOpened) {
        setState(initial);
      } else {
        setQueryParams(initial);
      }
    },
  };
};

type Props = {
  hide?: ('classification' | 'type' | 'status' | 'party' | 'subType')[];
};

export const GeneralLedgerDetailFilter = ({
  hide,
  view,
}: Props & ComponentProps<typeof Account>) => {
  return (
    <Group>
      <Search />
      {!hide?.includes('status') && <Status />}
      <Date />
      <Listing />
      <Account view={view} />
      {!hide?.includes('classification') && <AccountClassificationFilter />}
      {!hide?.includes('party') && <Party />}
      {!hide?.includes('type') && <Type />}
      {!hide?.includes('subType') && <SubType />}
    </Group>
  );
};

const Search = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const Status = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  const options = useMemo<SelectItem<'draft' | 'published'>[]>(
    () => [
      {
        label: 'Draft',
        value: 'draft',
        description:
          'Entries that have not been yet attached to an owner statement.',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
      {
        label: 'Published',
        value: 'published',
        description:
          'Entries have been locked & attached to an owner statement.',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.green[6],
            })}
          />
        ),
      },
    ],
    []
  );

  console.log(filter.status);

  const value = options.find((i) => i.value === filter.status) || null;

  return (
    <Filter.Select
      value={value}
      label="Status"
      setValue={(v) =>
        setFilter({
          status: v?.value,
        })
      }
      type="single"
      data={{
        options,
      }}
      hideIcon
      popoverWidth={300}
    />
  );
};

const Type = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <JournalEntryTypeFilter
      value={filter.type}
      setValue={(v) => setFilter({ type: v })}
    />
  );
};

const SubType = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <JournalEntrySubTypeFilter
      value={filter.subType}
      setValue={(v) => setFilter({ subType: v })}
    />
  );
};

const Party = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <JournalEntryPartyFilter
      value={filter.party}
      setValue={(v) => setFilter({ party: v })}
    />
  );
};

const Date = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const Listing = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useGeneralLedgerDetailFilter();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, search, meId, GL }, { limit, offset }) => {
      const where: listing_bool_exp = whereListings({
        tenantId: teamId,
        dashboard: 'propertyManager',
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      variables: {
        teamId,
        search: search?.trim(),
        meId,
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { listingId }) => {
      if (!listingId) return null;

      const value =
        q
          .listings({ where: { id: { _eq: listingId } } })
          .map<SelectItem>((item) => ({
            label: getListingName(item),
            value: item.id,
          }))[0] || null;

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      keepPreviousData: true,
      variables: {
        listingId: filter.listingId,
      },
    }
  );

  const value = data?.value || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingId: v?.value })}
      type="single"
      label="Listing"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const Account = ({
  view,
}: {
  view: ComponentProps<typeof JournalEntryAccountFilter>['view'];
}) => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <JournalEntryAccountFilter
      value={filter.accountId}
      setValue={(v) => setFilter({ accountId: v })}
      view={view}
    />
  );
};

const AccountClassificationFilter = () => {
  const { filter, setFilter } = useGeneralLedgerDetailFilter();

  return (
    <JournalEntryClassificationFilter
      value={filter.accountClassification?.filter(hasValue)}
      setValue={(v) => setFilter({ accountClassification: v })}
    />
  );
};
