import type {
  bigint_comparison_exp,
  payment_bool_exp,
} from '@finalytic/graphql';
import { day, ensure } from '@finalytic/utils';
import { z } from 'zod';
import { formatStringToAmountFilter } from '../utils';
import { whereConnectionStatusDefault } from './whereConnectionStatusDefault';

export const paymentsFilterInput = {
  date: z.tuple([z.date().nullable(), z.date().nullable()]).optional(),
  amount: z.string().optional(),
  connectionName: z.string().optional(),
  connectionApp: z.string().optional(),
  type2: z.array(z.string()).optional(),
  search: z.string().optional(),
  listingIds: z.array(z.string()).optional(),
  listingCollectionId: z.string().optional(),
  automationStatus: z.string().optional(),
  tenantId: z.string(),
};

const paymentsFilter = z.object(paymentsFilterInput);

export type PaymentsFilterParams = z.infer<typeof paymentsFilter>;

export function wherePayments({
  date,
  amount,
  connectionApp,
  connectionName,
  type2,
  search: s,
  listingIds,
  automationStatus,
  listingCollectionId,
  tenantId,
}: PaymentsFilterParams) {
  const search = s?.trim();
  const searchQuery = search
    ? ensure<payment_bool_exp[]>([
        {
          uniqueRef: { _ilike: `%${search}%` },
        },
        {
          lines: {
            _or: [
              {
                reservation: { guestName: { _ilike: `%${search}%` } },
              },
              {
                reservation: { confirmationCode: { _ilike: `%${search}%` } },
              },
              {
                reservation: { pmsReferenceCode: { _ilike: `%${search}%` } },
              },
              {
                reservation: { uniqueRef: { _ilike: `%${search}%` } },
              },
            ],
          },
        },
        {
          lines: {
            description: { _ilike: `%${search}%` },
          },
        },
      ])
    : undefined;

  const dateIsActive = Array.isArray(date) && !!date[0];

  const format = 'YYYY-MM-DDTHH:mm:ss';

  const start = date?.[0]
    ? day(date[0]).startOf('day').format(format)
    : undefined;

  const end = date?.[1] ? day(date[1]).endOf('day').format(format) : undefined;

  const formattedAmount = formatStringToAmountFilter(amount as any);

  return ensure<payment_bool_exp>({
    tenantId: { _eq: tenantId },
    status: { _neq: 'cancelled' },
    type: { _in: ['payout', 'custom', 'pmsPayout'] },
    actionLinks: automationStatus
      ? {
          action: {
            isCurrent: {
              _eq: true,
            },
            status: {
              _eq: automationStatus,
            },
          },
        }
      : undefined,
    payedAt: dateIsActive
      ? {
          _gte: start,
          _lte: end || day(start).endOf('day').format(format),
          _is_null: false,
        }
      : { _is_null: false },
    centTotal: formattedAmount
      ? ((): bigint_comparison_exp => {
          if (formattedAmount.type !== 'between') {
            return {
              _eq:
                formattedAmount.type === 'eq'
                  ? formattedAmount.centTotal
                  : undefined,
              _lte:
                formattedAmount.type === 'lt'
                  ? formattedAmount.centTotal
                  : undefined,
              _gte:
                formattedAmount.type === 'gt'
                  ? formattedAmount.centTotal
                  : undefined,
            };
          }

          return {
            _gte: formattedAmount.gtCentTotal,
            _lte: formattedAmount.ltCentTotal,
          };
        })()
      : undefined,
    connection:
      connectionName || connectionApp
        ? {
            id: connectionName ? { _eq: connectionName } : undefined,
            app: connectionApp ? { id: { _eq: connectionApp } } : undefined,
            status: whereConnectionStatusDefault,
          }
        : { status: whereConnectionStatusDefault },
    lines:
      type2?.length || listingIds?.length || listingCollectionId
        ? {
            type2: type2?.length
              ? {
                  _in: type2,
                }
              : undefined,
            reservation:
              listingIds?.length || listingCollectionId
                ? {
                    listingConnection: {
                      listingId: listingIds?.length
                        ? { _in: listingIds }
                        : undefined,
                      listing: listingCollectionId
                        ? { collectionId: { _eq: listingCollectionId } }
                        : undefined,
                    },
                  }
                : undefined,
          }
        : undefined,
    _or: search ? searchQuery : undefined,
  });
}
