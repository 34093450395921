import { Filter } from '@finalytic/components';
import type { journalEntryType_enum } from '@finalytic/graphql';
import type { SelectItem } from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { useMemo } from 'react';

type Props = {
  value: Maybe<(string | null)[]>;
  setValue: (value: Maybe<journalEntryType_enum[]>) => void;
};

export const JournalEntrySubTypeFilter = ({
  setValue,
  value: initial,
}: Props) => {
  const options = useMemo<SelectItem<journalEntryType_enum>[]>(() => {
    const opts: Record<journalEntryType_enum, string> = {
      reservation: 'Reservation',
      reservation_line: 'Reservation Line',
      reservation_adjustment: 'Reservation Adjustment',
      reservation_adjustment_cancellation:
        'Reservation Cancellation Adjustment',
      reservation_fee: 'Reservation Fee',
      reservation_fee_adjustment: 'Reservation Fee Adjustment',
      transaction_deposit: 'Deposit',
      transaction_deposit_channelFee: 'Deposit Channel Fee',
      transaction_deposit_line: 'Deposit Line',
      transaction_deposit_merchantFee: 'Deposit Merchant Fee',
      transaction_deposit_refund: 'Deposit Refund',
      transaction_deposit_openingBalance: 'Deposit Opening Balance',
      transaction_deposit_reserve: 'Deposit Reserve',
      transaction_expense: 'Expense',
      transaction_expense_line: 'Expense Line',
      transaction_expense_markup: 'Expense Markup',
      transaction_expense_markup_tax: 'Expense Markup Tax',
      transaction_transfer: 'Transfer',
      transaction_transfer_line: 'Transfer Line',
      reservation_fee_tax: 'Reservation Fee Tax',
      transaction_expense_payment: 'Expense Payment',
      transaction_deposit_vat: 'Deposit VAT',
    };

    return Object.entries(opts).map<SelectItem<journalEntryType_enum>>(
      ([v, label]) => ({
        value: v as journalEntryType_enum,
        label,
        group: (() => {
          if (v.startsWith('reservation')) return 'Reservation';
          if (v.startsWith('transaction_deposit')) return 'Deposit';
          if (v.startsWith('transaction_expense')) return 'Expense';
          if (v.startsWith('transaction_transfer')) return 'Transfer';
          return 'Other';
        })(),
      })
    );
  }, []);

  const value = options.filter((i) => initial?.includes(i.value)) || [];

  return (
    <Filter.Select
      value={value}
      label="Sub Type"
      hideIcon
      setValue={(v) =>
        v.length ? setValue(v.map((x) => x.value)) : setValue(null)
      }
      type="multiple"
      data={{
        options,
      }}
      popoverWidth={300}
    />
  );
};
