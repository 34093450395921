import { ConfirmModal } from '@finalytic/components';
import {
  type gqlV2,
  useApiMutation,
  useInvalidateQueries,
  useMutation,
} from '@finalytic/data';
import {
  StringParam,
  showLoadingNotification,
  updateErrorNotification,
  updateSuccessNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { useId } from 'react';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  owner: Maybe<{
    id: string;
    name: Maybe<string>;
    isArchived: boolean;
    tenantId: string;
  }>;
}

export const OwnerEllipsisMenuModals = ({
  deleteModal,
  archiveModal,
  owner: initial,
}: {
  owner: ModalProps['owner'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
  archiveModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const owner = initial || debounced;

  return (
    <>
      <Archive {...archiveModal} owner={owner} />
      <Delete {...deleteModal} owner={owner} />
    </>
  );
};

export const Archive = ({ closeModal, opened, owner }: ModalProps) => {
  // TODO: use API instead
  // const invalidate = useInvalidateQueries(["owners"])
  // const { mutateAsync, isPending: loading } = useApiMutation("put", "/contacts/{id}", {
  //   onError: (error) => {
  //     showApiErrorNotification({
  //       error,
  //       title: 'Failed to archive owner',
  //       defaultMessage: 'We failed to archive the owner. Please reach out to support if the problem persists.',
  //     });
  //   },
  // })

  const { mutate, loading } = useMutation(
    (q, args: { ownerId: string; status: gqlV2.owner_status_enum }) => {
      return q.updateContact({
        pk_columns: { id: args.ownerId },
        _set: { status: args.status },
      })?.status;
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );
  const ownerId = owner?.id;
  const ownerName = owner?.name;
  const isArchived = owner?.isArchived;

  const archiveOwner = async () => {
    if (!ownerId)
      return {
        isSuccess: false,
        message: 'Owner ID is missing',
      };

    await mutate({
      args: {
        ownerId,
        status: !isArchived ? 'inactive' : 'active',
      },
    }).then(closeModal);
  };

  return (
    <ConfirmModal
      type="confirm"
      onSubmit={archiveOwner}
      opened={opened}
      icon="FolderOpenIcon"
      loading={loading}
      closeModal={closeModal}
      title={`Are you sure you want to ${!isArchived ? 'archive' : 'unarchive'} ${ownerName || 'this owner'}?`}
      submitTitle={!isArchived ? 'Archive' : 'Unarchive'}
      subtitle={
        !isArchived
          ? 'The owner will be archived and hidden behind a table filter. Owners users will retain access to the data.'
          : "The owner will be unarchived and visible in the owner's table."
      }
    />
  );
};

export const Delete = ({ closeModal, opened, owner }: ModalProps) => {
  const setOwner = useQueryParamSet('owner', StringParam);
  const invalidate = useInvalidateQueries(['owners']);
  const notifyId = useId();

  const { mutateAsync: mutateV2, isPending: loading } = useApiMutation(
    'delete',
    '/contacts/{id}',
    {
      onSettled: () => {
        invalidate();
      },
    }
  );

  const ownerId = owner?.id;
  const ownerName = owner?.name;
  const tenantId = owner?.tenantId;

  const deleteOwner = async () => {
    try {
      if (!ownerId || !tenantId) return;

      showLoadingNotification({
        id: notifyId,
      });

      const res = await mutateV2({
        params: {
          path: {
            id: ownerId,
          },
          query: {
            onLocked: 'archive',
          },
        },
      });

      if (res.status === 'deleted')
        updateSuccessNotification({
          id: notifyId,
          title: 'Owner Deletion',
          message: 'Owner was deleted successfully.',
        });
      else
        updateSuccessNotification({
          id: notifyId,
          title: 'Owner Archival',
          message: 'Owner was archived successfully.',
        });
      setOwner(undefined);
      closeModal();
    } catch (error: any) {
      updateErrorNotification({
        id: notifyId,
        title: 'Failed to delete owner',
        message: error?.message || 'Failed to delete owner.',
      });
    }
  };

  return (
    <ConfirmModal
      closeModal={closeModal}
      onSubmit={deleteOwner}
      opened={opened}
      subtitle="The owner will be entirely removed from your team."
      title={`Are you sure you want to delete ${ownerName || 'this owner'}?`}
      loading={loading}
      type="delete"
    />
  );
};
