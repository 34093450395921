import {
  Badge,
  Input,
  InputAmount,
  InputFormulaTipTap,
  InputSelect,
} from '@finalytic/components';
import {
  captureSentryError,
  type gqlV2,
  useInfiniteQuery,
  useQuery,
  useTeam,
  useTeamId,
} from '@finalytic/data';
import { useSettingByIdMutation } from '@finalytic/data-ui';
import { ShuffleIcon } from '@finalytic/icons';
import {
  IconButton,
  LoadingIndicator,
  type SelectItem,
  showErrorNotification,
  showWarnNotification,
} from '@finalytic/ui';
import { type Maybe, expression, toTitleCase } from '@finalytic/utils';
import { Box, Center, Tooltip, rem, useMantineTheme } from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import {
  type MappingLeftSchemaUnion,
  getSourceDescription,
  whereAccounts,
} from '@vrplatform/ui-common';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSingleSettingByTargetQuery } from '../../hooks';
import { useAutomationsQuery } from '../../queries';
import { AutomationOverrideModal } from './AutomationOverrideModal';
import { useAutomationMappingEditorSourcesQuery } from './useAutomationMappingEditorSourcesQuery';
import { useAutomationOverrideAggregateQuery } from './useAutomationOverrideAggregateQuery';
import { useTabEditorType } from './useTabEditorType';

type EditorProps = {
  targetId: string;
  automation: {
    automationId: string;
    leftConnectionId: string;
    rightConnectionId: string;
    templateId: string | undefined;
    leftAppId: string | undefined;
    rightAppId: string | undefined;
  };
  mapping: {
    settingKey: string;
    leftType: string;
    rightType: string;
    rightParams: Record<string, any>;
    leftParams: Record<string, any>;
    label: string | undefined | ReactNode;
    isLocal: boolean;
    altLeftTypes: MappingLeftSchemaUnion[] | undefined;
    exceptionFilters: Record<string, Record<string, any>> | undefined;
  };
  parentSetting: { settingId: string; value: string; target: string } | null;
  invalidateKeys?: string[];
  onCompleted?: () => void;
  withOverrideModal?: boolean;
};

export const AutomationMappingEditor = (props: EditorProps) => {
  const hideModal = !!props.mapping.altLeftTypes?.length;

  if (props.withOverrideModal && hideModal)
    return <WithModalEditor {...props} />;

  return <Editor {...props} />;
};

const WithModalEditor = (props: EditorProps) => {
  const [opened, handlers] = useDisclosure(false);

  const { colors } = useMantineTheme();

  const {
    setting,
    loading: loadingValue,
    refetch,
  } = useSingleSettingByTargetQuery({
    automationId: props.automation.automationId,
    targetId: props.targetId,
    mapping: {
      settingKey: props.mapping.settingKey,
      isLocalOverwrite: props.mapping.isLocal,
      leftType: props.mapping.leftType,
      rightType: props.mapping.rightType,
      parentSettingId: props.parentSetting?.settingId,
    },
  });

  const isDefaultExceptions = props.targetId === '*';

  const { data: count, isLoading: loadingExceptions } =
    useAutomationOverrideAggregateQuery({
      automation: {
        automationId: props.automation.automationId,
        leftConnectionId: props.automation.leftConnectionId,
        rightConnectionId: props.automation.rightConnectionId,
      },
      mapping: {
        leftType: undefined, // target all left types,
      },
      parentMapping: {
        isLocal: props.mapping.isLocal,
        rightType: props.mapping.rightType,
        settingKey: props.mapping.settingKey,
      },
      parentSettingId: props.parentSetting?.settingId || null,
      isDefaultExceptions,
    });

  const isOverwriteDisabled = !setting?.settingId;

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        gap: theme.spacing.xs,
      })}
    >
      <Box sx={{ flex: 1 }}>
        <ChooseEditor
          {...props}
          setting={{
            loading: loadingValue,
            refetch,
            setting,
          }}
        />
      </Box>
      {loadingExceptions && !count ? (
        <LoadingIndicator size="xs" mt={rem(8)} />
      ) : count ? (
        !isOverwriteDisabled && (
          <Center>
            <Badge
              component="button"
              onClick={handlers.open}
              color="yellow"
              sx={() => ({
                marginTop: rem(8),
              })}
            >
              {count} exceptions
            </Badge>
          </Center>
        )
      ) : null}
      <Tooltip label="Show exceptions" withArrow withinPortal>
        <IconButton
          onClick={handlers.open}
          sx={{
            marginTop: rem(4),
          }}
          disabled={isOverwriteDisabled}
        >
          <ShuffleIcon
            color={colors.gray[isOverwriteDisabled ? 4 : 5]}
            size={18}
          />
        </IconButton>
      </Tooltip>
      <AutomationOverrideModal
        opened={opened}
        closeModal={handlers.close}
        automation={{
          automationId: props.automation.automationId,
          leftConnectionId: props.automation.leftConnectionId,
          rightConnectionId: props.automation.rightConnectionId,
          leftAppId: props.automation.leftAppId,
          rightAppId: props.automation.rightAppId,
          templateId: props.automation.templateId,
        }}
        parentMapping={{
          isLocal: props.mapping.isLocal,
          leftType: props.mapping.leftType,
          rightType: props.mapping.rightType,
          settingKey: props.mapping.settingKey,
          label: props.mapping.label,
          rightParams: props.mapping.rightParams || {},
          leftParams: props.mapping.leftParams || {},
          altLeftTypes: props.mapping.altLeftTypes || [],
          exceptionFilters: props.mapping.exceptionFilters,
        }}
        modalTitle={props.mapping.label}
        parentSetting={
          setting?.settingId
            ? {
                settingId: setting?.settingId,
                target: props.targetId,
                value: setting?.value || '',
              }
            : null
        }
        isDefaultExceptions={isDefaultExceptions}
        hideParentMapping={false}
      />
    </Box>
  );
};

const Editor = (props: EditorProps) => {
  const {
    setting,
    loading: loadingValue,
    refetch,
  } = useSingleSettingByTargetQuery({
    automationId: props.automation.automationId,
    targetId: props.targetId,
    mapping: {
      settingKey: props.mapping.settingKey,
      isLocalOverwrite: props.mapping.isLocal,
      leftType: props.mapping.leftType,
      rightType: props.mapping.rightType,
      parentSettingId:
        props.parentSetting?.target !== '*'
          ? props.parentSetting?.settingId
          : null,
    },
  });

  return (
    <ChooseEditor
      {...props}
      setting={{
        loading: loadingValue,
        refetch,
        setting,
      }}
    />
  );
};

type ChooseEditorProps = {
  setting: {
    loading: boolean;
    refetch: () => void;
    setting: Maybe<{
      settingId: string;
      value: Maybe<string>;
    }>;
  };
};

const ChooseEditor = (props: EditorProps & ChooseEditorProps) => {
  const [teamId] = useTeamId();
  const [error, setError] = useState(false);

  const { automation, mapping, targetId } = props;

  const { rightEditorType: editorType, rightEditorParams } = useTabEditorType({
    settingKey: props.mapping.settingKey,
    automationId: props.automation.automationId,
    rightSchema: props.mapping.rightType,
  });

  const {
    setting,
    loading: loadingValue,
    refetch,
    isFetching,
    error: settingQueryError,
  } = useSingleSettingByTargetQuery({
    automationId: props.automation.automationId,
    targetId,
    mapping: {
      settingKey: props.mapping.settingKey,
      isLocalOverwrite: props.mapping.isLocal,
      leftType: props.mapping.leftType,
      rightType: props.mapping.rightType,
      parentSettingId:
        props.parentSetting?.target !== '*'
          ? props.parentSetting?.settingId
          : null,
    },
  });

  const { mutate, loading: loadingMutation } = useSettingByIdMutation({
    invalidate: [targetId, ...(props.invalidateKeys || [])],
  });

  const onValueChange = useCallback(
    async (newValue: {
      settingId: string | undefined;
      value: Maybe<string>;
    }): Promise<any> => {
      const settingId = newValue.settingId;

      if (loadingValue || isFetching) {
        captureSentryError({
          name: 'LoadingValueError',
          message: "Haven't fetched the value yet before saving next",
        });

        return showWarnNotification({
          title: 'Please wait',
          message:
            'Please wait for the current setting to finish loading before making changes.',
        });
      }

      if (settingQueryError) {
        captureSentryError(settingQueryError);

        return showErrorNotification({
          title: settingQueryError?.name || 'Query failed',
          message: settingQueryError?.message || 'Error fetching setting',
        });
      }

      try {
        if (newValue?.value?.trim()) {
          await mutate({
            type: 'upsert',
            setting: {
              id: settingId,
              key: mapping.settingKey,
              target: targetId,
              value: newValue.value?.trim(),
              leftType: mapping.leftType,
              rightType: mapping.rightType,
              // automationId: automation.automationId,
              teamId: teamId,
              leftConnectionId: automation.leftConnectionId,
              rightConnectionId: automation.rightConnectionId,
              parentSettingId:
                props.parentSetting?.target !== '*'
                  ? props.parentSetting?.settingId
                  : undefined,
              localAutomationId: mapping.isLocal
                ? automation.automationId
                : undefined,
            },
          });
        } else if (!newValue?.value && settingId) {
          await mutate({ type: 'remove', settingId });
        }
      } catch (error: any) {
        console.error(error);
        captureSentryError(error);
        showErrorNotification({
          title: error?.name || 'Update failed',
          message: error?.message || 'Failed to update mapping.',
        });
      } finally {
        if (props?.onCompleted) props.onCompleted();
        refetch();
      }
    },
    [
      settingQueryError,
      targetId,
      props.onCompleted,
      refetch,
      automation.leftConnectionId,
      automation.rightConnectionId,
      mapping.leftType,
      automation.automationId,
      mutate,
      loadingValue,
      mapping.isLocal,
      teamId,
      mapping.rightType,
      mapping.settingKey,
      isFetching,
      props.parentSetting?.settingId,
      props.parentSetting?.target,
    ]
  );

  if (editorType === 'select') {
    return (
      <Select
        {...props}
        isDefaultSetting={targetId === '*'}
        onValueChange={onValueChange}
        loadingValue={loadingValue}
        settingQueryError={settingQueryError}
        loadingMutation={loadingMutation}
        setting={setting}
        options={rightEditorParams?.options}
        parentSettingValue={props.parentSetting?.value}
      />
    );
  }

  if (editorType === 'percentage') {
    return (
      <NumberEditor
        isDefaultSetting={targetId === '*'}
        onValueChange={onValueChange}
        loadingValue={loadingValue}
        settingQueryError={settingQueryError}
        loadingMutation={loadingMutation}
        setting={setting}
        parentSettingValue={props.parentSetting?.value}
      />
    );
  }

  if (editorType === 'expression') {
    if (automation.leftConnectionId === automation.rightConnectionId) {
      return (
        <ExpressionWithAccounts
          {...props}
          isDefaultSetting={targetId === '*'}
          onValueChange={onValueChange}
          loadingValue={loadingValue}
          settingQueryError={settingQueryError}
          loadingMutation={loadingMutation}
          setting={setting}
          error={error}
          setError={setError}
          parentSettingValue={props.parentSetting?.value}
        />
      );
    }

    return (
      <Expression
        {...props}
        isDefaultSetting={targetId === '*'}
        onValueChange={onValueChange}
        loadingValue={loadingValue}
        settingQueryError={settingQueryError}
        loadingMutation={loadingMutation}
        setting={setting}
        parentSettingValue={props.parentSetting?.value}
        error={error}
        setError={setError}
      />
    );
  }

  if (editorType === 'text') {
    return (
      <Text
        isDefaultSetting={targetId === '*'}
        onValueChange={onValueChange}
        loadingValue={loadingValue}
        settingQueryError={settingQueryError}
        loadingMutation={loadingMutation}
        setting={setting}
        parentSettingValue={props.parentSetting?.value}
        placeholder={rightEditorParams?.placeholder}
      />
    );
  }

  return null;
};

type InputProps = {
  setting: Maybe<{ settingId: string; value: Maybe<string> }>;
  settingQueryError: Error | null;
  loadingValue: boolean;
  loadingMutation: boolean;
  onValueChange: (o: {
    settingId: string | undefined;
    value: Maybe<string>;
  }) => Promise<void>;
  isDefaultSetting: boolean;
  parentSettingValue: Maybe<string>;
};

type SelectProps = EditorProps &
  InputProps & {
    options: string[] | undefined;
  };

const Select = ({
  automation,
  mapping,
  onValueChange,
  setting,
  loadingValue,
  loadingMutation,
  options: o,
  parentSettingValue,
  settingQueryError,
}: SelectProps) => {
  const { colors } = useMantineTheme();
  const [{ finalyticConnectionId }] = useTeam();

  const [search, setSearch] = useState('');

  const settingKey = mapping.settingKey;

  const connectionId = useMemo(() => {
    // TODO: TEMPORARY for prime vacations (Track to Intacct automations) => need test for all teams and automations
    if (
      automation.templateId === 'e5af0687-28d5-4a9b-bc76-b9f8670a9eed' &&
      automation.rightAppId &&
      automation.leftAppId
    ) {
      return mapping.rightType?.includes(automation.rightAppId)
        ? automation.rightConnectionId
        : automation.leftConnectionId;
    }

    return automation.rightConnectionId === finalyticConnectionId
      ? automation.leftConnectionId
      : automation.rightConnectionId;
  }, [
    automation.leftConnectionId,
    automation.rightConnectionId,
    finalyticConnectionId,
    mapping.rightType,
    automation.templateId,
    automation.rightAppId,
    automation.leftAppId,
  ]);

  const { queryData, source, parentSource, loadingSourceValue } =
    useAutomationMappingEditorSourcesQuery({
      sourceId: setting?.value || undefined,
      search,
      settingKey,
      customSkip: !!o?.length,
      parentSettingSourceId: parentSettingValue,
      connectionId,
      rightType: mapping.rightType,
      rightParams: mapping.rightParams,
    });

  const hasCustomOptions = !!o?.length;

  const options = hasCustomOptions
    ? o.map<SelectItem>((item) => ({ value: item, label: toTitleCase(item)! }))
    : [];

  const value: SelectItem | null = hasCustomOptions
    ? setting?.value
      ? { value: setting.value, label: toTitleCase(setting.value)! }
      : null
    : source || null;

  if (hasCustomOptions) {
    return (
      <InputSelect
        type="single"
        value={value}
        setValue={(value) =>
          onValueChange({ settingId: setting?.settingId, value: value?.value })
        }
        inputProps={{
          placeholder:
            toTitleCase(parentSettingValue || '') ||
            `Select ${toTitleCase(settingKey)}`,
          loadingQuery: loadingValue || loadingSourceValue,
          loadingMutation,
          placeholderColor: colors.gray[5],
          withClearButton: true,
          error: settingQueryError?.message,
          disabled: !!settingQueryError?.message,
        }}
        dropdownProps={{
          withinPortal: true,
          width: 'target',
        }}
        data={{
          options,
        }}
      />
    );
  }

  return (
    <InputSelect
      type="single"
      value={value}
      setValue={(value) =>
        onValueChange({ settingId: setting?.settingId, value: value?.value })
      }
      inputProps={{
        placeholder: parentSource?.label || `Select ${toTitleCase(settingKey)}`,
        loadingQuery: loadingValue || loadingSourceValue,
        loadingMutation,
        placeholderColor: colors.gray[5],
        withClearButton: true,
        error: settingQueryError?.message,
        disabled: !!settingQueryError?.message,
      }}
      dropdownProps={{
        withinPortal: true,
        width: 'target',
      }}
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const default_formula_vars: SelectItem[] = [
  {
    value: 'rate',
    label: 'Rate',
    description: 'The commission rate',
    group: 'Variables',
  },
  {
    value: 'reservation.nights',
    label: 'Reservation Nights',
    group: 'Fields',
  },
  {
    value: 'reservation.guests',
    label: 'Reservation Guests',
    group: 'Fields',
  },
];

const ExpressionWithAccounts = ({
  loadingMutation,
  loadingValue,
  error,
  settingQueryError,
  setError,
  setting,
  onValueChange,
  parentSettingValue,
}: EditorProps &
  InputProps & {
    setError: (val: boolean) => void;
    error: boolean;
  }) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [{ id: teamId }] = useTeam();

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const where = whereAccounts({
        search: args.search,
        tenantId: teamId,
      });

      const vars: SelectItem[] =
        offset !== 0
          ? []
          : args.default_formula_vars.filter((x) =>
              search
                ? x.label.toLowerCase().includes(search.toLowerCase())
                : true
            );

      const accountAggregate =
        q.accountAggregate({ where }).aggregate?.count() || 0;

      const accounts = q
        .accounts({
          where,
          limit,
          offset,
          order_by: [{ classification: 'asc' }, { title: 'asc_nulls_last' }],
        })
        .map<SelectItem>((account) => ({
          value: `acc.${account.id}`,
          label: account.title || 'No name',
          group: 'Accounts',
        }));

      const list = [...vars, ...accounts];

      const aggregate = accountAggregate + vars.length;

      return {
        aggregate,
        list,
      };
    },
    {
      variables: {
        search: search.trim(),
        default_formula_vars,
      },
    }
  );

  const valueQuery = useQuery(
    (q, args) => {
      const getFormatted = (copy: string, includeSpan = true) => {
        // match any words wrapped in quotes
        const matches = copy
          .match(/"(.*?)"/g)
          ?.filter((x, i, arr) => arr.findIndex((y) => y === x) === i);

        matches?.forEach((match) => {
          const v = match.slice(1, -1);

          const defaultMatch = args.default_formula_vars.find(
            (x) => x.value === v
          );

          const getSpan = (val: string, label: string) =>
            includeSpan
              ? `<span data-type="mention" data-id="${val}" data-label="${label}"></span>`
              : label;

          if (defaultMatch) {
            copy = copy!.replaceAll(match, getSpan(v, defaultMatch.label));
          } else if (v.startsWith('acc.')) {
            const account = q.account({ id: v.slice(4) });

            if (account) {
              copy = copy!.replaceAll(
                match,
                getSpan(v, account.title || 'No name')
              );
            }
          }
        });
        return copy;
      };

      return {
        value: getFormatted(args.value?.trim() || ''),
        parentValue: getFormatted(args.parentSettingValue?.trim() || '', false),
      };
    },
    {
      variables: {
        value: setting?.value,
        default_formula_vars,
        parentSettingValue,
      },
      keepPreviousData: true,
    }
  );

  return (
    <InputFormulaTipTap
      initialValue={valueQuery.data?.value || ''}
      loading={
        loadingValue || loadingMutation || loading || valueQuery.isLoading
      }
      placeholder={valueQuery.data?.parentValue || 'Formula'}
      error={error || settingQueryError?.message || false}
      // biome-ignore lint/suspicious/useAwait: <explanation>
      onChange={async (newValue) => {
        setError?.(false);
        let formula: Maybe<string> = undefined;

        // validate formula before saving
        try {
          if (!loading) {
            if (newValue?.trim() !== setting?.value?.trim()) {
              // validate formula
              const tree = expression.toTree(newValue || '');
              if (!tree) throw new Error('Invalid formula');

              formula = newValue;
            }

            setError?.(false);
          }
        } catch (error: any) {
          console.error(error);
          setError?.(true);
          return showWarnNotification({
            title: 'Invalid formula',
            message: `Your formula wasn't saved, Please check your formula and try again.`,
          });
        }

        setLoading(false);

        // only save if formula is validated
        if (formula !== undefined) {
          onValueChange({
            settingId: setting?.settingId,
            value: formula,
          });
        }
      }}
      queryData={{ ...queryData, setSearch }}
      disabled={false}
    />
  );
};

const Expression = ({
  loadingMutation,
  loadingValue,
  error,
  settingQueryError,
  setError,
  setting,
  onValueChange,
  parentSettingValue,
  mapping,
  mappings: overrideMappings,
  automation,
}: EditorProps &
  InputProps & {
    mappings?: any;

    setError: (val: boolean) => void;
    error: boolean;
  }) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [{ id: teamId }] = useTeam();
  const { data: automations = [], isLoading: loadingAutomations } =
    useAutomationsQuery();

  const settingKey = mapping.settingKey;

  const mappings =
    overrideMappings ||
    automations.find((a) => a.automationId === automation.automationId)
      ?.mappings;

  const itemMappingKey =
    mappings[settingKey].right.params?.itemMappingKey ||
    mappings[settingKey].params?.itemMappingKey;

  const itemType = mappings[itemMappingKey]?.right.schema.split('.')[1];

  const queryData = useInfiniteQuery(
    (q, args, { limit, offset }) => {
      const vars: SelectItem[] =
        offset !== 0
          ? []
          : args.default_formula_vars.filter((x) =>
              search
                ? x.label.toLowerCase().includes(search.toLowerCase())
                : true
            );

      const where: gqlV2.source_bool_exp = {
        type: { _eq: args.itemType },
        tenantId: { _eq: args.teamId },
        status: { _eq: 'active' },
        _or: search
          ? [
              {
                description: { _ilike: `%${search}%` },
              },
              {
                remoteId: { _eq: search },
              },
            ]
          : undefined,
      };

      const sourceAggregate =
        (q.sourceAggregate({ where })?.aggregate?.count() || 0) +
        (vars.length || 0);

      const sources = q
        .source({
          limit,
          order_by: [{ description: 'asc' }],
          where,
        })
        .map<SelectItem>((item) => {
          const label =
            getSourceDescription(item, { ignoreRemoteIdLength: true }) ||
            'Missing description';

          return {
            label,
            value: `itm.${item.remoteId}`,
            group: toTitleCase(args.itemMappingKey)!,
          };
        });

      const list = [...vars, ...sources];

      const aggregate = sourceAggregate + vars.length;

      return {
        aggregate,
        list,
      };
    },
    {
      variables: {
        search: search.trim(),
        default_formula_vars,
        itemMappingKey,
        itemType,
        teamId,
      },
    }
  );

  const valueQuery = useQuery(
    (q, args) => {
      const getFormatted = (copy: string, includeSpan = true) => {
        // match any words wrapped in quotes
        const matches = copy
          .match(/"(.*?)"/g)
          ?.filter((x, i, arr) => arr.findIndex((y) => y === x) === i);

        matches?.forEach((match) => {
          const v = match.slice(1, -1);

          const defaultMatch = args.default_formula_vars.find(
            (x) => x.value === v.toLowerCase()
          );

          const getSpan = (val: string, label: string) =>
            includeSpan
              ? `<span data-type="mention" data-id="${val}" data-label="${label}"></span>`
              : label;

          if (defaultMatch) {
            copy = copy!.replaceAll(match, getSpan(v, defaultMatch.label));
          } else if (v.startsWith('itm.')) {
            const account = q
              .source({
                where: {
                  type: { _eq: args.itemType },
                  remoteId: { _eq: v.slice(4) },
                  tenantId: { _eq: args.teamId },
                },
                order_by: [{ createdAt: 'desc_nulls_last' }],
              })
              .map((x) => ({
                id: x.id,
                label: getSourceDescription(x, { ignoreRemoteIdLength: true }),
              }))[0];

            if (account) {
              copy = copy!.replaceAll(
                match,
                getSpan(v, account.label || 'No name')
              );
            }
          }
        });
        return copy;
      };

      return {
        value: getFormatted(args.value?.trim() || ''),
        parentValue: getFormatted(args.parentSettingValue?.trim() || '', false),
      };
    },
    {
      variables: {
        value: setting?.value,
        default_formula_vars,
        parentSettingValue,
        itemType,
        teamId,
      },
      keepPreviousData: true,
    }
  );

  const onChange = useCallback(
    // biome-ignore lint/suspicious/useAwait: <explanation>
    async (newValue: string | null) => {
      setError?.(false);
      let formula: Maybe<string> = undefined;

      // validate formula before saving
      try {
        if (!loading) {
          if (newValue?.trim() !== setting?.value?.trim()) {
            // validate formula
            const tree = expression.toTree(newValue || '');
            if (!tree) throw new Error('Invalid formula');

            formula = newValue ? newValue.trim() : null;
          }

          setError?.(false);
        }
      } catch (error: any) {
        console.error(error);
        setError?.(true);
        return showWarnNotification({
          title: 'Invalid formula',
          message: `Your formula wasn't saved, Please check your formula and try again.`,
        });
      }

      setLoading(false);

      // only save if formula is validated
      if (formula !== undefined) {
        // skip is the formula is the same as the current value
        if (formula && formula === setting?.value?.trim()) return;

        console.log({ formula, val: setting?.value });

        onValueChange({
          settingId: setting?.settingId,
          value: formula,
        });
      }
    },
    [onValueChange, setting?.settingId, setting?.value, setError, loading]
  );

  return (
    <InputFormulaTipTap
      initialValue={valueQuery.data?.value || ''}
      loading={
        loadingValue ||
        loadingMutation ||
        loading ||
        valueQuery.isLoading ||
        loadingAutomations
      }
      disabled={loading || !!settingQueryError?.message}
      placeholder={valueQuery.data?.parentValue || 'Formula'}
      error={error || settingQueryError?.message || false}
      onChange={onChange}
      queryData={{ ...queryData, setSearch }}
    />
  );
};

const NumberEditor = ({
  loadingMutation,
  loadingValue,
  onValueChange,
  setting,
  isDefaultSetting,
  parentSettingValue,
  settingQueryError,
}: InputProps) => {
  const [value, setValue] = useDebouncedState<number | undefined>(
    undefined,
    500
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (setting?.value) {
      setValue(Number(setting.value));
    } else {
      setValue(undefined);
    }
  }, [setting?.value]);

  return (
    <InputAmount
      value={typeof value === 'number' ? value : ''}
      setValue={(num) => (typeof num === 'number' ? setValue(num || 0) : '')}
      placeholder={
        parentSettingValue || (isDefaultSetting ? '0.00' : 'Default')
      }
      radius="md"
      decimalScale={2}
      loadingQuery={loadingValue}
      loadingMutation={loadingMutation}
      disabled={!!settingQueryError?.message}
      onBlur={(event) => {
        const newValue = event.target.value
          ? Number.parseFloat(event.target.value)
          : '';

        const oldSetting = setting?.value
          ? Number.parseFloat(setting.value)
          : '';

        if (oldSetting !== newValue) {
          onValueChange({
            settingId: setting?.settingId,
            value:
              typeof newValue === 'number' ? newValue.toString() : newValue,
          });
        }
      }}
    />
  );
};

const Text = ({
  loadingMutation,
  loadingValue,
  onValueChange,
  setting,
  // isDefaultSetting,
  parentSettingValue,
  placeholder,
  settingQueryError,
}: InputProps & { placeholder?: string }) => {
  const [value, setValue] = useDebouncedState<string | undefined>(
    undefined,
    500
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (setting?.value) {
      setValue(setting.value);
    } else {
      setValue(undefined);
    }
  }, [setting?.value]);

  return (
    <Input
      value={value || ''}
      setValue={(newValue) => setValue(newValue)}
      placeholder={parentSettingValue || placeholder}
      loadingQuery={loadingValue}
      disabled={!!settingQueryError?.message}
      loadingMutation={loadingMutation}
      onBlur={(event) => {
        const newValue = event.target.value.trim();

        const oldSetting = (setting?.value || '').trim();

        if (oldSetting !== newValue) {
          onValueChange({
            settingId: setting?.settingId,
            value: newValue,
          });
        }
      }}
    />
  );
};

AutomationMappingEditor.Select = Select;
AutomationMappingEditor.Expression = Expression;
