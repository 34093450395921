import { type QueryKey, useApiClient, useTeamId } from '@finalytic/data';
import type { activeStatus_enum, party_enum } from '@finalytic/graphql';
import { type Maybe, ensure, hasValue } from '@finalytic/utils';
import { randomId, useDebouncedValue } from '@mantine/hooks';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import { getReservationFinancialGroup } from '../_utils';

export function useLedgerReservationDetailDrawerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const $api = useApiClient();

  const query = useTanstackQuery({
    queryKey: ['reservations' satisfies QueryKey, id, teamId],
    enabled: !!id && !!teamId,
    queryFn: async () => {
      if (!id) throw new Error('Missing reservation id');

      const result = await $api.GET('/reservations/{id}', {
        params: {
          path: {
            id: id!,
          },
          query: {
            includeFinancials: true,
          },
        },
      });

      if (result.error) {
        throw result.error;
      }

      const reservation = result.data;

      type apiFinancialLine = NonNullable<
        typeof reservation.financials
      >['lines'][number];

      type FinancialLine = {
        id: string;
        recurringFee: apiFinancialLine['recurringFee'];
        reservationLine: apiFinancialLine['line'];
        isAdjustment: boolean;
        title: string;
        status: apiFinancialLine['status'];
        centTotal: number;
        account: apiFinancialLine['account'];
      };

      type Expense = {
        id: string;
        status: activeStatus_enum;
        centTotal: number;
        description: string;
        party: party_enum | undefined | null;
        expense: {
          id: string;
          description: string;
          currency: string;
          date: any;
        };
      };

      const currency = reservation.currency ?? undefined;

      const feesAndCommissions: FinancialLine[] = (
        reservation.financials?.lines
          .map((line) => {
            const isFinancials =
              line.party === 'manager' &&
              getReservationFinancialGroup(line) === 'feeAndCommission';

            if (!isFinancials) return null;

            return {
              id: randomId(),
              centTotal: line.amount,
              isAdjustment: line.type === 'reservation_fee_adjustment',
              recurringFee: line.recurringFee,
              title: line.name,
              reservationLine: line.line,
              status: line.status,
              account: line.account,
            };
          })
          .filter(hasValue) ?? []
      ).sort((a, b) => {
        if (a.isAdjustment !== b.isAdjustment) {
          return a.isAdjustment ? 1 : -1;
        }
        if (a.title !== b.title) {
          return a.title.localeCompare(b.title);
        }
        return b.centTotal - a.centTotal;
      });

      const expenses: Expense[] =
        reservation.financials?.lines
          .map<Expense | null>((line) => {
            if (
              !line.transaction ||
              getReservationFinancialGroup(line) !== 'expense'
            )
              return null;

            return {
              id: line.transaction.id,
              centTotal: line.amount,
              description: line.name,
              party: line.party,
              status: line.status,
              expense: {
                currency: line.transaction.currency,
                date: line.transaction.date,
                description: line.transaction.description,
                id: line.transaction.id,
              },
            };
          })
          .filter(hasValue) ?? [];

      type Deposit = {
        id: string;
        description: string;
        currency: string;
        date: string;
        centTotal: number;
        status: activeStatus_enum;
        connection: {
          id: string;
          name: string;
          app:
            | {
                id: string;
                name: string;
                icon?: Maybe<string>;
              }
            | undefined;
        } | null;
      };

      const deposits: Deposit[] =
        reservation.financials?.lines
          .filter((line) => {
            return (
              getReservationFinancialGroup(line) === 'deposit' &&
              line.transaction &&
              line.type &&
              ensure<NonNullable<(typeof line)['type']>[]>([
                'transaction_deposit',
                'transaction_deposit_line',
                'transaction_deposit_refund',
              ]).includes(line.type)
            );
          })
          .reduce<Deposit[]>((acc, line) => {
            const transaction = line.transaction;
            if (!transaction) return acc;

            const index = acc.findIndex((d) => d.id === transaction.id);

            if (index === -1) {
              acc.push({
                id: transaction.id,
                centTotal: line.amount,
                status: line.status,
                description: transaction?.description,
                currency: transaction.currency,
                date: transaction.date,
                connection: transaction.connection
                  ? {
                      id: transaction.connection.id,
                      name: transaction.connection.name,
                      app: transaction.connection.app,
                    }
                  : null,
              });
            } else {
              acc[index].centTotal += line.amount;
            }

            return acc;
          }, []) ?? [];

      const financials: FinancialLine[] =
        reservation.financials?.lines
          .map<FinancialLine | null>((line) => {
            const isFinancials =
              line.party === 'owners' &&
              getReservationFinancialGroup(line) === 'financial';

            if (!isFinancials) return null;

            return {
              id: randomId(),
              title: line.name,
              centTotal: line.amount,
              recurringFee: line.recurringFee,
              reservationLine: line.line,
              status: line.status,
              account: line.account,
              isAdjustment:
                line.type === 'reservation_adjustment' ||
                line.type === 'reservation_adjustment_cancellation',
            };
          })
          .filter(hasValue)
          .sort((a, b) => {
            if (a.isAdjustment !== b.isAdjustment) {
              return a.isAdjustment ? 1 : -1;
            }

            if (b.centTotal !== a.centTotal) {
              return b.centTotal - a.centTotal;
            }

            return a.title.localeCompare(b.title);
          }) ?? [];

      const isPriorToStartDate =
        !reservation.financials?.lines.length ||
        reservation.financials.lines.every((x) => x.status === 'inactive');

      return {
        ...reservation,
        totals: reservation.financials?.totals,
        financials,
        feesAndCommissions,
        expenses,
        deposits,
        isPriorToStartDate,
        status: reservation.status,
        currency,
        isCancelledAndPending:
          reservation.status === 'canceled' &&
          reservation.payment.status !== 'paid',
      };
    },
  });

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type Reservation = NonNullable<
  ReturnType<typeof useLedgerReservationDetailDrawerQuery>['data']
>;
