import { CountBagde } from '@finalytic/components';
import { useDashboard, useQuery, useTeam, useTeamId } from '@finalytic/data';
import { useSubscription } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { IconButton } from '@finalytic/ui';
import { groupBy } from '@finalytic/utils';
import { Box, Stack, Text } from '@mantine/core';
import { XIMPLIFI_TENANT_ID } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useNavbarExpanded } from '../hooks';
import { NavbarRoute } from '../layout/navbar';
import { getPropertyManagementConnections } from '../queries';
import { PartnerConnectionErrorAggregateBadge } from '../views/partner-connections/PartnerConnectionErrorAggregateBadge';

function useTeamViews() {
  const [teamId] = useTeamId();

  const { data } = useQuery(
    (q, args) => {
      const views = q
        .views({
          where: {
            _or: [
              {
                tenantId: { _eq: args.teamId },
                type: { _eq: 'payments' },
                group: { _eq: 'payments' },
              },
              {
                tenantId: { _eq: args.teamId },
                group: { _eq: 'Reports' },
              },
              {
                isDefault: {
                  _eq: true,
                },
              },
            ],
          },
          order_by: [{ sortOrder: 'asc_nulls_last' }],
        })
        .map((view) => ({
          id: view.id,
          title: view.title || '',
          group: view.group,
          retoolViewId: view.retoolViewId,
          sortOrder: view.sortOrder,
        }));

      const hasPmsConnection = !!getPropertyManagementConnections(
        q,
        { teamId: args.teamId },
        { limit: 1 }
      ).length;

      return {
        views,
        hasPmsConnection,
      };
    },
    {
      queryKey: 'views',
      skip: !teamId,
      variables: {
        teamId,
      },
    }
  );

  const retoolViews = useMemo(() => {
    const grouped = groupBy(data?.views || [], (x) => x.group!);

    return (
      grouped?.Reports?.map((i) => ({
        id: i.id!,
        title: i.title,
        url: i.retoolViewId!,
        group: i.group!.toLowerCase(),
      })) ?? []
    );
  }, [data?.views]);

  return {
    retoolViews,
    data,
  };
}

const iconSize = 18;

const NavigationSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const { isDesktopExpanded } = useNavbarExpanded();

  return (
    <Box>
      {title && isDesktopExpanded && (
        <Text
          component="span"
          mb="xs"
          c="white"
          pl={5}
          sx={{ opacity: 0.5, display: 'block' }}
        >
          {title}
        </Text>
      )}
      <Stack gap="xs">{children}</Stack>
    </Box>
  );
};

export function PartnerNavigation() {
  const { retoolViews, data } = useTeamViews();
  // LEGACY
  const [dashboard] = useDashboard();
  const [{ partnerId, id: teamId }] = useTeam();
  const showMappingsV2 =
    partnerId === XIMPLIFI_TENANT_ID &&
    data !== undefined &&
    !data.hasPmsConnection;

  const reViews =
    retoolViews?.filter(
      (x) => !showMappingsV2 || x.url !== '11ef2837-7612-48db-a7c7-68c621d41efc'
    ) ?? [];
  const goto = useNavigate();

  const isXimplifiTeam = teamId === XIMPLIFI_TENANT_ID;
  const isPartnerDashboard = dashboard === 'partner';

  return (
    <NavigationSection
      title={!['partner', 'overview'].includes(dashboard) ? 'Legacy' : ''}
    >
      <NavbarRoute
        title="Overview"
        link={'/dashboard'}
        icon={<Icon icon="Activity2Icon" size={iconSize} />}
      />
      {isPartnerDashboard && (
        <NavbarRoute
          title="Your Team"
          link={'/members'}
          icon={<Icon icon="UsersIcon" size={iconSize} />}
        />
      )}

      <Box my="sm">
        <NavbarRoute
          title="Statements"
          link={'/team-statements'}
          icon={<Icon icon="ClipboardTextIcon" size={iconSize} />}
        />
      </Box>
      <Stack gap="xs" mb="sm">
        <NavbarRoute
          title="Automations"
          link={'/team-automations'}
          icon={<Icon icon="RocketIcon" size={iconSize} />}
        />

        <NavbarRoute
          title="Connections"
          link={'/team-connections?status=active'}
          icon={<Icon icon="ShareIcon" size={iconSize} />}
          notification={
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                goto('/team-connections/errors?status=active');
              }}
              sx={{
                width: 'unset',
              }}
            >
              <PartnerConnectionErrorAggregateBadge loadingColor="white" />
            </IconButton>
          }
        />
      </Stack>

      {isXimplifiTeam && isPartnerDashboard && (
        <NavbarRoute
          title="Line Master List"
          link={'/line-master-list'}
          icon={<Icon icon="ListUnorderedIcon" size={iconSize} />}
          notification={<LineMasterIssueBadge />}
        />
      )}

      {/* LEGACY */}
      {showMappingsV2 && isPartnerDashboard && (
        <NavbarRoute
          title="Mappings"
          link="/reports/11ef2837-7612-48db-a7c7-68c621d41efc"
          icon={<Icon icon="FileTextIcon" size={iconSize} />}
        />
      )}
      {reViews?.length > 0 && isPartnerDashboard && (
        <NavbarRoute
          title="Reports"
          link={`/${retoolViews[0].group}/${retoolViews[0].url}`}
          icon={<Icon icon="FileTextIcon" size={iconSize} />}
          subRoutes={retoolViews}
        />
      )}
    </NavigationSection>
  );
}
const LineMasterIssueBadge = () => {
  const [{ name: partnerName }] = useTeam();

  const { data, isLoading } = useSubscription(
    (q) => {
      const count =
        q
          .paymentLineClassificationAggregate({
            where: {
              _not: {
                settings: {
                  value: { _is_null: false },
                  key: { _eq: 'accountingType' },
                  parentSettingId: { _is_null: true },
                },
              },
            },
          })
          .aggregate?.count() || 0;

      return count;
    },
    { partnerName: partnerName.toLowerCase() }
  );

  return (
    <CountBagde count={data || 0} loading={isLoading} loadingColor="white" />
  );
};
