import { useGqtyClient } from '@finalytic/data';
import type { source_bool_exp } from '@finalytic/graphql';
import { FileDownloadIcon } from '@finalytic/icons';
import {
  EllipsisMenuItem,
  showLoadingNotification,
  updateErrorNotification,
  updateSuccessNotification,
  updateWarnNotification,
} from '@finalytic/ui';
import { useMantineTheme } from '@mantine/core';
import { type ReactNode, useId, useState } from 'react';

export const EllipsisMenuSourceDownloadItem = ({
  where,
  children,
  notification,
  // fileName,
}: {
  where: source_bool_exp;
  fileName: string;
  children?: ReactNode;
  notification?: string;
}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const $db = useGqtyClient();
  const notifyId = useId();

  return (
    <EllipsisMenuItem
      customIcon={
        <FileDownloadIcon size={16} color={theme.colors.neutral[5]} />
      }
      loading={loading}
      onClick={async () => {
        setLoading(true);
        showLoadingNotification({
          id: notifyId,
        });

        await $db
          .query(
            (q) =>
              q
                .source({
                  where,
                  limit: 1,
                  order_by: [
                    {
                      updatedAt: 'desc_nulls_last',
                    },
                  ],
                })
                .map((source) => (source.json() ?? {}) as object)[0]
          )
          .then((json) => {
            console.log(json);

            if (!json) {
              updateWarnNotification({
                id: notifyId,
                message: 'No source found',
              });

              return;
            }

            // show json in new tab
            const jsonString = JSON.stringify(json, null, 2);
            const blob = new Blob([jsonString], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank', 'noopener,noreferrer');

            updateSuccessNotification({
              id: notifyId,
              message: notification || 'Source was downloaded.',
            });
          })
          .catch((error) => {
            console.error(error);
            updateErrorNotification({
              id: notifyId,
              title: 'Failed to download source',
              message: error.message,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
    >
      {children || 'Download source'}
    </EllipsisMenuItem>
  );
};
