import type {
  Maybe,
  listingOwnershipPeriod_bool_exp,
} from '@finalytic/graphql';

export const whereListingPeriod = ({
  listingId,
  ownerIds,
  date,
}: {
  listingId?: Maybe<string>;
  ownerIds?: Maybe<string[]>;
  date?: Maybe<string>;
}): listingOwnershipPeriod_bool_exp => {
  return {
    listingId: listingId ? { _eq: listingId } : undefined,
    members: ownerIds?.length
      ? { contactId: { _in: ownerIds } }
      : { contactId: { _is_null: false } },
    _or: date
      ? [
          {
            startAt: {
              _lte: date,
            },
            endAt: {
              _gte: date,
            },
          },
          {
            startAt: {
              _lte: date,
            },
            endAt: {
              _is_null: true,
            },
          },
        ]
      : undefined,
  };
};
