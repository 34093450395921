import type { StateCreator } from 'zustand';

type DefaultValue = boolean;

type State<T = DefaultValue> = {
  allPagesSelected: boolean;
  selected: Record<string, T>;
};

type Actions<T = DefaultValue> = {
  setAllPagesSelected: (v: boolean) => void;
  setSelected: (data: Record<string, T>) => void;
  getSelectionState: () => State<T>;
  resetSelection: () => void;
};

export type TableSelectionSlice<T = DefaultValue> = State<T> & Actions<T>;

const initialState: any = {
  allPagesSelected: false,
  selected: {},
};

export const createTableSelectionSlice: StateCreator<TableSelectionSlice> = (
  set,
  get
) => ({
  allPagesSelected: false,
  selected: {},
  setAllPagesSelected: (val) => {
    set((state) => ({
      ...state,
      allPagesSelected: val,
    }));
  },
  getSelectionState: () => {
    return get();
  },
  setSelected: (selected) => {
    set((state) => ({
      ...state,
      selected,
    }));
  },
  resetSelection: () => set(initialState),
});
