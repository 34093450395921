import {
  Avatar,
  Button,
  SimpleGrid,
  type SimpleGridProps,
  Skeleton,
} from '@mantine/core';
import type { OnboardingIntegration } from './_types';

type SetIntegrationHandler = (
  integration: OnboardingIntegration | undefined
) => void;

type Props = {
  integrations: OnboardingIntegration[];
  notListedTitle: string | undefined;
  setIntegration: SetIntegrationHandler;
  handleIntegrationSubmit: (integration: OnboardingIntegration) => void;
  handleMissingIntegration: () => void;
  loading: boolean;
};

export const IntegrationList = ({
  integrations,
  notListedTitle,
  setIntegration,
  handleIntegrationSubmit,
  handleMissingIntegration,
  loading,
  ...props
}: Props & SimpleGridProps) => {
  if (loading)
    return (
      <SimpleGrid cols={2} {...props}>
        <Skeleton w="100%" height={55} />
        <Skeleton w="100%" height={55} />
        <Skeleton w="100%" height={55} />
        <Skeleton w="100%" height={55} />
        <Skeleton w="100%" height={55} />
        <Skeleton w="100%" height={55} />
      </SimpleGrid>
    );

  return (
    <SimpleGrid cols={!loading && !integrations.length ? 1 : 2} {...props}>
      {integrations.map((app) => (
        <AppButton
          key={app.id}
          {...app}
          setIntegration={setIntegration}
          handleIntegrationSubmit={handleIntegrationSubmit}
        />
      ))}
      {notListedTitle && (
        <NotListedButton
          title={notListedTitle}
          onClick={handleMissingIntegration}
        />
      )}
    </SimpleGrid>
  );
};

const AppButton = ({
  setIntegration,
  handleIntegrationSubmit,
  ...props
}: OnboardingIntegration & {
  setIntegration: SetIntegrationHandler;
  handleIntegrationSubmit: (integration: OnboardingIntegration) => void;
}) => {
  const { name, icon, id } = props;
  return (
    <Button
      variant="white"
      id={id}
      onClick={() => handleIntegrationSubmit(props)}
      onMouseEnter={() => setIntegration(props)}
      onMouseLeave={() => setIntegration(undefined)}
      h={55}
      px="sm"
      color="dark"
      styles={(theme) => ({
        root: {
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
          ':hover': {
            backgroundColor: theme.colors.gray[0],
          },
        },
        inner: {
          justifyContent: 'flex-start',
        },
        label: { fontWeight: 400 },
      })}
      sx={{
        borderColor: '#EEEFF1',
      }}
      leftSection={<Avatar size="sm" src={icon} />}
    >
      {name}
    </Button>
  );
};

const NotListedButton = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => {
  return (
    <Button
      onClick={onClick}
      variant="white"
      h={55}
      color="dark"
      styles={(theme) => ({
        root: {
          transition: 'background-color 0.1s ease-in-out',
          backgroundColor: theme.colors.gray[0],
          ':hover': {
            backgroundColor: theme.colors.gray[1],
          },
        },
        label: { fontWeight: 400 },
      })}
      sx={{
        borderColor: '#EEEFF1',
      }}
    >
      {title}
    </Button>
  );
};
