import { z } from 'zod';

export const deposits_csv_import_schema = z.object({
  Reference: z.coerce.string({
    message: 'Reference is required',
  }),
  Date: z.string().refine(
    (date) => {
      return date.match(/^\d{4}-\d{2}-\d{2}$/);
    },
    {
      message: 'Please provide a date in the format YYYY-MM-DD',
    }
  ),
  'Bank/CC Account': z.string({
    message: 'Bank/CC Account is required',
  }),
  Description: z.string({
    message: 'Description is required',
  }),
  Currency: z.string({
    message: 'Currency is required',
    invalid_type_error: 'Currency must be a string',
  }),
  Reservation: z.coerce.string().nullable(),
  Contact: z.coerce.string().nullable(),
  Listing: z.coerce.string().nullable(),
  Account: z.coerce.string().nullable(),
  'Line Description': z.coerce.string().nullable(),
  'Applied Amount': z.coerce.number({
    invalid_type_error: 'Applied Amount must be a number',
  }),
  'Merchant Fee': z.coerce.number({
    invalid_type_error: 'Merchant Fee must be a number',
  }),
  'Channel Fee': z.coerce.number({
    invalid_type_error: 'Channel Fee must be a number',
  }),
  Reserve: z.coerce.number({
    invalid_type_error: 'Reserve must be a number',
  }),
});
