import { InputSelect, InputWrapper } from '@finalytic/components';
import { type gqlV2, useTeam } from '@finalytic/data';
import { useInfiniteQuery, useQuery } from '@finalytic/data';
import type { SelectItem } from '@finalytic/ui';
import { isUUID, toTitleCase } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { whereAccounts } from '@vrplatform/ui-common';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import type { UnreconciledBankRecord } from '../../../reconciliation/bank-accounts/view/UnreconciledBankRecordCard';
import { useExpenseForm } from '../useExpenseForm';

function useAccountQuery(search: string) {
  const [{ id: teamId }] = useTeam();

  return useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: gqlV2.account_bool_exp = {
        ...whereAccounts({
          tenantId: teamId,
          search,
          type: 'bank',
        }),
        bankAccount: {},
      };

      const list = q
        .accounts({
          where,
          order_by: [
            { bankAccount: { category: 'desc_nulls_last' } },
            { title: 'asc_nulls_last' },
          ],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: res.bankAccount?.name || res.title || 'No name',
          group: toTitleCase(res.bankAccount?.category || '-'),
        }));

      const aggregate = q.accountAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['listings'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );
}

const EXTERNAL_ACCOUNT: SelectItem = {
  label: 'Non-Trust Account',
  value: 'non-trust',
};

export const ExpenseBankAccountInput = ({
  label,
  showRequired = false,
  required = true,
  bankRecord,
}: {
  label?: string;
  showRequired?: boolean;
  required?: boolean;
  bankRecord?: UnreconciledBankRecord;
}) => {
  const methods = useExpenseForm();
  const [search, setSearch] = useState('');

  const queryData = useAccountQuery(search);

  return (
    <Box>
      <Controller
        control={methods.control}
        name={'accountId'}
        rules={{
          required: required ? 'Please select an account' : false,
        }}
        render={({ field, fieldState }) => {
          const { data, isLoading: loading } = useQuery(
            (q, args) => {
              if (args.value === EXTERNAL_ACCOUNT.value)
                return EXTERNAL_ACCOUNT;

              if (!args.value || !isUUID(args.value)) return null;

              return q
                .accounts({
                  where: {
                    id: { _eq: args.value },
                  },
                })
                .map<SelectItem>((item) => ({
                  label: item.title || 'No name',
                  value: item.id!,
                }))[0];
            },
            {
              keepPreviousData: true,
              variables: {
                value: field.value,
              },
            }
          );

          const account = field.value
            ? field.value === 'non-trust'
              ? EXTERNAL_ACCOUNT
              : data || null
            : null;

          return (
            <InputWrapper
              error={fieldState.error?.message}
              label={label}
              required={showRequired}
            >
              <InputSelect
                type="single"
                value={account}
                setValue={(v) => field.onChange(v?.value || null)}
                infiniteData={{ ...queryData, setSearch }}
                inputProps={{
                  error: !!fieldState.error,
                  placeholder:
                    queryData.data?.pages?.[0]?.list?.[0]?.label ||
                    'Select Account',
                  loadingQuery: loading,
                  disabled: field.disabled || !!bankRecord,
                }}
                dropdownProps={{
                  width: 'target',
                }}
                customBottomActions={[
                  {
                    id: 'non-trust',
                    label: EXTERNAL_ACCOUNT.label,
                    onSubmit: () => field.onChange(EXTERNAL_ACCOUNT.value),
                    description:
                      'An external account not on your chart of account.',
                  },
                ]}
              />
            </InputWrapper>
          );
        }}
      />
    </Box>
  );
};
