import { Button, ConfirmModal } from '@finalytic/components';
import { useTeamId, useTrpcMutation } from '@finalytic/data';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useState } from 'react';

export const RefreshLedgerButton = () => {
  const [teamId] = useTeamId();
  const { loading, mutate } = useTrpcMutation('refreshGeneralLedger');
  const [opened, handlers] = useDisclosure(false);
  const [_runId, setRunId] = useState<string | null>(null);

  const submit = useCallback(
    async () =>
      await mutate({
        tenantId: teamId,
      }).then((id) => {
        setRunId(id);
        handlers.close();
      }),
    [mutate, teamId, handlers.close]
  );

  return (
    <>
      <Button
        onClick={handlers.open}
        loading={loading}
        variant="primary"
        leftIcon="RefreshCwIcon"
      >
        Reset Journal Entries
      </Button>
      <ConfirmModal
        closeModal={handlers.close}
        onSubmit={submit}
        loading={loading}
        opened={opened}
        title="Reset Journal Entries"
        subtitle="This will reset all journal entries on the current team and regenerate based on current data. This may take a few minutes."
        type="confirm"
        icon="RefreshCwIcon"
        submitTitle="Yes, reset"
      />
      {/* <TriggerModal runId={runId} closeModal={() => setRunId(null)} /> */}
    </>
  );
};

// const TriggerModal = ({
//   runId,
//   closeModal,
// }: { runId: string | null; closeModal: () => void }) => {
//   return (
//     <Modal
//       opened={!!runId}
//       onClose={closeModal}
//       size={380}
//       radius="md"
//       centered
//       styles={{
//         body: {
//           paddingInline: rem(30),
//           paddingBottom: rem(30),
//         },
//         header: {
//           paddingBottom: 0,
//           minHeight: 0,
//         },
//       }}
//     >
//       <Box
//         mb={'xl'}
//         sx={() => ({
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//         })}
//       >
//         {runId && <TriggerContent runId={runId} closeModal={closeModal} />}

//         <Group wrap="nowrap">
//           <Button
//             sx={{
//               width: '100%',
//             }}
//             onClick={closeModal}
//           >
//             Cancel
//           </Button>
//         </Group>
//       </Box>
//     </Modal>
//   );
// };
// const TriggerContent = ({
//   runId,
// }: {
//   runId: string;
//   closeModal: () => void;
// }) => {
//   const { isLoading, error, run: data } = useRun(runId);

//   if (isLoading) return <LoadingIndicator size="xs" />;

//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <ScrollArea mah={500}>
//       <pre>{JSON.stringify(data, null, 2)}</pre>
//     </ScrollArea>
//   );
// };
