import { type Query, useQuery, useTeamId } from '@finalytic/data';
import { whereConnectionStatusDefault } from '@vrplatform/ui-common';

export const getPropertyManagementConnections = (
  q: Query,
  args: { teamId: string },
  opts?: {
    limit?: number;
  }
) => {
  return q
    .connection({
      where: {
        status: whereConnectionStatusDefault,
        tenantId: { _eq: args.teamId },
        app: { category: { _eq: 'propertyManagementSystem' } },
      },
      order_by: [{ appId: 'asc_nulls_first' }],
      limit: opts?.limit,
    })
    .map((connection) => ({
      appId: connection.appId,
      name: connection.app.name,
      type: connection.app.type,
      category: connection.app.category,
      id: connection.id,
    }));
};

export function usePropertyManagementConnectionsQuery(
  opts?: Parameters<typeof getPropertyManagementConnections>[2]
) {
  const [teamId] = useTeamId();

  return useQuery(
    (q, { opts, ...args }) => getPropertyManagementConnections(q, args, opts),
    {
      keepPreviousData: true,
      variables: {
        teamId,
        opts,
      },
      queryKey: ['connections'],
    }
  );
}
