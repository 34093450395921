import { Filter } from '@finalytic/components';
import {
  type gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import type { account_bool_exp, paidStatus_enum } from '@finalytic/graphql';
import {
  ArrayParam,
  BooleanParam,
  type SelectItem,
  StringParam,
  useQueryParams,
} from '@finalytic/ui';
import { day, hasValue, isUUID, toTitleCase } from '@finalytic/utils';
import { Box, Group } from '@mantine/core';
import {
  formatOwnerName,
  getListingName,
  orderByAccount,
  orderByOwner,
  whereAccounts,
  whereContacts,
  whereListings,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';

export const useExpenseTableFilter = () => {
  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    listingIds: ArrayParam,
    date: StringParam,
    accountId: StringParam,
    contactId: StringParam,
    paidStatus: StringParam,
    reconciled: BooleanParam,
  });

  return {
    filter,
    setFilter,
    reset: () => {
      const initial = {
        search: undefined,
        listingIds: undefined,
        date: undefined,
        accountId: undefined,
        paidStatus: undefined,
        contactId: undefined,
        reconciled: undefined,
      };

      setFilter(initial);
    },
  };
};

export const ExpenseTableFilter = () => {
  return (
    <Group>
      <Search />
      <Date />
      <BankAccount />
      <PaidStatus />
      <Reconciled />
      <Listings />
      <Contacts />
    </Group>
  );
};

const Date = () => {
  const { filter, setFilter } = useExpenseTableFilter();

  return (
    <Filter.Date
      value={filter.date || undefined}
      setValue={(value) => {
        setFilter({
          date: value
            ?.filter(hasValue)
            .map((date) => day(date).yyyymmdd())
            .join('...'),
        });
      }}
    />
  );
};

const Search = () => {
  const { filter, setFilter } = useExpenseTableFilter();

  return (
    <Filter.Search
      value={filter.search || ''}
      setValue={(v) => setFilter({ search: v })}
    />
  );
};

const Listings = () => {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { filter, setFilter } = useExpenseTableFilter();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const { GL } = useEnabledFeatures();

  const queryData = useInfiniteQuery(
    (q, { teamId, dashboard, search, meId, GL }, { limit, offset }) => {
      const where: gqlV2.listing_bool_exp = whereListings({
        tenantId: teamId,
        dashboard,
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          order_by: [{ calculated_title: 'asc_nulls_last' }],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: getListingName(res),
        }));

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      variables: {
        teamId,
        search: search?.trim(),
        dashboard,
        meId,
        GL,
      },
    }
  );

  const { data } = useQuery(
    (q, { listingIds }) => {
      const value = q
        .listings({ where: { id: { _in: listingIds } } })
        .map<SelectItem>((item) => ({
          label: getListingName(item),
          value: item.id,
        }));

      return {
        value,
      };
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      keepPreviousData: true,
      variables: {
        listingIds: (filter.listingIds || []).filter(hasValue),
      },
    }
  );

  const value = data?.value || [];

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ listingIds: v.map((i) => i.value) })}
      label="Listing"
      type="multiple"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const Contacts = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useExpenseTableFilter();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where = whereContacts({
        search,
        tenantId: teamId,
        type: null,
      });

      const list = q
        .contacts({
          where,
          order_by: [{ type: 'desc_nulls_last' }, orderByOwner],
          limit,
          offset,
        })
        .map<SelectItem>((res) => ({
          value: res.id,
          label: formatOwnerName(res),
          group: toTitleCase(res.type),
        }));

      const aggregate = q.contactAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['contacts'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, { contactId }) => {
      if (!contactId) return null;

      return (
        q
          .contacts({ where: { id: { _eq: contactId } } })
          .map<SelectItem>((item) => ({
            label: formatOwnerName(item),
            value: item.id,
          }))[0] ?? null
      );
    },
    {
      skip: !teamId,
      queryKey: ['reservations', 'listings'],
      keepPreviousData: true,
      variables: {
        contactId: filter.contactId,
      },
    }
  );

  const value = data || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ contactId: v?.value })}
      label="Contact"
      type="single"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
    />
  );
};

const BankAccount = () => {
  const [teamId] = useTeamId();
  const { filter, setFilter } = useExpenseTableFilter();
  const [search, setSearch] = useState('');

  const queryData = useInfiniteQuery(
    (q, { teamId, search }, { limit, offset }) => {
      const where: account_bool_exp = {
        ...whereAccounts({
          tenantId: teamId,
          search,
          type: 'bank',
        }),
        bankAccount: {
          status: { _eq: 'active' },
        },
      };

      const list = q
        .accounts({
          where,
          order_by: [
            { bankAccount: { category: 'desc_nulls_last' } },
            ...orderByAccount,
          ],
          limit,
          offset,
        })
        .map<SelectItem>((account) => ({
          label: account.bankAccount?.name || account.title || 'No name',
          value: account.id,
          group: toTitleCase(account.bankAccount?.category) || '-',
        }));

      const aggregate =
        q
          .accountAggregate({
            where,
          })
          ?.aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: ['accounts'],
      variables: {
        teamId,
        search: search?.trim(),
      },
    }
  );

  const { data } = useQuery(
    (q, args) => {
      if (args.accountId === 'non-trust')
        return {
          label: 'Non-Trust Account',
          value: 'non-trust',
        };

      if (!args.accountId || !isUUID(args.accountId)) return null;

      return (
        q
          .accounts({
            where: {
              id: { _eq: args.accountId },
              tenantId: { _eq: args.teamId },
            },
            limit: 1,
          })
          .map<SelectItem>((x) => ({
            label: x.title!,
            value: x.id,
          }))[0] || null
      );
    },
    {
      skip: !teamId,
      queryKey: ['accounts'],
      keepPreviousData: true,
      variables: {
        accountId: filter.accountId,
        teamId,
      },
    }
  );

  return (
    <Filter.Select
      value={data || null}
      setValue={(v) => setFilter({ accountId: v?.value })}
      type="single"
      label="Bank Account"
      withinPortal
      infiniteData={{ ...queryData, setSearch }}
      customBottomActions={[
        {
          label: 'Non-Trust Account',
          onSubmit: () => setFilter({ accountId: 'non-trust' }),
          id: 'non-trust',
        },
      ]}
    />
  );
};

const PaidStatus = () => {
  const { filter, setFilter } = useExpenseTableFilter();

  const options = useMemo<SelectItem<paidStatus_enum>[]>(() => {
    return [
      {
        label: 'Paid',
        value: 'paid',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.green[6],
            })}
          />
        ),
      },
      {
        label: 'Unpaid',
        value: 'unpaid',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
    ];
  }, []);

  const value = options.find((o) => o.value === filter.paidStatus) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) => setFilter({ paidStatus: v?.value })}
      type="single"
      label="Status"
      withinPortal
      data={{
        options,
      }}
      hideIcon
    />
  );
};

const Reconciled = () => {
  const { filter, setFilter } = useExpenseTableFilter();

  const options = useMemo<SelectItem[]>(() => {
    return [
      {
        label: 'Matched',
        value: 'true',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.green[6],
            })}
          />
        ),
      },
      {
        label: 'Unmatched',
        value: 'false',
        icon: (
          <Box
            w={10}
            h={10}
            sx={(theme) => ({
              alignSelf: 'center',
              borderRadius: theme.radius.xl,
              backgroundColor: theme.colors.yellow[6],
            })}
          />
        ),
      },
    ];
  }, []);

  const value =
    options.find(
      (o) =>
        typeof filter.reconciled === 'boolean' &&
        o.value === (filter.reconciled ? 'true' : 'false')
    ) || null;

  return (
    <Filter.Select
      value={value}
      setValue={(v) =>
        setFilter({
          reconciled:
            typeof v?.value === 'string' ? v.value === 'true' : undefined,
        })
      }
      type="single"
      label="Reconciliation"
      withinPortal
      data={{
        options,
      }}
      hideIcon
    />
  );
};
