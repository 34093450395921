import { Badge } from '@finalytic/components';
import type { accountReservationRevRec_enum } from '@finalytic/graphql';
import type { SelectItem } from '@finalytic/ui';
import { Group, Text } from '@mantine/core';
import { useMemo } from 'react';

export function useRevenueRecognitionOptions(args?: {
  isTeamDefaultSelect: boolean;
}) {
  return useMemo(() => {
    const opts: SelectItem<accountReservationRevRec_enum>[] = [
      {
        label: args?.isTeamDefaultSelect
          ? ((
              <Group gap={5} display="inline-flex">
                <Text>Check-in</Text>
                <Badge
                  variant="outline"
                  size="xs"
                  sx={(theme) => ({
                    color: theme.colors[theme.primaryColor][8],
                  })}
                >
                  Default
                </Badge>
              </Group>
            ) as any)
          : 'Check-in',
        value: 'checkIn',
        // group: 'Reservation',
        description: 'The Check-in date of the reservation',
      },
      {
        label: 'Check-out',
        value: 'checkOut',
        // group: 'Reservation',
        description: 'The Check-out date of the reservation',
      },
      {
        label: 'Booked At',
        value: 'bookedAt',
        // group: 'Reservation',
        description: 'The date on which the reservation was booked',
      },
      {
        label: 'Pro Rata',
        value: 'proRata',
        // group: 'Reservation',
        description:
          'Revenue is split into equal nightly amounts across the entire length of the stay',
      },
    ];

    // if (args?.isTeamDefaultSelect) {
    //   return opts.filter((x) => x.value !== 'proRata');
    // }

    return opts;
  }, [args?.isTeamDefaultSelect]);
}
