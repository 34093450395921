import {
  type gqlV2,
  useDashboard,
  useEnabledFeatures,
  useInfiniteQuery,
  useMe,
  useQuery,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { type SelectItem, showErrorNotification } from '@finalytic/ui';
import { getListingName, whereListings } from '@vrplatform/ui-common';

export type SharedProps = {
  listingId: string;
  listingTeamId: string;
  listingName: string | undefined | null;
  closeDrawer: () => void;
};

export const ListingMigrateModal = ({
  listingTeamId,
  listingId,
  listingName,
  closeDrawer,
  ...props
}: {
  opened: boolean;
  close: () => void;
} & SharedProps) => {
  const { mutate: migrate } = useTrpcMutation('migrateListings', {
    successMessage: {
      title: 'Migration completed!',
      message: 'Successfully migrated the listing.',
    },
    errorMessage: {
      title: 'Migration failed',
      message: (error) => {
        if (error.message.includes('listing_owner_owner_id_listing_id_key')) {
          return 'A duplicate listing ownership already exists for the new listing. Please remove the ownership there or on this listing first to migrate.';
        }

        if (
          error.message.includes('listing_owner_listing_id_new_owner_id_key')
        ) {
          return 'One of the owners already exists on the listing you are trying to migrating to. Please remove the owner there first before continuing.';
        }

        if (error.message.includes('owner_statement_listing_id_start_at_key')) {
          return 'An owner statement for one of the months already exists for the new listing. Please remove the owner statement there or on this listing first to migrate.';
        }
      },
    },
    invalidateQueryKeys: ['listings'],
  });

  const submit = async (newListingId: string) => {
    if (!listingId)
      return showErrorNotification({ message: 'Missing listing id.' });
    const res = await migrate({
      newListing: newListingId,
      oldListing: listingId,
      teamId: listingTeamId,
    });
    if (res?.ok) closeDrawer();
  };

  return (
    <ListingMigrationModal
      {...props}
      onSubmit={submit}
      listingName={listingName}
    />
  );
};

import { Button, InputSelect } from '@finalytic/components';
import { emptyUUID } from '@finalytic/utils';
import { Avatar, Box, Group, Modal, Text, Tooltip, rem } from '@mantine/core';
import { useEffect, useState } from 'react';

type Props = {
  opened: boolean;
  close: () => void;
  onSubmit: (newListingId: string) => Promise<any>;
  listingName?: string | null;
};

const ListingMigrationModal = ({
  close: closeModal,
  opened,
  listingName,
  onSubmit,
}: Props) => {
  const [selectedListing, setSelectedListing] = useState<SelectItem | null>(
    null
  );
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        setSelectedListing(null);
      }, 300);
    }
  }, [opened]);

  const submit = async (newListingId: string | undefined) => {
    setSubmitLoading(true);
    if (!newListingId)
      return showErrorNotification({ message: 'Missing new listing id.' });
    try {
      await onSubmit(newListingId);
    } catch (error: any) {
      console.log(error?.message || error);
    }
    setSubmitLoading(false);
  };

  return (
    <Modal
      centered
      styles={{
        title: { fontWeight: 500 },
      }}
      radius="md"
      opened={opened}
      onClose={closeModal}
      size={550}
      title={`Migrate ${listingName || 'Listing'} Statements`}
      closeOnEscape={false}
    >
      <Box mb="lg" mt="xs">
        <Text component="p">
          Migrating will transfer all statements, ownerships, settings and
          connections from{' '}
          {listingName ? <b>{listingName}</b> : 'the old listing'} to the new
          listing.
        </Text>
        <Text component="p" mb={rem(4)} fw={500} size="xs" mt="lg">
          Select a listing:
        </Text>
        <ListingSelect
          listing={selectedListing}
          setListing={setSelectedListing}
          loadingSubmit={submitLoading}
        />
        <Text component="p" mb={'xl'} size="xs" c="gray">
          Balances will not be updated in case the new listings already has
          owner statements.
        </Text>
      </Box>
      <Group justify={'right'}>
        <Group>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() => submit(selectedListing?.value)}
            loading={submitLoading}
            disabled={!selectedListing?.value}
          >
            Submit
          </Button>
        </Group>
      </Group>
    </Modal>
  );
};

const ListingSelect = ({
  listing,
  setListing,
  loadingSubmit,
}: {
  listing: SelectItem | null;
  setListing: (val: SelectItem | null) => void;
  loadingSubmit: boolean;
}) => {
  const [tenantId] = useTeamId();
  const { id: meId } = useMe();
  const [search, setSearch] = useState('');
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();

  const listingId = listing?.value;

  const queryData = useInfiniteQuery(
    (q, { tenantId, dashboard, search, meId }, { limit, offset }) => {
      // * Query listings
      const where: gqlV2.listing_bool_exp = whereListings({
        tenantId: tenantId,
        dashboard,
        search,
        meId,
        GL,
      });

      const list = q
        .listings({
          where,
          limit,
          offset,
          order_by: [
            {
              calculated_title: 'asc_nulls_last',
            },
          ],
        })
        .map<SelectItem>((listing) => {
          const connection = listing.connections()[0]?.connection;
          const icon = connection?.app?.iconRound;
          const name = connection?.name;

          return {
            label: getListingName(listing),
            value: listing.id,
            icon: icon && (
              <Tooltip label={name} withArrow withinPortal>
                <Avatar size="xs" src={icon} />
              </Tooltip>
            ),
          };
        });

      const aggregate = q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        list,
        aggregate,
      };
    },
    {
      skip: !tenantId,
      queryKey: ['owners', 'listings'],
      variables: {
        tenantId,
        dashboard,
        search: search?.trim(),
        meId,
        GL,
      },
    }
  );

  const { data: data2, isLoading: loading } = useQuery(
    (q, { listingId }) => {
      const value = q
        .listings({ where: { id: { _eq: listingId || emptyUUID } }, limit: 1 })
        .map<SelectItem>((listing) => {
          return {
            label: getListingName(listing),
            value: listing.id,
          };
        })[0];
      return {
        value,
      };
    },
    {
      skip: !listingId,
      queryKey: ['listings'],
      variables: {
        listingId,
      },
    }
  );

  return (
    <InputSelect
      type="single"
      value={data2?.value || null}
      setValue={(value) => {
        setListing(value || null);
      }}
      inputProps={{
        loadingQuery: loading,
        loadingMutation: loadingSubmit,
        placeholder: 'Select listing',
        withClearButton: true,
      }}
      infiniteData={{ ...queryData, setSearch }}
      dropdownProps={{
        width: 'target',
        position: 'bottom-start',
        withinPortal: true,
      }}
    />
  );
};
