import {
  Badge,
  Button,
  IconButton,
  InputPercentage,
  Select,
} from '@finalytic/components';
import {
  type gqlV2,
  useBrowserTracking,
  useEnabledFeatures,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { CopyIcon, Icon, OfficeIcon, UserIcon } from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  type SelectItem,
  StringParam,
  type UrlUpdateType,
  showSuccessNotification,
  useQueryParamSet,
  useQueryParams,
} from '@finalytic/ui';
import { type Maybe, sum, utc } from '@finalytic/utils';
import {
  Alert,
  Avatar,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Title,
  Tooltip,
  rem,
  useMantineColorScheme,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useClipboard, useDebouncedState } from '@mantine/hooks';
import {
  type Address,
  formatAddress,
  formatOwnerName,
  formatPercentage,
  getCountryByIsoCode,
  getStateByIsoCode,
  whereOwners,
} from '@vrplatform/ui-common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ListingOwnerBadge } from '../../../components';
import {
  DrawerCollapsableTable,
  DrawerHeader,
} from '../../../drawers/_components';
import { StatementStatusBadge } from '../../statements/_components';
import {
  ListingEllipsisMenuItems,
  ListingEllipsisMenuModals,
  ListingInfoCard,
} from '../_components';
import { ListingClassMappingSelect } from './ListingClassMappingSelect';
import { ListingEditForm } from './ListingEditForm';
import { ListingPeriodForm } from './ListingPeriodForm';
import {
  type Listing,
  useListingDetailDrawerQuery,
} from './useListingDetailDrawerQuery';

type View = 'overview' | 'edit' | 'record-ownership';

export function useListingDetailDrawer() {
  const [opts, set] = useQueryParams({
    listing: StringParam,
    view: StringParam,
  });

  return {
    opened: !!opts.listing,
    open: (id: string, view?: View, updateType?: UrlUpdateType) =>
      set({ listing: id, view }, updateType),
    close: () => set({ listing: undefined, view: undefined }),
    listingId: opts.listing,
    view: (opts.view || 'overview') as View,
    setView: (view: View) => set({ view }),
  };
}

export const ListingDetailDrawer = () => {
  const { opened, close, listingId, view, setView } = useListingDetailDrawer();
  const { GL } = useEnabledFeatures();

  const goto = useNavigate();

  const { isLoading, data: listing } = useListingDetailDrawerQuery(listingId);

  const [modalOpened, setModalOpened] = useState<'delete' | 'migrate' | null>(
    null
  );

  const closeModal = () => setModalOpened(null);

  const { mutate: updateListing } = useMutation(
    (
      q,
      args: {
        listingId: string;
        tenantId: string;
        inputs: {
          title: string;
          address: Address & { country: string; state: string } & {
            id: Maybe<string>;
          };
        };
      }
    ) => {
      const line = args.inputs.address.line1.trim();
      const line2 = args.inputs.address.line2?.trim() || '';
      const city = args.inputs.address.city.trim();
      const state = args.inputs.address.state?.trim();
      const postalCode = args.inputs.address.postcode.trim();
      const country = args.inputs.address.country.trim();

      return q.insertListing({
        object: {
          id: args.listingId,
          title: args.inputs.title.trim(),
          tenantId: args.tenantId,
          addressRelation: {
            data: {
              id: args.inputs.address.id || undefined,
              line,
              lineDetails: line2,
              city,
              state,
              postalCode,
              country,
              stateCode: args.inputs.address.stateCode,
              countryCode: args.inputs.address.countryCode,
              full: formatAddress({
                line1: line,
                line2,
                city,
                state,
                postcode: postalCode,
                country,
                stateCode: args.inputs.address.stateCode,
                countryCode: args.inputs.address.countryCode,
              }),
              tenant_id: args.tenantId,
            },
            on_conflict: {
              constraint: 'address_pkey',
              update_columns: [
                'line',
                'lineDetails',
                'city',
                'state',
                'postalCode',
                'country',
                'full',
              ],
            },
          },
        },
        on_conflict: {
          constraint: 'listing_pkey',
          update_columns: ['title', 'address_id'],
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['listings'],
    }
  );

  const showMenuItems = view === 'overview' && listing;

  const isDeletedInPms = listing?.sourceStatus === 'deleted';

  const resetView = useCallback(() => {
    const path = window.location.pathname;

    if (path.startsWith('/listing/')) {
      close();
    } else {
      setView('overview');
    }
  }, [close, setView]);

  return (
    <>
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={
            view === 'record-ownership' ? (
              'Add new ownership period'
            ) : (
              <Group wrap="nowrap" gap={rem(8)}>
                {isDeletedInPms && (
                  <Tooltip label="This listing was deleted in your PMS">
                    <Icon
                      icon="AlertTriangleIcon"
                      color={(theme) => theme.colors.orange[7]}
                      size={20}
                    />
                  </Tooltip>
                )}
                <Title order={3} display="block">
                  {listing?.name || 'Missing name'}
                </Title>
              </Group>
            )
          }
          type="Listing"
          loading={isLoading}
          menuItems={
            showMenuItems && (
              <ListingEllipsisMenuItems
                listing={{
                  listingId: listing.id,
                  isPmsListing: listing.isPmsListing,
                  ownerStatements: listing.ownerStatements.length,
                  listingConnections: listing.listingConnections.length,
                }}
                handlers={{
                  openDeleteModal: () => setModalOpened('delete'),
                  openMigrationModal: () => setModalOpened('migrate'),
                }}
              />
            )
          }
        >
          {showMenuItems && GL && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                goto(`/listing/${listing.id}`);
              }}
              variant="outline"
              icon="ArrowRightCircleIcon"
              tooltip="Go to detail"
            />
          )}
        </DrawerHeader>
        {!listing && !isLoading ? (
          'No listing found'
        ) : view === 'edit' && listing ? (
          <ListingEditForm
            initialValues={{
              address: listing.address.values || '',
              title: listing.title || '',
            }}
            listingName={listing.pmsName || ''}
            isPmsConnection={listing.isPmsListing}
            onReset={resetView}
            isLoading={isLoading}
            handleSubmit={async (values) => {
              updateListing({
                args: {
                  inputs: {
                    title: values.title,
                    address: {
                      id: listing.addressId,
                      line1: values.address.line1,
                      line2: values.address.line2,
                      city: values.address.city,
                      postcode: values.address.postcode,
                      countryCode: values.address.countryCode,
                      stateCode: values.address.stateCode || '',
                      country:
                        getCountryByIsoCode(values.address.countryCode)?.name ||
                        '',
                      state:
                        getStateByIsoCode(
                          values.address.stateCode,
                          values.address.countryCode
                        )?.name || '',
                    },
                  },
                  listingId: listing.id,
                  tenantId: listing.tenantId,
                },
              }).then(() => resetView());
            }}
          />
        ) : view === 'record-ownership' && listing ? (
          <ListingPeriodForm
            initialValues={undefined}
            onReset={() => setView('overview')}
            loadingQuery={isLoading}
            previousValues={listing.currentPeriod}
            listingId={listing.id}
          />
        ) : (
          <ListingDetail listing={listing} isLoading={isLoading} />
        )}
        <ListingEllipsisMenuModals
          listing={listing || null}
          deleteModal={{
            opened: modalOpened === 'delete',
            closeModal,
          }}
          migrationModal={{
            opened: modalOpened === 'migrate',
            closeModal,
          }}
        />
      </Drawer>
    </>
  );
};

const ListingDetail = ({
  listing,
  isLoading,
}: { listing: Maybe<Listing>; isLoading: boolean }) => {
  const { GL } = useEnabledFeatures();

  if (!listing) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <ListingInfoCard {...listing} formattedAddress={listing.address.full} />

      {GL ? (
        <ListingPeriod period={listing.currentPeriod} />
      ) : (
        <>
          <ListingClassMappingSelect listingId={listing.id} />
          <ListingOwnerships
            listingId={listing.id}
            ownerships={listing.ownerships}
          />
        </>
      )}

      <Connections
        listingConnections={listing.listingConnections}
        listingId={listing.id}
      />

      <Statements statements={listing.ownerStatements} listingId={listing.id} />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const ListingOwnerships = ({
  ownerships,
  listingId,
}: { ownerships: Listing['ownerships']; listingId: string }) => {
  const { data, refetch } = useQuery(
    (q, args) => {
      const listingOwners = q
        .listingOwners({
          where: {
            id: { _in: args.ids },
          },
        })
        .map((x) => x.split)
        .filter((x) => typeof x === 'number');

      return {
        listingOwners,
        sum: sum(listingOwners),
      };
    },
    {
      variables: {
        ids: ownerships.map((x) => x.id),
      },
    }
  );

  const total =
    typeof data?.sum === 'number'
      ? data.listingOwners.length === 0
        ? 100
        : data.sum
      : sum(ownerships.map((x) => x.split || 0));

  const columns = useMemo<MRT_ColumnDef<Listing['ownerships'][number]>[]>(
    () => [
      {
        header: 'Listing',
        accessorKey: 'listing.name',
        Cell: ({ row }) => {
          const ownership = row.original;

          return (
            <ListingOwnerBadge
              role={ownership.role}
              name={ownership.owner.name}
            />
          );
        },
      },
      {
        header: 'Split',
        accessorKey: 'split',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const split = row.original.split;
          const initialValue = typeof split === 'number' ? split : '';
          const [value, setValue] = useDebouncedState<number | string>(
            initialValue,
            500
          );
          const ownershipId = row.original.id;

          useEffect(() => {
            setValue(initialValue);
          }, [initialValue, setValue]);

          const { track } = useBrowserTracking();

          const { mutate, loading } = useMutation(
            (q, args: { ownershipId: string; split: number | null }) => {
              return q.updateListingOwner({
                pk_columns: {
                  id: args.ownershipId,
                },
                _set: {
                  split: args.split,
                },
              })?.id;
            }
          );

          return (
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                gap: theme.spacing.xs,
              })}
            >
              <InputPercentage
                height={20}
                maw={90}
                value={value}
                onChange={setValue}
                loadingMutation={loading}
                placeholder="AUTO"
                hideControls
                decimalScale={2}
                onBlur={(event) => {
                  const newValue = event.target.value
                    ? parseFloat(event.target.value)
                    : '';

                  mutate({
                    args: {
                      ownershipId,
                      split: typeof newValue === 'number' ? newValue : null,
                    },
                  }).then(() => {
                    setValue(newValue);
                    refetch();
                    track('ownership_updated', {
                      listingId: listingId,
                      ownershipId: ownershipId,
                      ownerId: row.original.owner.id,
                    });
                  });
                }}
              />
            </Box>
          );
        },
      },
    ],
    [listingId, refetch]
  );

  return (
    <Box>
      <DrawerCollapsableTable
        title="Listing ownerships"
        rightSection={
          <Group wrap="nowrap">
            {ownerships.length > 0 && (
              <Text size="sm" color="gray">
                {total}%
              </Text>
            )}
            <AddOwnerToListing listingId={listingId} />
          </Group>
        }
        rowData={ownerships}
        columns={columns}
        emptyRowsFallback={() => (
          <Center>
            <Text size="sm" color="gray">
              No listing ownerships
            </Text>
          </Center>
        )}
        rowMenu={{
          menuItems: ({ row }) => {
            const { copy, copied } = useClipboard();
            const { track } = useBrowserTracking();

            const ownershipId = row.original.id;

            const { mutate, loading } = useMutation(
              (q, args: { ownershipId: string }) => {
                return q.deleteListingOwner({
                  id: args.ownershipId,
                })?.id;
              },
              {
                invalidateQueryKeys: ['listings', 'owners'],
              }
            );

            useEffect(() => {
              if (copied)
                showSuccessNotification({
                  message: 'Ownership ID copied to clipboard',
                });
            }, [copied]);

            return (
              <>
                <EllipsisMenuDangerItem
                  loading={loading}
                  onClick={() =>
                    mutate({
                      args: {
                        ownershipId,
                      },
                    }).then(() => {
                      track('ownership_deleted', {
                        listingId: listingId,
                        ownershipId: ownershipId,
                        ownerId: row.original.owner.id,
                      });
                    })
                  }
                >
                  Delete ownership
                </EllipsisMenuDangerItem>

                <HiddenFeatureIndicator permission="super-admin">
                  <EllipsisMenuDivider />
                  <EllipsisMenuItem
                    customIcon={<CopyIcon size={18} />}
                    onClick={() => copy(ownershipId)}
                  >
                    Copy ownership ID
                  </EllipsisMenuItem>
                </HiddenFeatureIndicator>
              </>
            );
          },
        }}
      />
      <Alert
        styles={{
          root: {
            maxHeight: !!total && total !== 100 ? 77 : 0,
            paddingBlock: !!total && total !== 100 ? undefined : 0,
            border: 'none',
            transition: 'all 500ms ease-in-out',
            overflow: 'hidden',
            height: '100%',
          },
        }}
        mt="xs"
        variant="light"
        color="yellow"
        icon={
          <Icon
            color={(theme) => theme.colors.yellow[7]}
            icon="AlertTriangleIcon"
            size={16}
          />
        }
        title={`Split Validation (${total}%)`}
        radius="md"
      >
        Split total should be 100%. Please adjust the split values.
      </Alert>
    </Box>
  );
};

const ListingPeriod = ({ period }: { period: Listing['currentPeriod'] }) => {
  const setOwner = useQueryParamSet('owner', StringParam);
  const { colorScheme } = useMantineColorScheme();
  const { setView } = useListingDetailDrawer();

  const rows = period?.members || [];

  return (
    <DrawerCollapsableTable
      title="Current Ownership"
      rightSection={
        <Button
          variant="light"
          leftIcon={'PlusIcon'}
          onClick={() => setView('record-ownership')}
        >
          Record {rows.length ? 'change' : 'ownership'}
        </Button>
      }
      rowData={rows}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const member = row.original;

            return (
              <Badge
                color={member.ownerType === 'company' ? 'violet' : undefined}
                leftIcon={
                  <Icon
                    icon={
                      member.ownerType === 'company' ? 'OfficeIcon' : 'UserIcon'
                    }
                    size={14}
                    style={{ marginRight: 5 }}
                    color={(theme) =>
                      theme.colors[
                        member.ownerType === 'company'
                          ? 'violet'
                          : theme.primaryColor
                      ][5]
                    }
                  />
                }
                sx={{
                  color: colorScheme === 'dark' ? undefined : 'black',
                }}
              >
                {member.ownerName}
              </Badge>
            );
          },
        },
        {
          header: 'Split',
          accessorKey: 'split',
          maxSize: 150,
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            const data = row.original;
            return formatPercentage(
              data.ownerSplitBasePoints || 0,
              'base-points'
            );
          },
        },
      ]}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No current period
          </Text>
        </Center>
      )}
      onRowClick={{
        handler: (row) => setOwner(row.original.ownerId, 'push'),
      }}
    />
  );
};

const Statements = ({
  statements,
  listingId,
}: { statements: Listing['ownerStatements']; listingId: string }) => {
  const goto = useNavigate();

  return (
    <DrawerCollapsableTable
      title="Owner statements"
      rightSection={null}
      rowData={statements}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const statement = row.original;

            return (
              <Box>
                <Text component="span" display="block" size="sm">
                  {utc(statement.startAt).format('MMM YYYY')}
                </Text>
                {statement.listing.name && (
                  <Text component="span" display="block" size="xs" color="gray">
                    {statement.listing.name}
                  </Text>
                )}
              </Box>
            );
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          maxSize: 150,
          mantineTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ row }) => {
            const statement = row.original;

            return <StatementStatusBadge status={statement.status} />;
          },
        },
      ]}
      onRowClick={{
        handler: (row) =>
          goto(
            `/statement/${row.original.listing.id}?date=${utc(
              row.original.startAt
            ).yyyymmdd()}&listing=${listingId}`
          ),
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No owner statements
          </Text>
        </Center>
      )}
    />
  );
};

const Connections = ({
  listingConnections,
}: {
  listingConnections: Listing['listingConnections'];
  listingId: string;
}) => {
  return (
    <DrawerCollapsableTable
      title="Connections"
      rightSection={null}
      rowData={listingConnections}
      columns={[
        {
          header: 'Owner',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const listingConnection = row.original;

            return (
              <Group wrap="nowrap" gap="sm">
                <Avatar
                  src={listingConnection.icon}
                  size="sm"
                  styles={{
                    placeholder: { visibility: 'hidden' },
                  }}
                  sx={(theme) => ({
                    border: `1px solid ${theme.colors.gray[2]}`,
                  })}
                />
                <Box>
                  <Text size="sm" component="p" m={0}>
                    {listingConnection.name}
                  </Text>
                  <Text size="xs" component="p" color="gray" m={0}>
                    {listingConnection.app}
                  </Text>
                </Box>
              </Group>
            );
          },
        },
      ]}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No connections
          </Text>
        </Center>
      )}
    />
  );
};

const AddOwnerToListing = ({ listingId }: { listingId: string }) => {
  const [teamId] = useTeamId();
  const [search, setSearch] = useState('');
  const { track } = useBrowserTracking();

  const queryData = useInfiniteQuery(
    (q, { teamId, search, listingId }, { limit, offset }) => {
      const where: gqlV2.owner_bool_exp = {
        ...whereOwners({
          search,
          teamId,
        }),
        _not: {
          ownerships: {
            listingId: {
              _eq: listingId,
            },
          },
        },
      };

      const list = q
        .owners({
          where,
          limit,
          offset,
          order_by: [
            {
              type: 'asc_nulls_last',
            },
            {
              name: 'asc_nulls_last',
            },
          ],
        })
        .map<SelectItem>((owner) => ({
          label: formatOwnerName(owner) || '',
          value: owner.id,
          group: owner.type === 'company' ? 'Company' : 'Individual',
          icon:
            owner.type === 'company' ? (
              <OfficeIcon size={16} />
            ) : (
              <UserIcon size={16} />
            ),
        }));

      const aggregate = q.ownerAggregate({ where }).aggregate?.count() || 0;
      return {
        list,
        aggregate,
      };
    },
    {
      skip: !teamId,
      queryKey: 'owners',
      variables: {
        teamId,
        search: search?.trim(),
        listingId,
      },
    }
  );

  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        ownerId: string;
        listingId: string;
      }
    ) => {
      return q.insertListingOwner({
        object: {
          newOwnerId: args.ownerId,
          listingId: args.listingId,
          role: 'owner',
        },
      })?.id as string | undefined;
    },
    {
      invalidateQueryKeys: ['listings'],
    }
  );

  return (
    <Select
      infiniteData={{ ...queryData, setSearch }}
      type="single"
      value={null}
      setValue={(value) => {
        if (!value) return;
        const ownerId = value.value;
        console.log(ownerId);

        mutate({ args: { ownerId, listingId } }).then((res) => {
          track('ownership_created', {
            listingId: listingId,
            ownerId: ownerId,
            ownershipId: res,
          });
        });
      }}
      dropdownProps={{
        position: 'bottom-end',
        noOptionsText: 'No owners available',
      }}
    >
      {() => {
        return (
          <IconButton icon="PlusIcon" loading={loading} onClick={undefined} />
        );
      }}
    </Select>
  );
};
