import { useAutomationsQuery } from '../../queries';

type EditorTypeUnion =
  | 'expression'
  | 'select'
  | 'percentage'
  | 'text'
  | 'number';

export function useTabEditorType({
  automationId,
  settingKey,
  rightSchema,
}: {
  automationId: string | undefined;
  settingKey: string | undefined;
  rightSchema?: string;
}): {
  rightEditorType: EditorTypeUnion;
  rightEditorParams?: any;
} {
  const { data: automations = [] } = useAutomationsQuery();

  const mappings = automations.find(
    (i) => i.automationId === automationId
  )?.mappings;

  if (!mappings) return { rightEditorType: 'select' };

  const tableTemplate = mappings[settingKey || ''];
  const schemaType = (rightSchema || tableTemplate.right.schema).split(
    '.'
  )[1] as EditorTypeUnion;
  const rightEditorParams = tableTemplate.right.params;
  const editorTypes: EditorTypeUnion[] = [
    'expression',
    'percentage',
    'select',
    'text',
    'number',
  ];

  const rightEditorType: EditorTypeUnion = editorTypes.includes(schemaType)
    ? schemaType
    : 'select';

  return {
    rightEditorType,
    rightEditorParams,
  };
}
