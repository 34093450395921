import type { CalendarFilterDateType } from '@finalytic/components';
import { useTeamId } from '@finalytic/data';
import { day, ensure, hasValue } from '@finalytic/utils';
import {
  type PaymentsFilterParams,
  wherePayments,
} from '@vrplatform/ui-common';
import { useCallback, useMemo } from 'react';
import { usePaymentFilter } from './PaymentFilter';

export function useWherePaymentsBase(input?: PaymentsFilterParams) {
  const [currentTeamId] = useTeamId();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const filter = useMemo(
    () =>
      wherePayments({
        ...input,
        tenantId: currentTeamId,
      }),
    [JSON.stringify(input), currentTeamId]
  );
  return filter;
}

export function useWherePayments() {
  const filter = useFormattedPaymentFilter();

  return useWherePaymentsBase(filter);
}

export function useFormattedPaymentFilter(): PaymentsFilterParams {
  const { filter } = usePaymentFilter();
  const [currentTeamId] = useTeamId();

  const getDate = useCallback((date: string | undefined | null) => {
    if (!date) return undefined;

    const [start, end] = date.split('...');

    return ensure<CalendarFilterDateType>([
      start ? day(start).toDate() : null,
      end ? day(end).toDate() : null,
    ]);
  }, []);

  return useMemo(
    () => ({
      date: getDate(filter.date),
      amount: filter.amount || undefined,
      connectionName: filter.connectionId || undefined,
      connectionApp: filter.appId || undefined,
      listingIds: filter.listingIds?.filter(hasValue) || undefined,
      type2: filter.lineTypes?.filter(hasValue) || undefined,
      search: filter.search || undefined,
      automationStatus: filter.automationResult || undefined,
      listingCollectionId: filter.listingCollectionId || undefined,
      tenantId: currentTeamId,
    }),
    [
      getDate,
      filter.date,
      filter.amount,
      filter.connectionId,
      filter.appId,
      filter.listingIds,
      filter.lineTypes,
      filter.search,
      filter.automationResult,
      filter.listingCollectionId,
      currentTeamId,
    ]
  );
}
