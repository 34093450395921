export const formatPercentage = (
  val: number | string,
  type: 'legacy' | 'base-points' = 'legacy'
) => {
  if (typeof val === 'string') val = Number(val);

  // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
  if (isNaN(val)) return 'Invalid percentage';

  if (type === 'base-points') val = val / 10000;

  return val.toLocaleString('en', {
    style: 'percent',
    maximumFractionDigits: 2,
  });
};
