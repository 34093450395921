import { useTeamId, useTrpcMutation } from '@finalytic/data';
import type { inferTrpcRouteOutput } from '@finalytic/trpc-api';
import { hideNotification, showLoadingNotification } from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import { captureException } from '@sentry/react';
import { useCallback, useId } from 'react';
import { useRunDrawer } from './useRunDrawer';

export const useExtractMutation = () => {
  const [teamId] = useTeamId();
  const { setWorkflowIds } = useRunDrawer();
  const notifyId = useId();
  const { mutate, loading } = useTrpcMutation('extractMultiple', {
    notificationId: notifyId,
  });

  const extract = useCallback(
    async ({
      connectionId,
      params,
      extractor,
      forceUpdate,
    }: {
      connectionId: string;
      extractor: string;
      forceUpdate?: boolean;
      params: {
        uniqueRef?: string | null;
        date?: string | null;
      };
    }) => {
      try {
        showLoadingNotification({
          id: notifyId,
        });
        const results = await mutate({
          teamId,
          connections: [
            {
              forceUpdate,
              connectionId,
              params,
              type: extractor,
            },
          ],
        });

        const workflowIds = results
          .map(
            (i: inferTrpcRouteOutput<'extractMultiple'>[number]) => i.workflowId
          )
          .filter(hasValue);
        const syncIds = results
          .map((i: inferTrpcRouteOutput<'extractMultiple'>[number]) => i.syncId)
          .filter(hasValue);

        hideNotification({ id: notifyId });
        setWorkflowIds(workflowIds, syncIds, {
          type: 'push',
        });
      } catch (error: any) {
        console.error(error);
        captureException(error);
        // updateErrorNotification({
        //   id: notifyId,
        //   title: 'Failed to refetch',
        //   message:
        //     error.message ||
        //     'Failed to refetch. Please reach out to us if the issue persists.',
        // });
      }
    },
    [notifyId, mutate, setWorkflowIds, teamId]
  );

  return {
    mutate: extract,
    loading,
  };
};
