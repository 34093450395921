import { Button } from '@finalytic/components';
import { useExtension } from '@finalytic/data';
import { InputsByJSONSchema, InputsBySchema } from '@finalytic/data-ui';
import { Anchor, Box, Stack, Text } from '@mantine/core';
import type {
  InputFormSchema,
  SchemaInputsStateType,
} from '@vrplatform/ui-common';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useConnectionModalContext } from '../_context';
import { ConnectingContainer } from '../components/ConnectingContainer';

export const ConnectionParametersModalScreen = () => {
  const { app, setView, issue, connectApp, resetView } =
    useConnectionModalContext();

  const [isRedirected, setIsRedirected] = useState(false);
  const { extension } = useExtension();

  const methods = useForm({ mode: 'onChange' });

  const formIsValid = ['link', 'oauth'].includes(issue?.action.type || '')
    ? isRedirected
    : methods.formState.isValid;

  const confirmParameters = (data: SchemaInputsStateType) => {
    const input = data;

    // hardcode for testing connection
    if (
      issue?.action.type === 'form' &&
      ['test', 'testPms'].includes(app?.id || '')
    ) {
      input.type = 'form';
    }

    connectApp({ input, sessionKey: issue?.state.sessionKey });
  };

  useEffect(() => {
    if (!issue) setView('list-view');

    if (['link', 'oauth'].includes(issue?.action.type || ''))
      setIsRedirected(false);
  }, [issue]);

  const redirect = () => {
    if (issue?.action.type === 'link')
      window.open(issue?.action.value as string);
    else if (issue?.action.type === 'oauth')
      window.location.href = issue?.action.value as string;
    setIsRedirected(true);
  };

  return (
    <ConnectingContainer
      icon={app?.iconRound}
      text={
        issue?.message && (
          <Text fw={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
            {issue.message}
          </Text>
        )
      }
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          minWidth: '100%',
          [`@media (min-width: ${theme.breakpoints.xs})`]: {
            minWidth: 380,
          },
        })}
      >
        {issue?.action.type === 'form' && issue.action.value.properties ? (
          <InputsByJSONSchema
            schema={issue?.action.value as any}
            methods={methods}
          />
        ) : issue?.action.type === 'form' ? (
          <InputsBySchema
            schema={issue.action.value as InputFormSchema}
            methods={methods}
          />
        ) : null}

        {issue?.action.type === 'extension' && (
          <Text my="sm" size="md" component="p" m={0} ta="center">
            Please connect to {app?.name} using our{' '}
            <Anchor
              onClick={resetView}
              href={extension.store_url}
              target="_blank"
              fw={'bold'}
              inherit
              sx={{ textDecoration: 'underline' }}
            >
              browser extension
            </Anchor>
            .
          </Text>
        )}

        {['link', 'oauth'].includes(issue?.action.type || '') && (
          <Button
            variant="primary"
            data-testid="oauth-connection"
            onClick={redirect}
            disabled={isRedirected}
          >
            {issue?.action.type === 'link'
              ? `Open to ${new URL(issue?.action.value as string).host} ...`
              : `Redirect to ${
                  new URL(issue?.action.value as string).host
                } ...`}
          </Button>
        )}
      </Box>

      {/* Buttons */}
      <Stack
        sx={(theme) => ({
          width: '100%',
          margin: '10px auto 0',
          maxWidth: '100%',
          [`@media (min-width: ${theme.breakpoints.xs})`]: {
            maxWidth: 380,
          },
        })}
      >
        {issue?.action.type !== 'oauth' &&
          issue?.action.type !== 'extension' && (
            <Button
              variant="primary"
              onClick={methods.handleSubmit(confirmParameters)}
              data-testid="confirm-parameters"
              disabled={!formIsValid}
              sx={{ height: 40 }}
            >
              Submit
            </Button>
          )}

        <Button onClick={resetView}>Cancel</Button>
      </Stack>
    </ConnectingContainer>
  );
};
