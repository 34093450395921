import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Button,
  Input,
  InputPassword,
  InputWrapper,
  passwordRegex,
} from '@finalytic/components';
import {
  useEnabledFeatures,
  useMe,
  useMutation,
  useQuery,
  useTeam,
  useTeamId,
  useTrpcMutation,
} from '@finalytic/data';
import { EmailIcon, RocketIcon } from '@finalytic/icons';
import {
  Modal,
  StringParam,
  showErrorNotification,
  showSuccessNotification,
  useAppName,
  useQueryParam,
} from '@finalytic/ui';
import { isUUID } from '@finalytic/utils';
import {
  Box,
  Card,
  Center,
  Group,
  Skeleton,
  Stack,
  Switch,
  Text,
  Title,
  rem,
  useMantineTheme,
} from '@mantine/core';
import {
  type Address,
  type NotificationSettingKey,
  getCountryByIsoCode,
  getStateByIsoCode,
  getUserAddress,
} from '@vrplatform/ui-common';
import { Controller, useForm } from 'react-hook-form';

type FormInputs = {
  firstName: string;
  lastName: string;
  address?: Address;
  taxId?: string;
  password: string;
  passwordConfirm: string;
  emailNotifications: boolean;
};

export function useIsWelcomeModalOpen() {
  const { user } = useUser();
  const { actor } = useAuth();

  const [resetPasswordParam] = useQueryParam('resetPassword', StringParam);

  if (actor?.sub) return false;

  return (
    !!user?.unsafeMetadata?.needPasswordChange ||
    !!user?.unsafeMetadata?.need_password_change ||
    !!resetPasswordParam
  );
}

function useWelcomeUserModalQuery() {
  const opened = useIsWelcomeModalOpen();
  const { id: meId } = useMe();

  return useQuery(
    (q, args) => {
      if (!args.meId || !isUUID(args.meId)) return null;

      return q
        .user({
          where: {
            id: { _eq: args.meId },
          },
          limit: 1,
        })
        .map((user) => ({
          firstName: user.firstName?.trim() ?? '',
          lastName: user.lastName?.trim() ?? '',
          id: user.id,
          address: { ...getUserAddress(user).values, id: user.address_id },
          taxId: user.taxId ?? '',
          notificationPreferences: user.notificationPreferences() ?? [],
        }))[0];
    },
    {
      skip: !opened || !meId,
      variables: {
        meId,
      },
      keepPreviousData: true,
      queryKey: ['users'],
    }
  );
}

export const WelcomeUserModal = () => {
  const theme = useMantineTheme();
  const [teamId] = useTeamId();
  const { user } = useUser();
  const { LIVEBLOCKS_NOTIFICATIONS } = useEnabledFeatures();

  const { mutate: updateClerkUser } = useTrpcMutation('updateClerkUser', {
    errorMessage: {
      title: 'Failed to update password',
      message: (error) => error.message.replace('Clerk Error: ', ''),
    },
  });
  const { userId: clerkUserId } = useAuth();
  const { id: meId } = useMe();
  const { data: userData, ...queryData } = useWelcomeUserModalQuery();
  const [{ role }] = useTeam();
  const { updateUserName: updateDbUser } = useUserMutation();

  const { mutate: mutateNewNotificationPreferences } = useMutation(
    (
      q,
      args: {
        tenantId: string;
        userId: string;
        notificationPreferences: NotificationSettingKey[];
      }
    ) => {
      return (
        q.updateTenantUser({
          where: {
            tenantId: { _eq: args.tenantId },
            userId: { _eq: args.userId },
          },
          _set: {
            notificationPreferences: args.notificationPreferences,
          },
        })?.affected_rows || 0
      );
    }
  );

  const [resetPasswordParam, setResetPasswordParam] = useQueryParam(
    'resetPassword',
    StringParam
  );

  const opened = useIsWelcomeModalOpen();

  const type = resetPasswordParam ? 'password-reset' : 'welcome';

  const { primaryColor, colors } = useMantineTheme();
  const { appName } = useAppName();

  const { control, handleSubmit, setError, formState } = useForm<FormInputs>({
    values: userData
      ? {
          emailNotifications: true,
          password: '',
          passwordConfirm: '',
          firstName: userData.firstName,
          lastName: userData.lastName,
          address: userData.address
            ? {
                city: userData.address.city || '',
                line1: userData.address.line1 || '',
                line2: userData.address.line2 || '',
                postcode: userData.address.postcode || '',
                stateCode: userData.address.stateCode || '',
                countryCode: userData.address.countryCode || '',
              }
            : undefined,
          taxId: userData.taxId,
        }
      : undefined,
  });

  const submit = async ({
    password,
    firstName,
    lastName,
    passwordConfirm,
    emailNotifications,
    address: newAddress,
    taxId: newTaxId,
  }: FormInputs) => {
    if (!user) {
      return showErrorNotification({
        title: 'Internal Error',
        message: 'User not found. Please refresh the page.',
        color: 'yellow',
      });
    }

    if (password.trim() !== passwordConfirm.trim()) {
      return setError('passwordConfirm', {
        type: 'validation',
        message: 'Password and Confirm Password must be the same.',
      });
    }

    const welcomePromise = async () => {
      if (type !== 'welcome') return Promise.resolve();

      const settingKey = 'owner-statements.status-change.email';
      let notifications = [...(userData?.notificationPreferences ?? [])];

      if (!emailNotifications) {
        notifications = notifications.filter((n) => n !== settingKey);
      } else {
        if (!notifications.includes(settingKey)) {
          notifications.push(settingKey);
        }
      }

      await Promise.all([
        updateDbUser({
          id: meId,
          firstName,
          lastName,
          notificationPreferences: LIVEBLOCKS_NOTIFICATIONS
            ? []
            : notifications,
          address: newAddress?.line1?.trim() ? newAddress : null,
          addressId: userData?.address?.id,
          taxId: newTaxId,
        }),
        (async () =>
          LIVEBLOCKS_NOTIFICATIONS
            ? mutateNewNotificationPreferences({
                args: {
                  tenantId: teamId,
                  userId: meId,
                  notificationPreferences: emailNotifications
                    ? []
                    : ['owner-statements.published.disabled'],
                },
              })
            : null)(),
      ]);
    };

    if (!clerkUserId)
      return Promise.reject(
        'Please refresh the page. If the problem persists, contact support.'
      );

    return await Promise.all([
      updateClerkUser({
        teamId,
        clerkUserId,
        data: {
          pw: password,
          first_name: type === 'welcome' ? firstName : undefined,
          last_name: type === 'welcome' ? lastName : undefined,
          unsafe_metadata: {},
        },
      }),
      welcomePromise(),
    ]).then(() => {
      showSuccessNotification({
        title: type === 'password-reset' ? 'Success' : 'Welcome to VRP!',
        id: `${type}-success-notification`,
        message:
          type === 'password-reset'
            ? 'Your password was changed.'
            : "You're all set up and ready to go.",
      });

      user.reload();
      setResetPasswordParam(undefined);
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={() => null}
      withCloseButton={false}
      size={500}
      styles={{
        body: {
          paddingBlock: rem(40),
          paddingInline: rem(40),
        },
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(submit, (errors) => {
          console.log(errors);
        })}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: rem(48),
        }}
      >
        {type === 'welcome' && (
          <Center>
            <RocketIcon
              color={colors[primaryColor][6]}
              size={45}
              strokeWidth={1}
            />
          </Center>
        )}
        <Box>
          <Title ta="center" size={rem(20)} mb={rem(24)}>
            {type === 'welcome' ? `Welcome to ${appName}` : 'Reset password'}
          </Title>
          <Text c="neutral" ta="center" size="sm">
            {type === 'welcome'
              ? 'We are thrilled to have you on board. Setup your name, password and email preferences.'
              : 'Please enter your new password. You will logged in automatically and can start using the platform.'}
          </Text>
        </Box>

        {queryData.isLoading ? (
          <Stack>
            <Skeleton h={30} />
            <Skeleton h={30} />
            <Skeleton h={30} />
          </Stack>
        ) : (
          <>
            <Stack>
              {type === 'welcome' && (
                <Group
                  wrap="nowrap"
                  align="flex-start"
                  sx={{
                    '> div': {
                      flex: 1,
                    },
                  }}
                >
                  <Controller
                    control={control}
                    name="firstName"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter your first name.',
                      },
                    }}
                    render={({
                      field: { value, onChange, name },
                      fieldState: { error },
                    }) => {
                      return (
                        <InputWrapper
                          label={'First name'}
                          error={error?.message}
                          required
                        >
                          <Input
                            value={value}
                            setValue={onChange}
                            name={name}
                            placeholder="First name"
                            error={error?.message}
                            autoComplete="given-name"
                            type="text"
                            required
                          />
                        </InputWrapper>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="lastName"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter your first name.',
                      },
                    }}
                    render={({
                      field: { value, onChange, name },
                      fieldState: { error },
                    }) => {
                      return (
                        <InputWrapper
                          label={'Last name'}
                          error={error?.message}
                          required
                        >
                          <Input
                            value={value}
                            setValue={onChange}
                            name={name}
                            placeholder="Last name"
                            error={error?.message}
                            autoComplete="family-name"
                            type="text"
                            required
                          />
                        </InputWrapper>
                      );
                    }}
                  />
                </Group>
              )}

              <Controller
                control={control}
                name="password"
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a password.',
                  },
                  pattern: {
                    value: passwordRegex,
                    message:
                      'Please enter a lower/uppercase letter, one number, one special symbol and at least 8 characters.',
                  },
                }}
                render={({
                  field: { value, onChange, name },
                  fieldState: { error },
                }) => {
                  return (
                    <InputWrapper
                      label={'Password'}
                      error={error?.message}
                      required
                    >
                      <InputPassword
                        value={value}
                        onChange={onChange}
                        name={name}
                        error={error?.message}
                        autoComplete="new-password"
                      />
                    </InputWrapper>
                  );
                }}
              />

              <Controller
                control={control}
                name="passwordConfirm"
                rules={{
                  required: {
                    value: true,
                    message: 'Please confirm your password.',
                  },
                }}
                render={({
                  field: { value, onChange, name },
                  fieldState: { error },
                }) => {
                  return (
                    <InputWrapper
                      label={'Confirm password'}
                      error={error?.message}
                      required
                    >
                      <InputPassword
                        value={value}
                        onChange={onChange}
                        name={name}
                        error={error?.message}
                        withPopover={false}
                        autoComplete="off"
                      />
                    </InputWrapper>
                  );
                }}
              />
            </Stack>

            {type === 'welcome' && role === 'owner' && (
              <Controller
                control={control}
                name="emailNotifications"
                defaultValue={true}
                render={({ field: { value, onChange, name }, fieldState }) => {
                  return (
                    <Card
                      withBorder
                      sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        gap: rem(12),
                        borderColor: fieldState.error?.message
                          ? theme.colors.red[6]
                          : undefined,
                      })}
                    >
                      <Center>
                        <EmailIcon size={18} />
                      </Center>
                      <Switch
                        checked={!!value}
                        onChange={onChange}
                        name={name}
                        label="I want to receive important platform notifications by email"
                        labelPosition="left"
                        styles={{
                          body: {
                            alignItems: 'center',
                          },
                        }}
                        color={theme.colors[theme.primaryColor][6]}
                        error={!!fieldState.error}
                      />
                    </Card>
                  );
                }}
              />
            )}
          </>
        )}

        <Button
          variant="primary"
          type="submit"
          loading={formState.isSubmitting}
          disabled={queryData.isLoading}
        >
          {type === 'welcome' ? `Start with ${appName}` : 'Reset password'}
        </Button>
      </Box>
    </Modal>
  );
};

function useUserMutation() {
  const [teamId] = useTeamId();
  const { mutate } = useTrpcMutation('updateOwnerUser');

  return {
    updateUserName: ({
      firstName,
      id,
      lastName,
      notificationPreferences,
      address,
      addressId,
      taxId,
    }: {
      id: string;
      firstName: string;
      lastName: string;
      notificationPreferences: string[];
      addressId: string | undefined;
      address: Address | null;
      taxId: string | undefined;
    }) =>
      mutate({
        ownerId: id || 'x',
        tenantId: teamId,
        input: {
          firstName,
          lastName,
          notificationPreferences,
          taxId: taxId || '',
          address: !address
            ? null
            : {
                id: addressId,
                line1: address.line1.trim(),
                line2: address.line2?.trim() || '',
                city: address.city.trim(),
                postcode: address.postcode.trim(),
                countryCode: address.countryCode,
                stateCode: address.stateCode || '',
                country: getCountryByIsoCode(address.countryCode)?.name || '',
                state:
                  getStateByIsoCode(address.stateCode, address.countryCode)
                    ?.name || '',
              },
        },
      }),
  };
}
